import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import api from "../services/api";
import "./../translations/i18n";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import LoadingContainer from "./LoadingContainer";
import {
  IonContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonPage,
} from "@ionic/react";
import { NodataContainer } from "./common/noDataContainer";

const RequestsContainer = () => {
  const { t } = useTranslation();
  // const location = useLocation<any>();
  // const { status } = location.state;
  const [details, setDetails] = useState<any>(false);

  const token = useSelector<any>((state) => state.userState.token);
  const role = localStorage.getItem("role");

  const [page, setPage] = useState(1);
  const [doneTotalCount, setDoneTotalCount] = useState(0);
  const [pendingToTalCount, setPendingTotalCount] = useState(0);
  const [requests, setRequests] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState("Pending");
  const [doneRequests, setDoneRequests] = useState<any>([]);
  const [pendingRequests, setPendingRequests] = useState<any>([]);

  const [InfiniteDisabled, setInfiniteDisabled] = useState<any>(false);
  let limit = 10;

  const [, getPendingRequests] = api.useAxios(
    {
      url:
        role === "merchant" ? "/merchant/req/list" : "/merchantUser/req/list",
      method: "post",
    },
    { manual: true }
  );
  const [, getDoneRequests] = api.useAxios(
    {
      url:
        role === "merchant" ? "/merchant/req/list" : "/merchantUser/req/list",
      method: "post",
    },
    { manual: true }
  );
  const [, postApproveRequest] = api.useAxios(
    {
      url:
        role === "merchant"
          ? "/merchant/req/approve"
          : "/merchantUser/req/approve",
      method: "post",
    },
    { manual: true }
  );

  const handleApproveRequest = (id: any) => {
    postApproveRequest({
      data: { token, reqId: id },
    })
      .then(() => {
        window.location.reload();
      })
      .catch((err) => {
        toast(err?.response?.data.message);
      });
  };

  useEffect(() => {
    getPendingRequests({
      data: { token, limit: 1000, status: "pending" },
    })
      .then((res) => {
        setPendingRequests([...res.data.data.rows]);
        setPendingTotalCount(res.data.data.count);

        setLoading(false);
      })
      .catch((err) => {
        toast(err?.response?.data.message);
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setLoading(true);

    getDoneRequests({ data: { token, limit, pageNum: page, status: "done" } })
      .then((res) => {
        setDoneRequests([...res.data.data.rows]);
        setDoneTotalCount(res.data.data.count);
        // let last = 0;
        // const plusPage = totalCount % limit > 0 ? 1 : 0;
        // last = Math.trunc(totalCount / limit) + plusPage;
        // setLastPage(last);

        setLoading(false);
      })
      .catch((err) => {
        toast(err?.response?.data.message);
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const handleLoadingMore = () => {
  //   setPage(page + 1);
  // };

  const DoneData = useCallback(
    (event: any) => {
      setLoading(false);
      setPage(page + 1);
      setInfiniteDisabled(false);
      setTimeout(() => {
        getDoneRequests({
          data: { token, limit, pageNum: page + 1, status: "done" },
        }).then((res: any) => {
          setDoneRequests([...res.data.data.rows]);
        });
        event.target.complete();
        if (pendingRequests.length < pendingToTalCount) {
          return setInfiniteDisabled(false);
        } else {
          return setInfiniteDisabled(true);
        }
      }, 500);
    },
    [
      getDoneRequests,
      limit,
      page,
      pendingRequests.length,
      pendingToTalCount,
      token,
    ]
  );
  const loadPendingData = useCallback(
    (event: any) => {
      setLoading(false);
      setPage(page + 1);
      setInfiniteDisabled(false);
      setTimeout(() => {
        getPendingRequests({
          data: { token, limit, pageNum: page + 1, status: "pending" },
        }).then((res: any) => {
          setPendingRequests([...res.data.data.rows]);
          setPendingTotalCount(res.data.data.count);
        });
        event.target.complete();
        if (doneRequests.length < pendingToTalCount) {
          return setInfiniteDisabled(false);
        } else {
          return setInfiniteDisabled(true);
        }
      }, 500);
    },
    [
      doneRequests.length,
      getPendingRequests,
      limit,
      page,
      pendingToTalCount,
      token,
    ]
  );

  const renderRequests = () => {
    return (
      <IonPage>
        <IonContent fullscreen>
          <div className="content">
            {" "}
            <div className="request-list">
              <>
                <div>
                  <h6 className="text-center requests-title m-4">
                    {" "}
                    <i
                      className="bi bi-bell-fill font-20 requests-bill"
                      style={{
                        color: "#FFC107",
                      }}
                    ></i>{" "}
                    {t("requests")}
                  </h6>
                  <i
                    data-bs-dismiss="offcanvas"
                    className="bi bi-x main-clr font-26 line-height-xl close-offcanvas"
                  ></i>
                  <ul className="nav nav-tabs capsuled" role="tablist">
                    <li className="nav-item">
                      <Link
                        className="nav-link active "
                        data-bs-toggle="tab"
                        to="#"
                        role="tab"
                        onClick={() => {
                          setList("Pending");
                        }}
                      >
                        Pending{" "}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        data-bs-toggle="tab"
                        to="#"
                        role="tab"
                        onClick={() => {
                          setList("Done");
                        }}
                      >
                        Done{" "}
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="tab-content mt-3 ">
                  {list === "Pending" ? (
                    <>
                      <div
                        className="tab-pane fade active show"
                        style={{ height: "20rem", overflow: "scroll" }}
                        id="pending"
                        role="tabpanel"
                      >
                        {pendingRequests.length !== 0 ? (
                          pendingRequests?.map((request: any) => (
                            <>
                              {
                                <Link
                                  key={request.id}
                                  to={`/request/${request?.id}`}
                                  className="row align-items-center requests-list "
                                >
                                  <div className="d-flex gap-3 col-8 align-items-center">
                                    <i className="has-bg rounded-xs bi p-1 bi bi-bell-fill requests-bill-container "></i>
                                    <div>
                                      <h5 className=" m-0 ">
                                        {request.user.name}
                                      </h5>
                                      <span
                                        className="mt-0   font-9"
                                        style={{
                                          color: "black",
                                        }}
                                      >
                                        <span className="d-inline   font-9">
                                          <i className="bi bi-geo-alt font-14 "></i>{" "}
                                          {request.Branch.address}
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                  {/* <div>
                    <div className="dash-request-list"></div>
                    <div className="dash-request-list"></div>
                    <div className="dash-request-list"></div>
                    <div className="dash-request-list"></div>
                  </div> */}
                                  <strong className="d-inline price text-end col-4 pe-0 ">
                                    {request.offerDetail.price} $
                                  </strong>
                                </Link>
                              }

                              {/* {pendingRequests.length > pendingToTalCount ? (
                                <IonInfiniteScroll
                                  onIonInfinite={loadPendingData}
                                  disabled={InfiniteDisabled}
                                  threshold="100px"
                                >
                                  <IonInfiniteScrollContent
                                    loadingText={"loading"}
                                  ></IonInfiniteScrollContent>
                                </IonInfiniteScroll>
                              ) : (
                                ``
                              )} */}
                            </>
                          ))
                        ) : (
                          <NodataContainer />
                        )}
                      </div>
                      <span className="total-requests">
                        {pendingRequests.length}{" "}
                        {pendingRequests.length > 1 ? "requests" : "request"}
                      </span>
                    </>
                  ) : (
                    <>
                      <div
                        className="tab-pane fade active show"
                        id="done"
                        role="tabpanel"
                        style={{ height: "20rem", overflow: "scroll" }}
                      >
                        {doneRequests.length !== 0 ? (
                          doneRequests?.map((request: any) => (
                            <>
                              {
                                <Link
                                  key={request.id}
                                  to={`/request/${request?.id}`}
                                  className="row align-items-center requests-list "
                                >
                                  <div className="d-flex col-8  gap-3 align-items-center">
                                    <i className="has-bg rounded-xs bi p-1 bi bi-bell-fill requests-bill-container "></i>
                                    <div>
                                      <h5 className=" m-0 ">
                                        {request.user.name}
                                      </h5>
                                      <span
                                        className="mt-0   font-9"
                                        style={{
                                          color: "black",
                                        }}
                                      >
                                        <span className="d-inline   font-9">
                                          <i className="bi bi-geo-alt font-14 "></i>{" "}
                                          {request.Branch.address}
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                  {/* <div>
                    <div className="dash-request-list"></div>
                    <div className="dash-request-list"></div>
                    <div className="dash-request-list"></div>
                    <div className="dash-request-list"></div>
                  </div> */}
                                  <strong className="d-inline price col-4 text-end">
                                    {request.offerDetail.price} $
                                  </strong>
                                </Link>
                              }
                              {/* 
                              {doneRequests.length > doneTotalCount ? (
                                <IonInfiniteScroll
                                  onIonInfinite={DoneData}
                                  disabled={InfiniteDisabled}
                                  threshold="100px"
                                >
                                  <IonInfiniteScrollContent
                                    loadingText={"loading"}
                                  ></IonInfiniteScrollContent>
                                </IonInfiniteScroll>
                              ) : (
                                ``
                              )} */}
                            </>
                          ))
                        ) : (
                          <NodataContainer />
                        )}
                      </div>
                      <span className="total-requests">
                        {doneRequests.length}{" "}
                        {doneRequests.length > 1 ? "requests" : "request"}
                      </span>
                    </>
                  )}
                </div>
              </>
            </div>
          </div>
        </IonContent>
      </IonPage>
    );
  };

  return (
    <>
      <div
        className="collapse show"
        id="tab-16"
        data-bs-parent="#tab-group-6"
        style={{
          height: "500px",
        }}
      >
        <div className="">
          {loading === true ? <LoadingContainer /> : renderRequests()}
        </div>
        {/* {(lastPage < page || page !== lastPage) && (
              <div className="text-center pb-2">
                <button onClick={handleLoadingMore} className="btn btn-primary">
                  {t("See More")}
                </button>
              </div>
            )} */}
      </div>
    </>
  );
};

export default RequestsContainer;
