import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import api from "../../services/api";
import LoadingContainer from "../../components/LoadingContainer";
import "../../translations/i18n";
import {
  IonContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonPage,
} from "@ionic/react";
import { toast } from "react-toastify";
import DefaultFormActionSheet from "../../components/common/DefaultFormActionSheet";
import EmployeeProfilePage from "../employee-profile/index";
import AddEmployee from "./AddEmployee";
import person from "../../assets/images/man-mainclr.png";
import { NodataContainer } from "../../components/common/noDataContainer";
import loginm from "../../assets/images/nothing.svg";

const Employeeslist = () => {
  const { t } = useTranslation();
  const token = useSelector<any>((state) => state.userState.token);

  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [employees, setEmployees] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [InfiniteDisabled, setInfiniteDisabled] = useState<any>(false);
  const [updateMployee, setUpdateEmployee] = useState(false);
  const [employeeDetails, setEmployeeDetails] = useState(false);
  const [employeeData, setEmployeeData] = useState<any>();
  const [addEmployee, setAddEmployee] = useState(false);
  let limit = 10;

  const [, getEmployees] = api.useAxios(
    { url: "/merchant/user/list", method: "post" },
    { manual: true }
  );

  useEffect(() => {
    setLoading(true);
    getEmployees({ data: { token, limit: 1000 } })
      .then((res) => {
        setEmployees([...res.data.data.rows]);
        setTotalCount(res.data.data.count);
        // let last = 0;
        // const plusPage = totalCount % limit > 0 ? 1 : 0;
        // last = Math.trunc(totalCount / limit) + plusPage;
        // setLastPage(last);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateMployee]);
  console.log(employees);

  const loadData = useCallback(
    (event: any) => {
      setLoading(false);
      setPage(page + 1);
      setInfiniteDisabled(false);
      setTimeout(() => {
        getEmployees({ data: { token, pageNum: page + 1, limit } }).then(
          (res) => {
            setEmployees([...employees, ...res.data.data.rows]);
            setTotalCount(res.data.data.count);
          }
        );
        event.target.complete();
        if (employees.length < totalCount) {
          return setInfiniteDisabled(false);
        } else {
          return setInfiniteDisabled(true);
        }
      }, 500);
    },
    [employees, getEmployees, limit, page, token, totalCount]
  );
  const [, getEmployeeDetails] = api.useAxios(
    { url: "/merchant/user/details", method: "post" },
    { manual: true }
  );
  const GetEmployeeProfile = (id: any) => {
    setEmployeeDetails(true);
    getEmployeeDetails({ data: { token, merchantUserId: id } })
      .then((res) => {
        setEmployeeData(res.data.data);
      })
      .catch((err: any) => {
        toast(err?.response?.data.message);
      });
  };
  console.log(employeeData);
  const renderOffers = () => {
    return (
      <IonPage>
        <IonContent fullscreen>
          {" "}
          <div className="content">
            {!addEmployee ? (
              !employeeDetails ? (
                <div>
                  <h6 className="text-center branch-title m-4">
                    {" "}
                    {t("employees")}
                  </h6>
                  <i
                    data-bs-dismiss="offcanvas"
                    className="bi bi-x main-clr font-26 line-height-xl close-offcanvas"
                  ></i>

                  {employees?.length == 0 ? (
                    <div className="text-center">
                      <img src={loginm} className="text-center svg-pages" />
                      <h6 className="text-sm">
                        {" "}
                        You don't have any employees here
                      </h6>
                    </div>
                  ) : (
                    <div
                      className="px-3 py-1"
                      style={{ height: "30rem", overflow: "scroll" }}
                    >
                      {employees?.slice(0, limit).map((employee: any) => (
                        <Link
                          key={employee.id}
                          to={`#`}
                          onClick={() => {
                            GetEmployeeProfile(employee?.merchantUser.id);
                          }}
                          className="row  align-items-center  employee-list-settings "
                        >
                          <div className="d-flex align-items-center  col-9 p-0 overflow-hidden">
                            {/* <i
                        className="has-bg rounded-xs p-1 bi bi-people"
                        style={{
                          color: "rgb(86, 238, 168)",
                          backgroundColor: "rgb(9, 44, 76)",
                        }}
                      ></i> */}
                            <img src={person} alt="icon" />
                            <div>
                              <h5 className=" mb-1 branch-name m-0">
                                {employee.name}
                              </h5>
                              <span className="mt-0  ">
                                <strong className="branches-address ">
                                  {t("rule")} :{" "}
                                  <span>{employee.merchantUser.rule}</span>
                                  &nbsp;&nbsp;&nbsp;
                                </strong>

                                <br />
                                <strong className=" branches-phone ">
                                  {t("phone")} :{" "}
                                  <span>{employee.merchantUser.mobileNum}</span>
                                </strong>
                              </span>
                            </div>
                          </div>
                          <div className="col-3 text-end">
                            <span className="badge  rounded-xl">
                              {employee.merchantUser.isActive ? (
                                <span className="branches-active">
                                  {t("branchActive")}
                                </span>
                              ) : (
                                <span className="branches-inactive">
                                  {t("branchNotActive")}
                                </span>
                              )}
                            </span>
                          </div>
                        </Link>
                      ))}
                    </div>
                  )}
                  {/* {employees.length < totalCount ? (
                    <IonInfiniteScroll
                      onIonInfinite={loadData}
                      disabled={InfiniteDisabled}
                      threshold="0px"
                    >
                      <IonInfiniteScrollContent
                        loadingText={"loading"}
                      ></IonInfiniteScrollContent>
                    </IonInfiniteScroll>
                  ) : (
                    ``
                  )} */}
                  <div className="d-flex justify-content-between align-items-center px-3 pt-2 ">
                    <span className="total-requests">
                      {totalCount}
                      {` `}
                      {totalCount > 1 ? "employee" : "employees"}
                    </span>
                    <button
                      className="addemployee-btn"
                      onClick={() => setAddEmployee(true)}
                    >
                      {t("addEmployee")}
                    </button>
                  </div>
                </div>
              ) : (
                <DefaultFormActionSheet
                  back={true}
                  backState={setEmployeeDetails}
                  title={t("updateEmployee")}
                >
                  <EmployeeProfilePage
                    backState={setEmployeeDetails}
                    update={updateMployee}
                    setUpdate={setUpdateEmployee}
                    employeeData={employeeData}
                  />
                </DefaultFormActionSheet>
              )
            ) : (
              <DefaultFormActionSheet
                back={true}
                backState={setAddEmployee}
                title={t("addEmployee")}
              >
                <AddEmployee
                  backState={setAddEmployee}
                  update={updateMployee}
                  setUpdate={setUpdateEmployee}
                />
              </DefaultFormActionSheet>
            )}
          </div>
        </IonContent>
      </IonPage>
    );
  };

  return (
    <>
      {InfiniteDisabled && employees?.length === 0 && <NodataContainer />}
      <div
        className="collapse show"
        style={{ height: "600px" }}
        id="tab-16"
        data-bs-parent="#tab-group-6"
      >
        <div className="">
          {loading === true ? <LoadingContainer /> : renderOffers()}
        </div>
        {/* {(lastPage < page || page !== lastPage) && (
          <div className="text-center pb-4">
            <button onClick={handleLoadingMore} className="btn btn-primary">
              {t("See More")}
            </button>
          </div>
        )} */}
      </div>
    </>
  );
};

export default Employeeslist;
