import {
  IonContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonPage,
  useIonViewDidEnter,
} from "@ionic/react";
import { useCallback, useEffect, useReducer, useState } from "react";

import mastercard from "../../assets/images/mastercard.png";
import visa from "../../assets/images/visa.png";
import cash from "../../assets/images/cash.png";
import { baseUrl } from "../../utils/config";
import { useHistory } from "react-router";
import axios from "axios";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import api from "../../services/api";
import { toast } from "react-toastify";
import { NodataContainer } from "../../components/common/noDataContainer";
import AddPaymentMethod from "../addpeymentmethods";

const PaymentMethods = () => {
  const history = useHistory();
  const [me, setMe] = useState<any>();
  const [loadingset, setLoadingset] = useState(false);
  const [cardlist, setCardList] = useState<any>();
  const token: any = useSelector<any>((state) => state.userState.token);
  const role = localStorage.getItem("role");
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);

  const [addNewCard, setAddNewCard] = useState<any>(false);
  const [update, setUpdate] = useState<any>(false);
  const [page, setPage] = useState(1);
  let limit = 10;
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  useEffect(() => {
    forceUpdate();
  }, []);

  const getCardList = useCallback(() => {
    axios({
      url: `${baseUrl}/api/merchant/card/list`,
      method: "post",
      data: { token, limit: 1000 },
    })
      .then((res) => {
        setCardList(res?.data.data);
      })
      .catch((err) => {
        setLoadingset(false);

        console.log(err);
      });
  }, [token, update]);

  const SetCardAsDefault = useCallback(
    (id: any, e: any) => {
      let checkboxes: any =
        document.getElementsByClassName("payment-checkboxes");
      for (let i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
        checkboxes[i].disabled = false;
        e.target.checked = true;
        e.target.disabled = true;
      }
      try {
        axios
          .post(`${baseUrl}/api/merchant/card/setDefault`, { id, token })
          .then(() => {
            localStorage.setItem("defaultCard", id);
            getCardList();
          });
      } catch (err: any) {
        console.log(err);
      }
    },
    [getCardList, token]
  );
  const HandleDeleteCard = useCallback(
    (id: any) => {
      try {
        axios
          .post(`${baseUrl}/api/merchant/card/delete`, { id, token })
          .then(() => {
            getCardList();
            forceUpdate();
            toast.success("Deleted Successfully");
          });
      } catch (err: any) {
        console.log(err);
      }
    },
    [getCardList, token]
  );
  const [, dashboardData] = api.useAxios(
    {
      url: role === "merchant" ? `/merchant/me` : `/merchantUser/me`,
      method: "post",
    },
    { manual: true }
  );
  useEffect(() => {
    dashboardData({ data: { token } })
      .then((res: any) => {
        setMe(res?.data?.data);
        localStorage.setItem("merchantId", res?.data?.data?.id);
      })
      .catch((err: any) => {
        toast(err?.response?.data.message);
        history.push("/login");
      });
  }, [dashboardData, history, token]);

  useEffect(() => {
    getCardList();
  }, [getCardList]);
  const loadData = useCallback(
    (event: any) => {
      setLoading(false);
      setPage(page + 1);

      axios({
        url: `${baseUrl}/api/merchant/card/list`,
        method: "post",
        data: { token, pageNum: page, limit },
      }).then((res) => {
        setCardList([...cardlist, ...res?.data.data]);
      });
      event.target.complete();
    },
    [cardlist, limit, page, token]
  );
  return (
    <IonPage>
      <IonContent fullscreen>
        <div style={{ height: "600px" }}>
          {addNewCard ? (
            <AddPaymentMethod
              backState={setAddNewCard}
              update={setUpdate}
              updateValue={update}
            />
          ) : (
            <div className="p-3 payment-methods content">
              <h1 className="head">Payment Options</h1>
              <i
                data-bs-dismiss="offcanvas"
                className="bi bi-x main-clr font-26 line-height-xl close-offcanvas"
              ></i>
              <div style={{ height: "26rem", overflow: "scroll" }}>
                <h5 className="opacity-50 mb-3">Personal Business</h5>
                <div className="row ">
                  {cardlist?.length !== 0 ? (
                    cardlist?.map((card: any) => (
                      <div
                        className={`row mb-2 border  align-items-center p-2 `}
                        key={card?.id}
                      >
                        <img className="col-2" src={visa} alt="visa" />
                        <div className=" col-9">
                          <p className="payment_methods-list mb-0">
                            {" "}
                            {card.nameOnCard}
                          </p>
                          <span className="payment_methods-list">
                            {" "}
                            {card.cardNumber}
                          </span>
                        </div>
                        <div className="col-1 flex-column align-items-center d-flex ">
                          {!card?.isDefault && (
                            <i
                              className="bi bi-x-circle text-secondary"
                              onClick={() => HandleDeleteCard(card?.id)}
                            ></i>
                          )}
                          {card?.isDefault && cardlist.length === 1 && (
                            <i
                              className="bi bi-x-circle text-secondary"
                              onClick={() => HandleDeleteCard(card?.id)}
                            ></i>
                          )}
                          <input
                            className="form-check-input mt-1 payment-checkboxes"
                            type="checkbox"
                            value={card?.isDefault}
                            id="flexCheckDefault"
                            defaultChecked={card?.isDefault}
                            onClick={(e: any) => SetCardAsDefault(card?.id, e)}
                            disabled={card?.isDefault === true ? true : false}
                          />
                        </div>
                      </div>
                    ))
                  ) : (
                    <NodataContainer />
                  )}
                  {/* {cardlist?.length < totalCount ? (
                  <IonInfiniteScroll
                    onIonInfinite={loadData}
                    disabled={InfiniteDisabled}
                    threshold="100px"
                  >
                    <IonInfiniteScrollContent
                      loadingText={"loading"}
                    ></IonInfiniteScrollContent>
                  </IonInfiniteScroll>
                ) : (
                  ``
                )} */}
                </div>
              </div>
              {/* <Link
                className="para-style"
                to="/"
                data-bs-toggle="offcanvas"
                data-bs-target="#menu-invoices"
              >
                <i className="bi bi-arrow-right-short"></i> Show All Transaction
              </Link>{" "} */}
              <div className="text-center mt-3">
                <button
                  className=" btn  w-full main-btn  mt-3"
                  onClick={() => setAddNewCard(true)}
                >
                  Add New Card
                </button>
              </div>
            </div>
          )}
        </div>
      </IonContent>
    </IonPage>
  );
};
export default PaymentMethods;
