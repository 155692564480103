import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import api from "../../services/api";
import { IonContent, IonPage } from "@ionic/react";
import FooterContainer from "../../components/common/Footer";
import GoogleMapReact from "google-map-react";
import ProductList from "../products/ProductList";
import OfferList from "../offers/OfferList";
import HeaderContainer from "../../components/common/Header";
import LoadingContainer from "../../components/LoadingContainer";
import gps from "../../assets/images/gps.png";

const AvailableDeliveriesDetails: React.FC = () => {
  const productRequestId: any = useParams();
  const { t } = useTranslation();
  const history = useHistory();
  const token: any = useSelector<any>((state) => state.userState.token);
  const [productDetails, setProductDetails] = useState<any>();
  const IMAGEURL = process.env.REACT_APP_IMAGEURL;
  const [list, setList] = useState("Information");
  const [loading, setLoading] = useState(false);
  const [, productRequestDetails] = api.useAxios(
    { url: "merchantUser/product/req/details", method: "post" },
    { manual: true }
  );

  const [, changeStatus] = api.useAxios(
    {
      url: "/merchantUser/product/req/changeStatus",
      method: "post",
    },
    { manual: true }
  );

  const handleChangeStatus = () => {
    changeStatus({
      data: {
        token,
        id: productRequestId?.id,
        deliveryStatus:
          productDetails?.deliveryStatus === "pending" ? "inProgress" : "done",
      },
    })
      .then(() => {
        window.location.reload();
      })
      .catch((err) => {
        toast(err?.response?.data.message);
      });
  };

  useEffect(() => {
    setLoading(true);
    productRequestDetails({ data: { token, id: productRequestId?.id } })
      .then((res: any) => {
        setProductDetails(res.data.data);
        setLoading(false);
      })
      .catch((err: any) => {
        toast(err?.response?.data.message);
        setLoading(false);
      });
  }, [productRequestId.id, productRequestDetails, token]);
  const defaultProps = {
    center: {
      lat: productDetails?.Address?.lat || 25.2854,
      lng: productDetails?.Address?.long || 51.531,
    },
    zoom: 14,
  };

  return (
    <>
      {loading ? (
        <LoadingContainer />
      ) : (
        <IonPage>
          <IonContent fullscreen>
            <HeaderContainer pageTitle={t("availableDeliveries")} />

            <div
              className="content mb-0 product____details____box__modified product____det overflow-scroll"
              style={{ height: "30rem" }}
            >
              <div className="card   border-0  align-items-center header_____box">
                <div className="row px-1 py-2 w-100 align-items-center">
                  <div className="col-6">
                    <strong className="no-click title">
                      {productDetails?.Product.name}
                    </strong>
                    <br />
                    <img src={gps} alt="gps" className="me-2" />
                    <strong className=" no-click  user">
                      {productDetails?.UserAddress.Address?.address1}
                    </strong>
                    <br />
                    <strong className=" no-click  user">
                      {productDetails?.User?.name}
                    </strong>
                    <br />
                  </div>
                  <div className="col-3">
                    <img
                      src={
                        productDetails?.Product.image !== null
                          ? `${IMAGEURL}${productDetails?.Product.image}`
                          : "./assets/images/pictures/1s.jpg"
                      }
                      width={50}
                      height={50}
                      className="rounded-xs"
                      alt="img"
                    />
                  </div>
                  <div className="col-3 bordered">
                    <div className="row  flex-col mt-2">
                      <strong className="price">
                        {" "}
                        {productDetails?.Product.price}$
                      </strong>

                      <br />
                      <strong className="points">
                        {productDetails?.Product.points}P
                      </strong>
                    </div>
                  </div>
                </div>
              </div>

              <div className="">
                <div className="content mb-0">
                  <div className="tabs tabs-pill" id="tab-group-2">
                    <ul className="nav nav-tabs capsuled mt-2" role="tablist">
                      <li className="nav-item">
                        <Link
                          className="nav-link active "
                          data-bs-toggle="tab"
                          to="#"
                          role="tab"
                          onClick={() => {
                            setList("Information");
                          }}
                        >
                          Information
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          data-bs-toggle="tab"
                          onClick={() => {
                            setList("Description");
                          }}
                          to="#"
                          role="tab"
                        >
                          Description
                        </Link>
                      </li>
                    </ul>
                    <div className="tab-content px-2 py-3 ">
                      {list === "Information" ? (
                        <div
                          className={`tab-pane  ${
                            list === "Information" ? "fade active show" : ``
                          } `}
                          role="tabpanel"
                        >
                          <div className="row">
                            <div className="d-flex align-items-left flex-column col-6">
                              <strong className="main">User :</strong>
                              <span className="det">
                                {productDetails?.user?.name}{" "}
                              </span>
                            </div>
                            <div className="d-flex align-items-left flex-column col-6">
                              <strong className="main">Stock:</strong>
                              <span className="det">
                                {productDetails?.Product.stockAmount}
                              </span>
                            </div>
                            {productDetails?.type === "delivery" && (
                              <>
                                <div className="d-flex align-items-left flex-column col-6">
                                  <strong className="main">
                                    User Address :
                                  </strong>
                                  <span className="det">
                                    {
                                      productDetails?.UserAddress?.Address
                                        .address1
                                    }
                                    {
                                      productDetails?.UserAddress?.Address
                                        .address2
                                    }
                                  </span>
                                </div>
                                <div className="d-flex align-items-left flex-column col-6">
                                  <strong className="main">area:</strong>
                                  <span className="det">
                                    {productDetails?.UserAddress?.Address.area}
                                  </span>
                                </div>
                                <div className="d-flex align-items-left flex-column col-6">
                                  <strong className="main">city:</strong>
                                  <span className="det">
                                    {productDetails?.UserAddress?.Address.city}
                                  </span>
                                </div>
                                <div className="d-flex align-items-left flex-column col-6">
                                  <strong className="main">long:</strong>
                                  <span className="det">
                                    {productDetails?.UserAddress?.Address.long}
                                  </span>
                                </div>
                                <div className="d-flex align-items-left flex-column col-6">
                                  <strong className="main">lat:</strong>
                                  <span className="det">
                                    {productDetails?.UserAddress?.Address.lat}
                                  </span>
                                </div>
                              </>
                            )}
                          </div>
                          <div
                            className="mx-auto mb-4 mt-3"
                            style={{ height: 220, width: "100%" }}
                          >
                            <GoogleMapReact
                              center={defaultProps.center}
                              defaultZoom={defaultProps.zoom}
                              bootstrapURLKeys={{
                                key: "AIzaSyDxsUzIfLpefNwPISG4wYuo_qrrKHuMUKQ",
                              }}
                              yesIWantToUseGoogleMapApiInternals={true}
                            >
                              <Marker
                                lat={defaultProps.center.lat}
                                lng={defaultProps.center.lng}
                                text="check"
                              />
                            </GoogleMapReact>
                          </div>
                        </div>
                      ) : (
                        <div
                          className={`tab-pane${
                            list !== "Information" ? "fade active show" : ``
                          } `}
                          role="tabpanel"
                        >
                          <div>
                            <strong className="desc">Description</strong> <br />
                            <strong className="desc-det">
                              {productDetails?.Product.description}
                            </strong>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {productDetails?.status === "pending" ? (
              <div className="text-center w-100 pb-5">
                <button
                  onClick={handleChangeStatus}
                  className="btn font-13 rounded-s main-btn mt-3  mb-5"
                >
                  {productDetails?.deliveryStatus === "pending"
                    ? "Change to in Progress"
                    : "Complete Delivery"}
                </button>
              </div>
            ) : null}
            {productDetails?.status === "done" ? (
              <p className="text-success text-center">
                this product has been delivered successfully
              </p>
            ) : null}
          </IonContent>
          <div
            id="productAvailableDeliveriesdet"
            className="offcanvas offcanvas-bottom offcanvas-detached  offcanvas-style "
            aria-modal="true"
            role="dialog"
          >
            <ProductList
              instock={"footerprod8"}
              outofstock={"outfooterprod8"}
            />
          </div>
          <div
            id="offerAvailableDeliveriesdet"
            className="offcanvas offcanvas-bottom offcanvas-detached  offcanvas-style "
            aria-modal="true"
            role="dialog"
          >
            <OfferList />
          </div>
          <FooterContainer
            offersheet={"offerAvailableDeliveriesdet"}
            productsheet={"productAvailableDeliveriesdet"}
          />
        </IonPage>
      )}
    </>
  );
};
export default AvailableDeliveriesDetails;

const Marker = ({ text }: any) => {
  return (
    <div className="SuperAwesomePin">
      <img
        height="25"
        width="25"
        src="https://img.icons8.com/color/48/000000/marker--v1.png"
        alt="location"
      />
    </div>
  );
};
