/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { NodataContainer } from "../../../components/common/noDataContainer";
import person from "../../../assets/images/man-mainclr.png";
import "../../../translations/i18n";

type Props = {
  users: [];
  handleLoadingMore: any;
};

export const OfferUsers: React.FC<Props> = ({ users, handleLoadingMore }) => {
  const { t } = useTranslation();

  return (
    <>
      {users?.length === 0 ? (
        <NodataContainer />
      ) : (
        <>
          <div className="collapse show">
            <div className="table-responsive">
              <div id="tab-5" data-bs-parent="#tab-group-2">
                {users?.map((user: any) => (
                  <div key={user.id}>
                    <Link
                      to={`#`}
                      className="d-flex  align-items-center p-3 employee-list"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#menu-activity-1"
                    >
                      <div className="align-self-center">
                        <img src={person} alt="icon" />
                      </div>
                      <div className="align-self-center ps-1">
                        <h5 className="pt-1 mt-0  mb-0 request_____name text-capitalize">
                          {user?.name}
                        </h5>
                        <strong className="request-code ">{user.mobile}</strong>
                      </div>
                    </Link>
                    <div className="divider my-2 opacity-50"></div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
