import React, { useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { clearSpinnerStatus } from "../../redux/actions";

export const ProtectedRoute = ({ children, ...rest }) => {
  // const { token } = useSelector(({ userState }) => userState);
  const token = useSelector((state) => state.userState.token);
  const [isApproved, setIsApproved] = useState();
  const [role, setRole] = useState();

  useEffect(() => {
    const isAproved = localStorage.getItem("isAproved");
    const role = localStorage.getItem("role");
    setIsApproved(isAproved);
    setRole(role);
  }, []);
  const distpatch = useDispatch();

  if (token !== null && role == "merchant" && isApproved == "false")
    return <Redirect exact to={"/profile/activateaccount"} />;

  return (
    <Route
      {...rest}
      render={({ location }) =>
        token
          ? children
          : distpatch(clearSpinnerStatus()) && (
              <Redirect
                to={{
                  pathname: "/login",
                  state: { from: location },
                }}
              />
            )
      }
    />
  );
};

ProtectedRoute.propTypes = {
  children: PropTypes.element.isRequired,
};
