import { IonContent, IonPage } from "@ionic/react";
import RedeemsContainer from "../../components/RedeemsContainer";
import HeaderContainer from "../../components/common/Header";
import FooterContainer from "../../components/common/Footer";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import ProductList from "../products/ProductList";
import OfferList from "../offers/OfferList";

const RedeemsPage: React.FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <IonPage>
        <HeaderContainer
          pageTitle={t("redeems")}
          pageIcon={
            <i
              className=" rounded-xs bi bi-wallet2 font-14"
              style={{
                color: "rgb(86, 238, 168)",
              }}
            ></i>
          }
        />

        <IonContent fullscreen>
          <RedeemsContainer />
        </IonContent>
        <FooterContainer
          offersheet={"RedeemOfferSheet"}
          productsheet={"RedeemProdSheet"}
        />
        <div
          id="RedeemProdSheet"
          className="offcanvas offcanvas-bottom offcanvas-detached  offcanvas-style "
          aria-modal="true"
          role="dialog"
        >
          <ProductList />
        </div>
        <div
          id="RedeemOfferSheet"
          className="offcanvas offcanvas-bottom offcanvas-detached  offcanvas-style "
          aria-modal="true"
          role="dialog"
        >
          <OfferList />
        </div>
      </IonPage>
    </>
  );
};

export default RedeemsPage;
