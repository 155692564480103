import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import api from "../../services/api";
import LoadingContainer from "../../components/LoadingContainer";
import { toast } from "react-toastify";
import "../../translations/i18n";
import InfiniteScroll from "react-infinite-scroll-component";
import { IonContent, IonPage } from "@ionic/react";
import AddBranch from "./AddBranch";
import DefaultFormActionSheet from "../../components/common/DefaultFormActionSheet";
import { NodataContainer } from "../../components/common/noDataContainer";
import loginm from "../../assets/images/nothing.svg";

const BranchesList = () => {
  const { t } = useTranslation();
  const token = useSelector<any>((state) => state.userState.token);
  const rule = localStorage.getItem("role");
  const [page, setPage] = useState(1);
  const [totalCountBranches, setTotalCountBranches] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [branches, setBranches] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [addBranch, setAddBranch] = useState(false);
  const [update, setUpdate] = useState(false);

  const [InfiniteDisabled, setInfiniteDisabled] = useState<any>(false);

  let limit = 10;

  const [, getBranches] = api.useAxios(
    { url: "/merchant/branch/list", method: "post" },
    { manual: true }
  );

  useEffect(() => {
    setLoading(true);
    rule === "merchant" &&
      getBranches({ data: { token, pageNum: page, limit: limit } })
        .then((res) => {
          setBranches([...res.data.data.rows]);
          setTotalCountBranches(res.data.data.count);
          // let last = 0;
          // const plusPage = totalCount % limit > 0 ? 1 : 0;
          // last = Math.trunc(totalCount / limit) + plusPage;
          // setLastPage(last);
          setLoading(false);
        })
        .catch((err) => {
          toast(err?.response?.data.message);
          setLoading(false);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [update]);

  // const handleLoadingMore = () => {
  //   setPage(page + 1);
  // };

  const loadData = () => {
    const nextPage = page + 1;
    if (branches?.length < totalCountBranches) {
      setTimeout(() => {
        getBranches({ data: { token, limit, pageNum: nextPage } }).then(
          (res: any) => {
            setBranches([...branches, ...res.data.data.rows]);
          }
        );
      }, 500);
    } else {
      setHasMore(false);
    }
  };

  console.log();

  const renderbranches = () => {
    return (
      <>
        <IonPage>
          <IonContent fullscreen>
            <div className="content">
              {addBranch ? (
                <DefaultFormActionSheet
                  back={true}
                  backState={setAddBranch}
                  title={t("addBranch")}
                >
                  <AddBranch
                    update={setUpdate}
                    updateValue={update}
                    setUpdate={setAddBranch}
                  />
                </DefaultFormActionSheet>
              ) : (
                <>
                  <h6 className="text-center branch-title m-4">
                    {" "}
                    {t("branches")}
                  </h6>
                  <i
                    data-bs-dismiss="offcanvas"
                    className="bi bi-x main-clr font-26 line-height-xl close-offcanvas"
                  ></i>

                  {branches?.length == 0 ? (
                    <div className="text-center">
                      <img src={loginm} className="text-center svg-pages" />
                      <h6 className="text-sm">
                        {" "}
                        You don't have any branch here
                      </h6>
                    </div>
                  ) : (
                    <InfiniteScroll
                      dataLength={branches?.length}
                      next={loadData}
                      loader={<p>loader</p>}
                      hasMore={hasMore}
                      height={420}
                      className="px-3"
                    >
                      {branches.map((branch: any, i: any) => (
                        <Link
                          key={Math.random()}
                          to={`/branch/${branch?.id}`}
                          className="row align-items-center p-3 branch-list"
                        >
                          <div className="col-9 d-flex align-items-center gap-2 overflow-hidden">
                            <i
                              className="has-bg rounded-xs bi bi-geo-alt-fill font-20 p-1"
                              style={{
                                color: `${
                                  branch?.isActive ? "#2C236A" : "#FFD341"
                                }`,
                              }}
                            ></i>
                            <div>
                              <h5 className=" mb-1 text-capitalize branch-name">
                                {i + 1}.{branch.branchName}
                              </h5>
                              <span className="mt-0  text-capitalize">
                                <strong className="branches-address">
                                  {" "}
                                  {t("address")}: <span>{branch.address}</span>
                                  &nbsp;&nbsp;&nbsp;
                                </strong>

                                <br />
                                <strong className="branches-phone">
                                  {" "}
                                  {t("phone")}: <span>{branch.phone}</span>
                                </strong>
                              </span>
                            </div>
                          </div>

                          {branch.isActive ? (
                            <div className="col-3 text-end">
                              <span className="badge  branches-active py-2 px-3">
                                {t("branchActive")}
                              </span>
                            </div>
                          ) : (
                            <div className="col-3 text-end">
                              <span className="badge branches-inactive">
                                {t("branchNotActive")}
                              </span>
                            </div>
                          )}
                        </Link>
                      ))}
                    </InfiniteScroll>
                  )}

                  <span className="total-requests">
                    {totalCountBranches}{" "}
                    {totalCountBranches > 1 ? "branches" : "branch"}
                  </span>
                  <button
                    className=" btn d-block w-full main-btn  mt-3"
                    style={{ width: "100%" }}
                    onClick={() => setAddBranch(true)}
                  >
                    {t("addBranch")}
                  </button>
                </>
              )}

              <></>
            </div>
          </IonContent>
        </IonPage>
      </>
    );
  };

  return (
    <div className=" ">
      {InfiniteDisabled && branches?.length === 0 && <NodataContainer />}
      <div
        className="collapse show "
        style={{
          height: "600px",
        }}
        id="tab-16"
        data-bs-parent="#tab-group-6"
      >
        <div>{loading === true ? <LoadingContainer /> : renderbranches()}</div>
      </div>
    </div>
  );
};

export default BranchesList;
