/* eslint-disable @typescript-eslint/no-unused-vars */
import { IonContent, IonPage } from "@ionic/react";
import {
  Route,
  useParams,
  useRouteMatch,
  NavLink,
  Switch,
  Redirect,
} from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect, useState, useRef } from "react";
import { toast, ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";

import api from "../../services/api";
import HeaderContainer from "../../components/common/Header";
import FooterContainer from "../../components/common/Footer";

import "../../translations/i18n";
import { url } from "inspector";
import ProductList from "../products/ProductList";
import OfferList from "../offers/OfferList";

const RedeemDetailsPage: React.FC = () => {
  const IMAGEURL = process.env.REACT_APP_IMAGEURL;

  const { t } = useTranslation();
  const { path } = useRouteMatch();
  const redeem = useParams<any>();
  const token: any = useSelector<any>((state) => state.userState.token);
  const role = localStorage.getItem("role");

  const [redeemData, setRedeemData] = useState<any>();

  const [, getRedeemDetials] = api.useAxios(
    {
      url:
        role === "merchant"
          ? "/merchant/req/redeem/detail"
          : "/merchantUser/req/redeem/detail",
      method: "post",
    },
    { manual: true }
  );

  const [, postApproveRedeem] = api.useAxios(
    {
      url:
        role === "merchant"
          ? "/merchant/req/redeem/approve"
          : "/merchantUser/req/redeem/approve",
      method: "post",
    },
    { manual: true }
  );

  useEffect(() => {
    getRedeemDetials({ data: { token, reqId: redeem?.id } })
      .then((res) => {
        setRedeemData(res.data.data);
      })
      .catch((err) => {
        toast(err?.response?.data.message);
      });
  }, [getRedeemDetials, redeem?.id, token]);

  const handleApproveRedeem = () => {
    postApproveRedeem({ data: { token, reqId: redeem?.id, isApproved: true } })
      .then(() => {
        window.location.reload();
      })
      .catch((err) => {
        toast(err?.response?.data.message);
      });
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <IonPage>
        <HeaderContainer
          pageTitle={t("redeemDetails")}
          pageIcon={
            <i
              className=" rounded-xs bi bi-wallet2 font-14"
              style={{
                color: "rgb(86, 238, 168)",
              }}
            ></i>
          }
        />
        <IonContent fullscreen>
          <div className="pt-5 pb-5 mb-5 theme-light">
            <div
              className="card card-style  shadow-card shadow-card-l"
              style={{ height: 225, backgroundColor: `#092C4C` }}
            >
              <strong
                className=" no-click font-12 p-3 font-monospace"
                style={{ color: `#56eea8` }}
              >
                {t("user")}: {redeemData?.user.name} <br />
                <span>
                  {t("merchant")}: {redeemData?.merchant.name}
                </span>
                <br />
                <span>
                  {t("branch")}: {redeemData?.Branch.branchName}
                </span>
              </strong>

              <strong
                className="no-click p-3 text-center font-monospace"
                style={{ color: `#56eea8` }}
              >
                {t("createdAt")}:{" "}
                {new Date(redeemData?.createdAt).toDateString()}
                <br />
                {t("updatedAt")}:{" "}
                {new Date(redeemData?.updatedAt).toDateString()}
                <br />
                {t("amount")}: {redeemData?.amount}
              </strong>
              {/* <div className="card-overlay bg-black opacity-50" /> */}
            </div>
            {!redeemData?.isApproved ? (
              <button
                onClick={handleApproveRedeem}
                className="btn btn-full btn-l rounded-s font-800 text-uppercase mx-auto shadow-bg shadow-bg-m"
                style={{
                  backgroundColor: `#56eea8`,
                  color: `#092C4C`,
                  width: `50%`,
                }}
              >
                {t("approveRedeem")}
              </button>
            ) : (
              <h5 className="text-success text-center">
                {t("redeemAlreadyApproved")}
              </h5>
            )}
          </div>
        </IonContent>
        <FooterContainer
          offersheet={"RedeemDetOfferSheet"}
          productsheet={"RedeemDetProdSheet"}
        />
        <div
          id="RedeemDetProdSheet"
          className="offcanvas offcanvas-bottom offcanvas-detached  offcanvas-style "
          aria-modal="true"
          role="dialog"
        >
          <ProductList
            instock={"footerprod24"}
            outofstock={"outfooterprod24"}
          />
        </div>
        <div
          id="RedeemDetOfferSheet"
          className="offcanvas offcanvas-bottom offcanvas-detached  offcanvas-style "
          aria-modal="true"
          role="dialog"
        >
          <OfferList />
        </div>
      </IonPage>
    </>
  );
};

export default RedeemDetailsPage;
