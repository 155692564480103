/* eslint-disable @typescript-eslint/no-unused-vars */
import { IonContent, IonPage } from "@ionic/react";
import {
  Route,
  useParams,
  useRouteMatch,
  NavLink,
  Switch,
  Redirect,
  Link,
} from "react-router-dom";
import { map } from "lodash";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import Rating from "@mui/material/Rating";
import api from "../../services/api";
import { OfferRequests, OfferUsers } from "./Components";
import HeaderContainer from "../../components/common/Header";
import FooterContainer from "../../components/common/Footer";

import "../../translations/i18n";
import { UpdateOffer } from "./Components/UpdateOffer";
import "../offers/index.css";
import LoadingContainer from "../../components/LoadingContainer";
import DefaultFormActionSheet from "../../components/common/DefaultFormActionSheet";
import ProductList from "../products/ProductList";
import OfferList from "../offers/OfferList";
import gps from "../../assets/images/gps.png";

const OfferDetailsPage: React.FC = () => {
  const { t } = useTranslation();
  const { path } = useRouteMatch();
  const IMAGEURL = process.env.REACT_APP_IMAGEURL;
  const offer = useParams<any>();
  const token: any = useSelector<any>((state) => state.userState.token);
  const role = localStorage.getItem("role");
  const [loading, setLoading] = useState(false);
  const [offerData, setOfferData] = useState<any>();
  const [offerUsers, setOfferUsers] = useState<any>();
  const [list, setList] = useState("Users");
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [forceUpdate, setForceUpdate] = useState<any>(false);

  const [, getOfferDetials] = api.useAxios(
    {
      url:
        role === "merchant"
          ? "/merchant/offer/details"
          : "/merchantUser/offer/details",
      method: "post",
    },
    { manual: true }
  );

  const [, getOfferUsers] = api.useAxios(
    { url: "admin/offer/users/list", method: "post" },
    { manual: true }
  );

  const [, uploadOfferImages] = api.useAxios(
    { url: "/merchant/offer/upload/images", method: "post" },
    { manual: true }
  );

  useEffect(() => {
    setLoading(true);
    getOfferDetials({ data: { token, offerId: offer.id } })
      .then((res) => {
        setOfferData(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast(err?.response?.data.message);
      });
  }, [getOfferDetials, offer.id, token, forceUpdate]);

  useEffect(() => {
    const limit = 10;
    getOfferUsers({ data: { token, offerId: offer.id, limit: 1000 } })
      .then((res) => {
        setOfferUsers(res.data.data.rows);
        setTotalCount(res.data.data.count);
        // let last = 0;
        // const plusPage = totalCount % limit > 0 ? 1 : 0;
        // last = Math.trunc(totalCount / limit) + plusPage;
        // setLastPage(last);
      })
      .catch((err) => {
        toast(err?.response?.data.message);
      });
  }, [getOfferUsers, offer.id, token, totalCount]);

  const handleUploadImages = async (e: any) => {
    e.preventDefault();

    if (e.target.files.length > 5) {
      toast.error("You could upload up to 5 images ");
    } else {
      const formData: any = new FormData();
      const finalData = {
        ...formData,
        token,
        offerId: offerData.id,
        image1: e.target.files[0] ? e.target.files[0] : ``,
        image2: e.target.files[1] ? e.target.files[1] : ``,
        image3: e.target.files[2] ? e.target.files[2] : ``,
        image4: e.target.files[3] ? e.target.files[3] : ``,
        image5: e.target.files[4] ? e.target.files[4] : ``,
      };

      uploadOfferImages({ data: finalData })
        .then(() => {
          getOfferDetials({ data: { token, offerId: offer.id } })
            .then((res) => {
              setOfferData(res.data.data);
              setLoading(false);
            })
            .catch((err) => {
              setLoading(false);
              toast(err?.response?.data.message);
            });
          toast.success("Image Changed Successfully");
        })
        .catch((err) => {
          toast(err?.response?.data.message);
        });
    }
  };

  const handleLoadingMore = () => {
    setPage(page + 1);
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {loading ? (
        <LoadingContainer />
      ) : (
        <IonPage>
          <IonContent fullscreen>
            <HeaderContainer pageTitle={t("offerDetails")} />{" "}
            <div className=" content mb-0 offer-det-box offer-det">
              <div className="card flex-row  border-0 d-flex justify-content-between align-items-center p-3 header-box">
                <div className="d-flex align-items-left gap-1 flex-column">
                  <span className="name"> {offerData?.title}</span>
                  <span className="branch">
                    <img src={gps} alt="gps" className="me-2" />
                    {offerData?.branchs[0]?.branchName}
                  </span>
                  <span className="date">
                    Start:{" "}
                    <span>{new Date(offerData?.startDate).toDateString()}</span>
                  </span>
                  <span className="date">
                    Expired:{" "}
                    <span>{new Date(offerData?.endDate).toDateString()}</span>
                  </span>
                </div>

                <div className="text-center">
                  <Rating
                    precision={0.5}
                    readOnly
                    defaultValue={offerData?.rate}
                  />
                  <img
                    src={
                      offerData?.images?.length !== 0 &&
                      offerData?.images !== null
                        ? `${IMAGEURL}${offerData?.images[0]}`
                        : "./assets/images/pictures/1s.jpg"
                    }
                    width={50}
                    height={50}
                    className="me-3 rounded-xs"
                    alt="img"
                  />
                </div>
                <div className="d-flex align-items-center gap-1 flex-column">
                  <span className="price">{offerData?.price}$</span>
                  <span className="points">{offerData?.points}P</span>
                </div>
              </div>

              {role === "merchant" ? (
                <div className=" my-2">
                  <div className="content text-center">
                    <label
                      htmlFor="images"
                      className=" px-4 py-2 d-flex align-items-center justify-content-center rounded-m main-btn"
                    >
                      <i className="bi bi-images me-1 font-20"></i>
                      {t("uploadOfferImages")}
                    </label>
                    <div>
                      <input
                        type="file"
                        onChange={handleUploadImages}
                        id="images"
                        style={{
                          border: "none",
                          width: `100%`,
                          display: "none",
                        }}
                        name="images"
                        multiple
                        // hidden
                      />
                    </div>
                    <div className="avatar-group mt-2">
                      {map(offerData?.images, (image: any, index: any) => (
                        <div className="avatar-group-item" key={index}>
                          <Link
                            to="#"
                            className="team-member d-inline-block"
                            id="member1"
                          >
                            <img
                              src={`${IMAGEURL}${image}`}
                              className="rounded-s avatar-xs"
                              alt=""
                            />
                          </Link>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ) : (
                ``
              )}

              <div className="">
                <div className="content mb-0">
                  <div className="tabs tabs-pill" id="tab-group-2">
                    <ul className="nav nav-tabs capsuled mt-2" role="tablist">
                      <li className="nav-item">
                        <Link
                          className="nav-link active "
                          data-bs-toggle="tab"
                          to="#"
                          role="tab"
                          onClick={() => setList("Users")}
                        >
                          Users
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          data-bs-toggle="tab"
                          to="#"
                          role="tab"
                          onClick={() => setList("Requests")}
                        >
                          Requests
                        </Link>
                      </li>
                    </ul>
                    <div className="tab-content ">
                      {list === "Users" ? (
                        <div
                          className="tab-pane  active show"
                          id="users"
                          role="tabpanel"
                        >
                          <div style={{ height: "17rem", overflow: "scroll" }}>
                            <OfferUsers
                              users={offerUsers}
                              handleLoadingMore={handleLoadingMore}
                            />
                          </div>
                          <div className="d-flex justify-content-between align-items-center px-3 pt-2 ">
                            <span className="total-requests">
                              {offerUsers?.length}
                              {` `}
                              {offerUsers?.length > 1 ? "user" : "users"}
                            </span>
                          </div>
                        </div>
                      ) : (
                        <div
                          className="tab-pane active show"
                          id="requests"
                          role="tabpanel"
                        >
                          {
                            <div
                              style={{ height: "17rem", overflow: "scroll" }}
                            >
                              <OfferRequests requests={offerData?.Requests} />
                            </div>
                          }
                          {
                            <div className="d-flex justify-content-between align-items-center px-3 pt-2 ">
                              <span className="total-requests">
                                {offerData?.Requests.length}
                                {` `}
                                {offerData?.Requests.length > 1
                                  ? "request"
                                  : "requests"}
                              </span>
                            </div>
                          }
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="content   "
              style={{
                marginBottom: "130px",
              }}
            >
              <button
                data-bs-toggle="offcanvas"
                data-bs-target="#update-offer"
                className=" btn d-block w-100 main-btn  mt-3"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-3 me-1"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                  />
                </svg>
                {t("updateOffer")}
              </button>
            </div>
            <FooterContainer
              offersheet={"offerOffersDetails"}
              productsheet={"productOfferDetails"}
            />
            <div
              id="productOfferDetails"
              className="offcanvas offcanvas-bottom offcanvas-detached  offcanvas-style "
              aria-modal="true"
              role="dialog"
            >
              <ProductList
                instock={"footerprod17"}
                outofstock={"outfooterprod17"}
              />
            </div>
            <div
              id="offerOffersDetails"
              className="offcanvas offcanvas-bottom offcanvas-detached  offcanvas-style "
              aria-modal="true"
              role="dialog"
            >
              <OfferList />
            </div>
            <div
              id="update-offer"
              className="offcanvas offcanvas-bottom offcanvas-detached  offcanvas-style"
              style={{
                display: "block",
                visibility: "hidden",
                height: "700px",
              }}
              aria-modal="true"
              role="dialog"
            >
              <DefaultFormActionSheet title={t("updateOffer")}>
                <UpdateOffer
                  offerData={offerData}
                  update={forceUpdate}
                  setUpdate={setForceUpdate}
                />
              </DefaultFormActionSheet>
            </div>
          </IonContent>
        </IonPage>
      )}
    </>
  );
};

export default OfferDetailsPage;
