import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { NodataContainer } from "../../components/common/noDataContainer";

type pendingProductProps = {
  data: any;
};

const PendingDeliveris = ({ data }: pendingProductProps) => {
  const { t } = useTranslation();
  return (
    <div className="px-3">
      {data?.pendingDeliveryProductRequests?.rows.length !== 0 ? (
        data?.pendingDeliveryProductRequests?.rows.map((pending: any) => (
          <div key={pending?.id}>
            <div key={pending?.id}>
              <div className="row justify-content-between align-items-center p-3 offer-det-list mt-1">
                <div className="align-items-center col-9 d-flex p-0">
                  <i className="has-bg rounded-xs bi p-1 bi bi-bag requests-bill-container me-3"></i>

                  <h5 className=" branch-name text-capitalize">
                    {pending?.User?.name}
                    <br />
                    <strong className="branches-phone">
                      {t("mobile")} : <span>{pending?.User?.mobile}</span>{" "}
                      <br />
                    </strong>
                    <strong className="branches-phone">
                      {" "}
                      {t("address")} :{" "}
                      <span>
                        {" "}
                        {pending?.UserAddress?.Address.address1}{" "}
                        {pending?.UserAddress?.Address.address2}
                      </span>
                    </strong>{" "}
                    <br />
                    <strong className="branches-phone">
                      {" "}
                      {t("product")} : <span>{pending?.Product?.name}</span>
                    </strong>
                  </h5>
                </div>

                <Link
                  to={`/deliveryProduct/${pending?.id}`}
                  className="align-self-center ms-auto text-end col-3"
                >
                  <span
                    className="btn btn-xxs main-bg text-uppercase "
                    style={{
                      color: "rgb(86, 238, 168)",
                    }}
                  >
                    {t("details")}
                  </span>
                </Link>
              </div>

              <div className="divider my-2 opacity-50"></div>
            </div>
            <div className="divider my-2 opacity-50"></div>
          </div>
        ))
      ) : (
        <NodataContainer />
      )}
    </div>
  );
};

export default PendingDeliveris;
