import { IonContent, IonPage } from "@ionic/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Redirect, Switch } from "react-router";
import { Link, NavLink, Route } from "react-router-dom";

import FooterContainer from "../../components/common/Footer";
import HeaderContainer from "../../components/common/Header";
import api from "../../services/api";

import AssignDelivery from "./assignDelivery";
import DeliveryProductRequests from "./DeliveryProductRequests";
import PickUpProductRequests from "./PickUpProductRequests";
import CanceledProductRequests from "./canceledProductRequests";
import DefaultFormActionSheet from "../../components/common/DefaultFormActionSheet";
import ProductRequestDetails from "../ProductRequestsDetails";
import LoadingContainer from "../../components/LoadingContainer";

const ProductRequests: React.FC = () => {
  const { t } = useTranslation();
  const token = useSelector<any>((state) => state.userState.token);
  const [userLists, setUserLists] = useState<any>([]);
  const [userListsPickup, setUserListPickup] = useState<any>([]);
  const [userListsDelivery, setuserListsDelivery] = useState<any>([]);
  const [userListCanceled, setUserListCanceled] = useState<any>([]);
  const [assignDelivery, setAssignDelivery] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState();
  const [list, setList] = useState("PickUp");
  const [, requestList] = api.useAxios(
    { url: "/merchant/product/req/list", method: "post" },
    { manual: true }
  );

  useEffect(() => {
    requestList({ data: { token, type: "delivery" } }).then((res: any) => {
      setUserLists(res?.data.data.productRequests);
    });
  }, []);

  return (
    <div className="tabs tabs-links   requestprod-list  " id="tab-group-6">
      <div className="">
        <div className="content ">
          {!assignDelivery ? (
            <>
              <h6 className="text-center branch-title m-4">
                {" "}
                <i
                  className="bi bi-bell-fill font-20 requests-bill"
                  style={{
                    color: "#FFC107",
                  }}
                ></i>{" "}
                {t("productRequests")}
              </h6>
              <i
                data-bs-dismiss="offcanvas"
                className="bi bi-x main-clr font-26 line-height-xl close-offcanvas"
              ></i>
              <div className="tabs tabs-pill" id="tab-group-2">
                <ul className="nav nav-tabs capsuled mt-4" role="tablist">
                  <li className="nav-item">
                    <Link
                      className="nav-link active "
                      data-bs-toggle="tab"
                      to="#"
                      role="tab"
                      onClick={() => {
                        setList("PickUp");
                      }}
                    >
                      {t("pickup")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      data-bs-toggle="tab"
                      to="#"
                      role="tab"
                      onClick={() => {
                        setList("Delivery");
                      }}
                    >
                      {t("delivery")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      data-bs-toggle="tab"
                      to="#"
                      role="tab"
                      onClick={() => {
                        setList("Canceled");
                      }}
                    >
                      Canceled
                    </Link>
                  </li>
                </ul>
                <div
                  className="tab-content pb-3 "
                  style={{ height: "28rem", overflow: "scroll" }}
                >
                  {list === "PickUp" ? (
                    <div
                      className="tab-pane fade active show"
                      id="pickup"
                      role="tabpanel"
                      style={{ height: "rem" }}
                    >
                      <PickUpProductRequests
                        id={setId}
                        showDetails={setShowDetails}
                      />
                    </div>
                  ) : list === "Delivery" ? (
                    <div className="tab-pane fade active show " role="tabpanel">
                      <DeliveryProductRequests
                        id={setId}
                        showDetails={setShowDetails}
                      />
                    </div>
                  ) : (
                    <div
                      className="tab-pane fade active show  "
                      id="canceled"
                      role="tabpanel"
                    >
                      <CanceledProductRequests
                        id={setId}
                        showDetails={setShowDetails}
                      />
                    </div>
                  )}
                </div>
                <div className="pb-2">
                  <button
                    className="addemployee-btn"
                    onClick={() => setAssignDelivery(true)}
                  >
                    {t("assigndelivery")}
                  </button>
                </div>
              </div>
            </>
          ) : (
            <DefaultFormActionSheet
              back={true}
              backState={setAssignDelivery}
              title={t("assigndelivery")}
            >
              <AssignDelivery data={userLists} backState={setAssignDelivery} />
            </DefaultFormActionSheet>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductRequests;
