import { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ToastContainer, toast } from "react-toastify";
import { ErrorMessage } from "@hookform/error-message";

import { ForgetPasswordValidation } from "../utils/validationSchema";
import { FormError } from "./form";
import { forgetPasswordUser, updateSpinnerStatus } from "../redux/actions";
import api from "../services/api";
import "../translations/i18n";

const ForgetPasswordContainer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting, isValid, isDirty },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(ForgetPasswordValidation),
  });

  const [{ loading: loginLoading }, forget] = api.useAxios(
    { url: "/merchant/forgetpassword", method: "post" },
    { manual: true }
  );

  const onSubmit = (data: any) => {
    if (!isValid || !isDirty) {
      return;
    }
    forget({ data: { data } })
      .then((res: any) => {
        reset();
        dispatch(forgetPasswordUser({ ...res.data.data }));
        history.push("/dashboard");
        toast.success("Logged In", {
          closeOnClick: true,
          draggable: false,
          autoClose: 1000,
          hideProgressBar: true,
          style: {
            top: "70px",
          },
        });
      })
      .catch((err: any) => {
        toast.error(err?.response?.data.message);
      });
  };

  useEffect(() => {
    dispatch(updateSpinnerStatus({ loginLoading }));
  }, [loginLoading]);

  return (
    <>
      <div className="page-content my-0 py-0">
        <div className="card bg-7 card-fixed">
          <div className="card-center mx-3 px-4 py-4 bg-white rounded-m">
            <h1 className="font-30 font-800 mb-0">{t("forgetpassword")}</h1>
            {/* <p>Login to your Account.</p> */}

            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-custom form-label form-border form-icon mb-4 bg-transparent">
                <i className="bi bi-asterisk font-13"></i>
                <input
                  type="password"
                  className="form-control rounded-xs"
                  {...register("username")}
                  placeholder={t("username")}
                />
                <label className="color-theme">username</label>
                <span>(required)</span>
                <ErrorMessage
                  errors={errors}
                  name="username"
                  render={FormError}
                />
              </div>

              <button
                className="btn btn-full gradient-highlight shadow-bg shadow-bg-s mt-4"
                disabled={isSubmitting}
                onClick={onSubmit}
                type="submit"
              >
                {t("forgetpassword")}
              </button>
              <div className="row">
                <div className="col-12 ">
                  <br />
                  <br />
                  <p>
                    <Link to="/login">{t("loginnow")}</Link>
                    <br />
                    <Link to="/languages">{t("changelanguage")}</Link>
                  </p>
                </div>
              </div>
            </form>
          </div>
          <div className="card-overlay rounded-0 m-0 bg-white opacity-50"></div>
        </div>
      </div>
    </>
  );
};

export default ForgetPasswordContainer;
