import { ErrorMessage } from "@hookform/error-message";
import { IonItem, IonList, IonSelect, IonSelectOption } from "@ionic/react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { FormError } from "../../components/form";
import api from "../../services/api";

const UpdateDeliveryEmployee = ({ employeeData, update, setUpdate }: any) => {
  const { t } = useTranslation();
  const token = useSelector<any>((state) => state.userState.token);
  const [edit, setEdit] = useState(false);
  const [branchId, setBranchId] = useState<any>(null);
  const [branches, setBranches] = useState<any>([]);
  const [selected, setSelected] = useState<any>(null);

  const {
    register,
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      fName: employeeData?.fName,
      mName: employeeData?.mName,
      lName: employeeData?.lName,
      branchId: employeeData?.branchId,
      rule: employeeData?.rule,
      mobileNum: employeeData?.mobileNum,
    },
    // resolver: yupResolver(CreateUserValidation),
  });
  const handleChange = () => {
    if (
      fName !== employeeData?.fName ||
      mName !== employeeData?.mName ||
      lName !== employeeData?.lName ||
      mobileNum !== employeeData?.mobileNum ||
      branchId !== employeeData?.branchId
    ) {
      setEdit(true);
    }
  };

  const history = useHistory();
  const fName = watch(`fName`);
  const mName = watch(`mName`);
  const lName = watch(`lName`);
  const mobileNum = watch("mobileNum");
  const [{ loading: addEmployeeLoading }, updateDeliveryEmployee] =
    api.useAxios(
      { url: "/merchant/employees/update", method: "post" },
      { manual: true }
    );
  const [, getBranches] = api.useAxios(
    { url: "/merchant/branch/list", method: "post" },
    { manual: true }
  );
  useEffect(() => {
    const limit = 1000;
    getBranches({ data: { pageNum: 1, limit, token } })
      .then((res) => {
        setBranches([...res.data.data.rows]);
      })

      .catch((err) => {
        console.error(err);
      });
  }, [getBranches, token]);
  useEffect(() => {
    setSelected(
      branches.find((branch: any) => branch.id === employeeData?.branchId)
    );
  }, [branches, employeeData?.branchId]);
  const onSubmit = (data: any) => {
    // const id = emplyeeid.id;
    data.rule = "delivery";

    const initialData = {
      userId: employeeData?.ownerId,
      token: token,
      fName: data.fName || employeeData?.fName,
      mName: data.mName || employeeData?.mName,
      lName: data.lName || employeeData?.lName,
      mobileNum: data.mobileNum || employeeData?.mobileNum,
      branchId: branchId || employeeData?.branchId,
    };

    updateDeliveryEmployee({ data: initialData })
      .then((res: any) => {
        reset();
        setUpdate(!update);
        toast.success("Updated !", {
          closeOnClick: true,
          draggable: false,
          autoClose: 1000,
          hideProgressBar: true,
          style: {
            top: "70px",
          },
        });
      })
      .catch((err: any) => {
        toast.error(err?.response?.data.message);
      });
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="form-custom form-label  w-100 p-0 m-0 ">
        <IonList lines="none">
          <IonItem className="" style={{ height: "70px" }}>
            <IonSelect
              interface="action-sheet"
              placeholder={t("selectBranch")}
              className="form-control rounded-xs d-flex w-100 h-100"
              color=""
              onIonChange={(e: any) => {
                setBranchId(e.detail.value);
                setEdit(true);
              }}
            >
              {branches &&
                branches.map((branch: any) => (
                  <IonSelectOption value={branch?.id} key={branch?.id}>
                    {branch?.branchName}
                  </IonSelectOption>
                ))}
            </IonSelect>
            <label
              className="form-label-always-active main-clr font-11 over-background"
              style={{
                top: "3px",
                zIndex: "999",
              }}
            >
              {t("chooseBranch")}
            </label>
          </IonItem>
        </IonList>
      </div>
      <div className="pb-3"></div>
      <div className="form-custom form-label form-icon">
        <i className="bi bi-person font-14 main-clr"></i>
        <input
          className="form-control rounded-xs"
          id="c32"
          type="text"
          placeholder={t("fName")}
          {...register("fName")}
          defaultValue={employeeData?.fName}
          onChange={handleChange}
          required
        />
        <label
          htmlFor="c3"
          className="form-label-always-active main-clr font-11"
        >
          {t("fName")}
        </label>
        <span className="font-10">{t("required")}</span>
        <div className="mb-30">
          <ErrorMessage errors={errors} name="fName" render={FormError} />
        </div>
      </div>
      <div className="pb-3"></div>
      <div className="form-custom form-label form-icon">
        <i className="bi bi-person font-14 main-clr"></i>
        <input
          className="form-control rounded-xs"
          id="c32"
          type="text"
          placeholder={t("mName")}
          {...register("mName")}
          defaultValue={employeeData?.mName}
          onChange={handleChange}
          required
        />
        <label
          htmlFor="c4"
          className="form-label-always-active main-clr font-11"
        >
          {t("mName")}
        </label>
        <span>{t("required")}</span>
        <div className="mb-30">
          <ErrorMessage errors={errors} name="mName" render={FormError} />
        </div>
      </div>
      <div className="pb-3"></div>
      <div className="form-custom form-label form-icon">
        <i className="bi bi-person font-14 main-clr"></i>
        <input
          type="text"
          className="form-control rounded-xs"
          id="c4"
          placeholder={t("lName")}
          defaultValue={employeeData?.lName}
          {...register("lName")}
          onChange={handleChange}
          required
        />
        <label
          htmlFor="c4"
          className="form-label-always-active main-clr font-11"
        >
          {t("lName")}
        </label>
        <span>{t("required")}</span>
        <div className="mb-30">
          <ErrorMessage errors={errors} name="lname" render={FormError} />
        </div>
      </div>
      <div className="pb-3"></div>
      <div className="form-custom form-label form-icon">
        <i className="bi bi-phone font-14 main-clr"></i>
        <input
          id="c7"
          type="number"
          className="form-control rounded-xs"
          placeholder={t("mNumber")}
          {...register("mobileNum")}
          defaultValue={employeeData?.mobileNum}
          onChange={handleChange}
          required
        />
        <label
          htmlFor="c4"
          className="form-label-always-active main-clr font-11"
        >
          {t("mNumber")}
        </label>
        <span>{t("required")}</span>
        <div className="mb-30">
          <ErrorMessage errors={errors} name="mobileNum" render={FormError} />
        </div>
      </div>

      {edit && branchId !== null ? (
        <button
          disabled={isSubmitting}
          className=" mb-3 btn d-block w-100 main-btn mt-3"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-3 me-1"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
            />
          </svg>
          {t("updateEmployee")}
        </button>
      ) : null}
    </form>
  );
};
export default UpdateDeliveryEmployee;
