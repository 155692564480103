/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { toast, ToastContainer } from "react-toastify";
import { ErrorMessage } from "@hookform/error-message";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import moment from "moment";
import Datetime from "react-datetime";
import api from "../../../services/api";
import { FormError } from "../../../components/form";
import { IonItem, IonList, IonSelect, IonSelectOption } from "@ionic/react";

type Props = {
  offerData: any;
  update: any;
  setUpdate: any;
};

export const UpdateOffer: React.FC<Props> = ({
  offerData,
  update,
  setUpdate,
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const animatedComponents = makeAnimated();
  const token = useSelector<any>((state) => state.userState.token);

  const [branches, setBranches] = useState<any>([]);
  const [selectedBranch, setSelectedBranch] = useState([]);

  const [tags, setTags] = useState<any>([]);
  const [selectedTag, setSelectedTag] = useState([]);
  const rule = localStorage.getItem("role");
  const {
    register,
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isValid, isDirty, isSubmitting },
  } = useForm({
    defaultValues: offerData,
  });

  const [, updateOffer] = api.useAxios(
    { url: "/merchant/offer/update", method: "post" },
    { manual: true }
  );

  const [, getBranches] = api.useAxios(
    { url: "/merchant/branch/list", method: "post" },
    { manual: true }
  );

  const [, getTags] = api.useAxios(
    { url: "/merchant/tag/list", method: "post" },
    { manual: true }
  );

  const inputStartProps = {
    placeholder: t("startDate"),
  };

  const inputEndProps = {
    placeholder: t("endDate"),
  };

  useEffect(() => {
    reset(offerData);
  }, [offerData, reset]);

  useEffect(() => {
    const limit = 1000;
    getBranches({ data: { limit, token } })
      .then((res: any) => {
        const branchesBeforeFilter = res.data.data.rows;
        const filteredBranchesToValueAndLabel = branchesBeforeFilter?.map(
          (item: any) => ({
            value: item.id,
            label: item.branchName,
          })
        );
        setBranches(filteredBranchesToValueAndLabel);
      })
      .catch((err: any) => {
        console.error(err);
      });
  }, [getBranches, token]);

  useEffect(() => {
    const limit = 1000;
    rule === "merchant" &&
      getTags({ data: { pageNum: 1, limit, token } })
        .then((res: any) => {
          const tagsBeforeFilter = res.data.data.rows;
          const filteredTagsToValueAndLabel = tagsBeforeFilter?.map(
            (item: any) => ({
              value: item.id,
              label: item.name,
            })
          );
          setTags(filteredTagsToValueAndLabel);
        })
        .catch((err: any) => {
          console.error(err);
        });
  }, [getTags, token]);

  const onSubmit = (data: any) => {
    if (Object.keys(errors).length > 0) {
      return;
    }

    data = {
      title: data.title || offerData.title,
      titleAr: data.titleAr || offerData.titleAr,
      description: data.description || offerData.description,
      descriptionAr: data.descriptionAr || offerData.descriptionAr,
      points: data.points || offerData.points,
      availableNumberOfOffers:
        data.availableNumberOfOffers || offerData.availableNumberOfOffers,
      price: data.price || offerData.price,
      startDate:
        moment(data.startDate).format("MM/DD/YYYY") ||
        moment(offerData?.startDate).format("MM/DD/YYYY"),
      endDate:
        moment(data.endDate).format("MM/DD/YYYY") ||
        moment(offerData?.endDate).format("MM/DD/YYYY"),
      branchsIds: JSON.stringify(selectedBranch.map((item: any) => item)),
      tagsIds: JSON.stringify(selectedTag.map((item: any) => item)),
    };

    const finalData = { ...data, token, offerId: offerData.id };
    updateOffer({ data: finalData })
      .then((res: any) => {
        reset();

        toast.success("updated successfully");
        setUpdate(!update);
      })
      .catch((err: any) => {
        toast(err?.response?.data.message);
      });
  };

  const handleBranchChange = (selectedOption: any) => {
    setSelectedBranch(selectedOption);
    setValue("chooseBranches", selectedOption);
  };

  const handleTagChange = (selectedOption: any) => {
    setSelectedTag(selectedOption);
    setValue("chooseTags", selectedOption);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="form-custom form-label form-icon w-100 p-0 m-0 ">
        <IonList lines="none">
          <IonItem className="" style={{ height: "70px" }}>
            <IonSelect
              multiple={true}
              interface="alert"
              placeholder={t("selectBranches")}
              // value = {offerData ? offerData.chooseBranches : getValues('chooseBranches') }
              className="form-select rounded-xs d-flex w-100 h-100"
              color=""
              onIonChange={(e: any) => {
                handleBranchChange(e.detail.value);
              }}
            >
              {branches &&
                branches.map((branch: any) => (
                  <IonSelectOption value={branch?.value} key={branch?.value}>
                    {branch.label}
                  </IonSelectOption>
                ))}
            </IonSelect>
            <label
              className="form-label-always-active  font-11 over-background main-clr"
              style={{
                top: "3px",
                zIndex: "999",
              }}
            >
              {t("chooseBranches")}
            </label>
          </IonItem>
        </IonList>
      </div>
      <div className="pb-2" />

      <div className="form-custom form-label form-icon">
        <i className="bi bi-pencil-fill font-12" style={{ color: "#747474" }} />
        <input
          className="form-control rounded-xs"
          id="c7"
          type="text"
          placeholder={t("title")}
          {...register("title")}
          defaultValue={offerData?.title}
        />
        <label
          htmlFor="c7"
          className="form-label-always-active main-clr font-11"
        >
          {t("title")}
        </label>
        <div className="mb-30">
          <ErrorMessage errors={errors} name="title" render={FormError} />
        </div>
      </div>
      <div className="pb-2" />

      <div className="form-custom form-label form-icon">
        <i className="bi bi-pencil-fill font-12" style={{ color: "#747474" }} />
        <input
          className="form-control rounded-xs"
          id="c7"
          type="text"
          placeholder={t("titleAr")}
          {...register("titleAr")}
          defaultValue={offerData?.titleAr}
        />
        <label
          htmlFor="c7"
          className="form-label-always-active main-clr font-11"
        >
          {t("titleAr")}
        </label>
        <div className="mb-30">
          <ErrorMessage errors={errors} name="titleAr" render={FormError} />
        </div>
      </div>
      <div className="pb-2" />

      <div className="form-custom form-label form-icon">
        <input
          className="form-control rounded-xs"
          id="c32"
          type="text"
          placeholder={t("points")}
          {...register("points")}
          defaultValue={offerData?.points}
        />
        <label
          htmlFor="c32"
          className="form-label-always-active main-clr font-11"
        >
          {t("points")}
        </label>
        <span>{t("required")}</span>
        <div className="mb-30">
          <ErrorMessage errors={errors} name="points" render={FormError} />
        </div>
      </div>
      <div className="pb-2" />
      <div className="form-custom form-label form-icon">
        <input
          className="form-control rounded-xs"
          id="c147"
          type="string"
          {...register("availableNumberOfOffers")}
          placeholder={t("availableNumberOfOffers")}
        />
        <label
          htmlFor="c147"
          className="form-label-always-active main-clr font-11 over-background"
        >
          {t("availableNumberOfOffers")}
        </label>
        <span>{t("required")}</span>
        <div className="mb-30">
          {errors.points && (
            <ErrorMessage
              errors={errors}
              name="availableNumberOfOffers"
              render={FormError}
            />
          )}
        </div>
      </div>
      <div className="pb-2" />

      <div className="form-custom form-label form-icon">
        <input
          className="form-control rounded-xs"
          id="c7"
          type="text"
          placeholder={t("Price")}
          {...register("price")}
          defaultValue={offerData?.price}
        />
        <label
          htmlFor="c7"
          className="form-label-always-active main-clr font-11"
        >
          {t("Price")}
        </label>
        <div className="mb-30">
          <ErrorMessage errors={errors} name="price" render={FormError} />
        </div>
      </div>
      <div className="pb-2" />

      <div className="form-custom form-label form-icon">
        <i className="bi bi-pencil-fill font-12" style={{ color: "#747474" }} />
        <Controller
          control={control}
          name="startDate"
          render={({ field: { onChange } }) => (
            <Datetime
              onChange={(date: any) =>
                onChange(moment(date).format("MM/DD/YYYY"))
              }
              className="text-white bg-dark "
              inputProps={inputStartProps}
              timeFormat={false}
              dateFormat="YYYY-MM-DD"
              closeOnSelect
              initialValue={moment(offerData?.startDate).format("YYYY-MM-DD")}
              // isValidDate={valid}
            />
          )}
        />
        <label
          htmlFor="c7"
          className="form-label-always-active main-clr font-11"
        >
          {t("startDate")}
        </label>
        <div className="mb-30">
          <ErrorMessage errors={errors} name="startDate" render={FormError} />
        </div>
      </div>
      <div className="pb-2" />

      <div className="form-custom form-label form-icon">
        <i className="bi bi-pencil-fill font-12" style={{ color: "#747474" }} />
        <Controller
          control={control}
          name="endDate"
          render={({ field: { onChange } }) => (
            <Datetime
              onChange={(date: any) =>
                onChange(moment.utc(date).format("MM/DD/YYYY"))
              }
              inputProps={inputEndProps}
              timeFormat={false}
              dateFormat="YYYY-MM-DD"
              closeOnSelect
              initialValue={moment.utc(offerData?.endDate).format("YYYY-MM-DD")}

              // isValidDate={valid}
            />
          )}
        />
        <label
          htmlFor="c7"
          className="form-label-always-active main-clr font-11"
        >
          {t("endDate")}
        </label>
        <div className="mb-30">
          <ErrorMessage errors={errors} name="endDate" render={FormError} />
        </div>
      </div>
      <div className="pb-2" />

      <div className="form-custom form-label form-icon mb-3">
        <i className="bi bi-pencil-fill font-12" style={{ color: "#747474" }} />
        <textarea
          className="form-control rounded-xs"
          placeholder="English Description"
          id="c7"
          defaultValue={offerData?.description}
          {...register("description")}
        />

        <label
          htmlFor="c7"
          className="form-label-always-active main-clr font-11"
        >
          {t("description")}
        </label>
        <div className="mb-30">
          <ErrorMessage errors={errors} name="description" render={FormError} />
        </div>
      </div>
      <div className="pb-2" />

      <div className="form-custom form-label form-icon mb-3">
        <i className="bi bi-pencil-fill font-12" style={{ color: "#747474" }} />
        <textarea
          className="form-control rounded-xs"
          placeholder={t("descriptionAr")}
          id="c7"
          defaultValue={offerData?.descriptionAr}
          {...register("descriptionAr")}
        />
        <label
          htmlFor="c7"
          className="form-label-always-active main-clr font-11"
        >
          {t("descriptionAr")}
        </label>
        <div className="mb-30">
          <ErrorMessage
            errors={errors}
            name="descriptionAr"
            render={FormError}
          />
        </div>
      </div>
      <div className="pb-2" />

      <div className="form-custom form-label form-icon w-100 p-0 m-0 ">
        <IonList lines="none">
          <IonItem className="" style={{ height: "70px" }}>
            <IonSelect
              multiple={true}
              interface="alert"
              placeholder={t("selectTags")}
              className="form-select rounded-xs d-flex w-100 h-100"
              color=""
              onIonChange={(e: any) => handleTagChange(e.detail.value)}
            >
              {tags &&
                tags.map((tag: any) => (
                  <IonSelectOption value={tag?.value} key={tag?.value}>
                    {tag.label}
                  </IonSelectOption>
                ))}
            </IonSelect>
            <label
              className="form-label-always-active main-clr font-11 over-background"
              style={{
                top: "3px",
                zIndex: "999",
              }}
            >
              {t("chooseTags")}
            </label>
          </IonItem>
        </IonList>
      </div>
      <div className="pb-2" />

      <button type="submit" className="btn btn-full main-btn w-100  mt-2 mb-3">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-3 me-1"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
          />
        </svg>
        {t("updateOffer")}
      </button>
    </form>
  );
};
