import { IonContent, IonPage } from "@ionic/react";
import { ToastContainer } from "react-toastify";

import ForgetPasswordContainer from "../../components/ForgetPassword";

const ForgetPasswordPage: React.FC = () => {
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
      />
      <IonPage>
        <IonContent fullscreen>
          <ForgetPasswordContainer />
        </IonContent>
      </IonPage>
    </>
  );
};

export default ForgetPasswordPage;
