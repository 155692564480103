import { IonContent, IonPage } from "@ionic/react";
import ProfileContainer from "../../components/ProfileContainer";
import HeaderContainer from "../../components/common/Header";
import FooterContainer from "../../components/common/Footer";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import ProductList from "../products/ProductList";
import OfferList from "../offers/OfferList";

const ProfilePage: React.FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <IonPage>
        <IonContent fullscreen>
          <HeaderContainer pageTitle={t("menuprofile")} />
          <ProfileContainer />
          <div
            id="ProfileProdSheet"
            className="offcanvas offcanvas-bottom offcanvas-detached  offcanvas-style "
            aria-modal="true"
            role="dialog"
          >
            <ProductList
              instock={"footerprod23"}
              outofstock={"outfooterprod23"}
            />
          </div>
          <div
            id="ProfileOfferSheet"
            className="offcanvas offcanvas-bottom offcanvas-detached  offcanvas-style "
            aria-modal="true"
            role="dialog"
          >
            <OfferList />
          </div>
          <FooterContainer
            offersheet={"ProfileOfferSheet"}
            productsheet={"ProfileProdSheet"}
          />
        </IonContent>
      </IonPage>
    </>
  );
};

export default ProfilePage;
