import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { NodataContainer } from "../../components/common/noDataContainer";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import api from "../../services/api";
import LoadingContainer from "../../components/LoadingContainer";

type PickUpProductProps = {
  id: any;
  showDetails: any;
};

const PickUpProductRequests = ({ id, showDetails }: PickUpProductProps) => {
  const [userListsPickup, setUserListPickup] = useState<any>();
  const token = useSelector<any>((state) => state.userState.token);
  const [loading, setLoading] = useState(false);
  const [, requestList] = api.useAxios(
    { url: "/merchant/product/req/list", method: "post" },
    { manual: true }
  );
  useEffect(() => {
    setLoading(true);
    requestList({ data: { token, type: "pickup" } }).then((res: any) => {
      setUserListPickup(res?.data.data.productRequests);
      setLoading(false);
    });
  }, []);
  const { t } = useTranslation();
  return (
    <div className="px-3">
      {loading ? (
        <LoadingContainer />
      ) : userListsPickup?.rows?.length !== 0 ? (
        userListsPickup?.rows?.map((pickup: any) => (
          <div key={pickup?.id}>
            <div key={pickup?.id}>
              <div className="row align-items-center p-3 offer-det-list mt-1 ">
                <div className="d-flex align-items-center gap-3 col-9">
                  <div className="align-self-center">
                    <i className="has-bg rounded-xs bi p-1 bi bi-bag requests-bill-container "></i>
                  </div>
                  <div className="align-self-center">
                    <h5 className="branch-name text-capitalize">
                      {pickup?.user?.name}
                      <br />
                      <span>
                        <strong className="branches-phone">
                          {" "}
                          {t("mobile")} <span> : {pickup?.user?.mobile} </span>
                        </strong>
                        <br />
                        <strong className="branches-phone">
                          {t("branch")} :
                          <span> {pickup?.Branch?.branchName}</span>{" "}
                        </strong>
                        <br />
                        <strong className="branches-phone">
                          {t("product")} :
                          <span> : {pickup?.Product?.name}</span>
                        </strong>
                      </span>
                    </h5>
                  </div>
                </div>
                <Link
                  to={`/productrequest/${pickup?.id}`}
                  className="align-self-center ms-auto text-end col-3"
                >
                  <span className="btn btn-xxs request-active text-uppercase ">
                    {t("details")}
                  </span>
                </Link>
              </div>
            </div>
          </div>
        ))
      ) : (
        <NodataContainer />
      )}
    </div>
  );
};

export default PickUpProductRequests;
