/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "../../../translations/i18n";
import person from "../../../assets/images/man-mainclr.png";
import { NodataContainer } from "../../../components/common/noDataContainer";

type Props = {
  employees: any;
};

export const BranchEmployees: React.FC<Props> = ({ employees }) => {
  const { t } = useTranslation();

  return (
    <>
      {employees?.length === 0 ? (
        <NodataContainer />
      ) : (
        <>
          <div className="collapse show">
            <div className="table-responsive">
              <div style={{ height: "22rem", overflow: "scroll" }}>
                <div id="tab-5" data-bs-parent="#tab-group-2">
                  {employees?.map((employee: any) => (
                    <>
                      <Link
                        key={employee.id}
                        to={`employee/`}
                        className="d-flex justify-content-between align-items-center p-3 employee-list"
                      >
                        <div className="d-flex align-items-center gap-2">
                          <img src={person} alt="icon" />
                          {/* <i
                            className="has-bg rounded-xs bi bi-person-fill font-20 p-1"
                            style={{
                              color: "#4ABA99",
                            }}
                          ></i> */}
                          <div>
                            <h5 className=" mb-1 text-capitalize employee-name">
                              {employee?.name}
                            </h5>
                            <span className="mt-0  text-capitalize">
                              <strong className="employee-phone">
                                {" "}
                                {t("phone")}:{" "}
                                <span> {employee.merchantUser.mobileNum}</span>
                              </strong>
                            </span>
                          </div>
                        </div>

                        <div>
                          <span className="badge  employee-active py-2 px-3">
                            {t("profile")}
                          </span>
                        </div>
                      </Link>
                    </>
                  ))}
                </div>

                {/* {employees?.length !== 0 ? (
                <div className="text-center mb-2">
                  <button
                    className="btn btn-primary m-auto align-center"
                    onClick={handleLoadingMore}
                  >
                    See More
                  </button>
                </div>
              ) : (
                ``
              )} */}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
