export const TRANSLATIONS_EN = {
  welcomeToTutorial: "Welcome to the tutorial",
  about: "about",
  languages: "Choose Language",
  branches: "Branches",
  loginhead: "Log in to continue to Swaplater.",
  registerhead: "Register to continue to Swaplater",
  changelanguage: "Change Language",
  changepasswordhead: "Change Password",
  newpassword: "New Password",
  confirmnewpassword: "Confirm New Password",
  signin: "sign in",
  forgetpassword: "Forget Password?",
  send: "send",
  havenotaccount: "Don't have an account?",
  haveaccount: "Do you have an account?",
  registernow: "Register Now",
  chooselanguage: "Choose Language",
  username: "Mobile number",
  enterpassword: "Enter Password",
  recentoffers: "Recent Offers",
  branch: "branch",
  viewDetails: "View Details",
  offer: "offer",
  workzone: "Work Zone",
  menudashboard: "Dashboard",
  menubranches: "Branches",
  menuoffers: "Offers",
  menuemployees: "Employees",
  menuofferrequests: "Offer Requests",
  menuaccount: "Account",
  menuprofile: "Profile",
  menuabout: "About",
  menulanguage: "Languages",
  menucreateuser: "Create User",
  logout: "Logout",
  merchantname: "Merchant Name",
  currency: "Qatar",
  entervalidname: "Please enter valid name",
  entervalidpassword: "Please enter valid password",
  entervalidmobile: "Please enter valid mobile number",
  failedtoregister: "Failed to create new account",
  failedtologin: "Failed to login",
  entervaliddata: "Enter valid mobile number and password",
  dashboard: "Dashboard",
  offers: "Offers",
  menu: "Menu",
  requests: "Requests",
  packages: "Packages",
  loginnow: "Login",
  alreadyhaveanaccount: "Already have an account?",
  confirm: "Confirm",
  activateacchead: "Notes To Activate Your Account",
  sendto: "Send Documents to",
  personalinformation: "Personal Information",
  changepw: "Change Password",
  activateaccount: "Activate Account",
  employees: "Employees",
  recentemployees: "Recent Employess",
  addemployee: "Add Employee",
  updateEmployee: "Update Employee",
  fName: "First Name",
  mName: "Middle Name",
  lName: "Last Name",
  mNumber: "Mobile Number",
  Rule: "Role",
  notifications: "Noftifications",
  yourpayments: "Your Payments",
  yourstatistics: "Your Statistics",
  colorscheme: "Color Scheme",
  get: "Get",
  points: "Points",
  price: "price",
  startDate: "Start Date",
  endDate: "End Date",
  description: "Description",
  descriptionAr: "Arabic Description",
  redeem: "Redeem",
  create: "Create",
  starts: "Starts",
  Point: "Point",
  Price: "Price",
  employee: "employee",
  buyNow: "Buy Now",
  Pro: "Pro",
  Username: "Username",
  Update: "Update",
  availableDeliveries: "Available Deliveries",
  activestatus: "Active Status",
  active: "(active)",
  deactive: "(not active)",
  users: "Users",
  for: "for",
  startsAt: "Starts at",
  endsAt: "Ends at",
  status: "status",
  recentRequests: "Recent Requests",
  updateBranch: "Update Branch",
  branchName: "Branch Name",
  phone: "phone",
  mobile: "Mobile",
  code: "Code",
  pendingRequests: "Pending Requests",
  createAccount: "Create Account",
  signIn: "SIGN IN",
  validTo: "Valid to",
  recentEmployees: "Recent Employees",
  addEmployee: "Add Employee",
  latestRequests: "Latest Requests",
  employeename: "Employee Name",
  recentbranches: "Recent Branches",
  address: "Address",
  addressAr: "Arabic Address",
  chooseRule: "Choose Role",
  yourBalance: "Your Balance",
  mainAccount: "Main Account",
  welcome: "Welcome",
  renewPlan: "Renew Plan",
  userRequeste: "User Requests",
  billsHistory: "Bills History",
  accountStatistics: "Account Statistics",
  totalActiveNonBranches: "Total active/non active branches",
  totalCount: "Total Count",
  totalActiveOffers: "Total active offers",
  totalpendingRequests: "Total pending request from clients",
  goLive: "GoLive",
  accountStats: "Account Stats",
  activateStats: "Activation Status",
  merchantApproveMessage:
    "Your merchant account was active successfully. and you are in live mode",
  merchantNotApprovedMessage:
    "Your merchant account has not been activated yet, please go live",
  userReedems: "User Redeems",
  redeems: "Redeems",
  yourAccountIsActive: "Your account is active.",
  yourAccountIsNotActive: "Your account is not active.",
  productRequests: "Product Requests",
  productRequestDetails: "Product Request Details",
  merchantSetting: "Merchant Setting",
  renew: "Renew",
  renewPackage: "Renew Package",
  billsAndInvoices: "Bills and Invoices",
  invoicesDetails: "Invoices Details",
  accountReports: "Account Reports",
  seeYourtStatistics: "See your Statistics",
  Pages: "Pages",
  products: "Products",
  invoices: "Invoices",
  pendingSettlements: "Pending Settlements",
  termsOfService: "Terms of Service",
  account: "Account",
  signOut: "Sign Out",
  branchActive: "Active",
  branchNotActive: "Not Active",
  addBranch: "Add Branch",
  Phone: "Phone",
  lat: "Latitude",
  long: "Longitude",
  required: "(required)",
  name: "Name",
  details: "Details",
  sorryBranchEmployees: " Sorry , you don't have any employees in your branch!",
  sorryBranchOffers: " Sorry , you don't have any offers in your offer!",
  latestOffers: "Latest Offers",
  sorryOffers: " Sorry , you don't have any offer in your account!",
  addOffer: "Add Offer",
  selectBranches: "Select Branch/es",
  chooseBranches: "Choose Branch/es",
  title: "Title",
  titleAr: "Title Arabic",
  selectTags: "Select Tag/s",
  chooseTags: "Choose Tag/s",
  offerDetails: "Offer Details",
  branchDetails: "Branch Details",
  uploadOfferImages: "Upload Offer Images",
  update: "Update",
  updateOffer: "Update Offer",
  sorryOfferUsers: " Sorry , you don't have any users in your offer!",
  sorryOfferRequests: " Sorry , you don't have any requests in your offer!",

  latestPackages: "Latest Packages",
  sorryPackages: " Sorry , There are no available packages!",
  packageDetails: "Package Details",
  taxes: "Taxes",
  total: "Total",
  sorryRequests: " Sorry , you don't have any request in your account!",
  requestDetails: "Request Details",
  approveRequest: "Approve request",
  requestAlreadyApproved: "This request is already approved",
  scanNow: "Scan the offer code",
  amount: "Amount",
  approved: "Approved",
  notApproved: "Not Approved",
  latestRedeems: "Latest Redeems",
  sorryRedeems: " Sorry , There are no available Redeems!",
  redeemDetails: "Redeem Details",
  user: "User",
  merchant: "Merchant",
  createdAt: "Created At",
  updatedAt: "Updated At",
  approveRedeem: "Approve redeem",
  redeemAlreadyApproved: "This redeem is already approved",
  addProduct: "Add Product",
  nameAr: "Arabic Name",
  stockAmount: "Stock Amount",
  latestProducts: "Latest Redeems",
  sorryProducts: " Sorry , you don't have any products in your account!",
  productDetails: "Product Details",
  uploadProductImage: "Upload Product Image",
  updateProduct: "Update Product",
  selectBranch: "Select Branch",
  chooseBranch: "Choose Branch",
  password: "Password",
  employeeProfile: "Employee Profile",

  latestInvoices: "Latest Package Requests",
  sorryInvoices: " Sorry , your history is empty!",
  invoiceDetails: "Invoice Details",
  adduser: "Add User",
  verifiedAccountHolder: "Verified Account Holder",
  uploadLogo: "Upload Logo",
  backtoProfile: "Back to Profile",
  submit: "Submit",
  changeYourAccountPassword: "Change Your Account Password.",
  thankYouRegistration: "Thank you for your Registration",
  accountUnderReview:
    "Your account is under review please submit required documents to our support",
  contactSupport: "Contact support",
  logintoyourAccount: "Login to your Account.",
  pointPrice: "Point Price",
  settings: "Settings",
  pending: "Pending",
  assigndelivery: "Assign Delivery",
  selectproduct: "Select Product Delivery",
  selectemployee: "Select Employee",
  pickup: "Pick Up",
  delivery: "Delivery",
  product: "Product",
  updatemployee: "Update Employee",
  deliveryemployees: "Delivery Employees",
  deliveryEmployeeDetails: "Delivery Employee",
  adddeliveryemployee: "Add Delivery Employee",
  updatedeliverymployee: "Update Delivery Employee",
  initialSettlements: "Initial Settlements",
  settlementDetails: "Settlement Details",
  noInitialTransactions: "There are no Initial Transactions",
  noPendingTransactions: "There are no Pending Transactions",
  branchOutgoingPoints: "Branch Outgoing Points",
  branchRevenue: "Branch Revenue",
  loginheader: "Login To Your Account",
  logindetails: "We've got some great deals waiting",
  logindetails2: "for you!",
  registerheader: "Join US",
  registerdetails: "create your new account and get",
  registerdetails2: " the most exclusive deals",
  privancyandpolicy: "I agree to Swaplater pay privacy policy ",
  offerRequests: "Offer Requests",
  availableNumberOfOffers: "Available Number Of Offers",
};
