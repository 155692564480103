import React from "react";
import nodata from "../../assets/images/nothing.svg";

export const NodataContainer: React.FC = () => {
  return (
    <div className="text-center">
      <br />
    
        <img src={nodata} className="text-center svg-pages" />
        <br />
        <span className="nothing-title">Nothing here yet</span>
    
    </div>
  );
};
