/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import loginm from "../assets/images/activate-p.gif";
import api from "../services/api";
import LoadingContainer from "./LoadingContainer";

const ActivateAccountContainer = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [userData, setUserData] = useState<any>();
  const role = localStorage.getItem("role");
  const token = useSelector<any>((state) => state.userState.token);
  const [loading, setLoading] = useState(false);

  const [, dashboardData] = api.useAxios(
    {
      url: role === "merchant" ? `/merchant/me` : `/merchantUser/me`,
      method: "post",
    },
    { manual: true }
  );
  const onSubmit = () => {
    localStorage.clear();
    history.push("/login");
  };
  // useEffect(() => {
  //   setLoading(true);
  //   dashboardData({ data: { token } })
  //     .then((res: any) => {
  //       setUserData(res?.data?.data);
  //       setLoading(false);
  //     })
  //     .catch((err: any) => {
  //       toast.error(err?.response?.data.message);
  //       setLoading(false);
  //       history.push("/login");
  //     });
  // }, [dashboardData, history, token]);
  return (
    <div className="row pt-0">
      {loading ? (
        <LoadingContainer />
      ) : (
        <div className="section">
          <div className="login-header">
            <img className="login-big-img" src={loginm} alt="lang" />
          </div>
          <div className="text-center">
            <h5>{t("thankYouRegistration")}</h5>

            <h6>{t("accountUnderReview")}</h6>
            <h4 className=" text-primary p-2 ">sales@swaplater.com</h4>
            <h6 className=" text-primary p-2 ">
              {t("contactSupport")}: +96547234343
            </h6>

            <button
              className="btn btn-block main-btn"
              onClick={onSubmit}
              type="button"
            >
              {t("logout")}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ActivateAccountContainer;
