/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  IonContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonPage,
} from "@ionic/react";
import HeaderContainer from "../../components/common/Header";
import FooterContainer from "../../components/common/Footer";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import api from "../../services/api";
import LoadingContainer from "../../components/LoadingContainer";
import "../../translations/i18n";
import { ToastContainer, toast } from "react-toastify";
import { NodataContainer } from "../../components/common/noDataContainer";

const InitialSettlementPage: React.FC = () => {
  const { t } = useTranslation();
  const token = useSelector<any>((state) => state.userState.token);
  const [page, setPage] = useState(1);
  const [InfiniteDisabled, setInfiniteDisabled] = useState<any>(false);

  const [initialSettlements, setInitialSettlements] = useState<any>([]);
  const [loading, setLoading] = useState(false);

  const [, getInitialSettlements] = api.useAxios(
    { url: "/merchant/settlement/initial/list", method: "post" },
    { manual: true }
  );
  let limit = 10;
  useEffect(() => {
    setLoading(true);
    getInitialSettlements({ data: { token, limit: 1000 } })
      .then((res) => {
        setInitialSettlements([...res.data.data]);
        setLoading(false);
      })
      .catch((err) => {
        toast(err?.response?.data.message);
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = async () => {
    setLoading(false);
    const nextPage = page + 1;
    setInfiniteDisabled(false);
    setTimeout(() => {
      getInitialSettlements({ data: { token, pageNum: nextPage, limit } }).then(
        (res: any) => {
          if (res?.data?.data.length === 0) {
            setInfiniteDisabled(true);
            return;
          }
          setInitialSettlements([...initialSettlements, ...res.data.data]);
        }
      );
    }, 500);
  };

  return (
    <>
      <IonPage>
        <IonContent fullscreen>
          {loading ? (
            <LoadingContainer />
          ) : (
            <div className="tabs tabs-links pb-2  bg-white" id="tab-group-6">
              <div className="">
                <div
                  className="collapse show"
                  id="tab-16"
                  data-bs-parent="#tab-group-6"
                >
                  <div className="content">
                    {loading ? (
                      <LoadingContainer />
                    ) : (
                      <>
                        <h6 className="text-center branch-title m-4">
                          {" "}
                          {t("initialSettlements")}
                        </h6>
                        <i
                          data-bs-dismiss="offcanvas"
                          className="bi bi-x main-clr font-26 line-height-xl close-offcanvas"
                        ></i>
                        <div className="px-3 pb-3">
                          {initialSettlements.length !== 0 ? (
                            initialSettlements.map((settlement: any) => (
                              <Link
                                className="row  align-items-center p-3 employee-list"
                                key={settlement.id}
                                to={`/settlement/${settlement.id}`}
                              >
                                <div className="d-flex align-items-center gap-3 col-9">
                                  <i
                                    className="has-bg rounded-xs bi bi-collection-fill p-1"
                                    style={{
                                      color: "rgb(86, 238, 168)",
                                      backgroundColor: "rgb(9, 44, 76)",
                                    }}
                                  ></i>
                                  <div>
                                    <h5 className="branch-name mb-1 text-capitalize mt-0">
                                      {settlement?.user?.name}
                                    </h5>
                                    <span className="mt-0  text-capitalize">
                                      <strong className="d-inline branches-address">
                                        {" "}
                                        {t("amount")} :{" "}
                                        <span>{settlement?.amount}</span>
                                        &nbsp;&nbsp;&nbsp;
                                      </strong>

                                      <br />
                                      <strong className="d-inline branches-phone">
                                        {" "}
                                        {t("phone")} :{" "}
                                        <span>{settlement?.user?.mobile}</span>
                                      </strong>
                                    </span>
                                  </div>
                                </div>

                                <div className="col-3 text-end">
                                  <span
                                    className="badge rounded-xl  py-2 px-3"
                                    style={{
                                      backgroundColor: "rgb(9, 44, 76)",
                                    }}
                                  >
                                    {settlement?.status}
                                  </span>
                                </div>
                              </Link>
                            ))
                          ) : (
                            <NodataContainer />
                          )}
                          {/* <IonInfiniteScroll
                            onIonInfinite={(e) => {
                              loadData();
                              setTimeout(() => e.target.complete(), 500);
                            }}
                            disabled={InfiniteDisabled}
                            threshold="100px"
                          >
                            <IonInfiniteScrollContent
                              loadingText={"loading"}
                            ></IonInfiniteScrollContent>
                          </IonInfiniteScroll> */}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </IonContent>
      </IonPage>
    </>
  );
};

export default InitialSettlementPage;
