import { IonContent, IonPage } from "@ionic/react";
import IntroContainer from "../../components/common/IntroContainer";
import HeaderContainer from "../../components/common/Header";
import FooterContainer from "../../components/common/Footer";
import ProductList from "../products/ProductList";
import OfferList from "../offers/OfferList";

const CompletePage: React.FC = () => {
  return (
    <IonPage>
      <HeaderContainer />
      <IonContent fullscreen>
        <IntroContainer />
      </IonContent>
      <FooterContainer
        offersheet={"offerComProf"}
        productsheet={"productComProf"}
      />
      <div
        id="productComProf"
        className="offcanvas offcanvas-bottom offcanvas-detached  offcanvas-style "
        aria-modal="true"
        role="dialog"
      >
        <ProductList instock={"footerprod11"} outofstock={"outfooterprod11"} />
      </div>
      <div
        id="offerComProf"
        className="offcanvas offcanvas-bottom offcanvas-detached  offcanvas-style "
        aria-modal="true"
        role="dialog"
      >
        <OfferList />
      </div>
    </IonPage>
  );
};

export default CompletePage;
