import { IonContent, IonPage } from "@ionic/react";
import SettingContainer from "../../components/Setting";
import HeaderContainer from "../../components/common/Header";
import { useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";

const SettingPage: React.FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <IonPage>
        <HeaderContainer
          pageTitle={t("settings")}
          pageIcon={
            <i
              className=" rounded-xs bi bi-sliders font-14"
              style={{
                color: "rgb(86, 238, 168)",
              }}
            ></i>
          }
        />
        <IonContent fullscreen>
          <SettingContainer />
        </IonContent>
      </IonPage>
    </>
  );
};

export default SettingPage;
