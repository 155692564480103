import { IonContent, IonPage } from "@ionic/react";
import LanguageContainer from "../../components/Language";
import HeaderContainer from "../../components/common/Header";
import { useTranslation } from "react-i18next";
import ProductList from "../products/ProductList";
import OfferList from "../offers/OfferList";
import FooterContainer from "../../components/common/Footer";

const LanguagesPage: React.FC = () => {
  const { t } = useTranslation();
  return (
    <IonPage>
      <IonContent fullscreen>
        <HeaderContainer pageTitle={t("languages")} />
        <LanguageContainer />
        <div
          id="langProdSheet"
          className="offcanvas offcanvas-bottom offcanvas-detached  offcanvas-style "
          aria-modal="true"
          role="dialog"
        >
          <ProductList
            instock={"footerprod16"}
            outofstock={"outfooterprod16"}
          />
        </div>
        <div
          id="langOfferSheet"
          className="offcanvas offcanvas-bottom offcanvas-detached  offcanvas-style "
          aria-modal="true"
          role="dialog"
        >
          <OfferList />
        </div>
        <FooterContainer
          offersheet={"langOfferSheet"}
          productsheet={"langProdSheet"}
        />
      </IonContent>
    </IonPage>
  );
};

export default LanguagesPage;
