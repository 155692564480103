import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import api from "../services/api";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
interface UserData {
  name: string;
  mobile: string;
}

const PersonalInformationContainer = () => {
  const { t } = useTranslation();
  const token = useSelector<any>((state) => state.userState.token);
  const role = localStorage.getItem("role");

  const [merchant, setMerchant] = useState<UserData | any>(null);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const history = useHistory();

  const {
    register,
    handleSubmit,
    watch,
    formState: { isSubmitting },
  } = useForm({
    mode: "onChange",

    // resolver: yupResolver(LoginValidation),
  });

  const fullName = watch(`nameEn`);
  const phoneNumber = watch(`phone`);

  const [, dashboardData] = api.useAxios(
    {
      url: role === "merchant" ? `/merchant/me` : `/merchantUser/me`,
      method: "post",
    },
    { manual: true }
  );

  useEffect(() => {
    dashboardData({ data: { token } })
      .then((res: any) => {
        setMerchant(res.data.data);
      })
      .catch((err: any) => {
        toast(err?.response?.data.message);
      });
  }, [dashboardData, token]);
  const [{ loading: loginLoading }, update] = api.useAxios(
    { url: "/merchant/updateProfile", method: "post" },
    { manual: true }
  );

  const handleChange = () => {
    if (fullName !== merchant?.name || phoneNumber !== merchant?.mobile) {
      setIsEdit(true);
    }
  };

  const onSubmit = (data: any) => {
    const initialData = {
      mobile: data.mobile || merchant?.mobile,
      fName: data.fname || merchant?.name,
      token: token,
    };
    update({ data: initialData })
      .then((res: any) => {
        history.push("/profile");
        toast.success("Updated !", {
          closeOnClick: true,
          draggable: false,
          autoClose: 1000,
          hideProgressBar: true,
          style: {
            top: "70px",
          },
        });
      })
      .catch((err: any) => {
        toast.error(err?.response?.data.message);
      });
  };
  const [, uploadLogoImage] = api.useAxios(
    { url: "/merchant/upload/logo", method: "post" },
    { manual: true }
  );
  const handleUploadImage = async (e: any) => {
    e.preventDefault();

    const formData: any = new FormData();

    const finalData = {
      ...formData,
      token,
      logo: e.target.files[0],
    };

    uploadLogoImage({ data: finalData })
      .then(() => {
        window.location.reload();
      })
      .catch((err: any) => {
        toast(err?.response?.data.message);
      });
  };

  return (
    <>
      {merchant ? (
        <>
          {role === "merchant" ? (
            <form onSubmit={handleSubmit(onSubmit)}>
              <h5 className="pb-3 pt-4">{t("personalinformation")}</h5>
              <div className="form-custom form-label form-border mb-3 bg-transparent">
                <input
                  {...register("mobile")}
                  type="number"
                  className="form-control rounded-xs"
                  id="c1a"
                  defaultValue={merchant?.mobile}
                  onChange={handleChange}
                  required
                />
                <label
                  htmlFor="c1a"
                  className="form-label-always-active color-highlight"
                >
                  {t("username")}
                </label>
                <span>{t("required")}</span>
              </div>
              <div className="form-custom form-label form-border mb-3 bg-transparent">
                <input
                  type="text"
                  className="form-control rounded-xs"
                  {...register("fname")}
                  id="c1ab"
                  placeholder={merchant?.name}
                  defaultValue={merchant?.name}
                  onChange={handleChange}
                  required
                />
                <label
                  htmlFor="c1ab"
                  className="form-label-always-active color-highlight"
                >
                  {t("merchantname")}
                </label>
                <span>{t("required")}</span>
              </div>
              {/* <input type="file" onChange={handleUploadImage} /> */}

              {isEdit ? (
                <div className="text-center">
                  <button
                    className="main-btn p-4 rounded "
                    disabled={isSubmitting}
                  >
                    {t("submit")}
                  </button>
                </div>
              ) : (
                ``
              )}
            </form>
          ) : (
            <div className="page-content mt-5 my-0 py-0">
              <div className="content mt-0 card card-style">
                <div className="content mt-0">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <h5 className="pb-3 pt-4">{t("personalinformation")}</h5>
                    <div className="form-custom form-label form-border mb-3 bg-transparent">
                      <div className="form-custom form-label form-border mb-3 bg-transparent">
                        <h5 className=" color-highlight">{t("username")}</h5>
                        <p className="mt-1">{merchant.mobile}</p>
                      </div>
                    </div>
                    <div className="form-custom form-label form-border mb-3 bg-transparent">
                      <h5 className=" color-highlight">{t("merchantname")}</h5>
                      <p className="mt-1">{merchant.name}</p>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        ``
      )}
    </>
  );
};

export default PersonalInformationContainer;
