import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { ErrorMessage } from "@hookform/error-message";

import { FormError } from "../../components/form";
import api from "../../services/api";

import "../../translations/i18n";
import { IonItem, IonList, IonSelect, IonSelectOption } from "@ionic/react";
type EmployeeProps = {
  backState?: any;
  setUpdate?: any;
  update?: any;
  from?: string;
  setList?: any;
};
const AddEmployee = ({
  backState,
  setUpdate,
  update,
  from,
  setList,
}: EmployeeProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t } = useTranslation();
  const history = useHistory();
  const token = useSelector<any>((state) => state.userState.token);
  const [branches, setBranches] = useState<any>([]);
  const [branchId, setBranchId] = useState<any>();

  const [, getBranches] = api.useAxios(
    { url: "/merchant/branch/list", method: "post" },
    { manual: true }
  );

  useEffect(() => {
    const limit = 1000;
    getBranches({ data: { limit, token } })
      .then((res) => {
        setBranches([...res.data.data.rows]);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [getBranches, token]);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty, isValid },
  } = useForm({
    mode: "onChange",
    // resolver: yupResolver(CreateUserValidation),
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [{ loading: addEmployeeLoading }, addEmployee] = api.useAxios(
    { url: "/merchant/addEmployee", method: "post" },
    { manual: true }
  );

  const onSubmit = (data: any) => {
    data.branchId = branchId;
    if (!data.branchId) {
      toast.error("please choose a branch");
      return;
    }
    if (!isDirty || !isValid) {
      return;
    }
    const finalData = { ...data, token };

    addEmployee({ data: finalData })
      .then((res: any) => {
        toast.success("added successfuly");
        reset();
        from === "branch" && setUpdate(!update);
        backState(false);
        setUpdate(!update);
      })
      .catch((err: any) => {
        toast(err?.response?.data.message);
      });
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-custom form-label  w-100 p-0 m-0 ">
          <IonList lines="none">
            <IonItem className="" style={{ height: "70px" }}>
              <IonSelect
                interface="action-sheet"
                placeholder={t("selectBranch")}
                className="form-control rounded-xs d-flex w-100 h-100"
                color=""
                onIonChange={(e: any) => setBranchId(e.detail.value)}
              >
                {branches &&
                  branches.map((branch: any) => (
                    <IonSelectOption value={branch?.id} key={branch?.id}>
                      {branch.branchName}
                    </IonSelectOption>
                  ))}
              </IonSelect>
              <label
                className="form-label-always-active main-clr font-11 over-background"
                style={{
                  top: "3px",
                  zIndex: "999",
                }}
              >
                {t("chooseBranch")}
              </label>
            </IonItem>
          </IonList>
        </div>
        <div className="pb-3"></div>
        <div className="form-custom form-label form-icon">
          <i className="bi bi-person font-14 main-clr"></i>
          <input
            type="text"
            className="form-control rounded-xs"
            id="c3"
            {...register("fName", { required: "field is required" })}
            placeholder={t("fName")}
            required
          />
          <label
            htmlFor="c3"
            className="form-label-always-active main-clr font-11"
          >
            {t("fName")}
          </label>
          <span className="font-10">{t("required")}</span>
          <div className="mb-30">
            <ErrorMessage errors={errors} name="fName" render={FormError} />
          </div>
        </div>
        <div className="pb-3"></div>
        <div className="form-custom form-label form-icon">
          <i className="bi bi-person font-14 main-clr"></i>
          <input
            type="text"
            className="form-control rounded-xs"
            placeholder={t("mName")}
            id="c4"
            {...register("mName", { required: "field is required" })}
            required
          />
          <label
            htmlFor="c4"
            className="form-label-always-active main-clr font-11"
          >
            {t("mName")}
          </label>
          <span>{t("required")}</span>
          <div className="mb-30">
            <ErrorMessage errors={errors} name="mName" render={FormError} />
          </div>
        </div>
        <div className="pb-3"></div>
        <div className="form-custom form-label form-icon">
          <i className="bi bi-person font-14 main-clr"></i>
          <input
            type="text"
            className="form-control rounded-xs"
            id="c4"
            placeholder={t("lName")}
            {...register("lName", { required: "field is required" })}
            required
          />
          <label
            htmlFor="c4"
            className="form-label-always-active main-clr font-11"
          >
            {t("lName")}
          </label>
          <span>{t("required")}</span>
          <div className="mb-30">
            <ErrorMessage errors={errors} name="lname" render={FormError} />
          </div>
        </div>
        <div className="pb-3"></div>
        <div className="form-custom form-label form-icon">
          <i className="bi bi-phone font-14 main-clr"></i>
          <input
            type="number"
            className="form-control rounded-xs"
            id="c4"
            placeholder={t("mNumber")}
            {...register("mobileNum", {
              required: "field is required",
            })}
            required
          />
          <label
            htmlFor="c4"
            className="form-label-always-active main-clr font-11"
          >
            {t("mNumber")}
          </label>
          <span>{t("required")}</span>
          <div className="mb-30">
            <ErrorMessage errors={errors} name="mobileNum" render={FormError} />
          </div>
        </div>
        <div className="pb-3"></div>
        <div className="form-custom form-label form-icon">
          <i className="bi bi-pencil-fill font-14 main-clr"></i>
          <input
            type="password"
            className="form-control rounded-xs"
            id="c4"
            placeholder={t("password")}
            {...register("password", { required: "field is required" })}
            required
          />
          <label
            htmlFor="c4"
            className="form-label-always-active main-clr font-11"
          >
            {t("password")}
          </label>
          <span>{t("required")}</span>
          <div className="mb-30">
            <ErrorMessage errors={errors} name="password" render={FormError} />
          </div>
        </div>
        <div className="pb-3"></div>
        <div className="form-custom form-label form-icon">
          <i className="bi bi-pencil-fill font-14 main-clr"></i>
          <input
            type="text"
            className="form-control rounded-xs"
            id="c4"
            placeholder={t("Rule")}
            {...register("rule", { required: "field is required" })}
            required
          />
          <label
            htmlFor="c4"
            className="form-label-always-active main-clr font-11"
          >
            {t("Rule")}
          </label>
          <span>{t("required")}</span>
          <div className="mb-30">
            <ErrorMessage errors={errors} name="rule" render={FormError} />
          </div>
        </div>
        <div className="text-center pb-1">
          <div className="text-center "></div>
          <button
            data-bs-toggle={`${errors ? null : "offcanvas"}`}
            data-bs-target="#menu-add-employee"
            className=" mb-3 btn d-block w-full main-btn shadow-bg shadow-bg-s mt-3"
            style={{ width: "100%" }}
            onClick={() => from === "branch" && setList("Employees")}
          >
            {t("addEmployee")}
          </button>
        </div>
      </form>
    </>
  );
};

export default AddEmployee;
