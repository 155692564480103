import {
  IonContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonPage,
} from "@ionic/react";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import DefaultFormActionSheet from "../../components/common/DefaultFormActionSheet";
import { NodataContainer } from "../../components/common/noDataContainer";
import LoadingContainer from "../../components/LoadingContainer";
import api from "../../services/api";
import CreateTicketForm from "./CreateTicketForm";

const Tickets = () => {
  const { t } = useTranslation();
  const [tickets, setTickets] = useState<any>();
  const [totalTickets, setTotalTickets] = useState<any>();
  const [addTicket, setAddTicket] = useState<any>(false);
  const token = useSelector<any>((state) => state.userState.token);
  const [loading, setLoading] = useState(false);
  const [InfiniteDisabled, setInfiniteDisabled] = useState<any>(false);

  let limit = 10;
  const [page, setPage] = useState(1);

  const [, getTickets] = api.useAxios(
    { url: "/merchant/ticket/list", method: "post" },
    { manual: true }
  );
  const [, closeTicket] = api.useAxios(
    { url: "/merchant/ticket/close", method: "post" },
    { manual: true }
  );

  const HandleCloseTicket = useCallback(
    (id: any) => {
      closeTicket({ data: { token, id } })
        .then(() => {
          toast.success("Closed Successfully");

          getTickets({ data: { token, limit: 1000 } }).then((res: any) => {
            setTickets(res?.data.data.rows);
            setTotalTickets(res?.data.data.count);
          });
        })
        .catch((err: any) => {
          toast.error(err?.response?.data.message);
        });
    },
    [closeTicket, token]
  );

  useEffect(() => {
    setLoading(true);
    getTickets({ data: { token, limit: 1000 } }).then((res: any) => {
      setTickets(res?.data.data.rows);
      setTotalTickets(res?.data.data.count);
    });
    setLoading(false);
  }, [getTickets, token, addTicket, HandleCloseTicket, page, limit]);

  const loadData = useCallback(
    (event: any) => {
      setLoading(false);
      setPage(page + 1);
      setInfiniteDisabled(false);
      setTimeout(() => {
        getTickets({ data: { token, pageNum: page + 1, limit } }).then(
          (res: any) => {
            setTickets([...tickets, ...res?.data.data.rows]);
            setTotalTickets(res?.data.data.count);
          }
        );
        event.target.complete();
        if (tickets?.length < totalTickets) {
          return setInfiniteDisabled(false);
        } else {
          return setInfiniteDisabled(true);
        }
      }, 500);
    },
    [page, getTickets, token, limit, tickets, totalTickets]
  );
  console.log(page, "page");
  return (
    <IonPage>
      <IonContent fullscreen>
        {loading ? (
          <LoadingContainer />
        ) : (
          <div className="content">
            {addTicket ? (
              <DefaultFormActionSheet
                back={true}
                backState={setAddTicket}
                title={t("Add Ticket")}
              >
                <CreateTicketForm setUpdate={setAddTicket} />
              </DefaultFormActionSheet>
            ) : (
              <div className="content">
                <h6 className="text-center branch-title m-4">
                  {" "}
                  {t("Tickets")}
                </h6>
                <i
                  data-bs-dismiss="offcanvas"
                  className="bi bi-x main-clr font-26 line-height-xl close-offcanvas"
                ></i>

                {tickets?.length === 0 ? (
                  <NodataContainer />
                ) : (
                  <div
                    className=""
                    style={{
                      height: "29rem",
                      overflow: "auto",
                    }}
                  >
                    {tickets?.length !== 0 ? (
                      tickets?.map((ticket: any, i: any) => (
                        <div className=" branch-list p-3 ">
                          <Link
                            key={ticket.id}
                            to={`/ticket/${ticket?.id}`}
                            className="row align-items-center px-3 "
                          >
                            <div className="col-9 d-flex align-items-center gap-2 overflow-hidden">
                              <i
                                className="has-bg rounded-xs bi bi-geo-alt-fill font-20 p-1"
                                style={{
                                  color: "#2C236A",
                                }}
                              ></i>
                              <div>
                                <h5 className=" mb-1 text-capitalize branch-name">
                                  {i + 1}
                                </h5>
                                <span className="mt-0  text-capitalize">
                                  <strong className="branches-address">
                                    {" "}
                                    {t("Type")} :{" "}
                                    <span>{ticket?.ticketType}</span>
                                    &nbsp;&nbsp;&nbsp;
                                  </strong>

                                  <br />
                                  <strong className="branches-phone">
                                    {" "}
                                    {t("Message")} :{" "}
                                    <span>{ticket.ticketBody}</span>
                                  </strong>
                                  <br />
                                </span>
                              </div>
                            </div>

                            <div className="col-3 text-end  p-0">
                              <span className="badge  branches-active py-2 px-3">
                                {ticket?.status}
                              </span>
                            </div>
                          </Link>
                          <div className="mt-1 px-3 col-12">
                            {!ticket?.isClosed ? (
                              <Link
                                to="#"
                                className="text-secondary"
                                onClick={() => HandleCloseTicket(ticket?.id)}
                              >
                                {t("Close Ticket")}
                              </Link>
                            ) : (
                              <Link className="text-danger" to="#">
                                {t("Closed Ticket")}
                              </Link>
                            )}
                          </div>
                        </div>
                      ))
                    ) : (
                      <NodataContainer />
                    )}
                    {/* {tickets?.length < totalTickets ? (
                      <IonInfiniteScroll
                        onIonInfinite={loadData}
                        disabled={false}
                        threshold="108px"
                      >
                        <IonInfiniteScrollContent
                          loadingText={"loading"}
                        ></IonInfiniteScrollContent>
                      </IonInfiniteScroll>
                    ) : (
                      ``
                    )} */}
                  </div>
                )}
                <span className="total-requests">
                  {totalTickets} {totalTickets > 1 ? "Tickets" : "Ticket"}
                </span>
                <button
                  className=" btn d-block w-full main-btn  mt-3"
                  style={{ width: "100%" }}
                  onClick={() => setAddTicket(true)}
                >
                  {t("Add Ticket")}
                </button>
              </div>
            )}

            <></>
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};
export default Tickets;
