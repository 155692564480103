/* eslint-disable @typescript-eslint/no-unused-vars */
import { IonContent, IonPage } from "@ionic/react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Route,
  useParams,
  useRouteMatch,
  NavLink,
  Switch,
  Redirect,
  Link,
} from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";

import HeaderContainer from "../../components/common/Header";
import FooterContainer from "../../components/common/Footer";
import api from "../../services/api";
import {
  BranchEmployees,
  BranchRequests,
  BranchOffers,
  UpdateBranch,
} from "./Components/index";

import "../../translations/i18n";
import DefaultFormActionSheet from "../../components/common/DefaultFormActionSheet";
import AddOffer from "../offers/AddOffer";
import AddEmployee from "../employees/AddEmployee";
import LoadingContainer from "../../components/LoadingContainer";
import ProductList from "../products/ProductList";
import OfferList from "../offers/OfferList";

const BranchPage: React.FC = () => {
  const { t } = useTranslation();
  const { path } = useRouteMatch();
  const branch = useParams<any>();
  const token = useSelector<any>((state) => state.userState.token);
  const rule = localStorage.getItem("role");
  const [branchData, setBranchData] = useState<any>();
  const [list, setList] = useState("Employees");
  const [employees, setEmployees] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [lastPage, setLastPage] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [forceUpdateDetails, setForceUpdateDetails] = useState(false);
  const [forceUpdateEmployees, setForceUpdateEmployees] = useState(false);
  const [forceUpdateAddOffer, setForceUpdateAddOffer] = useState(false);
  const [offers, setOffers] = useState<any>([]);

  const [, getBranchDetails] = api.useAxios(
    { url: "/merchant/branch/details", method: "post" },
    { manual: true }
  );

  const [, getEmployees] = api.useAxios(
    { url: "/merchant/user/list", method: "post" },
    { manual: true }
  );

  const [, getOffers] = api.useAxios(
    { url: "/merchant/offer/list", method: "post" },
    { manual: true }
  );

  useEffect(() => {
    setLoading(true);
    getBranchDetails({ data: { token, branchId: branch.id } })
      .then((res) => {
        setBranchData(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        toast(err?.response?.data.message);
      });
  }, [branch.id, getBranchDetails, token, , forceUpdateDetails]);

  useEffect(() => {
    const limit = 10;
    getEmployees({
      data: { pageNum: page, limit: 1000, token, branchId: branch.id },
    })
      .then((res) => {
        setEmployees([...res.data.data.rows]);
        setTotalCount(res.data.data.count);
        let last = 0;
        const plusPage = totalCount % limit > 0 ? 1 : 0;
        last = Math.trunc(totalCount / limit) + plusPage;
        setLastPage(last);
      })
      .catch((err) => {
        toast(err?.response?.data.message);
      });
  }, [branch.id, getEmployees, page, token, totalCount, forceUpdateEmployees]);

  useEffect(() => {
    const limit = 10;
    rule === "merchant" &&
      getOffers({ data: { branchId: branch.id, token } })
        .then((res) => {
          setOffers([...res.data.data.rows]);
          setTotalCount(res.data.data.count);
          setLoading(false);
        })
        .catch((err) => {
          toast(err?.response?.data.message);
          setLoading(false);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceUpdateAddOffer]);

  const handleLoadingMore = () => {
    setPage(page + 1);
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {loading ? (
        <LoadingContainer />
      ) : (
        <IonPage>
          <IonContent fullscreen>
            <HeaderContainer pageTitle={t("branchDetails")} />{" "}
            <div className=" pb-5 mb-5 content">
              <div className="d-flex gap-3 align-items-center">
                <i
                  className="has-bg rounded-xs bi bi-geo-alt-fill font-24 p-1"
                  style={{
                    color: "#4ABA99",
                  }}
                ></i>
                <div>
                  <h1 className="branchname-det">{branchData?.branchName}</h1>
                  <strong className="d-block branchaddress-det">
                    Address: {branchData?.address}
                  </strong>
                  <strong className="branchphone-det">
                    Phone: <span>{branchData?.phone}</span>
                  </strong>
                </div>
              </div>

              <ul className="nav nav-tabs capsuled mt-4" role="tablist">
                <li className="nav-item">
                  <Link
                    className={`nav-link ${
                      list === "Employees" ? "active" : null
                    } `}
                    data-bs-toggle="tab"
                    to="#"
                    role="tab"
                    onClick={() => setList("Employees")}
                  >
                    Employees
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={`nav-link ${
                      list === "Offers" ? "active" : null
                    } `}
                    data-bs-toggle="tab"
                    to="#"
                    role="tab"
                    onClick={() => setList("Offers")}
                  >
                    Offers
                  </Link>
                </li>
              </ul>
              <div className="tab-content ">
                {list === "Employees" ? (
                  <div className="tab-pane active show " role="tabpanel">
                    <div style={{ height: "22rem", overflow: "scroll" }}>
                      <BranchEmployees employees={employees} />
                    </div>
                    <div className="d-flex justify-content-between align-items-center px-3 pt-2 ">
                      <span className="total-requests">
                        {employees.length}
                        {` `}
                        {employees.length > 1 ? "employees" : "employee"}
                      </span>
                      <button
                        data-bs-toggle="offcanvas"
                        data-bs-target="#menu-add-employee"
                        className="addemployee-btn"
                      >
                        Add Employee
                      </button>
                    </div>
                    <div
                      id="menu-add-employee"
                      className="offcanvas offcanvas-bottom offcanvas-detached rounded-m "
                      style={{
                        display: "block",
                        visibility: "hidden",
                        height: "700px",
                      }}
                      aria-modal="true"
                      role="dialog"
                    >
                      <DefaultFormActionSheet title={t("addEmployee")}>
                        <AddEmployee
                          from="branch"
                          update={forceUpdateEmployees}
                          setUpdate={setForceUpdateEmployees}
                          setList={setList}
                        />
                      </DefaultFormActionSheet>
                    </div>
                  </div>
                ) : (
                  <div className="tab-pane active show " role="tabpanel">
                    <div style={{ height: "22rem", overflow: "scroll" }}>
                      <BranchOffers offers={offers} />
                    </div>
                    <div className="d-flex justify-content-between align-items-center px-3 pt-2 ">
                      <span className="total-requests">
                        {offers.length}
                        {` `}
                        {offers.length > 1 ? "offers" : "offer"}
                      </span>
                      <button
                        className="addemployee-btn"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#menu-add-offer"
                      >
                        Add offer
                      </button>
                    </div>
                    <div
                      id="menu-add-offer"
                      className="offcanvas offcanvas-bottom offcanvas-detached offcanvas-style"
                      style={{
                        display: "block",
                        visibility: "hidden",
                        height: "700px",
                      }}
                      aria-modal="true"
                      role="dialog"
                    >
                      <DefaultFormActionSheet title={t("addOffer")}>
                        <AddOffer
                          from="branch"
                          update={forceUpdateAddOffer}
                          setUpdate={setForceUpdateAddOffer}
                          setList={setList}
                        />
                      </DefaultFormActionSheet>
                    </div>
                  </div>
                )}

                <div className="text-center mt-3">
                  <UpdateBranch
                    branchData={branchData}
                    setUpdate={setForceUpdateDetails}
                    update={forceUpdateDetails}
                  />
                </div>
              </div>
            </div>
            <div
              id="productBranches"
              className="offcanvas offcanvas-bottom offcanvas-detached  offcanvas-style "
              aria-modal="true"
              role="dialog"
            >
              <ProductList
                instock={"footerprod9"}
                outofstock={"outfooterprod9"}
              />
            </div>
            <div
              id="offerBranches"
              className="offcanvas offcanvas-bottom offcanvas-detached  offcanvas-style "
              aria-modal="true"
              role="dialog"
            >
              <OfferList />
            </div>
            <FooterContainer
              offersheet={"offerBranches"}
              productsheet={"productBranches"}
            />
          </IonContent>
        </IonPage>
      )}
    </>
  );
};

export default BranchPage;
