import {
  IonContent,
  IonItem,
  IonList,
  IonPage,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import HeaderContainer from "../../components/common/Header";
import FooterContainer from "../../components/common/Footer";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import api from "../../services/api";
import { toast, ToastContainer } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { ErrorMessage } from "@hookform/error-message";
import { FormError } from "../../components/form";
// import swaplaterProfile from "../../components/common/avatar.svg";
import swaplaterProfile from "../../assets/images/avatar.svg";
import { Link } from "react-router-dom";
import DeliveryProductRequests from "../deliveryEmployee/DeliveryProductRequests";
import DefaultFormActionSheet from "../../components/common/DefaultFormActionSheet";
import ProductList from "../products/ProductList";
import OfferList from "../offers/OfferList";
import LoadingContainer from "../../components/LoadingContainer";
import UpdateDeliveryEmployee from "./UpDateDeliveryEmployee";
type Props = {
  updateState: any;
  update: any;
};
const DeliveryEmployeeDetailsPage: React.FC<Props> = ({
  update,
  updateState,
}: Props) => {
  const { t } = useTranslation();
  const employee = useParams<any>();
  const token = useSelector<any>((state) => state.userState.token);
  const history = useHistory();
  const animatedComponents = makeAnimated();
  const [branches, setBranches] = useState<any>([]);
  const [selected, setSelected] = useState<any>(null);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [list, setList] = useState("Information");
  const [branchId, setBranchId] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  const [employeeData, setEmployeeData] = useState<any>();

  const [, getEmployeeDetails] = api.useAxios(
    { url: "/merchant/user/details", method: "post" },
    { manual: true }
  );
  const [, getBranches] = api.useAxios(
    { url: "/merchant/branch/list", method: "post" },
    { manual: true }
  );
  const selectStyles = {
    control: (styles: any) => ({
      ...styles,
      height: "55px",
      color: "rgb(9, 44, 76)",
    }),

    menu: (styles: any) => ({
      ...styles,
      zIndex: " 99999999 !important",
      opacity: "1 !important",
      width: "100%",
      backgroundColor: "white !important",
      height: "100px",
      color: "rgb(9, 44, 76)",
      padding: "10px",
      overflow: "scroll",
    }),
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  useEffect(() => {
    setLoading(true);
    getEmployeeDetails({ data: { token, merchantUserId: employee?.id } })
      .then((res) => {
        setEmployeeData(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        toast(err?.response?.data.message);
        setLoading(false);
      });
  }, [employee?.id, forceUpdate, getEmployeeDetails, token]);

  useEffect(() => {
    const limit = 1000;
    getBranches({ data: { pageNum: 1, limit, token } })
      .then((res) => {
        setBranches([...res.data.data.rows]);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [employeeData?.branchId, getBranches, token]);

  useEffect(() => {
    setSelected(
      branches.find((branch: any) => branch.id === employeeData?.branchId)
    );
  }, [branches, employeeData?.branchId]);

  console.log(employeeData?.fName);
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {loading ? (
        <LoadingContainer />
      ) : (
        <IonPage>
          <IonContent fullscreen>
            <HeaderContainer pageTitle={t("deliveryEmployeeDetails")} />
            <div className="relative profile-page">
              {/* {merchant ? ( */}

              <div className="text-center">
                <img
                  // src={swaplaterProfile}
                  src={swaplaterProfile}
                  alt="Avatar"
                  width="140"
                  height="140"
                />
                <h3 className="color-theme text-center text-capitalize font-20 mb-0">
                  {employeeData?.fName} {employeeData?.mName}{" "}
                  {employeeData?.lName}
                </h3>

                <div className="mx-auto my-2">
                  <button
                    className=" editprof-btn"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#update-deliveryemployee"
                  >
                    <i className=" bi-pencil-fill" />
                    <span> {t("updatedeliverymployee")}</span>
                  </button>
                </div>
              </div>
              <div className="p-4">
                <ul className="nav nav-tabs capsuled" role="tablist">
                  <li className="nav-item">
                    <Link
                      className="nav-link active "
                      data-bs-toggle="tab"
                      to="#"
                      role="tab"
                      onClick={() => {
                        setList("Information");
                      }}
                    >
                      Information{" "}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      data-bs-toggle="tab"
                      to="#"
                      role="tab"
                      onClick={() => {
                        setList("EmployeeInfos");
                      }}
                    >
                      Employee Info{" "}
                    </Link>
                  </li>
                </ul>
                <div className="tab-content pb-5">
                  {list === "Information" ? (
                    <div className="tab-pane  active show pb-5" role="tabpanel">
                      <div className="profile-box p-2">
                        <div className="row">
                          <div className="d-flex align-items-left flex-column col-6">
                            <strong className="main">Name</strong>
                            <span className="det">
                              {" "}
                              {employeeData?.fName} {employeeData?.mName}{" "}
                              {employeeData?.lName}
                            </span>
                          </div>
                          <div className="d-flex align-items-left flex-column col-6">
                            <strong className="main">Mobile</strong>
                            <span className="det">
                              {employeeData?.mobileNum}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      className="tab-pane active show pb-5"
                      style={{ height: "285px", overflow: "scroll" }}
                      role="tabpanel"
                    >
                      <DeliveryProductRequests
                        requests={employeeData?.merchantUser.ProductRequests}
                      />
                    </div>
                  )}
                </div>
              </div>
              {/* ) : ( */}
              <div />
              {/* )} */}
            </div>

            <div
              id="update-deliveryemployee"
              className="offcanvas offcanvas-bottom offcanvas-detached  offcanvas-style"
              style={{
                display: "block",
                visibility: "hidden",
                height: "600px",
              }}
              aria-modal="true"
              role="dialog"
            >
              <DefaultFormActionSheet title={t("updatedeliverymployee")}>
                <UpdateDeliveryEmployee
                  employeeData={employeeData}
                  setUpdate={setForceUpdate}
                  update={forceUpdate}
                />
              </DefaultFormActionSheet>
            </div>

            <FooterContainer
              offersheet={"offerDeliveryEmployee"}
              productsheet={"productDeliveryEmployee"}
            />
            <div
              id="productDeliveryEmployee "
              className="offcanvas offcanvas-bottom offcanvas-detached  offcanvas-style "
              aria-modal="true"
              role="dialog"
            >
              <ProductList
                instock={"footerprod13"}
                outofstock={"outfooterprod13"}
              />
            </div>
            <div
              id="offerDeliveryEmployee"
              className="offcanvas offcanvas-bottom offcanvas-detached  offcanvas-style "
              aria-modal="true"
              role="dialog"
            >
              <OfferList />
            </div>
          </IonContent>
        </IonPage>
      )}
    </>
  );
};

export default DeliveryEmployeeDetailsPage;
