/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import LoadingContainer from "../../components/LoadingContainer";
import { Link, useHistory } from "react-router-dom";
import alert from "../../assets/images/alert.svg";
import heart from "../../assets/images/heart.svg";
import wallet from "../../assets/images/wallet.svg";
import coins from "../../assets/images/coins.png";
import request from "../../assets/images/gift.svg";
import sights from "../../assets/images/sights.svg";
import location from "../../assets/images/location.svg";
import i18n from "i18next";
import api from "../../services/api";
import "../../translations/i18n";
import {
  IonContent,
  IonItem,
  IonList,
  IonPage,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import FooterContainer from "../common/Footer";
import AddOfferActionSheet from "../common/DefaultFormActionSheet";

import DefaultActionSheet from "../common/DefaultFormActionSheet";
import DefaultFormActionSheet from "../common/DefaultFormActionSheet";
import AddOffer from "../../pages/offers/AddOffer";
import AddProduct from "../../pages/products/AddProduct";
import RequestsContainer from "../RequestsContainer";
import ProductList from "../../pages/products/ProductList";
import OfferList from "../../pages/offers/OfferList";
import AddBranch from "../../pages/branches/AddBranch";
import AddEmployee from "../../pages/employees/AddEmployee";
import AddUser from "../../pages/addUser";
import PackageContainer from "../PackageContainer";

const DashBoard: React.FC = () => {
  const { t } = useTranslation();
  const token = useSelector<any>((state) => state.userState.token);
  const role = localStorage.getItem("role");
  const history = useHistory();
  const [userData, setUserData] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [totalCountBranches, setTotalCountBranches] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const [, dashboardData] = api.useAxios(
    {
      url: role === "merchant" ? `/merchant/me` : `/merchantUser/me`,
      method: "post",
    },
    { manual: true }
  );

  const [, goLiveRequest] = api.useAxios(
    { url: `/merchant/goLive`, method: "post" },
    { manual: true }
  );
  const [, getBranches] = api.useAxios(
    { url: "/merchant/branch/list", method: "post" },
    { manual: true }
  );
  const [, getRequests] = api.useAxios(
    {
      url:
        role === "merchant" ? "/merchant/req/list" : "/merchantUser/req/list",
      method: "post",
    },
    { manual: true }
  );

  useEffect(() => {
    role === "merchant" &&
      getBranches({ data: { token } })
        .then((res) => {
          setTotalCountBranches(res.data.data.count);
          // let last = 0;
          // const plusPage = totalCount % limit > 0 ? 1 : 0;
          // last = Math.trunc(totalCount / limit) + plusPage;
          // setLastPage(last);
          setLoading(false);
        })
        .catch((err) => {
          toast(err?.response?.data.message);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    role === "merchant" &&
      getRequests({ data: { token } })
        .then((res) => {
          setTotalCount(res.data.data.count);

          setLoading(false);
        })
        .catch((err) => {
          toast(err?.response?.data.message);
          setLoading(false);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dashboardData({ data: { token } })
      .then((res: any) => {
        setUserData(res?.data?.data);
        localStorage.setItem("merchantId", res?.data?.data?.id);
        setLoading(false);
      })
      .catch((err: any) => {
        setLoading(false);
        toast.error(err?.response?.data.message);
        history.push("/login");
      });
  }, [dashboardData, history, token]);

  const handleGoLive = (e: any) => {
    const data = {
      isLive: e.target.checked,
    };
    const finalData = { ...data, token };
    goLiveRequest({ data: finalData })
      .then((res: any) => {
        if (res.status == 201) {
          return toast.error(res?.data?.message);
        }
        window.location.reload();
      })
      .catch((err: any) => {
        toast(err?.response?.data.message);
      });
  };

  const chooseLang = (e: any) => {
    let lang = e === "en" ? "ar" : "en";
    localStorage.setItem("language", lang);
    i18n.changeLanguage(lang);
  };
  const language: any = localStorage.getItem("language");
  console.log(userData?.isLive);
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <IonPage>
        <IonContent fullscreen>
          {loading === false && userData ? (
            <>
              {role === "merchant" ? (
                <div className="page-content home px-3 ">
                  <div className="pt-5 row align-items-center">
                    <div className="col-6 d-flex gap-3">
                      <h1 className="main-h1">Hi {userData?.name}!</h1>
                      <Link
                        to="/login"
                        onClick={() => toast.success("logged out")}
                      >
                        <i className="bi bi-box-arrow-left logout-home"></i>
                      </Link>
                    </div>
                    <div className="col-6 text-right noti-alert d-flex align-items-center justify-content-end">
                      <button
                        className="bg-white main-clr"
                        style={{
                          fontWeight: "bold",
                        }}
                        onClick={(e: any) => {
                          chooseLang(e.target.innerHTML);
                        }}
                      >
                        {language}
                      </button>
                      {/* <Link to="">
                        <img className="" src={heart} alt="alert" />
                      </Link>
                      <Link to="#">
                        <img className="" src={alert} alt="lang" />
                      </Link> */}
                    </div>
                  </div>

                  <div className="col-12 mb-2 mt-4">
                    <img className="wallet-img" src={wallet} alt="wallet" />
                    <span className="sub-title">Your Balance</span>
                  </div>

                  <div className="col-12 points-container d-flex justify-content-center">
                    <div className="d-flex flex-column">
                      <h3>
                        <img src={coins} alt="icon" className="me-2" />
                        POINTS
                      </h3>
                      <span>total Earned !</span>
                      <p>
                        {userData?.balance} &nbsp;
                        {/* <sup>P</sup> */}
                      </p>
                      <Link
                        to="#"
                        className="d-flex align-items-center link-home"
                      >
                        Points
                        {/* <i className="bi bi-plus"></i> */}
                      </Link>
                    </div>
                    <div className="d-flex flex-column">
                      <div className="green-line"></div>
                      <div className="green-line"></div>
                      <div className="green-line"></div>
                      <div className="green-line"></div>
                      <div className="green-line"></div>
                    </div>
                    <div className="d-flex flex-column">
                      <h3>
                        <i className="bi bi-cash me-1"></i>CASH
                      </h3>
                      <span>total Earned !</span>
                      <p>
                        {userData?.balance / 5}&nbsp;
                        {/* <sup>$</sup> */}
                      </p>
                      <Link
                        to="#"
                        className="d-flex align-items-center link-home"
                      >
                        Qatar Riyal
                        {/* <i className="bi bi-arrow-right-short icon-dshadow"></i> */}
                      </Link>
                    </div>
                  </div>
                  <div className="single-carousal   mb-4">
                    <ul className="splide__list gap-2 ">
                      <li
                        className="home-boxes"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#menu-add-offer-home"
                      >
                        <i className="bi bi-gift "></i>
                        <span> Create Offer</span>
                      </li>
                      <li
                        className="home-boxes"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#menu-add-product-home"
                      >
                        <i className=" bi bi-bag "></i>
                        <span> Create Product</span>
                      </li>
                      <li
                        className="home-boxes"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#menu-show-more"
                      >
                        <i className=" bi bi-plus "></i>
                      </li>
                    </ul>
                  </div>
                  <div
                    id="menu-add-offer-home"
                    className="offcanvas offcanvas-bottom offcanvas-detached offcanvas-style "
                    style={{
                      display: "block",
                      visibility: "hidden",
                      height: "650px",
                    }}
                    aria-modal="true"
                    role="dialog"
                  >
                    <DefaultFormActionSheet title={t("addOffer")}>
                      <AddOffer from="home" />
                    </DefaultFormActionSheet>
                  </div>
                  <div
                    id="menu-show-more"
                    className="offcanvas offcanvas-bottom offcanvas-detached offcanvas-style "
                    style={{
                      display: "block",
                      visibility: "hidden",
                      height: "360px",
                    }}
                    aria-modal="true"
                    role="dialog"
                  >
                    <DefaultFormActionSheet title={t("More Options")}>
                      <div className="row justify-content-center gap-2">
                        <li
                          className="home-boxes col-4"
                          data-bs-toggle="offcanvas"
                          data-bs-target="#menu-add-product-home"
                        >
                          <i className=" bi bi-basket "></i>
                          <span> Create Product</span>
                        </li>
                        <li
                          className="home-boxes col-4"
                          data-bs-toggle="offcanvas"
                          data-bs-target="#menu-add-offer-home"
                        >
                          <i className=" bi bi-gift "></i>
                          <span> Create Offer</span>
                        </li>
                        <li
                          className="home-boxes col-4"
                          data-bs-toggle="offcanvas"
                          data-bs-target="#menu-3"
                        >
                          <i className=" bi bi-building "></i>
                          <span> Create Branch</span>
                        </li>
                        <li
                          className="home-boxes col-4"
                          data-bs-toggle="offcanvas"
                          data-bs-target="#menu-4"
                        >
                          <i className=" bi bi-person-check "></i>
                          <span> Add Employee</span>
                        </li>
                        <li
                          className="home-boxes col-4"
                          data-bs-toggle="offcanvas"
                          data-bs-target="#menu-5"
                        >
                          <i className=" bi bi-box-seam "></i>
                          <span> Buy Package</span>
                        </li>
                        <li
                          className="home-boxes col-4"
                          data-bs-toggle="offcanvas"
                          data-bs-target="#menu-6"
                        >
                          <i className=" bi bi-person-square "></i>
                          <span> Create User</span>
                        </li>
                      </div>
                    </DefaultFormActionSheet>
                  </div>
                  <div
                    id="menu-add-product-home"
                    className="offcanvas offcanvas-bottom offcanvas-detached offcanvas-style  "
                    style={{
                      display: "block",
                      visibility: "hidden",
                      height: "650px",
                    }}
                    aria-modal="true"
                    role="dialog"
                  >
                    <DefaultFormActionSheet title={t("addProduct")}>
                      <AddProduct from="home" />
                    </DefaultFormActionSheet>
                  </div>

                  <div className="card home-cards ">
                    <div className="">
                      <Link
                        className="d-flex "
                        to="#"
                        // data-bs-toggle="offcanvas"
                        // data-bs-target="#menu-branches-home"
                      >
                        <div className="align-self-center">
                          <span className="icon rounded-s me-2 gradient-newgreen  shadow-bg shadow-bg-s">
                            <img src={location} alt="location" />
                          </span>
                        </div>
                        <div className="align-self-center ps-3">
                          <h5 className="pt-1 mb-n1">{t("branches")}</h5>
                          <p className="mb-0">
                            {t("totalActiveNonBranches")}{" "}
                            <span className="copyright-year"></span>
                          </p>
                        </div>
                        <div className="align-self-center ms-auto text-end">
                          <span className="home__branches___count">
                            {totalCountBranches}
                          </span>
                          <i className="bi bi-arrow-up-right"></i>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div
                    // id="menu-branches-home"
                    // className="offcanvas offcanvas-bottom offcanvas-detached rounded-m "
                    style={{ display: "block", visibility: "hidden" }}
                    aria-modal="true"
                    role="dialog"
                  >
                    {/* <BranchesActionSheet /> */}
                  </div>
                  <div className="card home-cards">
                    <div className="">
                      <Link
                        className="d-flex "
                        to="#"
                        // data-bs-toggle="offcanvas"
                        // data-bs-target="#menu-requests-home"
                      >
                        <div className="align-self-center">
                          <span className="icon rounded-s me-2 gradient-newgreen shadow-bg shadow-bg-s">
                            <img src={request} alt="gift" />
                          </span>
                        </div>
                        <div className="align-self-center ps-3">
                          <h5 className="pt-1 mb-n1">{t("requests")}</h5>
                          <p className="mb-0 ">
                            {t("pending requests")}{" "}
                            <span className="copyright-year"></span>
                          </p>
                        </div>
                        <div className="align-self-center ms-auto text-end">
                          <span className="home__req___count">
                            {" "}
                            {totalCount}
                          </span>
                          <i className="bi bi-arrow-up-right"></i>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className="card home-cards">
                    <div className="">
                      <Link
                        className="d-flex "
                        to="#"
                        // data-bs-toggle="offcanvas"
                        // data-bs-target="#menu-requests-home"
                      >
                        <div className="align-self-center">
                          <span className="icon rounded-s me-2 gradient-newgray shadow-bg shadow-bg-s">
                            <img src={sights} alt="insights" />
                          </span>
                        </div>
                        <div className="align-self-center ps-3">
                          <h5 className="pt-1 mb-n1">{t("Insights")}</h5>
                          <p className="mb-0 ">
                            {t("pending requests")}{" "}
                            <span className="copyright-year"></span>
                          </p>
                        </div>
                        <div className="align-self-center ms-auto text-end">
                          <i className="bi bi-arrow-up-right yellow-arrow "></i>
                        </div>
                      </Link>
                    </div>
                  </div>
                  {/* <div
                    id="menu-requests-home"
                    className="offcanvas offcanvas-bottom offcanvas-detached rounded-m "
                    style={{ display: "block", visibility: "hidden" }}
                    aria-modal="true"
                    role="dialog"
                  >
                    <RequestsContainer />
                  </div> */}
                  <div
                    id="menu-1"
                    className="offcanvas offcanvas-bottom offcanvas-detached  offcanvas-style "
                    aria-modal="true"
                    role="dialog"
                    style={{
                      display: "block",
                      visibility: "hidden",
                      height: "650px",
                    }}
                  >
                    <DefaultFormActionSheet title={t("addProduct")}>
                      <AddProduct from="home" />
                    </DefaultFormActionSheet>
                  </div>
                  <div
                    id="menu-2"
                    className="offcanvas offcanvas-bottom offcanvas-detached  offcanvas-style "
                    aria-modal="true"
                    role="dialog"
                    style={{
                      display: "block",
                      visibility: "hidden",
                      height: "650px",
                    }}
                  >
                    <DefaultFormActionSheet title={t("addOffer")}>
                      <AddOffer from="home" />
                    </DefaultFormActionSheet>
                  </div>
                  <div
                    id="menu-3"
                    className="offcanvas offcanvas-bottom offcanvas-detached  offcanvas-style "
                    aria-modal="true"
                    style={{
                      display: "block",
                      visibility: "hidden",
                      height: "650px",
                    }}
                  >
                    <DefaultFormActionSheet title={t("addBranch")}>
                      <AddBranch />
                    </DefaultFormActionSheet>
                  </div>
                  <div
                    id="menu-4"
                    className="offcanvas offcanvas-bottom offcanvas-detached  offcanvas-style "
                    aria-modal="true"
                    style={{
                      display: "block",
                      visibility: "hidden",
                      height: "650px",
                    }}
                  >
                    <DefaultFormActionSheet title={t("addEmployee")}>
                      <AddEmployee />
                    </DefaultFormActionSheet>
                  </div>
                  <div
                    id="menu-5"
                    className="offcanvas offcanvas-bottom offcanvas-detached  offcanvas-style "
                    aria-modal="true"
                    role="dialog"
                    style={{
                      display: "block",
                      visibility: "hidden",
                      height: "650px",
                    }}
                  >
                    <PackageContainer />
                  </div>
                  <div
                    id="menu-6"
                    className="offcanvas offcanvas-bottom offcanvas-detached  offcanvas-style "
                    aria-modal="true"
                    role="dialog"
                    style={{
                      display: "block",
                      visibility: "hidden",
                      height: "350px",
                    }}
                  >
                    <DefaultFormActionSheet title={t("adduser")}>
                      <AddUser />
                    </DefaultFormActionSheet>
                  </div>

                  <div className="card home-cards mb-5">
                    <div className="">
                      <div className="d-flex ">
                        <div className="align-self-center">
                          <span className="icon rounded-s me-2 gradient-newgray shadow-bg shadow-bg-s">
                            <i
                              className="bi bi-globe font-20 "
                              style={{
                                color: "#2C236A",
                              }}
                            ></i>
                          </span>
                        </div>
                        <div className="align-self-center ps-3">
                          <h5 className="pt-1 mb-n1">{t("goLive")}</h5>
                          <p className="mb-0 ">
                            {t("active offers and products")}{" "}
                            <span className="copyright-year"></span>
                          </p>
                        </div>
                        <div className="align-self-center ms-auto text-end">
                          <div className="form-switch ios-switch switch-green switch-xs">
                            <input
                              type="checkbox"
                              className="ios-input"
                              id="switch-1s"
                              value={userData?.isLive}
                              defaultChecked={userData?.isLive}
                              onClick={(e: any) => handleGoLive(e)}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="switch-1s"
                            ></label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <br />
                  <FooterContainer
                    productsheet={"productreq-dashb1"}
                    offersheet={"offerreq-dashb1"}
                  />
                  <div
                    id="productreq-dashb1"
                    className="offcanvas offcanvas-bottom offcanvas-detached  offcanvas-style "
                    aria-modal="true"
                    role="dialog"
                  >
                    <ProductList
                      instock={"footerprod3"}
                      outofstock={"outfooterprod3"}
                    />
                  </div>
                  <div
                    id="offerreq-dashb1"
                    className="offcanvas offcanvas-bottom offcanvas-detached  offcanvas-style "
                    aria-modal="true"
                    role="dialog"
                  >
                    <OfferList />
                  </div>
                </div>
              ) : role === "merchantUser" ? (
                <div className="page-content home px-3 ">
                  <div className="pt-5 row align-items-center">
                    <div className="col-6 d-flex gap-3">
                      <h1 className="main-h1">Hi {userData?.name}!</h1>
                      <Link
                        to="/login"
                        onClick={() => toast.success("logged out")}
                      >
                        <i className="bi bi-box-arrow-left logout-home"></i>
                      </Link>
                    </div>
                    <div className="col-6 text-right noti-alert d-flex align-items-center justify-content-end">
                      <button
                        className="bg-white main-clr"
                        style={{
                          fontWeight: "bold",
                        }}
                        onClick={(e: any) => {
                          chooseLang(e.target.innerHTML);
                        }}
                      >
                        {language}
                      </button>
                      {/* <Link to="#">
                        <img className="" src={heart} alt="alert" />
                      </Link>
                      <Link to="#">
                        <img className="" src={alert} alt="lang" />
                      </Link> */}
                    </div>
                  </div>

                  <div className="col-12 mb-2 mt-4">
                    <img className="wallet-img" src={wallet} alt="wallet" />
                    <span className="sub-title">Main Account</span>
                  </div>

                  <div className="col-12 points-container d-flex justify-content-center">
                    <div className="d-flex flex-column">
                      <h3>{userData.merchantUser.rule}</h3>
                      <span>{userData?.mobile}</span>

                      <p>
                        Welcome {userData?.merchantUser?.fName}{" "}
                        {userData?.merchantUser?.mName}{" "}
                        {userData?.merchantUser?.lName}
                      </p>
                    </div>
                  </div>

                  <div className="card home-cards ">
                    <div className="">
                      <Link className="d-flex " to="#">
                        <div className="align-self-center">
                          <span className="icon rounded-s me-2 gradient-newgreen  shadow-bg shadow-bg-s">
                            <i
                              className="bi bi-building font-20"
                              style={{
                                color: "#2C236A",
                              }}
                            ></i>
                          </span>
                        </div>
                        <div className="align-self-center ps-3">
                          <h5 className="pt-1 mb-n1">{t("offers")}</h5>
                          <p className="mb-0">
                            {t("totalActiveNonBranches")}{" "}
                            <span className="copyright-year"></span>
                          </p>
                        </div>
                        <div className="align-self-center ms-auto text-end">
                          <i className="bi bi-arrow-up-right"></i>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div
                    // id="menu-branches-home"
                    // className="offcanvas offcanvas-bottom offcanvas-detached rounded-m "
                    style={{ display: "block", visibility: "hidden" }}
                    aria-modal="true"
                    role="dialog"
                  >
                    {/* <BranchesActionSheet /> */}
                  </div>
                  <div className="card home-cards">
                    <div className="">
                      <Link
                        className="d-flex "
                        to="#"
                        // data-bs-toggle="offcanvas"
                        // data-bs-target="#menu-requests-home"
                      >
                        <div className="align-self-center">
                          <span className="icon rounded-s me-2 gradient-newgreen shadow-bg shadow-bg-s">
                            <i
                              className="bi bi-gift  font-20"
                              style={{
                                color: "#2C236A",
                              }}
                            ></i>
                          </span>
                        </div>
                        <div className="align-self-center ps-3">
                          <h5 className="pt-1 mb-n1">{t("requests")}</h5>
                          <p className="mb-0 ">
                            {t("pending requests")}{" "}
                            <span className="copyright-year"></span>
                          </p>
                        </div>
                        <div className="align-self-center ms-auto text-end">
                          <i className="bi bi-arrow-up-right"></i>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className="card home-cards">
                    <div className="">
                      <Link
                        className="d-flex "
                        to="#"
                        // data-bs-toggle="offcanvas"
                        // data-bs-target="#menu-requests-home"
                      >
                        <div className="align-self-center">
                          <span className="icon rounded-s me-2 gradient-newgreen shadow-bg shadow-bg-s">
                            <i
                              className="bi bi-gift  font-20"
                              style={{
                                color: "#2C236A",
                              }}
                            ></i>
                          </span>
                        </div>
                        <div className="align-self-center ps-3">
                          <h5 className="pt-1 mb-n1">{t("activestatus")}</h5>
                          <p className="mb-0 ">
                            {userData?.merchantUser?.isActive
                              ? t("active")
                              : t("deactive")}
                            <span className="copyright-year"></span>
                          </p>
                        </div>
                      </Link>
                    </div>
                  </div>

                  <br />
                  <FooterContainer
                    productsheet={"productreq-dashb2"}
                    offersheet={"offerreq-dashb2"}
                  />
                  <div
                    id="productreq-dashb2"
                    className="offcanvas offcanvas-bottom offcanvas-detached  offcanvas-style "
                    aria-modal="true"
                    role="dialog"
                  >
                    <ProductList
                      instock={"footerprod4"}
                      outofstock={"outfooterprod4"}
                    />
                  </div>
                  <div
                    id="offerreq-dashb2"
                    className="offcanvas offcanvas-bottom offcanvas-detached  offcanvas-style "
                    aria-modal="true"
                    role="dialog"
                  >
                    <OfferList />
                  </div>
                </div>
              ) : (
                <>
                  <div className="page-content home px-3 ">
                    <div className="pt-5 row align-items-center">
                      <div className="col-6 d-flex gap-3">
                        <h1 className="main-h1">Hi {userData?.name}!</h1>
                        <Link
                          to="/login"
                          onClick={() => toast.success("logged out")}
                        >
                          <i className="bi bi-box-arrow-left logout-home"></i>
                        </Link>
                      </div>
                      <div className="col-6 text-right noti-alert d-flex align-items-center justify-content-end">
                        <button
                          className="bg-white main-clr"
                          style={{
                            fontWeight: "bold",
                          }}
                          onClick={(e: any) => {
                            chooseLang(e.target.innerHTML);
                          }}
                        >
                          {language}
                        </button>
                        <Link to="#">
                          <img className="" src={heart} alt="alert" />
                        </Link>
                        <Link to="#">
                          <img className="" src={alert} alt="lang" />
                        </Link>
                      </div>
                    </div>

                    <div className="col-12 mb-2 mt-4">
                      <img className="wallet-img" src={wallet} alt="wallet" />
                      <span className="sub-title">Main Account</span>
                    </div>

                    <div className="col-12 points-container d-flex justify-content-center">
                      <div className="d-flex flex-column">
                        <h3>{userData.merchantUser.rule}</h3>
                        <span>{userData?.mobile}</span>

                        <p>
                          Welcome {userData?.merchantUser?.fName}{" "}
                          {userData?.merchantUser?.mName}{" "}
                          {userData?.merchantUser?.lName}
                        </p>
                      </div>
                    </div>
                    <div className="card home-cards">
                      <div className="">
                        <Link
                          className="d-flex "
                          to="#"
                          // data-bs-toggle="offcanvas"
                          // data-bs-target="#menu-requests-home"
                        >
                          <div className="align-self-center">
                            <span className="icon rounded-s me-2 gradient-newgreen shadow-bg shadow-bg-s">
                              <i
                                className="bi-truck color-white  font-20"
                                style={{
                                  color: "#2C236A",
                                }}
                              ></i>
                            </span>
                          </div>
                          <div className="align-self-center ps-3">
                            <Link to="#" className="pt-1 mb-n1">
                              {t("availableDeliveries")}
                            </Link>
                          </div>
                        </Link>
                      </div>
                    </div>

                    <br />
                  </div>
                  <FooterContainer
                    productsheet={"productreq-dashb3"}
                    offersheet={"offerreq-dashb3"}
                  />
                  <div
                    id="productreq-dashb3"
                    className="offcanvas offcanvas-bottom offcanvas-detached  offcanvas-style "
                    aria-modal="true"
                    role="dialog"
                  >
                    <ProductList
                      instock={"footerprod5"}
                      outofstock={"outfooterprod5"}
                    />
                  </div>
                  <div
                    id="offerreq-dashb3"
                    className="offcanvas offcanvas-bottom offcanvas-detached  offcanvas-style "
                    aria-modal="true"
                    role="dialog"
                  >
                    <OfferList />
                  </div>
                </>
              )}
            </>
          ) : (
            <LoadingContainer />
          )}
        </IonContent>
      </IonPage>
    </>
  );
};

export default DashBoard;
