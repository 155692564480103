// user
export const LOGIN_USER = "LOGIN_USER";
export const LOGOUT_USER = "LOGOUT_USER";
export const UPDATE_USER = "UPDATE_USER";
export const CREATE_USER = "CREATE_USER";
export const FORGET_PASSWORD_USER = "  FORGET_PASSWORD_USER";
export const CHANGE_PASSWORD_USER = "ForgetPassword_USER";

// Toggle Spinner
export const UPDATE_SPINNER_STATUS = "UPDATE_SPINNER_STATUS";
export const CLEAR_SPINNER_STATUS = "CLEAR_SPINNER_STATUS";

// Upload Progress
export const SET_UPLOAD_PROGRESS = "SET_UPLOAD_PROGRESS";

// Set Branches
export const SET_BRANCHES = "SET_BRANCHES";

// Req List

export const SET_DETAILS = "SET_DETAILS";
