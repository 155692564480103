import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { NodataContainer } from "../../components/common/noDataContainer";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import api from "../../services/api";
import LoadingContainer from "../../components/LoadingContainer";

type CanceledProductProps = {
  id: any;
  showDetails: any;
};

const CanceledProductRequests = ({ id, showDetails }: CanceledProductProps) => {
  const [, requestList] = api.useAxios(
    { url: "/merchant/product/req/list", method: "post" },
    { manual: true }
  );
  const [userListCanceled, setUserListCanceled] = useState<any>();
  const token = useSelector<any>((state) => state.userState.token);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    requestList({ data: { token, isCanceled: true } }).then((res: any) => {
      setUserListCanceled(res?.data.data.productRequests);
      setLoading(false);
    });
  }, []);
  const { t } = useTranslation();
  return (
    <div className="px-1">
      {loading ? (
        <LoadingContainer />
      ) : userListCanceled?.rows?.length !== 0 ? (
        userListCanceled?.rows?.map((canceledRequest: any) => (
          <div key={canceledRequest?.id}>
            <div key={canceledRequest?.id}>
              <div className="d-flex justify-content-between align-items-center p-3 offer-det-list mt-1">
                <div className="d-flex align-items-center gap-3">
                  <div className="align-self-center">
                    <i className="has-bg rounded-xs bi p-1 bi bi-bag requests-bill-container "></i>
                  </div>
                  <div className="align-self-center">
                    <h5 className="  text-capitalize branch-name">
                      {canceledRequest?.user?.name}
                      <br />
                      <span>
                        <strong className="branches-phone">
                          {" "}
                          {t("mobile")} :{" "}
                          <span>{canceledRequest?.user?.mobile}</span>{" "}
                        </strong>
                        <br />
                        <strong className="branches-phone">
                          {" "}
                          {t("product")} :{" "}
                          <span>{canceledRequest?.Product?.name}</span>
                        </strong>{" "}
                        <br />
                        <span className="text-danger font-weight-bold">
                          {canceledRequest?.isCanceled === true
                            ? "Order is Canceled"
                            : "Acive"}
                        </span>{" "}
                        <br />
                      </span>
                    </h5>
                  </div>
                </div>
                <Link
                  to={`/productrequest/${canceledRequest?.id}`}
                  className="align-self-center ms-auto text-end"
                >
                  <span className="btn btn-xxs request-active text-uppercase ">
                    {t("details")}
                  </span>
                </Link>
              </div>
            </div>
          </div>
        ))
      ) : (
        <NodataContainer />
      )}
    </div>
  );
};

export default CanceledProductRequests;
