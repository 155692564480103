import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import api from "../../services/api";
import { IonContent, IonPage } from "@ionic/react";
import FooterContainer from "../../components/common/Footer";
import axios from "axios";
import HeaderContainer from "../../components/common/Header";
import ProductList from "../products/ProductList";
import OfferList from "../offers/OfferList";
import LoadingContainer from "../../components/LoadingContainer";
import gps from "../../assets/images/gps.png";
import { Link } from "react-router-dom";
import QRCode from "react-qr-code";
import moment from "moment";
const ProductRequestDetails: React.FC = () => {
  const productId: any = useParams();
  const { t } = useTranslation();
  const token: any = useSelector<any>((state) => state.userState.token);
  const [productDetails, setProductDetails] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState("Information");
  const role = localStorage.getItem("role");

  const [, productRequestDetails] = api.useAxios(
    { url: "merchant/product/req/details", method: "post" },
    { manual: true }
  );
  const [, PickedProduct] = api.useAxios(
    { url: "merchant/product/req/changeStatus", method: "post" },
    { manual: true }
  );
  const PickedProductRequest = () => {
    PickedProduct({ data: { token, id: productId?.id, status: "done" } })
      .then(() => {
        productRequestDetails({ data: { token, id: productId?.id } })
          .then((res: any) => {
            setProductDetails(res.data.data);
            setLoading(false);
          })
          .catch((err: any) => {
            toast(err?.response?.data.message);
            setLoading(false);
          });
      })
      .catch((err: any) => {
        toast(err?.response?.data.message);
      });
  };
  const [, postApproveRequest] = api.useAxios(
    {
      url: "/user/req/confirm/refund",
      method: "post",
    },
    { manual: true }
  );
  const IMAGEURL = process.env.REACT_APP_IMAGEURL;

  const handleApproveRequest = useCallback(() => {
    postApproveRequest({
      data: {
        token,
        reqId: productDetails?.id,
        comment: productDetails?.refundComment,
      },
    })
      .then(() => {
        productRequestDetails({ data: { token, id: productId?.id } })
          .then((res: any) => {
            setProductDetails(res.data.data);
            setLoading(false);
          })
          .catch((err: any) => {
            toast(err?.response?.data.message);
            setLoading(false);
          });
      })
      .catch((err: any) => {
        toast(err?.response?.data.message);
      });
  }, [
    postApproveRequest,
    productDetails?.id,
    productDetails?.refundComment,
    productId?.id,
    productRequestDetails,
    token,
  ]);

  useEffect(() => {
    setLoading(true);
    productRequestDetails({ data: { token, id: productId?.id } })
      .then((res: any) => {
        setProductDetails(res.data.data);
        setLoading(false);
      })
      .catch((err: any) => {
        toast.error(err?.response?.data.message);
        setLoading(false);
      });
  }, [productId?.id, productRequestDetails, token, handleApproveRequest]);
  return (
    <IonPage>
      {loading ? (
        <LoadingContainer />
      ) : (
        <IonContent fullscreen>
          <HeaderContainer pageTitle={t("productRequestDetails")} />
          <div
            className="content mb-0 product____details____box__modified product____det overflow-scroll"
            style={{ height: "30rem" }}
          >
            <div className="card   border-0  align-items-center header_____box">
              <div className="row px-1 py-2 w-100 align-items-center">
                <div className="col-6">
                  <strong className="no-click title">
                    {productDetails?.Product.name}
                  </strong>
                  <br />

                  <img src={gps} alt="gps" className="me-2" />
                  <strong className=" no-click  user">
                    {productDetails?.type === "pickup"
                      ? productDetails?.Branch?.address
                      : productDetails?.UserAddress.Address?.address1}
                  </strong>
                  <br />
                  <strong className=" no-click  user">
                    created At :{" "}
                    {moment(productDetails?.createdAt).format("DD-MM-YYYY")}
                  </strong>
                </div>

                <div className="col-3">
                  <img
                    src={
                      productDetails?.Product.image !== null
                        ? `${IMAGEURL}${productDetails?.Product.image}`
                        : "./assets/images/pictures/1s.jpg"
                    }
                    width={50}
                    height={50}
                    className="rounded-xs"
                    alt="img"
                  />
                </div>
                <div className="col-3 bordered">
                  <div className="row  flex-col mt-2">
                    <strong className="price">
                      {" "}
                      {productDetails?.Product.price}$
                    </strong>

                    <br />
                    <strong className="points">
                      {productDetails?.Product.points}P
                    </strong>
                  </div>
                </div>
              </div>
            </div>

            <div className="">
              <div className="content mb-0">
                <div className="tabs tabs-pill" id="tab-group-2">
                  <ul className="nav nav-tabs capsuled mt-2" role="tablist">
                    <li className="nav-item">
                      <Link
                        className="nav-link active "
                        data-bs-toggle="tab"
                        to="#"
                        role="tab"
                        onClick={() => {
                          setList("Information");
                        }}
                      >
                        Information
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        data-bs-toggle="tab"
                        to="#"
                        role="tab"
                        onClick={() => {
                          setList("Description");
                        }}
                      >
                        Description
                      </Link>
                    </li>
                  </ul>
                  <div className="tab-content px-2 py-3 ">
                    {list === "Information" ? (
                      <div
                        className="tab-pane fade active show "
                        role="tabpanel"
                      >
                        <div className="row">
                          <div className="d-flex align-items-left flex-column col-6">
                            <strong className="main">User:</strong>
                            <span className="det">
                              {productDetails?.user?.name}
                            </span>
                          </div>
                          <div className="d-flex align-items-left flex-column col-6">
                            <strong className="main">User Mobile:</strong>
                            <span className="det">
                              {productDetails?.user?.mobile}
                            </span>
                          </div>
                          <div className="d-flex align-items-left flex-column col-6">
                            <strong className="main">Stock:</strong>
                            <span className="det">
                              {productDetails?.Product.stockAmount}
                            </span>
                          </div>
                          {/* <div className="d-flex align-items-left flex-column col-6">
                            <strong className="main">Latest Update:</strong>
                            <span className="det">
                              {moment(productDetails?.updatedAt).format(
                                "DD-MM-YYYY"
                              )}
                            </span>
                          </div> */}
                          {productDetails?.type === "pickup" && (
                            <>
                              <div className="d-flex align-items-left flex-column col-6">
                                <strong className="main">Branch Name:</strong>
                                <span className="det">
                                  {productDetails?.Branch?.branchName}
                                </span>
                              </div>
                              <div className="d-flex align-items-left flex-column col-6">
                                <strong className="main">Branch Phone:</strong>
                                <span className="det">
                                  {productDetails?.Branch?.phone}
                                </span>
                              </div>
                            </>
                          )}
                          {productDetails?.type === "delivery" && (
                            <>
                              <div className="d-flex align-items-left flex-column col-6">
                                <strong className="main">
                                  User Address Name:
                                </strong>
                                <span className="det">
                                  {
                                    productDetails?.UserAddress?.Address
                                      .address1
                                  }
                                  {
                                    productDetails?.UserAddress?.Address
                                      .address2
                                  }
                                </span>
                              </div>
                              <div className="d-flex align-items-left flex-column col-6">
                                <strong className="main">area:</strong>
                                <span className="det">
                                  {productDetails?.UserAddress?.Address.area}
                                </span>
                              </div>
                              <div className="d-flex align-items-left flex-column col-6">
                                <strong className="main">city:</strong>
                                <span className="det">
                                  {productDetails?.UserAddress?.Address.city}
                                </span>
                              </div>
                              <div className="d-flex align-items-left flex-column col-6">
                                <strong className="main">long:</strong>
                                <span className="det">
                                  {productDetails?.UserAddress?.Address.long}
                                </span>
                              </div>
                              <div className="d-flex align-items-left flex-column col-6">
                                <strong className="main">lat:</strong>
                                <span className="det">
                                  {productDetails?.UserAddress?.Address.lat}
                                </span>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    ) : (
                      <div className="tab-pane active show" role="tabpanel">
                        <div>
                          <strong className="desc">Description</strong> <br />
                          <strong className="desc-det">
                            {productDetails?.Product.description}
                          </strong>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {productDetails?.id &&
              productDetails?.status === "refund.pending" ? (
                <div className="card card-style pb-5 mb-5 mt-4">
                  <div className="content mb-0">
                    <div className="section m-auto text-center mt-3 full mb-2">
                      <QRCode value={productDetails?.id} />
                      <strong className="d-block no-click scan pt-2">
                        {t("scanNow")}
                      </strong>
                    </div>
                  </div>
                </div>
              ) : (
                ``
              )}
            </div>
          </div>

          {productDetails?.type === "pickup" &&
          productDetails.Product.deliveryAvailability === false &&
          productDetails?.status === "pending" ? (
            <div className="text-center mt-3 pb-5 mb-10-modified">
              <p className="text-danger">PickUp</p>
              <button
                className="btn font-13 rounded-s main-btn "
                onClick={PickedProductRequest}
              >
                Picked
              </button>
            </div>
          ) : null}

          {productDetails?.status === "refund.pending" &&
            productDetails?.isRefund && (
              <div className="text-center mt-3 pb-5 mb-10-modified">
                <button
                  className="btn font-13 rounded-s main-btn"
                  onClick={handleApproveRequest}
                >
                  Refund
                </button>
              </div>
            )}
          {productDetails?.status === "refund.done" && (
            <p className="text-danger text-center mt-3">
              This Product Has Been Refunded , Refund Message is :{" "}
              {productDetails?.refundComment}
            </p>
          )}
          {productDetails?.type === "pickup" &&
            productDetails?.status === "done" && (
              <p className="text-success text-center mt-3">
                This Product Has Been Picked
              </p>
            )}
          {productDetails?.type === "delivery" &&
            productDetails?.status === "done" && (
              <p className="text-success text-center mt-3">
                This Product Has Been Delivered
              </p>
            )}
          {productDetails?.status === "canceled" && (
            <p className="text-danger text-center mt-3">
              This Product Has Been Canceled
            </p>
          )}
          {productDetails?.status === "pending" &&
            productDetails?.type === "delivery" && (
              <p className="text-danger text-center mt-3">
                This Product Hasn't Delivered Yet
              </p>
            )}

          <FooterContainer
            offersheet={"prodreqdetOfferSheet"}
            productsheet={"prodreqdetSheet"}
          />
          <div
            id="prodreqdetSheet"
            className="offcanvas offcanvas-bottom offcanvas-detached  offcanvas-style "
            aria-modal="true"
            role="dialog"
          >
            <ProductList
              instock={"footerprod22"}
              outofstock={"outfooterprod22"}
            />
          </div>
          <div
            id="prodreqdetOfferSheet"
            className="offcanvas offcanvas-bottom offcanvas-detached  offcanvas-style "
            aria-modal="true"
            role="dialog"
          >
            <OfferList />
          </div>
        </IonContent>
      )}
    </IonPage>
  );
};
export default ProductRequestDetails;
