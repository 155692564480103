import { IonContent, IonPage } from "@ionic/react";
import React from "react";
import { ToastContainer } from "react-toastify";
import ChangePasswordContainer from "../../components/ChangePassword";
import FooterContainer from "../../components/common/Footer";
import HeaderContainer from "../../components/common/Header";
import OfferList from "../offers/OfferList";
import ProductList from "../products/ProductList";

const ChangePasswordPage: React.FC = () => {
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <IonPage>
        <IonContent fullscreen>
          <ChangePasswordContainer />
        </IonContent>
        <div
          id="productChangepw"
          className="offcanvas offcanvas-bottom offcanvas-detached  offcanvas-style "
          aria-modal="true"
          role="dialog"
        >
          <ProductList
            instock={"footerprod10"}
            outofstock={"outfooterprod10"}
          />{" "}
        </div>
        <div
          id="offerChangepw"
          className="offcanvas offcanvas-bottom offcanvas-detached  offcanvas-style "
          aria-modal="true"
          role="dialog"
        >
          <OfferList />
        </div>
        <FooterContainer
          offersheet={"offerChangepw"}
          productsheet={"productChangepw"}
        />
      </IonPage>
    </>
  );
};

export default ChangePasswordPage;
