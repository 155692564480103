import { SET_DETAILS } from "../types";
const INITIAL_STATE = {
  user: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case SET_DETAILS:
      return {
        user: { ...state, ...action.data },
      };

    default:
      return state;
  }
};
