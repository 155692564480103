import { IonContent, IonPage } from "@ionic/react";
import RegisterContainer from "../../components/RegisterContainer";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";

const RegisterPage: React.FC = () => {
  const history = useHistory();

  useEffect(() => {
    if (localStorage.getItem("languages")) return history.push("/languages");
    if (localStorage.getItem("user")) return history.push("/home");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={1000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
      />
      <IonPage>
        <IonContent fullscreen>
          <div>
            <RegisterContainer />
          </div>
        </IonContent>
      </IonPage>
    </>
  );
};

export default RegisterPage;
