/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { ErrorMessage } from "@hookform/error-message";
import makeAnimated from "react-select/animated";
import moment from "moment";
import Datetime from "react-datetime";

import { AddOfferSchema } from "../../utils/validationSchema";
import { FormError } from "../../components/form";
import { updateSpinnerStatus } from "../../redux/actions";
import api from "../../services/api";

import "../../translations/i18n";
import "./index.css";
import { IonItem, IonList, IonSelect, IonSelectOption } from "@ionic/react";
type OfferProps = {
  backState?: any;
  setUpdate?: any;
  update?: any;
  from?: string;
  setList?: any;
};
const AddOffer = ({
  backState,
  update,
  setUpdate,
  from,
  setList,
}: OfferProps) => {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const token = useSelector<any>((state) => state.userState.token);
  const [branches, setBranches] = useState<any>([]);
  const [selectedBranch, setSelectedBranch] = useState([]);
  const rule = localStorage.getItem("role");
  const [tags, setTags] = useState<any>([]);
  const [selectedTag, setSelectedTag] = useState([]);

  const [, getBranches] = api.useAxios(
    { url: "/merchant/branch/list", method: "post" },
    { manual: true }
  );

  const [, getTags] = api.useAxios(
    { url: "/merchant/tag/list", method: "post" },
    { manual: true }
  );

  useEffect(() => {
    const limit = 1000;
    getBranches({ data: { limit, token } })
      .then((res) => {
        const branchesBeforeFilter = res.data.data.rows;
        const filteredBranchesToValueAndLabel = branchesBeforeFilter?.map(
          (item: any) => ({
            value: item.id,
            label: item.branchName,
          })
        );
        setBranches(filteredBranchesToValueAndLabel);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [getBranches, token]);

  useEffect(() => {
    const limit = 1000;
    rule === "merchant" &&
      getTags({ data: { pageNum: 1, limit, token } })
        .then((res) => {
          const tagsBeforeFilter = res.data.data.rows;
          const filteredTagsToValueAndLabel = tagsBeforeFilter?.map(
            (item: any) => ({
              value: item.id,
              label: item.name,
            })
          );
          setTags(filteredTagsToValueAndLabel);
        })
        .catch((err) => {
          console.error(err);
        });
  }, [getTags, token]);

  const inputStartProps = {
    placeholder: t("startDate"),
  };

  const inputEndProps = {
    placeholder: t("endDate"),
  };

  const {
    register,
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isValid, isDirty, isSubmitting },
  } = useForm(
    {
      defaultValues: {
        chooseBranches: [],
        chooseTags: [],
        title: "",
        description: "",
        descriptionAr: "",
        titleAr: "",
        price: "",
        startDate: "",
        endDate: "",
        points: "",
        availableNumberOfOffers: "",
      },
      mode: "onChange",
      resolver: yupResolver(AddOfferSchema),
    }
    //changes!
  );

  const [{ loading: addOfferLoading }, addOffer] = api.useAxios(
    { url: "/merchant/offer/add", method: "post" },
    { manual: true }
  );

  const onSubmit = (data: any) => {
    if (Object.keys(errors).length > 0) {
      return;
    }

    data.branchsIds = JSON.stringify(selectedBranch.map((item: any) => item));
    data.tagsIds = JSON.stringify(selectedTag.map((item: any) => item));

    const finalData = { ...data, token };

    addOffer({ data: finalData })
      .then((res: any) => {
        toast.success("added successfully");
        reset();
        console.log(from);
        if (from == "home") {
          return window.location.reload();
        }

        from === "branch" && setUpdate(!update);
        setUpdate(!update);
        backState(false);
      })

      .catch((err: any) => {
        toast(err?.response?.data.message);
      });
  };

  useEffect(() => {
    dispatch(updateSpinnerStatus({ addOfferLoading }));
  }, [addOfferLoading, dispatch]);

  const handleBranchChange = (selectedOption: any) => {
    setSelectedBranch(selectedOption);
    setValue("chooseBranches", selectedOption);
  };

  const handleTagChange = (selectedOption: any) => {
    setSelectedTag(selectedOption);
    setValue("chooseTags", selectedOption);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="form-custom form-label form-icon w-100 p-0 m-0 ">
        <IonList lines="none">
          <IonItem className="" style={{ height: "70px" }}>
            <IonSelect
              multiple={true}
              interface="alert"
              placeholder={t("selectBranches")}
              className="form-select rounded-xs d-flex w-100 h-100"
              color=""
              onIonChange={(e: any) => {
                handleBranchChange(e.detail.value);
              }}
            >
              {branches &&
                branches.map((branch: any) => (
                  <IonSelectOption value={branch?.value} key={branch?.value}>
                    {branch.label}
                  </IonSelectOption>
                ))}
            </IonSelect>
            <label
              className="form-label-always-active main-clr font-11 over-background"
              style={{
                top: "3px",
                zIndex: "999",
              }}
            >
              {t("chooseBranches")}
            </label>
          </IonItem>
        </IonList>
        <div className="mb-30">
          <ErrorMessage
            errors={errors}
            name="chooseBranches"
            render={FormError}
          />
        </div>
      </div>
      <div className="pb-2" />

      <div className="form-custom form-label form-icon">
        <i className="bi bi-pencil-fill font-12 main-clr" />
        <input
          className="form-control rounded-xs"
          id="c7"
          type="text"
          {...register("title")}
          placeholder={t("title")}
        />
        <label
          htmlFor="c7"
          className="form-label-always-active main-clr font-11 over-background"
        >
          {t("title")}
        </label>
        <div className="mb-30">
          {errors.title && (
            <ErrorMessage errors={errors} name="title" render={FormError} />
          )}
        </div>
      </div>
      <div className="pb-2" />

      <div className="form-custom form-label form-icon">
        <i className="bi bi-pencil-fill font-12 main-clr" />
        <input
          className="form-control rounded-xs"
          id="c7"
          type="text"
          {...register("titleAr")}
          placeholder={t("titleAr")}
        />
        <label
          htmlFor="c7"
          className="form-label-always-active main-clr font-11 over-background"
        >
          {t("titleAr")}
        </label>
        <div className="mb-30">
          {errors.titleAr && (
            <ErrorMessage errors={errors} name="titleAr" render={FormError} />
          )}
        </div>
      </div>
      <div className="pb-2" />

      <div className="form-custom form-label form-icon">
        <input
          className="form-control rounded-xs"
          id="c32"
          type="string"
          {...register("points")}
          placeholder={t("points")}
        />
        <label
          htmlFor="c32"
          className="form-label-always-active main-clr font-11 over-background"
        >
          {t("points")}
        </label>
        <span>{t("required")}</span>
        <div className="mb-30">
          {errors.points && (
            <ErrorMessage errors={errors} name="points" render={FormError} />
          )}
        </div>
      </div>
      <div className="pb-2" />

      <div className="form-custom form-label form-icon">
        <input
          className="form-control rounded-xs"
          id="c147"
          type="string"
          {...register("availableNumberOfOffers")}
          placeholder={t("availableNumberOfOffers")}
        />
        <label
          htmlFor="c147"
          className="form-label-always-active main-clr font-11 over-background"
        >
          {t("availableNumberOfOffers")}
        </label>
        <span>{t("required")}</span>
        <div className="mb-30">
          {errors.points && (
            <ErrorMessage
              errors={errors}
              name="availableNumberOfOffers"
              render={FormError}
            />
          )}
        </div>
      </div>
      <div className="pb-2" />

      <div className="form-custom form-label form-icon">
        <input
          className="form-control rounded-xs"
          id="c7"
          type="text"
          {...register("price")}
          placeholder={t("Price")}
        />
        <label
          htmlFor="c7"
          className="form-label-always-active main-clr font-11 over-background"
        >
          {t("Price")}
        </label>
        <div className="mb-30">
          {errors.price && (
            <ErrorMessage errors={errors} name="price" render={FormError} />
          )}
        </div>
      </div>
      <div className="pb-2" />

      <div className="form-custom form-label form-icon">
        <i className="bi bi-pencil-fill font-12 main-clr" />
        <Controller
          control={control}
          name="startDate"
          render={({ field: { onChange } }) => (
            <Datetime
              onChange={(date: any) =>
                onChange(moment(date).format("MM/DD/YYYY"))
              }
              className="text-white bg-dark "
              inputProps={inputStartProps}
              timeFormat={false}
              dateFormat="YYYY-MM-DD"
              closeOnSelect
              // isValidDate={valid}
            />
          )}
        />
        <label
          htmlFor="c7"
          className="form-label-always-active main-clr font-11 over-background"
        >
          {t("startDate")}
        </label>
        <div className="mb-30">
          {errors.startDate && (
            <ErrorMessage errors={errors} name="startDate" render={FormError} />
          )}
        </div>
      </div>
      <div className="pb-2" />

      <div className="form-custom form-label form-icon">
        <i className="bi bi-pencil-fill font-12 main-clr" />
        <Controller
          control={control}
          name="endDate"
          render={({ field: { onChange } }) => (
            <Datetime
              onChange={(date: any) =>
                onChange(moment(date).format("MM/DD/YYYY"))
              }
              inputProps={inputEndProps}
              timeFormat={false}
              dateFormat="YYYY-MM-DD"
              closeOnSelect
              // isValidDate={valid}
            />
          )}
        />
        <label
          htmlFor="c7"
          className="form-label-always-active main-clr font-11 over-background"
        >
          {t("endDate")}
        </label>
        <div className="mb-30">
          {errors.endDate && (
            <ErrorMessage errors={errors} name="endDate" render={FormError} />
          )}
        </div>
      </div>
      <div className="pb-2" />

      <div className="form-custom form-label form-icon mb-3">
        <i className="bi bi-pencil-fill font-12 main-clr" />
        <textarea
          className="form-control rounded-xs"
          id="c7"
          defaultValue={""}
          {...register("description")}
          placeholder={t("description")}
        />
        <label
          htmlFor="c7"
          className="form-label-always-active main-clr font-11 over-background"
        >
          {t("description")}
        </label>
        <div className="mb-30">
          {errors.description && (
            <ErrorMessage
              errors={errors}
              name="description"
              render={FormError}
            />
          )}
        </div>
      </div>
      <div className="pb-2" />

      <div className="form-custom form-label form-icon mb-3">
        <i className="bi bi-pencil-fill font-12 main-clr" />
        <textarea
          className="form-control rounded-xs"
          id="c7"
          defaultValue={""}
          {...register("descriptionAr")}
          placeholder={t("descriptionAr")}
        />
        <label
          htmlFor="c7"
          className="form-label-always-active main-clr font-11 over-background"
        >
          {t("descriptionAr")}
        </label>
        <div className="mb-30">
          {errors.descriptionAr && (
            <ErrorMessage
              errors={errors}
              name="descriptionAr"
              render={FormError}
            />
          )}
        </div>
      </div>
      <div className="pb-2" />
      <div className="form-custom form-label form-icon w-100 p-0 m-0 ">
        <IonList lines="none">
          <IonItem className="" style={{ height: "70px" }}>
            <IonSelect
              multiple={true}
              interface="alert"
              placeholder={t("selectTags")}
              className="form-select rounded-xs d-flex w-100 h-100"
              color=""
              onIonChange={(e: any) => handleTagChange(e.detail.value)}
            >
              {tags &&
                tags.map((tag: any) => (
                  <IonSelectOption value={tag?.value} key={tag?.value}>
                    {tag.label}
                  </IonSelectOption>
                ))}
            </IonSelect>
            <label
              className="form-label-always-active main-clr font-11 over-background"
              style={{
                top: "3px",
                zIndex: "999",
              }}
            >
              {t("chooseTags")}
            </label>
          </IonItem>
        </IonList>
        <div className="mb-30">
          <ErrorMessage errors={errors} name="chooseTags" render={FormError} />
        </div>
      </div>
      <div className="pb-2" />

      <div className="pb-1 text-center">
        <button
          type="submit"
          className="btn btn-full main-btn w-100 rounded-s shadow-bg shadow-bg-xs mt-2 mb-3"
          onClick={() => from === "branch" && setList("Offers")}
        >
          {t("addOffer")}
        </button>
      </div>
    </form>
  );
};

export default AddOffer;
