import { IonContent, IonPage } from "@ionic/react";
import { ToastContainer } from "react-toastify";

import PersonalInformationContainer from "../../components/PersonalInformation";

const PersonalInfomationPage = () => {
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <IonPage>
        <IonContent fullscreen>
          <PersonalInformationContainer />
        </IonContent>
      </IonPage>
    </>
  );
};

export default PersonalInfomationPage;
