import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { toast, ToastContainer } from "react-toastify";
import { ErrorMessage } from "@hookform/error-message";

import { FormError } from "../../components/form";
import api from "../../services/api";

import "../../translations/i18n";

const AddBranch: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const token = useSelector<any>((state) => state.userState.token);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    // resolver: yupResolver(CreateUserValidation),
  });

  const [, addBranch] = api.useAxios(
    { url: "/merchant/addBranch", method: "post" },
    { manual: true }
  );

  const onSubmit = (data: any) => {
    const finalData = { ...data, token };
    addBranch({ data: finalData })
      .then((res: any) => {
        reset();

        window.location.reload();
        toast.success("added successfully");
      })
      .catch((err: any) => {
        toast(err?.response?.data.message);
      });
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div
        className="collapse show mb-5 pb-5"
        id="tab-17"
        data-bs-parent="#tab-group-6"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-custom form-label form-icon mt-3">
            <i className="bi bi-pencil-fill font-12" />
            <input
              className="form-control rounded-xs"
              id="c32"
              type="text"
              placeholder={t("Branch Name")}
              {...register("branchName")}
            />
            <label className="color-highlight" htmlFor="c32">
              {t("Branch Name")}
            </label>
            <span>(required)</span>
            <div className="mb-30">
              <ErrorMessage
                errors={errors}
                name="branchName"
                render={FormError}
              />
            </div>
          </div>
          <div className="pb-2" />

          <div className="form-custom form-label form-icon">
            <i className="bi bi-pencil-fill font-12" />
            <input
              className="form-control rounded-xs"
              id="c32"
              type="text"
              placeholder={t("Phone")}
              {...register("phone")}
            />
            <label htmlFor="c32" className="color-highlight">
              {t("Phone")}
            </label>
            <span>(required)</span>
            <div className="mb-30">
              <ErrorMessage errors={errors} name="phone" render={FormError} />
            </div>
          </div>
          <div className="pb-2" />

          <div className="form-custom form-label form-icon">
            <i className="bi bi-pencil-fill font-12" />
            <input
              className="form-control rounded-xs"
              id="c7"
              type="text"
              placeholder={t("Address")}
              {...register("address")}
            />
            <label htmlFor="c7" className="color-highlight font-11">
              {t("Address")}
            </label>
            <span>(required)</span>
            <div className="mb-30">
              <ErrorMessage errors={errors} name="address" render={FormError} />
            </div>
          </div>
          <div className="pb-2" />

          <div className="form-custom form-label form-icon">
            <i className="bi bi-pencil-fill font-12" />
            <input
              className="form-control rounded-xs"
              id="c7"
              type="text"
              placeholder={t("Lat")}
              {...register("lat")}
            />
            <label htmlFor="c7" className="color-highlight font-11">
              {t("Lat")}
            </label>
            <span>(required)</span>
            <div className="mb-30">
              <ErrorMessage errors={errors} name="lat" render={FormError} />
            </div>
          </div>
          <div className="pb-2" />

          <div className="form-custom form-label form-icon">
            <i className="bi bi-pencil-fill font-12" />
            <input
              className="form-control rounded-xs"
              id="c7"
              type="text"
              placeholder={t("long")}
              {...register("long")}
            />
            <label htmlFor="c7" className="color-highlight font-11">
              {t("long")}
            </label>
            <span>(required)</span>
            <div className="mb-30">
              <ErrorMessage errors={errors} name="long" render={FormError} />
            </div>
          </div>
          <div className="pb-2" />

          <button
            disabled={isSubmitting}
            className="btn btn-full gradient-highlight w-100 rounded-s shadow-bg shadow-bg-xs mt-3 mb-3"
          >
            {t("Add Branch")}
          </button>
        </form>
      </div>
    </>
  );
};

export default AddBranch;
