/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { NodataContainer } from "../../components/common/noDataContainer";

import "../../translations/i18n";

type Props = {
  requests: [];
};

const DeliveryProductRequests: React.FC<Props> = ({ requests }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="card ">
        <div className="content mb-0">
          <div className="tabs tabs-pill" id="tab-group-2">
            {requests?.length === 0 ? (
              <NodataContainer />
            ) : (
              <>
                <div className="collapse show">
                  <div className="table-responsive">
                    <div id="tab-5" data-bs-parent="#tab-group-2">
                      {requests?.map((request: any) => (
                        <>
                          <Link
                            to={`/productrequest/${request.id}`}
                            className="d-flex justify-content-between align-items-center px-3 py-1 employee-list"
                          >
                            <div className="d-flex align-items-center gap-3">
                              <div className="align-self-center">
                                <span
                                  className="icon rounded-s me-2  shadow-bg shadow-bg-xs"
                                  style={{
                                    border: "1px solid rgb(86, 238, 168) ",
                                    backgroundColor: "rgb(86, 238, 168) ",
                                  }}
                                >
                                  <i
                                    className="bi bi-wallet font-18 "
                                    style={{ color: `#092C4C` }}
                                  ></i>
                                </span>
                              </div>
                              <div className="align-self-center ps-1">
                                <h5 className="pt-1 mb-n1 text-capitalize branch-name">
                                  {request.Product.name}
                                </h5>
                                <p className="mb-0 branches-adress">
                                  {t("points")}:{" "}
                                  <span>{request.Product.points}</span>
                                </p>
                                <p className="mb-0 branches-phone">
                                  {t("price")}:
                                  <span> {request?.Product?.price}</span>
                                </p>
                                <p className="mb-0 branches-phone">
                                  {t("status")}:<span> {request.status}</span>
                                </p>
                              </div>
                            </div>
                            <div className="align-self-center ms-auto text-center">
                              <Link
                                to={`/productrequest/${request.id}`}
                                className="btn btn-xxs main-btn   "
                              >
                                {t("details")}
                              </Link>
                            </div>
                          </Link>
                          <div className="divider my-2 opacity-50"></div>
                        </>
                      ))}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default DeliveryProductRequests;
