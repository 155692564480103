import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { toast, ToastContainer } from "react-toastify";
import { ErrorMessage } from "@hookform/error-message";

import api from "../../../services/api";
import { FormError } from "../../../components/form";
import { Link } from "react-router-dom";
import GoogleMapReact from "google-map-react";

type Props = {
  branchData: any;
  update?: any;
  setUpdate?: any;
};

export const UpdateBranch: React.FC<Props> = ({
  branchData,
  update,
  setUpdate,
}) => {
  const [lat, setLat] = useState();
  const [long, setLong] = useState();
  const defaultProps = {
    center: {
      lat: lat || branchData?.lat,
      lng: long || branchData?.long,
    },
    zoom: 14,
  };
  const history = useHistory();
  const { t } = useTranslation();
  const token = useSelector<any>((state) => state.userState.token);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    // resolver: yupResolver(AddOfferSchema),
  });

  const [, updateBranch] = api.useAxios(
    { url: "/merchant/updateBranch", method: "post" },
    { manual: true }
  );

  const onSubmit = (data: any) => {
    data = {
      branchName: data.branchName || branchData.branchName,
      phone: data.phone || branchData.phone,
      address: data.address || branchData.address,
      addressAr: data.addressAr || branchData.addressAr,
      lat: defaultProps.center.lat,
      long: defaultProps.center.lng,
    };

    const finalData = { ...data, token, branchId: branchData.id };
    updateBranch({ data: finalData })
      .then((res: any) => {
        reset();
        setUpdate(!update);
        toast.success("updated successfully");
      })
      .catch((err: any) => {
        toast(err?.response?.data.message);
      });
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div
        className="collapse show mb-3"
        id="tab-17"
        data-bs-parent="#tab-group-6"
      >
        <div className=" mx-auto mb-0 py-2">
          <div
            className="text-center "
            data-bs-toggle="offcanvas"
            data-bs-target="#menu-update-branch"
          >
            <Link to="#" className=" mb-3 btn d-block w-100 main-btn mt-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-3 me-1"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                />
              </svg>{" "}
              <span> {t("updateBranch")}</span>
            </Link>
          </div>
        </div>
        <div
          id="menu-update-branch"
          className="offcanvas offcanvas-bottom offcanvas-detached rounded-m "
          style={{ display: "block", visibility: "hidden" }}
          aria-modal="true"
          role="dialog"
        >
          <div className="menu-size" style={{ height: "600px" }}>
            <div className="d-flex mx-3 mt-3 py-1">
              <div className="align-self-center">
                <h1
                  className="m-0"
                  style={{
                    color: "#092C4C",
                    fontWeight: "700",
                    fontSize: "18px",
                    lineHeight: "30px",
                  }}
                >
                  {t("update")} {branchData?.branchName || `Branch`}
                </h1>
              </div>

              <div className="align-self-center ms-auto">
                <Link
                  to="#"
                  className="ps-4"
                  shadow-0=""
                  me-n2
                  data-bs-dismiss="offcanvas"
                >
                  <i
                    style={{ color: "#092C4C" }}
                    className="bi bi-x font-26 line-height-xl"
                  ></i>
                </Link>
              </div>
            </div>
            <div className="divider divider-margins mt-3"></div>
            <div className="content mt-0">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-custom form-label form-icon mt-3">
                  <i
                    className="bi bi-pencil-fill font-12"
                    style={{ color: "#092C4C" }}
                  />
                  <input
                    className="form-control rounded-xs"
                    id="c32"
                    type="text"
                    placeholder={t("branchName")}
                    defaultValue={branchData?.branchName}
                    {...register("branchName")}
                  />
                  <label
                    className="form-label-always-active font-11"
                    style={{ color: `#092C4C` }}
                    htmlFor="c32"
                  >
                    {t("branchName")}
                  </label>
                  <div className="mb-30">
                    <ErrorMessage
                      errors={errors}
                      name="branchName"
                      render={FormError}
                    />
                  </div>
                </div>
                <div className="pb-2" />

                <div className="form-custom form-label form-icon">
                  <i
                    className="bi bi-pencil-fill font-12"
                    style={{ color: "#092C4C" }}
                  />
                  <input
                    className="form-control rounded-xs"
                    id="c32"
                    type="text"
                    placeholder={t("phone")}
                    {...register("phone")}
                    defaultValue={branchData?.phone}
                  />
                  <label
                    htmlFor="c32"
                    className="form-label-always-active font-11"
                    style={{ color: `#092C4C` }}
                  >
                    {t("phone")}
                  </label>
                  <div className="mb-30">
                    <ErrorMessage
                      errors={errors}
                      name="phone"
                      render={FormError}
                    />
                  </div>
                </div>
                <div className="pb-2" />

                <div className="form-custom form-label form-icon">
                  <i
                    className="bi bi-pencil-fill font-12"
                    style={{ color: "#092C4C" }}
                  />
                  <input
                    className="form-control rounded-xs"
                    id="c7"
                    type="text"
                    placeholder={t("address")}
                    {...register("address")}
                    defaultValue={branchData?.address}
                  />
                  <label
                    htmlFor="c7"
                    className="form-label-always-active font-11"
                    style={{ color: `#092C4C` }}
                  >
                    {t("address")}
                  </label>
                  <div className="mb-30">
                    <ErrorMessage
                      errors={errors}
                      name="address"
                      render={FormError}
                    />
                  </div>
                </div>
                <div className="pb-2" />

                <div className="form-custom form-label form-icon">
                  <i
                    className="bi bi-pencil-fill font-12"
                    style={{ color: "#092C4C" }}
                  />
                  <input
                    className="form-control rounded-xs"
                    id="c7"
                    type="text"
                    placeholder={t("addressAr")}
                    {...register("addressAr")}
                    defaultValue={branchData?.addressAr}
                  />
                  <label
                    htmlFor="c7"
                    className="form-label-always-active font-11"
                    style={{ color: `#092C4C` }}
                  >
                    {t("addressAr")}
                  </label>
                  <div className="mb-30">
                    <ErrorMessage
                      errors={errors}
                      name="addressAr"
                      render={FormError}
                    />
                  </div>
                </div>
                <div className="pb-2" />
                <div style={{ height: 400, width: "100%" }}>
                  <GoogleMapReact
                    center={defaultProps.center}
                    defaultZoom={defaultProps.zoom}
                    bootstrapURLKeys={{
                      key: "AIzaSyDxsUzIfLpefNwPISG4wYuo_qrrKHuMUKQ",
                    }}
                    yesIWantToUseGoogleMapApiInternals={true}
                    onClick={(e: any) => {
                      setLat(e.lat);
                      setLong(e.lng);
                    }}
                  >
                    <Marker
                      lat={defaultProps.center.lat}
                      lng={defaultProps.center.lng}
                      text="check"
                    />
                  </GoogleMapReact>
                </div>
                {/* <div className="form-custom form-label form-icon">
                  <i
                    className="bi bi-pencil-fill font-12"
                    style={{ color: "#092C4C" }}
                  />
                  <input
                    className="form-control rounded-xs"
                    id="c7"
                    type="text"
                    placeholder={t("lat")}
                    {...register("lat")}
                    defaultValue={branchData?.lat}
                  />
                  <label
                    htmlFor="c7"
                    className="form-label-always-active font-11"
                    style={{ color: `#092C4C` }}
                  >
                    {t("lat")}
                  </label>
                  <div className="mb-30">
                    <ErrorMessage
                      errors={errors}
                      name="lat"
                      render={FormError}
                    />
                  </div>
                </div> */}
                <div className="pb-2" />

                {/* <div className="form-custom form-label form-icon">
                  <i
                    className="bi bi-pencil-fill font-12"
                    style={{ color: "#092C4C" }}
                  />
                  <input
                    className="form-control rounded-xs"
                    id="c7"
                    type="text"
                    placeholder={t("long")}
                    {...register("long")}
                    defaultValue={branchData?.long}
                  />
                  <label
                    htmlFor="c7"
                    className="form-label-always-active font-11"
                    style={{ color: `#092C4C` }}
                  >
                    {t("long")}
                  </label>
                  <div className="mb-30">
                    <ErrorMessage
                      errors={errors}
                      name="long"
                      render={FormError}
                    />
                  </div>
                </div>
                <div className="pb-2" /> */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-3 me-1"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                  />
                </svg>
                <button
                  disabled={isSubmitting}
                  className="btn btn-full w-100 main-btn mt-3 mb-3"
                >
                  {t("Update Branch")}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const Marker = ({ text }: any) => {
  return (
    <div className="SuperAwesomePin">
      <img
        height="25"
        width="25"
        src="https://img.icons8.com/color/48/000000/marker--v1.png"
        alt="location"
      />
    </div>
  );
};
