import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { NodataContainer } from "../../../components/common/noDataContainer";
import "../../../translations/i18n";

type Props = {
  offers: any;
};

export const BranchOffers: React.FC<Props> = ({ offers }) => {
  const { t } = useTranslation();
  const [activeOffer, setActiveOffer] = useState<any>();

  const IMAGEURL = process.env.REACT_APP_IMAGEURL;
  return (
    <>
      {offers?.length === 0 ? (
        <NodataContainer />
      ) : (
        <>
          <div className="collapse show">
            <div className="table-responsive">
              <div id="tab-5" data-bs-parent="#tab-group-2">
                <div
                  className="row product_____list justify-content-center"
                  style={{ height: "22rem", overflow: "scroll" }}
                >
                  {offers?.map((offer: any) => (
                    <>
                      <Link
                        key={offer.id}
                        to={`/offer/${offer?.id}`}
                        className={`col-6 gap-3 align-items-center p-3  ${
                          offer?.id === activeOffer
                            ? "product_____list__active"
                            : "product_____list__inactive"
                        }  `}
                        onClick={() => {
                          setActiveOffer(offer?.id);
                        }}
                      >
                        {offer?.images !== null ? (
                          <img
                            src={`${IMAGEURL}${offer?.images[0]}`}
                            width={33}
                            height={50}
                            className=" rounded-xs"
                            alt="img"
                          />
                        ) : (
                          <i
                            className="has-bg rounded-xs bi bi-gift font-20 p-1 "
                            style={{
                              color: "#4ABA99",
                            }}
                          ></i>
                        )}
                        <div>
                          <h5 className=" m-0 text-capitalize name">
                            {offer.title}
                          </h5>
                          <p className="desc mt-1 mb-0">{offer?.description}</p>

                          <div className="d-flex justify-content-between">
                            <span className="points-price">
                              {offer?.price}$
                            </span>

                            <span className="points-price">
                              {offer?.points}P
                            </span>
                          </div>
                        </div>
                      </Link>
                    </>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
