/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { ErrorMessage } from "@hookform/error-message";
import { AddProductSchema } from "../../utils/validationSchema";
import { FormError } from "../../components/form";
import { updateSpinnerStatus } from "../../redux/actions";
import api from "../../services/api";

import makeAnimated from "react-select/animated";
import { IonItem, IonList, IonSelect, IonSelectOption } from "@ionic/react";

import "../../translations/i18n";
import "./index.css";

const deliveryOptions = [
  {
    value: true,
    label: "Yes",
  },
  {
    value: false,
    label: "No",
  },
];
type ProductProps = {
  backState?: any;
  update?: any;
  setUpdate?: any;
  from?: any;
};

const AddProduct = ({ backState, update, setUpdate, from }: ProductProps) => {
  const history = useHistory();
  const { t } = useTranslation();
  const animatedComponents = makeAnimated();
  const dispatch = useDispatch();
  const token = useSelector<any>((state) => state.userState.token);
  const rule = localStorage.getItem("role");
  const [merchant, setMerchant] = useState<any>();

  const [tags, setTags] = useState<any>([]);
  const [selectedTag, setSelectedTag] = useState([]);

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors, isDirty, isValid, isSubmitting },
  } = useForm({
    // defaultValues: { points: "", stockAmount : "", name : "",nameAr : "", description: "", descriptionAr: "", endDate : ""} ,
    mode: "onChange",
    resolver: yupResolver(AddProductSchema),
    //changes!
  });

  const [{ loading: addProductLoading }, AddProduct] = api.useAxios(
    { url: "/merchant/product/create", method: "post" },
    { manual: true }
  );

  const [, merchantData] = api.useAxios(
    {
      url: rule === "merchant" ? `/merchant/me` : `/merchantUser/me`,
      method: "post",
    },
    { manual: true }
  );

  const [, getTags] = api.useAxios(
    { url: "/merchant/tag/list", method: "post" },
    { manual: true }
  );

  useEffect(() => {
    merchantData({ data: { token } })
      .then((res: any) => {
        setMerchant(res?.data?.data);
      })
      .catch((err: any) => {
        toast(err?.response?.data.message);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const limit = 1000;
    rule === "merchant" &&
      getTags({ data: { pageNum: 1, limit, token } })
        .then((res) => {
          const tagsBeforeFilter = res.data.data.rows;
          const filteredTagsToValueAndLabel = tagsBeforeFilter?.map(
            (item: any) => ({
              value: item.id,
              label: item.name,
            })
          );
          setTags(filteredTagsToValueAndLabel);
        })
        .catch((err) => {
          console.error(err);
        });
  }, [getTags, token]);

  const onSubmit = (data: any) => {
    if (Object.keys(errors).length > 0) {
      return;
    }

    data.tagsIds = JSON.stringify(selectedTag.map((item: any) => item));

    const finalData = { ...data, token, merchantId: merchant?.id };
    AddProduct({ data: finalData })
      .then((res: any) => {
        reset();
        toast.success("added successfully");
        if (from) {
          return window.location.reload();
        }
        backState(false);
        setUpdate(!update);
      })
      .catch((err: any) => {
        toast(err?.response?.data.message);
      });
  };

  useEffect(() => {
    dispatch(updateSpinnerStatus({ addProductLoading }));
  }, [addProductLoading, dispatch]);

  const handleTagChange = (selectedOption: any) => {
    setSelectedTag(selectedOption);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="form-custom form-label form-icon mt-3"></div>
      <div className="pb-2" />

      <div className="form-custom form-label form-icon">
        <i className="bi bi-pencil-fill font-12" style={{ color: "#747474" }} />
        <input
          className="form-control rounded-xs"
          id="c7"
          type="text"
          {...register("name")}
          placeholder={t("name")}
        />
        <label
          htmlFor="c7"
          className="form-label-always-active color-highlight font-11"
        >
          {t("name")}
        </label>
        <div className="mb-30">
          <ErrorMessage errors={errors} name="name" render={FormError} />
        </div>
      </div>
      <div className="pb-2" />

      <div className="form-custom form-label form-icon">
        <i className="bi bi-pencil-fill font-12" style={{ color: "#747474" }} />
        <input
          className="form-control rounded-xs"
          id="c7"
          type="text"
          {...register("nameAr")}
          placeholder={t("nameAr")}
        />
        <label
          htmlFor="c7"
          className="form-label-always-active color-highlight font-11"
        >
          {t("nameAr")}
        </label>
        <div className="mb-30">
          <ErrorMessage errors={errors} name="nameAr" render={FormError} />
        </div>
      </div>
      <div className="pb-2" />

      <div className="form-custom form-label form-icon">
        <input
          className="form-control rounded-xs"
          id="c7"
          type="number"
          {...register("price")}
          placeholder={t("price")}
        />
        <label
          htmlFor="c7"
          className="form-label-always-active color-highlight font-11"
        >
          {t("price")}
        </label>
        <div className="mb-30">
          <ErrorMessage errors={errors} name="price" render={FormError} />
        </div>
      </div>
      <div className="pb-2" />

      {/* <div className="form-custom form-label form-icon">
                <i
                  className="bi bi-currency-dollar font-14"
                  style={{ color: "#747474" }}
                />
                <input
                  className="form-control rounded-xs"
                  id="c7"
                  type="text"
                  {...register("price")}
                  placeholder={t("Price")}
                />
                <label
                  htmlFor="c7"
                  className="form-label-always-active color-highlight font-11"
                >
                  {t("Price")}
                </label>
                <div className="mb-30">
                  <ErrorMessage
                    errors={errors}
                    name="price"
                    render={FormError}
                  />
                </div>
              </div>
              <div className="pb-2" /> */}

      <div className="form-custom form-label form-icon w-100 p-0 m-0 ">
        <IonList lines="none">
          <IonItem className="" style={{ height: "70px" }}>
            <IonSelect
              multiple={true}
              interface="alert"
              placeholder={t("selectTags")}
              className="form-select rounded-xs d-flex w-100 h-100"
              color=""
              onIonChange={(e: any) => handleTagChange(e.detail.value)}
            >
              {tags &&
                tags.map((tag: any) => (
                  <IonSelectOption value={tag?.value} key={tag?.value}>
                    {tag.label}
                  </IonSelectOption>
                ))}
            </IonSelect>
            <label
              className="form-label-always-active color-highlight font-11 over-background"
              style={{
                top: "3px",
                zIndex: "999",
              }}
            >
              {t("chooseTags")}
            </label>
          </IonItem>
        </IonList>
      </div>
      <div className="pb-2" />

      <div className="form-custom form-label form-icon">
        <i className="bi bi-pencil-fill font-12" style={{ color: "#747474" }} />
        <input
          className="form-control rounded-xs"
          id="c7"
          type="text"
          {...register("stockAmount")}
          placeholder={t("stockAmount")}
        />
        <label
          htmlFor="c7"
          className="form-label-always-active color-highlight font-11"
        >
          {t("stockAmount")}
        </label>
        <div className="mb-30">
          <ErrorMessage errors={errors} name="stockAmount" render={FormError} />
        </div>
      </div>
      <div className="pb-2" />

      <div className="pb-2" />

      <div className="form-custom form-label form-icon mb-3">
        <i className="bi bi-pencil-fill font-12" style={{ color: "#747474" }} />
        <textarea
          className="form-control rounded-xs"
          id="c7"
          defaultValue={""}
          {...register("description")}
          placeholder={t("description")}
        />
        <label
          htmlFor="c7"
          className="form-label-always-active color-highlight font-11"
        >
          {t("description")}
        </label>
        <div className="mb-30">
          <ErrorMessage errors={errors} name="description" render={FormError} />
        </div>
      </div>
      <div className="pb-2" />

      <div className="form-custom form-label form-icon mb-3">
        <i className="bi bi-pencil-fill font-12" style={{ color: "#747474" }} />
        <textarea
          className="form-control rounded-xs"
          id="c7"
          defaultValue={""}
          {...register("descriptionAr")}
          placeholder={t("descriptionAr")}
        />
        <label
          htmlFor="c7"
          className="form-label-always-active color-highlight font-11"
        >
          {t("descriptionAr")}{" "}
        </label>
        <div className="mb-30">
          <ErrorMessage
            errors={errors}
            name="descriptionAr"
            render={FormError}
          />
        </div>
      </div>
      <div className="pb-2" />

      {/* <div className="form-custom form-label form-icon mb-5">
          <Controller
            control={control}
            name="deliveryAvailability"
            render={({ field: { onChange } }) => (
              <Select
                options={deliveryOptions}
                getOptionValue={(option: any) => option.value}
                getOptionLabel={(option: any) => option.label}
                components={animatedComponents}
                styles={selectStyles}
                onChange={(option: any) => onChange(option.value)}
                placeholder="Select delivery availability"
              />
            )}
          />
          <label htmlFor="c2" className="color-highlight">
            Is available for delivery?
          </label>
          <span>{t("required")}</span>
        </div> */}

      <button type="submit" className="btn btn-full main-btn w-100 s mt-2 mb-3">
        {t("addProduct")}
      </button>
    </form>
  );
};

export default AddProduct;
