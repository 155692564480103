/* eslint-disable @typescript-eslint/no-unused-vars */
import { IonContent, IonPage } from "@ionic/react";
import {
  Route,
  useParams,
  useRouteMatch,
  NavLink,
  Switch,
  Redirect,
  Link,
} from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect, useState, useRef } from "react";
import { toast, ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";

import api from "../../services/api";
import HeaderContainer from "../../components/common/Header";
import FooterContainer from "../../components/common/Footer";

import "../../translations/i18n";
import { url } from "inspector";
import { UpdateProduct } from "./Components/UpdateProduct";
import DefaultFormActionSheet from "../../components/common/DefaultFormActionSheet";
import ProductList from "../products/ProductList";
import OfferList from "../offers/OfferList";
import LoadingContainer from "../../components/LoadingContainer";
import { Rating } from "@mui/material";
type Props = {
  updateProduct: any;
  productState: any;
  ProductDetails: any;
};
const ProductDetailsPage: React.FC<Props> = ({
  updateProduct,
  productState,
  ProductDetails,
}: Props) => {
  const IMAGEURL = process.env.REACT_APP_IMAGEURL;
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const { path } = useRouteMatch();
  const product = useParams<any>();
  const role = localStorage.getItem("role");
  const token: any = useSelector<any>((state) => state.userState.token);
  const [list, setList] = useState("Informaion");
  const fileInputRef: any = useRef();

  const [productData, setProductData] = useState<any>();

  const [, getProductDetials] = api.useAxios(
    {
      url:
        role === "merchant"
          ? "/merchant/product/details"
          : "/merchantUser/product/details",
      method: "post",
    },
    { manual: true }
  );

  const [, uploadProductImage] = api.useAxios(
    { url: "/merchant/product/upload/image", method: "post" },
    { manual: true }
  );

  useEffect(() => {
    setLoading(true);
    getProductDetials({ data: { token, id: product?.id } })
      .then((res) => {
        setProductData(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        toast(err?.response?.data.message);
        setLoading(false);
      });
  }, [getProductDetials, product?.id, token]);

  const handleUploadImage = async (e: any) => {
    e.preventDefault();

    const formData: any = new FormData();

    const finalData = {
      ...formData,
      token,
      productId: product?.id,
      image: e.target.files[0],
    };
    uploadProductImage({ data: finalData })
      .then(() => {
        getProductDetials({ data: { token, id: product?.id } })
          .then((res) => {
            setProductData(res.data.data);
            setLoading(false);
          })
          .catch((err) => {
            toast(err?.response?.data.message);
            setLoading(false);
          });
        toast.success("Image Changed Successfully");
      })
      .catch((err) => {
        toast(err?.response?.data.message);
      });
  };
  console.log(productData);
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <IonPage>
        <IonContent fullscreen>
          {loading ? (
            <LoadingContainer />
          ) : (
            <>
              <HeaderContainer pageTitle={t("productDetails")} />
              <>
                <div className="text-center">
                  <div
                    id="update-setting-product"
                    className="offcanvas offcanvas-bottom offcanvas-detached offcanvas-style "
                    style={{
                      display: "block",
                      visibility: "hidden",
                      height: "650px",
                    }}
                    aria-modal="true"
                    role="dialog"
                  >
                    <DefaultFormActionSheet
                      back={true}
                      title={t("updateProduct")}
                    >
                      <UpdateProduct productData={productData} />
                    </DefaultFormActionSheet>
                  </div>
                </div>
                <div className="content mb-0 product____details____box__modified product____det">
                  <div className="card   border-0  align-items-center header_____box">
                    <div className="row px-1 py-2 w-100 align-items-center">
                      <div className="col-6">
                        <strong className="no-click title">
                          {productData?.name}
                        </strong>
                        <br />
                        <strong className=" no-click  user">
                          {productData?.merchant?.name}
                        </strong>
                        <br />
                        <Rating
                          precision={0.5}
                          readOnly
                          defaultValue={productData?.rate}
                        />
                      </div>
                      <div className="col-3 text-center">
                        <img
                          src={
                            productData?.image !== null
                              ? `${IMAGEURL}${productData?.image}`
                              : "./assets/images/pictures/1s.jpg"
                          }
                          width={50}
                          height={50}
                          className="rounded-xs"
                          alt="img"
                        />
                      </div>
                      <div className="col-3 bordered">
                        <div className="row  flex-col mt-2">
                          <strong className="price">
                            {" "}
                            {productData?.price}$
                          </strong>

                          <br />
                          <strong className="points">
                            {productData?.points}P
                          </strong>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="">
                    <div className="content">
                      <label
                        htmlFor="image"
                        className="main-btn   px-4 py-2 d-flex align-items-center justify-content-center rounded-s mb-1"
                      >
                        <i className="bi bi-images me-1 font-20"></i>
                        {t("uploadProductImage")}
                      </label>
                      <div>
                        <input
                          type="file"
                          onChange={handleUploadImage}
                          id="image"
                          style={{
                            width: `100%`,
                          }}
                          name="image"
                          className="mb-1 rounded d-none"
                        />
                      </div>

                      <img
                        src={
                          productData?.image !== null
                            ? `${IMAGEURL}${productData?.image}`
                            : "./assets/images/pictures/1s.jpg"
                        }
                        width={50}
                        height={50}
                        className="rounded-xs"
                        alt="img"
                      />
                    </div>
                  </div>
                  <div className="">
                    <div className="content mb-0">
                      <div className="tabs tabs-pill" id="tab-group-2">
                        <ul
                          className="nav nav-tabs capsuled mt-2"
                          role="tablist"
                        >
                          <li className="nav-item">
                            <Link
                              className="nav-link active "
                              data-bs-toggle="tab"
                              to="#"
                              role="tab"
                              onClick={() => setList("Informaion")}
                            >
                              Information
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              className="nav-link"
                              data-bs-toggle="tab"
                              to="#"
                              role="tab"
                              onClick={() => setList("Description")}
                            >
                              Description
                            </Link>
                          </li>
                        </ul>
                        <div className="tab-content px-2 py-3 ">
                          {list === "Informaion" ? (
                            <div
                              className="tab-pane  active show "
                              id="Information-proddet"
                              role="tabpanel"
                            >
                              <div className="row">
                                <div className="d-flex align-items-left flex-column col-6">
                                  <strong className="main">Merchant:</strong>
                                  <span className="det">
                                    {productData?.merchant?.name}
                                  </span>
                                </div>
                                <div className="d-flex align-items-left flex-column col-6">
                                  <strong className="main">Stock:</strong>
                                  <span className="det">
                                    {productData?.stockAmount}
                                  </span>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div
                              className="tab-pane active show"
                              id="desc-proddet"
                              role="tabpanel"
                            >
                              <div>
                                <strong className="desc">Description</strong>{" "}
                                <br />
                                <strong className="desc-det">
                                  {productData?.description}
                                </strong>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-center my-1 mb-5 pb-5 mb-3 content">
                  <button
                    className=" btn d-block w-100 main-btn  mt-3 mb-5"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#update-setting-product"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-3 me-1"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                      />
                    </svg>
                    {t("updateProduct")}
                  </button>
                </div>
              </>
            </>
          )}

          <FooterContainer
            offersheet={"prodDetOfferSheet"}
            productsheet={"prodDetProdSheet"}
          />
          <div
            id="prodDetProdSheet"
            className="offcanvas offcanvas-bottom offcanvas-detached  offcanvas-style "
            aria-modal="true"
            role="dialog"
          >
            <ProductList
              instock={"footerprod21"}
              outofstock={"outfooterprod21"}
            />
          </div>
          <div
            id="prodDetOfferSheet"
            className="offcanvas offcanvas-bottom offcanvas-detached  offcanvas-style "
            aria-modal="true"
            role="dialog"
          >
            <OfferList />
          </div>
        </IonContent>
      </IonPage>
    </>
  );
};

export default ProductDetailsPage;
