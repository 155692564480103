import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
// import swaplaterProfile from "../../src/components/common/swaplaterprofile.jpeg";
import swaplaterProfile from "../assets/images/avatar.svg";

import api from "../services/api";
import "./../translations/i18n";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import DefaultFormActionSheet from "./common/DefaultFormActionSheet";
import PersonalInformationContainer from "./PersonalInformation";

const ProfileContainer = () => {
  const { t } = useTranslation();
  const IMAGEURL = process.env.REACT_APP_IMAGEURL;
  const token = useSelector<any>((state) => state.userState.token);
  const [list, setList] = useState("Information");
  const [activeOffers, setActiveOffers] = useState<any>();
  const role = localStorage.getItem("role");

  const [merchant, setMerchant] = useState<any>(null);

  const [, dashboardData] = api.useAxios(
    {
      url: role === "merchant" ? `/merchant/me` : `/merchantUser/me`,
      method: "post",
    },
    { manual: true }
  );

  const [, uploadLogoImage] = api.useAxios(
    { url: "/merchant/upload/logo", method: "post" },
    { manual: true }
  );

  useEffect(() => {
    dashboardData({ data: { token } })
      .then((res: any) => {
        setMerchant(res.data.data);
      })
      .catch((err: any) => {
        toast(err?.response?.data.message);
      });
  }, [dashboardData, token]);

  const handleUploadImage = async (e: any) => {
    e.preventDefault();

    const formData: any = new FormData();

    const finalData = {
      ...formData,
      token,
      logo: e.target.files[0],
    };
    uploadLogoImage({ data: finalData })
      .then(() => {
        window.location.reload();
      })
      .catch((err: any) => {
        toast(err?.response?.data.message);
      });
  };
  const [, goLiveRequest] = api.useAxios(
    { url: `/merchant/goLive`, method: "post" },
    { manual: true }
  );
  const handleGoLive = (e: any) => {
    const data = {
      isLive: e.target.checked,
    };
    const finalData = { ...data, token };
    goLiveRequest({ data: finalData })
      .then((res: any) => {
        if (res.status == 201) {
          return toast.error(res?.data?.message);
        }
        window.location.reload();
      })
      .catch((err: any) => {
        toast(err?.response?.data.message);
      });
  };
  let activeOff: any;
  let inActiveOff: any;
  let activerequests: any;
  const ActiveOffers = merchant?.countNumbOffers.filter((active: any) =>
    active.isActive === true ? (activeOff = active.count) : null
  );

  const ActiveReq = merchant?.countNumbReq.filter((req: any) =>
    req.isAproved === true ? (activerequests = req.count) : null
  );
  const inActiveReq = merchant?.countNumbReq.filter((req: any) =>
    req.isAproved === true ? (activerequests = req.count) : null
  );

  return (
    <>
      <></>
      <div className="relative profile-page">
        {/* {merchant ? ( */}

        <div className="text-center">
          <img
            // src={swaplaterProfile}
            src={
              merchant?.MerchantProfile?.logo !== undefined || null
                ? `${IMAGEURL}${merchant?.MerchantProfile?.logo}`
                : swaplaterProfile
            }
            alt="Avatar"
            width="150"
            height="150"
          />
          <h3 className="color-theme text-cente text-capitalize font-20 mb-0">
            {role === "merchant"
              ? merchant?.name
              : (merchant?.merchantUser.fName || ``) +
                " " +
                (merchant?.merchantUser.mName || ``) +
                " " +
                (merchant?.merchantUser.lName || ``)}
          </h3>
          <p className="text-center font-11 mb-1">
            <i className="bi bi-check-circle-fill color-green-dark pe-2"></i>
            {t("verifiedAccountHolder")}
          </p>
          <button
            className="editprof-btn"
            data-bs-toggle="offcanvas"
            data-bs-target="#edit-profile"
          >
            <i className="font-16 bi bi-pencil-fill"></i>Edit Profile
          </button>
          <div
            id="edit-profile"
            className="offcanvas offcanvas-bottom offcanvas-detached rounded-m "
            style={{ display: "block", visibility: "hidden", height: "32rem" }}
            aria-modal="true"
            role="dialog"
          >
            <DefaultFormActionSheet>
              <PersonalInformationContainer />
            </DefaultFormActionSheet>
          </div>
        </div>
        <div className="p-4 mb-5">
          <ul className="nav nav-tabs capsuled" role="tablist">
            <li className="nav-item">
              <Link
                className="nav-link active "
                data-bs-toggle="tab"
                role="tab"
                to="#"
                onClick={() => {
                  setList("Information");
                }}
              >
                Information{" "}
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link"
                data-bs-toggle="tab"
                to="#"
                role="tab"
                onClick={() => {
                  setList("MerChantInfo");
                }}
              >
                Merchant Info{" "}
              </Link>
            </li>
          </ul>
          <div className="tab-content ">
            {list === "Information" ? (
              <div
                className="tab-pane  active show"
                id="Information"
                role="tabpanel"
              >
                <div className="profile-box p-2 mb-5">
                  <div className="row">
                    <div className="d-flex align-items-left flex-column col-6">
                      <strong className="main">Full Name</strong>
                      <span className="det">
                        {role === "merchant"
                          ? merchant?.name
                          : (merchant?.merchantUser.fName || ``) +
                            " " +
                            (merchant?.merchantUser.mName || ``) +
                            " " +
                            (merchant?.merchantUser.lName || ``)}
                      </span>
                    </div>
                    <div className="d-flex align-items-left flex-column col-6">
                      <strong className="main">Activate Account</strong>
                      <span className="det">
                        <div
                          className="form-switch ios-switch switch-green switch-xs"
                          style={{ width: "0px" }}
                        >
                          <input
                            type="checkbox"
                            className="ios-input"
                            id="switch-1s"
                            value={merchant?.isLive}
                            defaultChecked={merchant?.isLive}
                            onClick={(e: any) => handleGoLive(e)}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="switch-1s"
                          ></label>
                        </div>
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="d-flex align-items-left flex-column col-6">
                      <strong className="main">Phone Number</strong>
                      <span className="det">{merchant?.mobile}</span>
                    </div>
                    <div className="d-flex align-items-left flex-column col-6">
                      <strong className="main">Branches</strong>
                      <span className="det">
                        {merchant?.countNumbBranches[0]?.count}
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="d-flex align-items-left flex-column col-6">
                      <strong className="main">HotLine</strong>
                      <span className="det">
                        {merchant?.merchantProfile?.hotLine}
                      </span>
                    </div>
                    <div className="d-flex align-items-left flex-column col-6">
                      <strong className="main">Refund Period</strong>
                      <span className="det">
                        {merchant?.merchantProfile?.refundPeriod}
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="d-flex align-items-left flex-column col-6">
                      <strong className="main">Employees</strong>
                      <span className="det">
                        {" "}
                        {merchant?.countNumbEmployees[0]?.count} Employees
                      </span>
                    </div>
                    <div className="d-flex align-items-left flex-column col-6">
                      <strong className="main">Active Offers</strong>
                      <span className="color-green-dark font-9">
                        {" "}
                        {activeOff && activeOff > 0 ? activeOff : 0} Offer
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="d-flex align-items-left flex-column col-6">
                      <strong className="main">inActive Offers</strong>
                      <span className="text-danger font-9">
                        {merchant?.countNumbReq[0].count > 0
                          ? merchant?.countNumbReq[0].count
                          : 0}{" "}
                        Offers
                      </span>
                    </div>
                    <div className="d-flex align-items-left flex-column col-6">
                      <strong className="main">Done Offers</strong>
                      <span className="det">
                        {" "}
                        {merchant?.countNumbReq[1].count > 0
                          ? merchant?.countNumbReq[1].count
                          : 0}{" "}
                        Offers
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="d-flex align-items-left flex-column col-6">
                      <strong className="main">Commission Percentage</strong>
                      <span className="text-danger font-9">
                        {merchant?.merchantProfile?.commissionPercentage}
                      </span>
                    </div>
                    <div className="d-flex align-items-left flex-column col-6">
                      <strong className="main">Tax egistrationNumber</strong>
                      <span className="text-danger font-9">
                        {merchant?.merchantProfile?.taxRegistrationNumber}
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="d-flex align-items-left flex-column col-12">
                      <strong className="main">Refund Rules</strong>
                      <span className="text-danger font-9">
                        {merchant?.merchantProfile?.refundRules}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div
                className="tab-pane fade active show"
                id="Statistics"
                role="tabpanel"
              ></div>
            )}
          </div>
        </div>
        {/* ) : ( */}
        <div />
        {/* )} */}
      </div>
    </>
  );
};

export default ProfileContainer;
