import styled from "styled-components";

// import loading from "../assets/images/loading.svg";

import loading from "../assets/images/loading.gif";

const LoadingContainer = () => {
  const Loader = styled.div`
    @keyframes ldio-r4wo7k2d9u-o {
      0% {
        opacity: 1;
        transform: translate(0 0);
      }
      49.99% {
        opacity: 1;
        transform: translate(40px, 0);
      }
      50% {
        opacity: 0;
        transform: translate(40px, 0);
      }
      100% {
        opacity: 0;
        transform: translate(0, 0);
      }
    }
    @keyframes ldio-r4wo7k2d9u {
      0% {
        transform: translate(0, 0);
      }
      50% {
        transform: translate(40px, 0);
      }
      100% {
        transform: translate(0, 0);
      }
    }
    .ldio-r4wo7k2d9u div {
      position: absolute;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      top: 30px;
      left: 10px;
    }
    .ldio-r4wo7k2d9u div:nth-child(1) {
      background: rgb(9, 44, 76);
      animation: ldio-r4wo7k2d9u 1s linear infinite;
      animation-delay: -0.5s;
    }
    .ldio-r4wo7k2d9u div:nth-child(2) {
      background: rgb(86, 238, 168);
      animation: ldio-r4wo7k2d9u 1s linear infinite;
      animation-delay: 0s;
    }
    .ldio-r4wo7k2d9u div:nth-child(3) {
      background: rgb(9, 44, 76);
      animation: ldio-r4wo7k2d9u-o 1s linear infinite;
      animation-delay: -0.5s;
    }
    .loadingio-spinner-dual-ball-xsb3d7x7z7j {
      width: 70px;
      height: 70px;
      display: inline-block;
      overflow: hidden;
      background: none;
    }
    .ldio-r4wo7k2d9u {
      width: 100%;
      height: 100%;
      position: relative;
      transform: translateZ(0) scale(0.7);
      backface-visibility: hidden;
      transform-origin: 0 0; /* see note above */
    }
    .ldio-r4wo7k2d9u div {
      box-sizing: content-box;
    }
  `;

  return (
    <div className="text-center">
      <br />
      <img src={loading} className="text-center svg" />
      <h4 className="loading-text-header">
        Loading <br />
        <span className="loading-text-header-sm">Please wait</span>
      </h4>
     
    </div>
  );
};

export default LoadingContainer;
