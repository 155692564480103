import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { IonApp, IonRouterOutlet, setupIonicReact } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";

import Home from "./pages/Home";
import AboutPage from "./pages/about/index";
import BranchPage from "./pages/branch/index";
// import BranchesPage from "./pages/branches/index";
import AddBranch from "./pages/addBranch/AddBranch";
import CompletePage from "./pages/complete-profile/index";
import CreateUser from "./pages/create-user/index";

import EmployeeProfilePage from "./pages/employee-profile/index";
// import EmployeesPage from "./pages/employees/index";
import AddEmployee from "./pages/addemployee/AddEmployee";
import LoginPage from "./pages/login/index";
import OfferDetailsPage from "./pages/offer-details/index";
// import OffersPage from "./pages/offers/index";
import SearchPage from "./pages/SearchPage";
import AddOffer from "./pages/addOffer/AddOffer";
import ProfilePage from "./pages/profile/index";
import RegisterPage from "./pages/register/index";
import RequestPage from "./pages/request-details/index";
// import RequestsPage from "./pages/requests-list/index";
import RedeemsPage from "./pages/redeems-list/index";
import SettingPage from "./pages/setting/index";
import VerificationPage from "./pages/verification/index";
import LanguagesPage from "./pages/languages/index";
// import PackagePage from "./pages/packages/index";
import PackageDetailsPage from "./pages/packageDetails/PackageDetails";
import ForgetPasswordPage from "./pages/forgetpassword";
import ChangePasswordPage from "./pages/changepassword";
import pendingSettlementPage from "./pages/pendingSettlement/index";

// import AddEmployee from './pages/employees/AddEmployee';

// import AddOffer from './pages/offers/AddOffer';

// import MainPage from "./pages/main/index";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";
/* Theme variables */
import "./theme/variables.css";
import ActivateAccountPage from "./pages/activateaccount";
import PersonalInfomationPage from "./pages/personalinformation";
import InvoicesPage from "./pages/invoices";
import InvoicesDetails from "./pages/invoicesDetails";
// import ProductsPage from "./pages/products";
import ProductDetailsPage from "./pages/ProductDetails";
import RedeemDetailsPage from "./pages/RedeemDetails";
import { UpdateBranch } from "./pages/branch/Components";
import { UpdateOffer } from "./pages/offer-details/Components/UpdateOffer";
import ProductRequests from "./pages/ProductRequests";
import ProductRequestDetails from "./pages/ProductRequestsDetails";
import AvailableDeliveries from "./pages/AvailableDeliveries";
import AvailableDeliveriesDetails from "./pages/AvailableDeliveriesDetails";
// import DeliveryEmployeesPage from "./pages/deliveryEmployee";
import DeliveryEmployeeDetailsPage from "./pages/deliveryEmployee/DeliveryEmployeeDetails";
import DashBoard from "./components/DashBoard/Dashboard";
import initialSettlementPage from "./pages/initialSettlement";
import SettlementDetails from "./pages/SettlementDetails";
import AccountReports from "./pages/AccountReports";
import { useEffect } from "react";
import { ProtectedRoute } from "./components/common/ProtectedRoute";
import AddPaymentMethod from "./pages/addpeymentmethods";
import Transactions from "./pages/Transactions Details";
import TransactionDetails from "./pages/Transactions Details";

import FirbasePushNotifications from "./utils/firebase";
import { Capacitor } from "@capacitor/core";
import TicketDetails from "./pages/tickets/TicketDetails";

const isPushNotificationsAvailable =
  Capacitor.isPluginAvailable("PushNotifications");

// import UpdatedEmployee from "./pages/employees/UpdateEmployee";
// import { useSelector } from "react-redux";
// import { PushNotificationSchema, PushNotifications, Token, ActionPerformed } from '@capacitor/push-notifications';

setupIonicReact();

const App: React.FC = () => {
  const history = useHistory();
  localStorage.setItem("language", "en");

  return (
    <IonApp>
      <IonReactRouter>
        {isPushNotificationsAvailable && <FirbasePushNotifications />}
        <IonRouterOutlet>
          <Switch>
            <ProtectedRoute path="/dashboard">
              <DashBoard />
            </ProtectedRoute>

            <Redirect exact from="/" to="/dashboard" />
            <Route
              exact
              path="/profile/activateaccount"
              component={ActivateAccountPage}
            />
            <Route exact path="/home" component={Home} />
            <Route exact path="/about" component={AboutPage} />
            <Route path="/branch/:id" component={BranchPage} />
            {/* <Route path="/branches" component={BranchesPage} /> */}
            <Route exact path="/add-branch" component={AddBranch} />
            <Route exact path="/:id/update-branch" component={UpdateBranch} />
            <Route exact path="/completeprofile" component={CompletePage} />
            <Route exact path="/createuser" component={CreateUser} />
            <Route exact path="/ticket/:id" component={TicketDetails} />
            <Route
              exact
              path="/transactiondetails"
              component={TransactionDetails}
            />

            <Route
              exact
              path="/productrequest/:id"
              component={ProductRequestDetails}
            />
            {/* <Route path="/productrequests" component={ProductRequests} /> */}

            <Route
              exact
              path="/deliveryProduct/:id"
              component={AvailableDeliveriesDetails}
            />
            <Route
              path="/availableDeliveries"
              component={AvailableDeliveries}
            />

            {/* <Route exact path="/dashboard" component={DashBoard} /> */}
            {/* <Route path="/employeeslist" component={EmployeesPage} /> */}
            {/* <Route
            path="/deliveryemployeeslist"
            component={DeliveryEmployeesPage}
          /> */}
            <Route
              path="/deliveryemployeelist/:id"
              component={DeliveryEmployeeDetailsPage}
            />
            <Route exact path="/employee/:id" component={EmployeeProfilePage} />
            <Route exact path="/add-employee" component={AddEmployee} />
            <Route path="/offer/:id" component={OfferDetailsPage} />

            {/* <Route path="/offers" component={OffersPage} /> */}
            <Route exact path="/:id/update-offer" component={UpdateOffer} />
            <Route path="/product/:id" component={ProductDetailsPage} />
            {/* <Route path="/products" component={ProductsPage} /> */}
            <Route exact path="/add-offer" component={AddOffer} />
            {/* <Route exact path="/add-user" component={AddUser} /> */}
            <Route exact path="/search" component={SearchPage} />
            <Route exact path="/profile" component={ProfilePage} />
            <Route exact path="/request/:id" component={RequestPage} />

            {/* <Route exact path="/requests" component={RequestsPage} /> */}
            <Route exact path="/redeems" component={RedeemsPage} />
            <Route exact path="/redeem/:id" component={RedeemDetailsPage} />
            <Route exact path="/setting" component={SettingPage} />
            {/* <Route exact path="/invoices" component={InvoicesPage} /> */}
            <Route exact path="/invoices/:id" component={InvoicesDetails} />
            <Route exact path="/accountReports" component={AccountReports} />

            {/* <Route
            exact
            path="/initialSettlement"
            component={initialSettlementPage}
          />
          <Route
            exact
            path="/pendingSettlement"
            component={pendingSettlementPage}
          /> */}
            <Route exact path="/settlement/:id" component={SettlementDetails} />
            <Route
              exact
              path="/profile/personalinformation"
              component={PersonalInfomationPage}
            />
            <Route
              exact
              path="/profile/changepassword"
              component={ChangePasswordPage}
            />

            <Route exact path="/verification" component={VerificationPage} />
            <Route exact path="/languages" component={LanguagesPage} />
            {/* <Route exact path="/packages" component={PackagePage} /> */}
            <Route exact path="/package/:id" component={PackageDetailsPage} />
            <Route exact path="/login" component={LoginPage} />
            <Route exact path="/register" component={RegisterPage} />
            <Route
              exact
              path="/forgetpassword"
              component={ForgetPasswordPage}
            />
          </Switch>
        </IonRouterOutlet>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
