import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ErrorMessage } from "@hookform/error-message";
// import loginm from "../assets/images/register-m.svg";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import loginm from "../assets/images/register-dash.gif";

import { loginUser, updateSpinnerStatus } from "../redux/actions";
import { RegisterValidation } from "../utils/validationSchema";
import api from "../services/api";
import { FormError } from "./form";
import "./../translations/i18n";

const RegisterContainer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [loadingset, setLoadingset] = useState(false);
  const [value, setValue] = useState<any>();
  const [phoneErrMssg, setPhoneErrMssg] = useState<any>(false);
  const [country, setCountry] = useState<any>();

  useEffect(() => {
    localStorage.clear();
  }, []);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting, isValid, isDirty },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(RegisterValidation),
  });

  const [{ loading: registerLoading }, registration] = api.useAxios(
    { url: "/merchant/signup", method: "post" },
    { manual: true }
  );

  const onSubmit = (data: any) => {
    if (!isValid || !isDirty) {
      return;
    }
    if (
      value === undefined ||
      value.length < 5 ||
      value.replace(country?.dialCode, "") == ""
    ) {
      return setPhoneErrMssg(true);
    }
    data.userCurrncy = "USD";
    data.mobile = value;
    setLoadingset(true);
    console.log(data);

    registration({ data })
      .then((res: any) => {
        reset();
        data.role = "merchant";
        console.log(data);
        // dispatch(createUser({ ...res.data.data }));
        localStorage.setItem("role", res.data.data.rule);
        dispatch(loginUser({ ...res.data.data }));
        history.push("/profile/activateaccount");
        toast.success("Logged In", {
          closeOnClick: true,
          draggable: false,
          autoClose: 1000,
          hideProgressBar: true,
          style: {
            top: "70px",
          },
        });
        setValue("");
        setLoadingset(false);
      })
      .catch((err: any) => {
        setValue("");
        setPhoneErrMssg(false);
        toast.error(err?.response?.data.message, {
          closeOnClick: true,
          draggable: false,
          autoClose: 1000,
          hideProgressBar: true,
        });
        setLoadingset(false);
      });
  };

  useEffect(() => {
    dispatch(updateSpinnerStatus({ registerLoading }));
  }, [dispatch, registerLoading]);

  return (
    <>
      <div className="pt-0">
        <div className="">
          <div className="section login-header">
            <img className="login-big-img" src={loginm} alt="lang" />
            <h1 className="login-h1 ">{t("registerheader")}</h1>
            <h6
              className="login-h6"
              style={{
                lineHeight: "25px",
              }}
            >
              {t("registerdetails")}
              <br /> {t("registerdetails2")}
            </h6>
          </div>

          <form onSubmit={handleSubmit(onSubmit)} className="px-3">
            <label className="login-label">{t("merchantname")}</label>
            <div className="form-group boxed">
              <input
                className="form-control register-input "
                type="text"
                placeholder={t("merchantname")}
                {...register("name")}
              />

              <span>{t("required")}</span>
              <ErrorMessage errors={errors} name="name" render={FormError} />
            </div>
            <div className="form-group boxed">
              <label className="login-label">{t("username")}</label>
              <PhoneInput
                inputClass="form-control register-input w-100 "
                country="qa"
                inputProps={{
                  name: "phone",
                  required: true,
                  autoFocus: true,
                }}
                inputStyle={{
                  textIndent: "30px",
                }}
                value={value}
                onChange={(e: any) => {
                  setValue(e);
                  setCountry(country);
                }}
              />
              {/* <input
                type="number"
                className="form-control register-input "
                placeholder={t("username")}
                {...register("mobile")}
              />

              <span>{t("required")}</span>
              <ErrorMessage errors={errors} name="mobile" render={FormError} /> */}{" "}
              {phoneErrMssg && (
                <span className="text-danger font-9">Invalid Phone Num</span>
              )}
              */
            </div>
            <label className="login-label">{t("enterpassword")}</label>
            <div className="form-group boxed">
              <input
                type="password"
                className="form-control register-input "
                {...register("password")}
                placeholder={t("enterpassword")}
              />

              <span>{t("required")}</span>
              <ErrorMessage
                errors={errors}
                name="password"
                render={FormError}
              />
            </div>

            <div className="my-4 form-check ">
              <div className="d-flex items-center">
                <input
                  {...register("check")}
                  type="checkbox"
                  className="form-check-input "
                  id="exampleCheck1"
                />

                <label
                  className="form-check-label ps-2   mt-1"
                  htmlFor="exampleCheck1"
                >
                  I agree to Swaplater pay privacy policy
                </label>
              </div>

              <ErrorMessage errors={errors} render={FormError} name="check" />
            </div>

            {/* <input
              hidden
              className="form-control register-input "
              type="text"
              defaultValue="usd"
              placeholder={t("usd")}
              {...register("userCurrncy")}
            /> */}
            {/* <div className="form-custom form-label form-border form-icon mb-4 bg-transparent">
                <i className="bi bi-asterisk font-13"></i>
                <input
                  className="form-control rounded-xs"
                  type="text"
                  placeholder={t("userCurrncy")}
                  {...register("userCurrncy")}
                />
                <label className="color-theme">{t("userCurrncy")}</label>
                <span>{t("required")}</span>
                <ErrorMessage
                  errors={errors}
                  name="userCurrncy"
                  render={FormError}
                />
              </div> */}

            <button
              className="btn main-btn w-100  mt-4"
              disabled={isSubmitting}
              type="submit"
            >
              {t("registernow")}
            </button>
            <div className="row text-center">
              <div className="col-12 ">
                <br />
                <br />
                <p
                  style={{
                    color: "#092C4C",
                  }}
                >
                  {t("alreadyhaveanaccount")}{" "}
                  <Link
                    to="/login"
                    style={{
                      color: "#25E38B",
                    }}
                  >
                    {t("loginnow")}
                  </Link>
                  <br />
                  <Link
                    style={{
                      color: "#092C4C",
                    }}
                    to="/languages"
                  >
                    {t("changelanguage")}
                  </Link>
                </p>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default RegisterContainer;
