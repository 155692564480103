import { IonContent, IonPage } from "@ionic/react";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import FooterContainer from "../../components/common/Footer";
import HeaderContainer from "../../components/common/Header";
import { NodataContainer } from "../../components/common/noDataContainer";
import LoadingContainer from "../../components/LoadingContainer";
import api from "../../services/api";
import OfferList from "../offers/OfferList";
import ProductList from "../products/ProductList";

const TicketDetails: React.FC = () => {
  const { t } = useTranslation();
  const TicketId: any = useParams();
  const token = useSelector<any>((state) => state.userState.token);
  const [ticketDetails, setTicketDetails] = useState<any>();
  const [ticketMessagesList, setTicketMessagesList] = useState<any>();
  const [tab, setTab] = useState("info");
  const [message, setMessage] = useState<any>();
  const [loading, setLoading] = useState(false);

  const [, TicketDetails] = api.useAxios(
    { url: "/merchant/ticket/details", method: "post" },
    { manual: true }
  );
  const [, getTicketMessagesList] = api.useAxios(
    { url: "/merchant/ticket/messageHistory/list", method: "post" },
    { manual: true }
  );
  const [, replayTicketMessage] = api.useAxios(
    { url: "/merchant/userTicket/message/reply", method: "post" },
    { manual: true }
  );
  const [, deleteMessage] = api.useAxios(
    { url: "/merchant/ticket/message/delete", method: "post" },
    { manual: true }
  );

  const HandleReplayMessage = useCallback(() => {
    replayTicketMessage({
      data: { token, ticketId: TicketId?.id, messageBody: message },
    })
      .then(() => {
        toast.success("Replayed Successfully");
        getTicketMessagesList({
          data: { token, ticketId: TicketId?.id },
        }).then((res: any) => {
          setTicketMessagesList(res?.data.data.ticketsHistoryMessage.rows);

          setLoading(false);
        });
      })
      .catch((err: any) => {
        toast.error(err?.response?.data.message);
      });
  }, [
    TicketId?.id,
    getTicketMessagesList,
    message,
    replayTicketMessage,
    token,
  ]);
  console.log(ticketMessagesList);
  const HandleDeleteMessage = useCallback(
    (id) => {
      deleteMessage({ data: { token, messageId: id } })
        .then(() => {
          toast.success("Deleted Successfully");
          getTicketMessagesList({
            data: { token, ticketId: TicketId?.id },
          }).then((res: any) => {
            setTicketMessagesList(res?.data.data.ticketsHistoryMessage.rows);
            setLoading(false);
          });
        })
        .catch((err: any) => {
          toast.error(err?.response?.data.message);
        });
    },

    [TicketId?.id, deleteMessage, getTicketMessagesList, token]
  );
  useEffect(() => {
    setLoading(true);
    TicketDetails({ data: { token, id: TicketId?.id } })
      .then((res) => {
        setTicketDetails(res?.data.data);
        setLoading(false);
      })
      .catch((err: any) => {
        toast.error(err?.response?.data.message);
        setLoading(false);
      });
  }, [TicketId?.id, token]);
  useEffect(() => {
    getTicketMessagesList({ data: { token, ticketId: TicketId?.id } }).then(
      (res: any) => {
        console.log(res.data.data.ticketsHistoryMessage.rows);
        setTicketMessagesList(res?.data.data.ticketsHistoryMessage.rows);
        setLoading(false);
      }
    );
  }, [TicketId?.id, getTicketMessagesList, token]);
  return (
    <IonPage>
      {loading ? (
        <LoadingContainer />
      ) : (
        <IonContent>
          <ToastContainer
            position="top-right"
            autoClose={1000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <HeaderContainer pageTitle={t("Ticket Details")} />

          <div
            className="content mb-0 product____details____box__modified product____det"
            style={{ height: "37rem", overflow: "scroll" }}
          >
            <div className="card   border-0  align-items-center header_____box">
              <div className="row px-1 py-2 w-100 align-items-center">
                <div className="col-6">
                  <strong className=" no-click  title">
                    {ticketDetails?.User?.name}
                  </strong>
                  <br />
                  <strong className="no-click user">
                    {ticketDetails?.status}
                  </strong>
                  <br />
                  <strong className="no-click user">
                    {ticketDetails?.isClosed ? "Closed" : null}
                  </strong>

                  <br />
                </div>
              </div>
            </div>

            <div className="">
              <div className="content mb-0">
                <div className="tabs tabs-pill" id="tab-group-2">
                  <ul className="nav nav-tabs capsuled mt-2" role="tablist">
                    <li className="nav-item">
                      <Link
                        className="nav-link active "
                        data-bs-toggle="tab"
                        to="#"
                        role="tab"
                        onClick={() => {
                          setTab("info");
                        }}
                      >
                        Information
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        data-bs-toggle="tab"
                        to="#"
                        role="tab"
                        onClick={() => {
                          setTab("messages");
                        }}
                      >
                        Messages
                      </Link>
                    </li>
                  </ul>
                  <div className="tab-content px-2 py-3 ">
                    {tab === "info" ? (
                      <div
                        className="tab-pane fade active show "
                        role="tabpanel"
                      >
                        <div className="row">
                          <div className="d-flex align-items-left flex-column ">
                            <strong className="main">Ticket Message : </strong>
                            <span className="det">
                              {ticketDetails?.ticketBody}
                            </span>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="tab-pane active show" role="tabpanel">
                        <div className="text-center mx-auto mb-5 pb-5">
                          <div className="p-2 messagelist mb-3">
                            {ticketMessagesList.length !== 0 ? (
                              ticketMessagesList?.map(
                                (message: any, i: any) => (
                                  <div className=" card  mb-2 ">
                                    <div className="card-body">
                                      <p className="messagelist-from mb-0">
                                        {i === 0 ? "Recently" : ""}
                                      </p>
                                      <p className="messagelist-body mb-1">
                                        {message?.messageBody}
                                      </p>
                                      <p className="messagelist-recently mb-1">
                                        {message?.adminId ? (
                                          " From Admin"
                                        ) : (
                                          <p className="messagelist-recently">
                                            From User{" "}
                                            {!ticketDetails?.isClosed ? (
                                              <i
                                                onClick={() =>
                                                  HandleDeleteMessage(
                                                    message?.id
                                                  )
                                                }
                                                className="bi bi-x-circle position-absolute"
                                                style={{
                                                  right: "5px",
                                                  top: "8px",
                                                  fontSize: "20px",
                                                }}
                                              />
                                            ) : null}
                                          </p>
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                )
                              )
                            ) : (
                              <NodataContainer />
                            )}
                          </div>
                          {!ticketDetails.isClosed &&
                          ticketMessagesList?.length !== 0 ? (
                            <>
                              <textarea
                                className="form-control main-clr rounded-xs"
                                placeholder="Message"
                                cols={30}
                                rows={10}
                                onChange={(e: any) => {
                                  setMessage(e.target.value);
                                }}
                              />

                              <div className="p-3">
                                <button
                                  className="btn main-btn btn-block btn-lg"
                                  onClick={HandleReplayMessage}
                                >
                                  Replay
                                </button>
                              </div>
                            </>
                          ) : (
                            <NodataContainer />
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <FooterContainer
            offersheet={"TicketDetailsOfferSheet"}
            productsheet={"TicketDetailsProductSheet"}
          />
          <div
            id="TicketDetailsProductSheet"
            className="offcanvas offcanvas-bottom offcanvas-detached  offcanvas-style "
            aria-modal="true"
            role="dialog"
          >
            <ProductList
              instock={"footerprod23"}
              outofstock={"outfooterprod23"}
            />
          </div>
          <div
            id="TicketDetailsOfferSheet"
            className="offcanvas offcanvas-bottom offcanvas-detached  offcanvas-style "
            aria-modal="true"
            role="dialog"
          >
            <OfferList />
          </div>
        </IonContent>
      )}
    </IonPage>
  );
};

export default TicketDetails;
