import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import api from "../../services/api";
import LoadingContainer from "../../components/LoadingContainer";
import { toast } from "react-toastify";
import "../../translations/i18n";
import { IonInfiniteScroll, IonInfiniteScrollContent } from "@ionic/react";
import DefaultFormActionSheet from "../../components/common/DefaultFormActionSheet";
import AddProduct from "./AddProduct";
import ProductDetailsPage from "../ProductDetails";
import { UpdateProduct } from "../ProductDetails/Components/UpdateProduct";
import { NodataContainer } from "../../components/common/noDataContainer";
import loginm from "../../assets/images/nothing.svg";

const ProductList = ({ instock, outofstock }: any) => {
  const { t } = useTranslation();
  const token = useSelector<any>((state) => state.userState.token);

  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [products, setProducts] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [addProduct, setAddProduct] = useState(false);
  const [productDetails, setProductDetails] = useState<any>(false);
  const [productId, setProductId] = useState<any>();
  const [inStockProduct, setInStockProducts] = useState<any>();
  const [outOfStockProducts, setOutOfStockProducts] = useState<any>();
  const [updateProduct, setUpdateProduct] = useState<any>(false);
  const [InfiniteDisabled, setInfiniteDisabled] = useState<any>(false);
  const [activeProd, setActiveProd] = useState<any>();
  const [list, setList] = useState("instock");
  const [forceUpdate, setForceUpdate] = useState(false);
  const rule = localStorage.getItem("role");
  let merchantId = localStorage.getItem("merchantId");
  const IMAGEURL = process.env.REACT_APP_IMAGEURL;
  let limit = 10;
  console.log(rule);
  const [, getProducts] = api.useAxios(
    {
      url:
        rule === "merchant"
          ? "/merchant/product/list"
          : "/merchantUser/product/list",
      method: "post",
    },
    { manual: true }
  );

  useEffect(() => {
    setLoading(true);
    try {
      (rule == "merchant" || rule == "merchantUser") &&
        getProducts({
          data: {
            token,
            merchantId: merchantId,
            limit: 1000,
          },
        }).then((res) => {
          setProducts([...res.data.data.rows]);
          setLoading(false);
        });
    } catch (err: any) {
      toast(err?.response?.data.message);
      setLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceUpdate]);

  console.log(products);

  const loadData = useCallback(
    (event: any) => {
      setLoading(false);
      setPage(page + 1);
      setInfiniteDisabled(false);
      setTimeout(() => {
        getProducts({
          data: {
            token,
            merchantId: localStorage.getItem("merchantId"),
            limit,
            pageNum: page + 1,
          },
        }).then((res) => {
          setProducts([...products, ...res.data.data.rows]);
          setTotalCount(res.data.data.count);
        });
        event.target.complete();
        if (products.length < totalCount) {
          return setInfiniteDisabled(false);
        } else {
          return setInfiniteDisabled(true);
        }
      }, 500);
    },
    [page, getProducts, token, limit, products, totalCount]
  );
  useEffect(() => {
    const inStockProducts = products.filter(
      (product: any) => product.stockAmount > 0
    );
    const outOfStockProducts = products.filter(
      (product: any) => product.stockAmount == 0
    );

    setInStockProducts(inStockProducts);
    setOutOfStockProducts(outOfStockProducts);
  }, [products]);

  const renderProducts = () => {
    return (
      <>
        {!addProduct ? (
          <div>
            <h6 className="text-center branch-title m-4"> {t("products")}</h6>
            <i
              data-bs-dismiss="offcanvas"
              className="bi bi-x main-clr font-26 line-height-xl close-offcanvas"
            ></i>

            <div className="">
              <div className=" mb-0">
                <div className="tabs tabs-pill" id="tab-group-2">
                  <ul className="nav nav-tabs capsuled mt-2" role="tablist">
                    <li className="nav-item">
                      <Link
                        className="nav-link active "
                        data-bs-toggle="tab"
                        to="#"
                        onClick={() => {
                          setList("instock");
                        }}
                        role="tab"
                      >
                        In Stock
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        data-bs-toggle="tab"
                        to="#"
                        onClick={() => {
                          setList("outofstock");
                        }}
                        role="tab"
                      >
                        Out Of Stock
                      </Link>
                    </li>
                  </ul>
                  <div className="tab-content  ">
                    {list === "instock" ? (
                      <div
                        className="tab-pane fade active show"
                        id={instock}
                        role="tabpanel"
                      >
                        <span className="total-requests">
                          {inStockProduct?.length}
                          {` `}
                          {inStockProduct?.length > 1 ? "products" : "product"}
                        </span>
                        {inStockProduct?.length === 0 ? (
                          <div className="text-center">
                            <img
                              src={loginm}
                              className="text-center svg-pages"
                            />
                            <h6 className="text-sm">
                              {" "}
                              You don't have any product here
                            </h6>
                          </div>
                        ) : (
                          <div
                            className="row product_____list justify-content-center"
                            style={{ height: "25rem", overflow: "scroll" }}
                          >
                            {inStockProduct?.map((product: any) => (
                              <Link
                                key={product.id}
                                to={`/product/${product?.id}`}
                                onClick={() => {
                                  setActiveProd(product?.id);
                                }}
                                className={`col-6 gap-3 align-items-center p-3  ${
                                  product?.id === activeProd
                                    ? "product_____list__active"
                                    : "product_____list__inactive"
                                }  `}
                              >
                                <img
                                  src={
                                    product?.image !== null
                                      ? `${IMAGEURL}${product?.image}`
                                      : "./assets/images/pictures/1s.jpg"
                                  }
                                  width={50}
                                  height={50}
                                  className="rounded-xs"
                                  alt="img"
                                />

                                <div className="mt-1">
                                  <h6 className="name m-0">{product?.name}</h6>
                                  <div className="">
                                    <p className="desc mt-1 mb-0">
                                      {product?.description}
                                    </p>
                                    <div className="d-flex justify-content-between">
                                      <span className="points-price">
                                        {product?.price}$
                                      </span>

                                      <span className="points-price">
                                        {product?.points}P
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            ))}
                            {/* {products.length < totalCount ? (
                              <IonInfiniteScroll
                                onIonInfinite={loadData}
                                disabled={InfiniteDisabled}
                                threshold="0px"
                              >
                                <IonInfiniteScrollContent
                                  loadingText={"loading"}
                                ></IonInfiniteScrollContent>
                              </IonInfiniteScroll>
                            ) : (
                              ``
                            )} */}
                          </div>
                        )}
                      </div>
                    ) : (
                      <div
                        className="tab-pane fade active show"
                        id={outofstock}
                        role="tabpanel"
                      >
                        <span className="total-requests">
                          {outOfStockProducts?.length}
                          {` `}
                          {outOfStockProducts?.length > 1
                            ? "products"
                            : "product"}
                        </span>
                        {outOfStockProducts?.length === 0 ? (
                          <div className="text-center">
                            <img
                              src={loginm}
                              className="text-center svg-pages"
                            />
                            <h6 className="text-sm">
                              {" "}
                              You don't have any product here
                            </h6>
                          </div>
                        ) : (
                          <div
                            className="row product_____list justify-content-center"
                            style={{ height: "25rem", overflow: "scroll" }}
                          >
                            {outOfStockProducts?.map((product: any) => (
                              <Link
                                key={product.id}
                                to={`/product/${product?.id}`}
                                onClick={() => {
                                  setActiveProd(product?.id);
                                }}
                                className={`col-6 gap-3 align-items-center p-3  ${
                                  product?.id === activeProd
                                    ? "product_____list__active"
                                    : "product_____list__inactive"
                                }  `}
                              >
                                <img
                                  src={
                                    product?.image !== null
                                      ? `${IMAGEURL}${product?.image}`
                                      : "./assets/images/pictures/1s.jpg"
                                  }
                                  width={50}
                                  height={50}
                                  className="rounded-xs"
                                  alt="img"
                                />

                                <div className="mt-3">
                                  <h6 className="name m-0">{product?.name}</h6>
                                  <div className="">
                                    <p className="desc mt-1 mb-0">
                                      {product?.description}
                                    </p>
                                    <div className="d-flex justify-content-between">
                                      <span className="points-price">
                                        {product?.price}$
                                      </span>

                                      <span className="points-price">
                                        {product?.points}P
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            ))}
                            {/* {products.length < totalCount ? (
                              <IonInfiniteScroll
                                onIonInfinite={loadData}
                                disabled={InfiniteDisabled}
                                threshold="0px"
                              >
                                <IonInfiniteScrollContent
                                  loadingText={"loading"}
                                ></IonInfiniteScrollContent>
                              </IonInfiniteScroll>
                            ) : (
                              ``
                            )} */}
                          </div>
                        )}
                      </div>
                    )}

                    {rule == "merchant" ? (
                      <div className="d-flex justify-content-end  px-3 pt-2 ">
                        <button
                          className="addproduct-btn"
                          onClick={() => setAddProduct(true)}
                        >
                          {t("addProduct")}
                        </button>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <DefaultFormActionSheet
            back={true}
            backState={setAddProduct}
            title={t("addProduct")}
          >
            <AddProduct
              backState={setAddProduct}
              update={forceUpdate}
              setUpdate={setForceUpdate}
            />
          </DefaultFormActionSheet>
        )}
      </>
    );
  };

  return (
    <>
      {InfiniteDisabled && products?.length === 0 && <NodataContainer />}
      <div
        className="collapse show content"
        id="tab-16"
        data-bs-parent="#tab-group-6"
      >
        <div className="">
          {loading === true ? <LoadingContainer /> : renderProducts()}
        </div>
        {/* {(lastPage < page || page !== lastPage) && (
          <div className="text-center pb-4">
            <button onClick={handleLoadingMore} className="btn btn-primary">
              {t("See More")}
            </button>
          </div>
        )} */}
      </div>
    </>
  );
};

export default React.memo(ProductList);
