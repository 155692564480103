/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import "./../../translations/i18n";
import { useEffect, useState } from "react";
import alert from "../../assets/images/alert.svg";
import heart from "../../assets/images/heart.svg";
import i18n from "i18next";
type HeaderContainerProps = {
  pageTitle?: any;
  pageIcon?: any;
};

const HeaderContainer = ({ pageIcon, pageTitle }: HeaderContainerProps) => {
  const { t } = useTranslation();
  const user = useSelector<any>((state) => state.userState.token);
  const approvedUser = useSelector<any>((state) => state.userState.isAproved);
  const history = useHistory();

  const backbtn = () => {
    history.goBack();
  };

  useEffect(() => {
    if (user) {
    } else if (approvedUser === false) {
      history.push("/profile/activateaccount");
    } else {
      history.push("/login");
    }
  }, [approvedUser, history, user]);
  const chooseLang = (e: any) => {
    let lang = e === "en" ? "ar" : "en";
    localStorage.setItem("language", lang);
    i18n.changeLanguage(lang);
  };
  const language: any = localStorage.getItem("language");
  return (
    <div className=" row align-items-center p-3 header_____pages">
      <div className="col-3 " onClick={() => history.goBack()}>
        <i className="bi bi-arrow-left-short bg-white main-clr font-20"></i>
      </div>
      <div className="col-5 justify-content-end text-center">
        <h5 className="main_____header">{pageTitle}</h5>
      </div>
      <div className="col-4 text-right noti-alert d-flex align-items-center justify-content-end">
        <button
          className="bg-white main-clr"
          style={{
            fontWeight: "bold",
          }}
          onClick={(e: any) => {
            chooseLang(e.target.innerHTML);
          }}
        >
          {language}
        </button>
        {/* <Link to="notifications">
          <img className="" src={heart} alt="alert" />
        </Link>
        <Link to="notifications">
          <img className="" src={alert} alt="lang" />
        </Link> */}
      </div>
    </div>
  );
};

export default HeaderContainer;
