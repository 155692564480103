import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast, ToastContainer } from "react-toastify";
import { ErrorMessage } from "@hookform/error-message";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import moment from "moment";
import Datetime from "react-datetime";

import { AddOfferSchema } from "../../utils/validationSchema";
import { FormError } from "../../components/form";
import api from "../../services/api";

import "../../translations/i18n";
import { IonContent, IonPage } from "@ionic/react";
import HeaderContainer from "../../components/common/Header";
import FooterContainer from "../../components/common/Footer";

const AddOffer: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const animatedComponents = makeAnimated();
  const token = useSelector<any>((state) => state.userState.token);
  const [branches, setBranches] = useState<any>([]);

  const [, getBranches] = api.useAxios(
    { url: "/merchant/branch/list", method: "post" },
    { manual: true }
  );

  useEffect(() => {
    const limit = 1000;
    getBranches({ data: { pageNum: 1, limit, token } })
      .then((res) => {
        setBranches([...res.data.data.rows]);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [getBranches, token]);

  const inputProps = {
    placeholder: t("Pickup Date"),
  };

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(AddOfferSchema),
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [{ loading: addOfferLoading }, addOffer] = api.useAxios(
    { url: "/merchant/offer/add", method: "post" },
    { manual: true }
  );

  const onSubmit = (data: any) => {
    const finalData = { ...data, token };

    addOffer({ data: finalData })
      .then((res: any) => {
        reset();
        history.push("/setting");
        toast.success("added successfully");
      })
      .catch((err: any) => {
        toast(err?.response?.data.message);
      });
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <IonPage>
        <HeaderContainer pageTitle="Add Offer" />

        <IonContent fullscreen>
          <div className="card card-style">
            <div className="content mb-0">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-custom form-label form-icon mt-3">
                  <Controller
                    control={control}
                    name="branchsIds"
                    render={({ field: { onChange } }) => (
                      <Select
                        options={branches}
                        getOptionValue={(option: any) => option.id}
                        getOptionLabel={(option: any) => option.branchName}
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        isMulti
                        onChange={(options: any) =>
                          onChange(options?.map((option: any) => option.id))
                        }
                      />
                    )}
                  />
                  <label htmlFor="c2" className="color-highlight">
                    {t("Branches")}
                  </label>
                  <span>(required)</span>
                </div>
                <div className="pb-2" />

                <div className="form-custom form-label form-icon">
                  <input
                    className="form-control rounded-xs"
                    id="c32"
                    type="text"
                    placeholder={t("points")}
                    {...register("points")}
                  />
                  <label htmlFor="c32" className="color-highlight">
                    {t("points")}
                  </label>
                  <span>(required)</span>
                  <div className="mb-30">
                    <ErrorMessage
                      errors={errors}
                      name="points"
                      render={FormError}
                    />
                  </div>
                </div>
                <div className="pb-2" />
                <div className="form-custom form-label form-icon">
                  <input
                    className="form-control rounded-xs"
                    id="c147"
                    type="string"
                    {...register("availableNumberOfOffers")}
                    placeholder={t("availableNumberOfOffers")}
                  />
                  <label
                    htmlFor="c147"
                    className="form-label-always-active main-clr font-11 over-background"
                  >
                    {t("availableNumberOfOffers")}
                  </label>
                  <span>{t("required")}</span>
                  <div className="mb-30">
                    {errors.points && (
                      <ErrorMessage
                        errors={errors}
                        name="availableNumberOfOffers"
                        render={FormError}
                      />
                    )}
                  </div>
                </div>
                <div className="pb-2" />

                <div className="form-custom form-label form-icon">
                  <i className="bi bi-pencil-fill font-12" />
                  <input
                    className="form-control rounded-xs"
                    id="c7"
                    type="text"
                    placeholder={t("price")}
                    {...register("price")}
                  />
                  <label htmlFor="c7" className="color-highlight font-11">
                    {t("price")}
                  </label>
                  <div className="mb-30">
                    <ErrorMessage
                      errors={errors}
                      name="price"
                      render={FormError}
                    />
                  </div>
                </div>
                <div className="pb-2" />

                <div className="form-custom form-label form-icon">
                  <i className="bi bi-pencil-fill font-12" />
                  <Controller
                    control={control}
                    name="startDate"
                    render={({ field: { onChange } }) => (
                      <Datetime
                        onChange={(date: any) =>
                          onChange(moment(date).format("YYYY-MM-DD"))
                        }
                        inputProps={inputProps}
                        timeFormat={false}
                        dateFormat="YYYY-MM-DD"
                      />
                    )}
                  />
                  <label htmlFor="c7" className="color-highlight font-11">
                    {t("startDate")}
                  </label>
                  <div className="mb-30">
                    <ErrorMessage
                      errors={errors}
                      name="startDate"
                      render={FormError}
                    />
                  </div>
                </div>
                <div className="pb-2" />

                <div className="form-custom form-label form-icon">
                  <i className="bi bi-pencil-fill font-12" />
                  <Controller
                    control={control}
                    name="endDate"
                    render={({ field: { onChange } }) => (
                      <Datetime
                        onChange={(date: any) =>
                          onChange(moment(date).format("YYYY-MM-DD"))
                        }
                        inputProps={inputProps}
                        timeFormat={false}
                        dateFormat="YYYY-MM-DD"
                      />
                    )}
                  />
                  <label htmlFor="c7" className="color-highlight font-11">
                    {t("startDate")}
                  </label>
                  <div className="mb-30">
                    <ErrorMessage
                      errors={errors}
                      name="endDate"
                      render={FormError}
                    />
                  </div>
                </div>
                <div className="pb-2" />

                <div className="form-custom form-label form-icon mb-3">
                  <i className="bi bi-pencil-fill font-12" />
                  <textarea
                    className="form-control rounded-xs"
                    placeholder="Requested Details"
                    id="c7"
                    defaultValue={""}
                    {...register("description")}
                  />
                  <label htmlFor="c7" className="color-highlight font-11">
                    {t("description")}
                  </label>
                  <div className="mb-30">
                    <ErrorMessage
                      errors={errors}
                      name="description"
                      render={FormError}
                    />
                  </div>
                </div>
                <div className="pb-2" />

                <div className="form-custom form-label form-icon mb-3">
                  <i className="bi bi-pencil-fill font-12" />
                  <textarea
                    className="form-control rounded-xs"
                    placeholder="Requested Details"
                    id="c7"
                    defaultValue={""}
                    {...register("descriptionAr")}
                  />
                  <label htmlFor="c7" className="color-highlight font-11">
                    {t("description in arabic")}
                  </label>
                  <div className="mb-30">
                    <ErrorMessage
                      errors={errors}
                      name="descriptionAr"
                      render={FormError}
                    />
                  </div>
                </div>
                <div className="pb-2" />

                <button
                  disabled={isSubmitting}
                  className="btn btn-full gradient-highlight w-100 rounded-s shadow-bg shadow-bg-xs mt-3 mb-3"
                >
                  Add Offer
                </button>
              </form>
            </div>
          </div>
        </IonContent>
      </IonPage>
    </>
  );
};

export default AddOffer;
