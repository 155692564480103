import { combineReducers } from "redux";
import userState from "./user";
import generalState from "./general";
import requestDetails from "./reqList";

export default combineReducers({
  userState,
  generalState,
  requestDetails,
});
