/* eslint-disable jsx-a11y/anchor-is-valid */
import { IonContent, IonPage } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import FooterContainer from "../../components/common/Footer";
import HeaderContainer from "../../components/common/Header";
import api from "../../services/api";
import "../../translations/i18n";
import { useTranslation } from "react-i18next";
import LoadingContainer from "../../components/LoadingContainer";
import ProductList from "../products/ProductList";
import OfferList from "../offers/OfferList";
import mastercard from "../../assets/images/mastercard.png";
import visa from "../../assets/images/visa.png";
import cash from "../../assets/images/cash.png";
import axios from "axios";
import { baseUrl } from "../../utils/config";
import { Link } from "react-router-dom";
const PackageDetails: React.FC = () => {
  const { t } = useTranslation();
  const packageID = useParams<any>();
  const history = useHistory();
  const token = useSelector<any>((state) => state.userState.token);
  const [loading, setLoading] = useState(false);
  const [packageData, setPackageData] = useState<any>();
  const [showmore, setShowMore] = useState<any>(false);
  const [cardId, setCardId] = useState<any>();

  const [cardlist, setCardList] = useState<any>();
  const [handleShowingCardNum, setHandleShowCardNum] = useState<any>(1);
  const [, getPackageDetials] = api.useAxios(
    { url: "/merchant/package/details", method: "post" },
    { manual: true }
  );
  const [, buyPackage] = api.useAxios(
    { url: "/merchant/req/package", method: "post" },
    { manual: true }
  );
  useEffect(() => {
    setCardId(cardlist?.filter((card: any) => card?.isDefault == true)[0]?.id);
  }, [cardlist]);

  useEffect(() => {
    setLoading(true);
    getPackageDetials({ data: { token, id: packageID.id } })
      .then((res) => {
        setPackageData(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast(err?.response?.data.message);
      });
  }, [getPackageDetials, packageID.id, token]);

  const handleBuyPackage = () => {
    buyPackage({
      data: {
        token,
        packageId: packageData?.id,
        cardId: cardId,
        paymentType: "stripe",
        paymentId: "gh-ftukekqljvnnhaiith",
      },
    })
      .then((res) => {
        history.push("/dashboard");
        toast.success(" successfully");
        window.location.reload();
      })
      .catch((err) => {
        console.error(err);
      });
  };
  useEffect(() => {
    axios({
      url: `${baseUrl}/api/merchant/card/list`,
      method: "post",
      data: { token },
    })
      .then((res) => {
        setCardList(
          res?.data.data.sort((a: any, b: any) =>
            a.isDefault === true ? -1 : 1
          )
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, [history, token]);
  const handleValidDate = (date: any) => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {loading ? (
        <LoadingContainer />
      ) : (
        <IonPage>
          <IonContent fullscreen>
            <HeaderContainer pageTitle={t("packageDetails")} />{" "}
            <div
              id="buyPackage"
              className="offcanvas offcanvas-bottom offcanvas-detached offcanvas-style pt-2"
              style={{
                display: "block",
                visibility: "hidden",
                height: "32rem",
              }}
              aria-modal="true"
              role="dialog"
            >
              {" "}
              <div className="row mt-2 px-4">
                <div className="col-12 ">
                  <div className="payment-methods">
                    <div>
                      <h5 className="opacity-50 mb-3">Payment Methods</h5>
                      <div className="row justify-content-center">
                        {cardlist?.length !== 0 ? (
                          cardlist
                            ?.slice(0, handleShowingCardNum)
                            .map((card: any) => (
                              <div
                                className={`          ${
                                  cardId === card?.id
                                    ? "active-payment-card"
                                    : "border"
                                } 
              
         
                       
                       row mb-2 align-items-center p-2`}
                                key={card?.id}
                                onClick={() => setCardId(card?.id)}
                              >
                                <img className="col-2" src={visa} alt="visa" />
                                <div className=" col-9">
                                  <p className="payment_methods-list mb-0">
                                    {" "}
                                    {card?.nameOnCard}
                                  </p>
                                  <span className="payment_methods-list">
                                    {" "}
                                    {card?.cardNumber}
                                  </span>
                                </div>
                              </div>
                            ))
                        ) : (
                          <div className="text-center d-flex flex-column ">
                            <strong className="text-danger">
                              you don't have any payment Methods
                            </strong>
                            <Link to="/setting">Add Payment Method</Link>
                          </div>
                        )}
                        {cardlist?.length > 1 &&
                          (showmore === false ? (
                            <div className="text-center">
                              <button
                                className="btn btn-secondary btn-block w-75"
                                onClick={() => {
                                  setShowMore(true);
                                  setHandleShowCardNum(cardlist.length);
                                }}
                              >
                                <i className="bi bi-plus-circle-dotted"></i>{" "}
                                show more
                              </button>
                            </div>
                          ) : (
                            <div className="mx-auto text-center">
                              <button
                                className="btn btn-secondary btn-block  w-75"
                                onClick={() => {
                                  setShowMore(false);
                                  setHandleShowCardNum(1);
                                }}
                              >
                                <i className="bi bi-dash-circle-dotted"></i>{" "}
                                show less
                              </button>
                            </div>
                          ))}
                        <div className="row m-2">
                          <div className="col-12">
                            <button
                              className="btn main-btn btn-block w-100"
                              disabled={cardlist?.length === 0 ? true : false}
                              onClick={handleBuyPackage}
                            >
                              Pay Now
                              <label className="total-pay">
                                {packageData?.amount}$
                              </label>
                            </button>
                          </div>
                        </div>
                        ;
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="content invoivces-details">
              <h3 className="text-center mt-3 py-3 ">{packageData?.name}</h3>
              <div className="card  invoivces-details-card">
                <div className="">
                  <div
                    className="d-flex pb-2"
                    style={{
                      borderBottom: "1px dashed #211CA0",
                    }}
                  >
                    <div className="mt-1">
                      <h1 className="mb-2 branch-name">{packageData?.name}</h1>
                      <h5 className="det m-0">{packageData?.description}</h5>
                    </div>
                  </div>

                  <div
                    className="d-flex justify-content-between my-5 pb-2"
                    style={{
                      borderBottom: "1px dashed #211CA0",
                    }}
                  >
                    <div className=" d-flex align-items-center gap-2  flex-column">
                      {" "}
                      <h5 className=" main m-0">{t("points")}</h5>
                      <h5 className=" det m-0"> {packageData?.points}</h5>
                    </div>
                  </div>

                  <div className="d-flex mb-3 justify-content-between align-items-center">
                    <div className="d-flex flex-column align-items-center gap-2 ">
                      <div>
                        <h3 className=" main m-0">{t("total")}</h3>
                      </div>
                      <div className=" price">
                        <h3 className="m-0 ">{packageData?.amount}$ </h3>
                      </div>
                    </div>
                    <div>
                      <i className="bi bi-upc text-dark font-22"></i>
                      <i className="bi bi-upc text-dark font-22"></i>
                      <i className="bi bi-upc text-dark font-22"></i>
                    </div>
                  </div>
                </div>
              </div>
              <button
                // onClick={handleBuyPackage}
                data-bs-toggle="offcanvas"
                data-bs-target="#buyPackage"
                className="btn btn-full  rounded-s main-btn mt-3"
                style={{ backgroundColor: `#56eea8`, color: `#092C4C` }}
              >
                {t("buyNow")}
              </button>
            </div>
            <FooterContainer
              offersheet={"packageOfferSheet"}
              productsheet={"packageProdSheet"}
            />
            <div
              id="packageProdSheet"
              className="offcanvas offcanvas-bottom offcanvas-detached  offcanvas-style "
              aria-modal="true"
              role="dialog"
            >
              <ProductList
                instock={"footerprod18"}
                outofstock={"outfooterprod18"}
              />
            </div>
            <div
              id="packageOfferSheet"
              className="offcanvas offcanvas-bottom offcanvas-detached  offcanvas-style "
              aria-modal="true"
              role="dialog"
            >
              <OfferList />
            </div>
          </IonContent>
        </IonPage>
      )}
    </>
  );
};

export default PackageDetails;
