import { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ToastContainer, toast } from "react-toastify";
import { ErrorMessage } from "@hookform/error-message";
import makeAnimated from "react-select/animated";

import { CreateUserValidation } from "../../utils/validationSchema";
import { FormError } from "../../components/form";
import { loginUser, updateSpinnerStatus } from "../../redux/actions";
import api from "../../services/api";
import "../../translations/i18n";
import FooterContainer from "../../components/common/Footer";
import HeaderContainer from "../../components/common/Header";
import { IonContent, IonPage } from "@ionic/react";
import ProductList from "../products/ProductList";
import OfferList from "../offers/OfferList";

const CreateUser: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const animatedComponents = makeAnimated();
  const token = useSelector<any>((state) => state.userState.token);
  const [branches, setBranches] = useState<any>([]);

  const [, getBranches] = api.useAxios(
    { url: "/merchant/branch/list", method: "post" },
    { manual: true }
  );

  useEffect(() => {
    const limit = 1000;
    getBranches({ data: { limit, token } })
      .then((res) => {
        setBranches([...res.data.data.rows]);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [getBranches]);

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    // resolver: yupResolver(CreateUserValidation),
  });

  const [{ loading: addEmployeeLoading }, addEmployee] = api.useAxios(
    { url: "/merchant/addEmployee", method: "post" },
    { manual: true }
  );

  const onSubmit = (data: any) => {
    const finalData = { ...data, token };
    addEmployee({ data: finalData })
      .then((res: any) => {
        reset();
        dispatch(loginUser({ ...res.data.data }));
        history.push("/dashboard");
        toast.success("successful");
      })
      .catch((err: any) => {
        toast(err?.response?.data.message);
      });
  };

  useEffect(() => {
    dispatch(updateSpinnerStatus({ addEmployeeLoading }));
  }, [addEmployeeLoading, dispatch]);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <IonPage>
        <HeaderContainer pageTitle="Create User" />

        <IonContent fullscreen>
          <div className="login-wrapper">
            <div className="custom-container">
              <div className="register-form mt-4 mb-5 pb-5">
                <h6 className="mb-3 text-center">{t("Create User")}</h6>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-group">
                    <label className="form-label" htmlFor="fName">
                      {t("First Name")}
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder={t("First Name")}
                      {...register("fName")}
                    />
                    <div className="mb-30">
                      <ErrorMessage
                        errors={errors}
                        name="fName"
                        render={FormError}
                      />
                    </div>
                  </div>

                  <div className="form-group">
                    <label className="form-label" htmlFor="fName">
                      {t("Middle Name")}
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder={t("Middle Name")}
                      {...register("mName")}
                    />
                    <div className="mb-30">
                      <ErrorMessage
                        errors={errors}
                        name="mName"
                        render={FormError}
                      />
                    </div>
                  </div>

                  <div className="form-group">
                    <label className="form-label" htmlFor="lName">
                      {t("Last Name")}
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder={t("Last Name")}
                      {...register("lName")}
                    />
                    <div className="mb-30">
                      <ErrorMessage
                        errors={errors}
                        name="lName"
                        render={FormError}
                      />
                    </div>
                  </div>

                  <div className="form-group">
                    <label className="form-label" htmlFor="mobileNum">
                      {t("Mobile Number")}
                    </label>
                    <input
                      className="form-control"
                      type="number"
                      placeholder={t("Mobile Number")}
                      {...register("mobileNum")}
                    />
                    <div className="mb-30">
                      <ErrorMessage
                        errors={errors}
                        name="mobileNum"
                        render={FormError}
                      />
                    </div>
                  </div>

                  <div className="form-group">
                    <label className="form-label" htmlFor="branch">
                      {t("Branch")}
                    </label>
                    <Controller
                      control={control}
                      name="branchId"
                      render={({ field: { onChange } }) => (
                        <Select
                          options={branches}
                          getOptionValue={(option: any) => option.id}
                          getOptionLabel={(option: any) => option.branchName}
                          closeMenuOnSelect={false}
                          components={animatedComponents}
                          onChange={(option: any) => onChange(option.id)}
                        />
                      )}
                    />
                  </div>

                  <div className="form-group">
                    <label className="form-label" htmlFor="rule">
                      {t("Rule")}
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder={t("Rule")}
                      {...register("rule")}
                    />
                    <div className="mb-30">
                      <ErrorMessage
                        errors={errors}
                        name="rule"
                        render={FormError}
                      />
                    </div>
                  </div>

                  <button
                    className="btn btn-primary w-100"
                    disabled={isSubmitting}
                  >
                    {t("Create User")}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </IonContent>
        <FooterContainer
          offersheet={"offerCreateUser"}
          productsheet={"productCreateUser"}
        />
        <div
          id="productCreateUser"
          className="offcanvas offcanvas-bottom offcanvas-detached  offcanvas-style "
          aria-modal="true"
          role="dialog"
        >
          <ProductList
            instock={"footerprod12"}
            outofstock={"outfooterprod12"}
          />
        </div>
        <div
          id="offerCreateUser"
          className="offcanvas offcanvas-bottom offcanvas-detached  offcanvas-style "
          aria-modal="true"
          role="dialog"
        >
          <OfferList />
        </div>
      </IonPage>
    </>
  );
};

export default CreateUser;
