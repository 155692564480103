import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { NodataContainer } from "../../components/common/noDataContainer";

type DeliveryProductProps = {
  data: any;
};

const InProgressDeliveris = ({ data }: DeliveryProductProps) => {
  const { t } = useTranslation();
  return (
    <div className="px-3">
      {data.inProgressDeliveryProductRequests?.rows.length !== 0 ? (
        data?.inProgressDeliveryProductRequests?.rows.map((delivery: any) => (
          <div key={delivery?.id}>
            <div className="row justify-content-between align-items-center p-3 offer-det-list mt-1">
              <div className="align-items-center col-9 d-flex p-0">
                <i className="has-bg rounded-xs bi p-1 bi bi-bag requests-bill-container me-3 "></i>

                <h5 className=" branch-name text-capitalize">
                  {delivery?.User?.name}
                  <br />
                  <strong className="branches-phone">
                    {t("mobile")} : <span>{delivery?.User?.mobile}</span> <br />
                  </strong>
                  <strong className="branches-phone">
                    {" "}
                    {t("address")} :{" "}
                    <span>
                      {delivery?.UserAddress?.Address.address1}{" "}
                      {delivery?.UserAddress?.Address.address2}
                    </span>
                  </strong>{" "}
                  <br />
                  <strong className="branches-phone">
                    {" "}
                    {t("product")} : <span>{delivery?.Product?.name}</span>
                  </strong>
                </h5>
              </div>

              <Link
                to={`/deliveryProduct/${delivery?.id}`}
                className="align-self-center ms-auto text-end col-3"
              >
                <span
                  className="btn btn-xxs main-bg text-uppercase "
                  style={{
                    color: "rgb(86, 238, 168)",
                  }}
                >
                  {t("details")}
                </span>
              </Link>
            </div>

            <div className="divider my-2 opacity-50"></div>
          </div>
        ))
      ) : (
        <NodataContainer />
      )}
    </div>
  );
};

export default InProgressDeliveris;
