import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import api from "../../services/api";
import LoadingContainer from "../../components/LoadingContainer";
import "../../translations/i18n";
import {
  IonContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonPage,
} from "@ionic/react";
import AddDeliveryEmployee from "./AddDeliveryEmployee";
import DefaultFormActionSheet from "../../components/common/DefaultFormActionSheet";
import DeliveryEmployeeDetailsPage from "./DeliveryEmployeeDetails";
import person from "../../assets/images/man-mainclr.png";
import { NodataContainer } from "../../components/common/noDataContainer";
import loginm from "../../assets/images/nothing.svg";

const DeliveryEmployeeList = () => {
  const { t } = useTranslation();
  const token = useSelector<any>((state) => state.userState.token);

  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [deliveryEmployees, setDeliveryEmployees] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [InfiniteDisabled, setInfiniteDisabled] = useState<any>(false);
  const [deliveryEmployee, setDeliveryEmployee] = useState(false);
  const [deliveryEmployeeDetails, setDeliveryEmployeeDetails] = useState(false);
  const [update, setUpdate] = useState();
  const [id, setId] = useState<any>();
  let limit = 10;

  const [, getDeliveryEmployees] = api.useAxios(
    { url: "/merchant/user/list", method: "post" },
    { manual: true }
  );

  useEffect(() => {
    setLoading(true);
    getDeliveryEmployees({
      data: { token, limit: 1000, rule: "delivery" },
    })
      .then((res) => {
        setDeliveryEmployees([...res.data.data.rows]);
        setTotalCount(res.data.data.count);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [update]);

  const loadData = useCallback(
    (event: any) => {
      setLoading(false);
      setPage(page + 1);
      setInfiniteDisabled(false);
      setTimeout(() => {
        getDeliveryEmployees({
          data: { token, pageNum: page + 1, limit },
        }).then((res) => {
          setDeliveryEmployees([...deliveryEmployees, ...res.data.data.rows]);
          setTotalCount(res.data.data.count);
        });
        event.target.complete();
        if (deliveryEmployees.length < totalCount) {
          return setInfiniteDisabled(false);
        } else {
          return setInfiniteDisabled(true);
        }
      }, 500);
    },
    [deliveryEmployees, getDeliveryEmployees, limit, page, token, totalCount]
  );

  const renderOffers = () => {
    return (
      <>
        <IonPage>
          <IonContent fullscreen>
            <div className="content">
              {!deliveryEmployee ? (
                <div>
                  <h6 className="text-center branch-title m-4">
                    {" "}
                    {t("deliveryemployees")}
                  </h6>
                  <i
                    data-bs-dismiss="offcanvas"
                    className="bi bi-x main-clr font-26 line-height-xl close-offcanvas"
                  ></i>

                  {deliveryEmployees?.length == 0 ? (
                    <div className="text-center">
                      <img src={loginm} className="text-center svg-pages" />
                      <h6 className="text-sm">
                        {" "}
                        You don't have any employees here
                      </h6>
                    </div>
                  ) : (
                    <div
                      className="px-3 py-1"
                      style={{ height: "29rem", overflow: "scroll" }}
                    >
                      {deliveryEmployees?.map((employee: any) => (
                        <Link
                          key={employee.id}
                          to={`/deliveryemployeelist/${employee.merchantUser?.id}`}
                          onClick={() => {
                            setDeliveryEmployeeDetails(true);
                            setId(employee?.id);
                          }}
                          className="row align-items-center  employee-list-settings "
                        >
                          <div className="d-flex align-items-center overflow-hidden p-0  col-9">
                            <img src={person} alt="icon" />
                            <div>
                              <h5 className="branch-name mb-1 m-0 ">
                                {employee.merchantUser.fName}{" "}
                                {employee.merchantUser.mName}{" "}
                                {employee.merchantUser.lName}
                              </h5>
                              <span className="mt-0 ">
                                <strong className="d-inline branches-address">
                                  {t("rule")} :
                                  <span>{employee.merchantUser.rule}</span>
                                  &nbsp;&nbsp;&nbsp;
                                </strong>

                                <br />
                                <strong className="d-inline branches-phone">
                                  {t("phone")}:
                                  <span>{employee.merchantUser.mobileNum}</span>
                                </strong>
                              </span>
                            </div>
                          </div>
                          <div className="col-3 text-end">
                            <span className="badge rounded-xl">
                              {employee.merchantUser.isActive ? (
                                <span className="branches-active">
                                  {t("branchActive")}
                                </span>
                              ) : (
                                <span className="branches-inactive">
                                  {t("branchNotActive")}
                                </span>
                              )}
                            </span>
                          </div>
                        </Link>
                      ))}
                      {/* {deliveryEmployees.length < totalCount ? (
                        <IonInfiniteScroll
                          onIonInfinite={loadData}
                          disabled={InfiniteDisabled}
                          threshold="0px"
                        >
                          <IonInfiniteScrollContent
                            loadingText={"loading"}
                          ></IonInfiniteScrollContent>
                        </IonInfiniteScroll>
                      ) : (
                        ``
                      )} */}
                    </div>
                  )}
                  <div className="pb-1">
                    <button
                      className=" btn d-block w-full main-btn  mt-3"
                      style={{ width: "100%" }}
                      onClick={() => setDeliveryEmployee(true)}
                    >
                      {t("adddeliveryemployee")}
                    </button>
                  </div>
                </div>
              ) : (
                <DefaultFormActionSheet
                  back={true}
                  backState={setDeliveryEmployee}
                  title={t("adddeliveryemployee")}
                >
                  <AddDeliveryEmployee
                    backState={setDeliveryEmployee}
                    update={update}
                    setUpdate={setUpdate}
                  />
                </DefaultFormActionSheet>
              )}
            </div>
          </IonContent>
        </IonPage>
      </>
    );
  };

  return (
    <>
      {InfiniteDisabled && deliveryEmployees?.length === 0 && (
        <NodataContainer />
      )}
      <div
        className="collapse show "
        id="tab-16"
        data-bs-parent="#tab-group-6"
        style={{
          height: "600px",
          visibility: "visible",
        }}
      >
        <div className="">
          {loading === true ? <LoadingContainer /> : renderOffers()}
        </div>
        {/* {(lastPage < page || page !== lastPage) && (
          <div className="text-center pb-4">
            <button onClick={handleLoadingMore} className="btn btn-primary">
              {t("See More")}
            </button>
          </div>
        )} */}
      </div>
    </>
  );
};

export default DeliveryEmployeeList;
