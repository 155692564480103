import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { ErrorMessage } from "@hookform/error-message";
import { FormError } from "../../components/form";
import api from "../../services/api";
import GoogleMapReact from "google-map-react";

import "../../translations/i18n";
import { useState } from "react";
type AddProps = {
  setUpdate?: any;
  update?: any;
  updateValue?: any;
};
const AddBranch: React.FC<AddProps> = ({
  setUpdate,
  update,
  updateValue,
}: AddProps) => {
  const [lat, setLats] = useState();
  const [long, setLong] = useState();
  const defaultProps = {
    center: {
      lat: lat || 25.2854,
      lng: long || 51.531,
    },
    zoom: 14,
  };
  const { t } = useTranslation();
  const history = useHistory();
  const token = useSelector<any>((state) => state.userState.token);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    // resolver: yupResolver(CreateUserValidation),
  });

  const [, addBranch] = api.useAxios(
    { url: "/merchant/addBranch", method: "post" },
    { manual: true }
  );

  const onSubmit = (data: any) => {
    data.lat = defaultProps.center.lat;
    data.long = defaultProps.center.lng;
    const finalData = { ...data, token };

    addBranch({ data: finalData })
      .then((res: any) => {
        reset();

        toast.success("added successfully");
        setUpdate(false);
        update(!updateValue);
      })
      .catch((err: any) => {
        toast(err?.response?.data.message);
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="pb-3"></div>
      <div className="form-custom form-label form-icon">
        <i className="bi bi-person font-14 main-clr"></i>
        <input
          type="text"
          className="form-control rounded-xs"
          id="c3"
          {...register("branchName")}
          placeholder={t("branchName")}
          required
        />
        <label
          htmlFor="c3"
          className="form-label-always-active main-clr font-11"
        >
          {t("branchName")}
        </label>
        <span className="font-10">{t("required")}</span>
        <div className="mb-30">
          <ErrorMessage errors={errors} name="branchName" render={FormError} />
        </div>
      </div>
      <div className="pb-3"></div>
      <div className="form-custom form-label form-icon">
        <i className="bi bi-phone font-14 main-clr"></i>
        <input
          type="number"
          className="form-control rounded-xs"
          id="c4"
          {...register("phone")}
          placeholder={t("Phone")}
          required
        />
        <label
          htmlFor="c4"
          className="form-label-always-active main-clr font-11"
        >
          {t("Phone")}
        </label>
        <span>{t("required")}</span>
        <div className="mb-30">
          <ErrorMessage errors={errors} name="phone" render={FormError} />
        </div>
      </div>
      <div className="pb-3"></div>
      <div className="form-custom form-label form-icon">
        <i className="bi bi-house-door font-14 main-clr"></i>
        <input
          type="text"
          className="form-control rounded-xs"
          id="c4"
          {...register("address")}
          placeholder={t("address")}
          required
        />
        <label
          htmlFor="c4"
          className="form-label-always-active main-clr font-11"
        >
          {t("address")}
        </label>
        <span>{t("required")}</span>
        <div className="mb-30">
          <ErrorMessage errors={errors} name="address" render={FormError} />
        </div>
      </div>
      <div className="pb-3"></div>
      <div className="form-custom form-label form-icon">
        <i className="bi bi-house-door font-14 main-clr"></i>
        <input
          type="text"
          className="form-control rounded-xs"
          id="c4"
          {...register("addressAr")}
          placeholder={t("addressAr")}
          required
        />
        <label
          htmlFor="c4"
          className="form-label-always-active main-clr font-11"
        >
          {t("addressAr")}
        </label>
        <span>{t("required")}</span>
        <div className="mb-30">
          <ErrorMessage errors={errors} name="addressAr" render={FormError} />
        </div>
      </div>
      <div className="pb-3"></div>

      <div style={{ height: 400, width: "100%" }}>
        <GoogleMapReact
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}
          bootstrapURLKeys={{
            key: "AIzaSyDxsUzIfLpefNwPISG4wYuo_qrrKHuMUKQ",
          }}
          yesIWantToUseGoogleMapApiInternals={true}
          onClick={(e: any) => {
            setLats(e.lat);
            setLong(e.lng);
          }}
        >
          <Marker
            lat={defaultProps.center.lat}
            lng={defaultProps.center.lng}
            text="check"
          />
        </GoogleMapReact>
      </div>

      {/* <div className="form-custom form-label form-icon">
                <i className="bi bi-geo-alt font-14 main-clr"></i>
                <input
                  type="text"
                  className="form-control rounded-xs"
                  id="c4"
                  {...register("lat")}
                  placeholder={t("lat")}
                  required
                />
                <label
                  htmlFor="c4"
                  className="form-label-always-active main-clr font-11"
                >
                  {t("lat")}
                </label>
                <span>{t("required")}</span>
                <div className="mb-30">
                  <ErrorMessage errors={errors} name="lat" render={FormError} />
                </div>
              </div> */}

      {/* <div className="form-custom form-label form-icon">
                <i className="bi bi-geo-alt font-14 main-clr"></i>
                <input
                  type="text"
                  className="form-control rounded-xs"
                  id="c4"
                  {...register("long")}
                  placeholder={t("long")}
                  required
                />
                <label
                  htmlFor="c4"
                  className="form-label-always-active main-clr font-11"
                >
                  {t("long")}
                </label>
                <span>{t("required")}</span>
                <div className="mb-30">
                  <ErrorMessage
                    errors={errors}
                    name="long"
                    render={FormError}
                  />
                </div>
              </div> */}
      <div className="pb-1 text-center">
        <button
          className=" mb-3 btn d-block w-full main-btn shadow-bg shadow-bg-s mt-3"
          style={{ width: "100%" }}
        >
          {t("addBranch")}
        </button>
      </div>
    </form>
  );
};

export default AddBranch;

const Marker = ({ text }: any) => {
  return (
    <div className="SuperAwesomePin">
      <img
        height="25"
        width="25"
        src="https://img.icons8.com/color/48/000000/marker--v1.png"
        alt="location"
      />
    </div>
  );
};
