import { IonContent, IonPage } from "@ionic/react";
import HeaderContainer from "../../components/common/Header";
import FooterContainer from "../../components/common/Footer";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import api from "../../services/api";
import { toast, ToastContainer } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { ErrorMessage } from "@hookform/error-message";
import { FormError } from "../../components/form";
// import swaplaterProfile from "../../components/common/swaplaterprofile.jpeg";
import swaplaterProfile from "../../assets/images/avatar.svg";

import { Link } from "react-router-dom";
import LoadingContainer from "../../components/LoadingContainer";
type EmployeeProfilePageProps = {
  employeeData: any;
  backState?: any;
  update: any;
  setUpdate: any;
};

const EmployeeProfilePage: React.FC<EmployeeProfilePageProps> = ({
  employeeData,
  backState,
  update,
  setUpdate,
}: EmployeeProfilePageProps) => {
  const { t } = useTranslation();
  const employee = useParams<any>();
  const token = useSelector<any>((state) => state.userState.token);
  const history = useHistory();
  const animatedComponents = makeAnimated();
  const [branches, setBranches] = useState<any>([]);
  const [selected, setSelected] = useState(null);
  const [edit, setEdit] = useState(false);

  const [, getEmployeeDetails] = api.useAxios(
    { url: "/merchant/user/details", method: "post" },
    { manual: true }
  );
  const [, getBranches] = api.useAxios(
    { url: "/merchant/branch/list", method: "post" },
    { manual: true }
  );
  const selectStyles = {
    control: (styles: any) => ({
      ...styles,
      height: "55px",
      color: "rgb(9, 44, 76)",
    }),

    menu: (styles: any) => ({
      ...styles,
      zIndex: " 99999999 !important",
      opacity: "1 !important",
      width: "100%",
      backgroundColor: "white !important",
      height: "100px",
      color: "rgb(9, 44, 76)",
      padding: "10px",
      overflow: "scroll",
    }),
  };

  const {
    register,
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      fName: employeeData?.fName,
      mName: employeeData?.mName,
      lName: employeeData?.lName,
      branchId: employeeData?.branchId,
      rule: employeeData?.rule,
      mobileNum: employeeData?.mobileNum,
    },
    // resolver: yupResolver(CreateUserValidation),
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [{ loading: addEmployeeLoading }, updateEmployee] = api.useAxios(
    { url: "/merchant/employees/update", method: "post" },
    { manual: true }
  );
  // useEffect(() => {
  //   getEmployeeDetails({ data: { token, merchantUserId: employee.id } })
  //     .then((res) => {
  //       setEmployeeData(res.data.data);
  //     })
  //     .catch((err) => {
  //       toast(err?.response?.data.message);
  //     });
  // }, [employee.id, getEmployeeDetails, token]);

  useEffect(() => {
    const limit = 1000;
    getBranches({ data: { limit, token } })
      .then((res) => {
        setBranches([...res.data.data.rows]);
      })

      .catch((err) => {
        console.error(err);
      });
  }, [employeeData?.branchId, getBranches, token]);

  useEffect(() => {
    setSelected(
      branches.find((branch: any) => branch.id === employeeData?.branchId)
    );
  }, [branches, employeeData?.branchId]);

  const fName = watch(`fName`);
  const mName = watch(`mName`);
  const lName = watch(`lName`);
  const mobileNum = watch("mobileNum");
  const rule = watch("rule");
  const branchId = watch("branchId");

  const handleChange = () => {
    if (
      fName !== employeeData?.fName ||
      mName !== employeeData?.mName ||
      lName !== employeeData?.lName ||
      mobileNum !== employeeData?.mobileNum ||
      rule !== employeeData?.rule ||
      branchId !== employeeData?.branchId
    ) {
      setEdit(true);
    }
  };

  const onSubmit = (data: any) => {
    // const id = emplyeeid.id;

    const initialData = {
      userId: employeeData?.ownerId,
      token: token,
      fName: data.fName || employeeData?.fName,
      mName: data.mName || employeeData?.mName,
      lName: data.lName || employeeData?.lName,
      rule: data.rule || employeeData?.rule,
      mobileNum: data.mobileNum || employeeData?.mobileNum,
      branchId: data.branchId || employeeData?.branchId,
    };

    updateEmployee({ data: initialData })
      .then((res: any) => {
        reset();

        toast.success("Updated !", {
          closeOnClick: true,
          draggable: false,
          autoClose: 1000,
          hideProgressBar: true,
          style: {
            top: "70px",
          },
        });
        setUpdate(!update);
        backState(false);
      })
      .catch((err: any) => {
        toast.error(err?.response?.data.message);
      });
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      {
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-custom form-label form-icon mt-3">
            <Controller
              control={control}
              defaultValue={branches.filter(
                (branch: any) => branch.id === employeeData?.branchId
              )}
              name="branchId"
              render={({ field: { onChange } }) => (
                <Select
                  value={selected}
                  options={branches}
                  getOptionValue={(option: any) => option.id}
                  getOptionLabel={(option: any) => option.branchName}
                  closeMenuOnSelect={false}
                  components={animatedComponents}
                  styles={selectStyles}
                  onChange={(option: any) => {
                    onChange(option.id);
                    setSelected(option);
                    handleChange();
                  }}
                />
              )}
            />
            <label htmlFor="c2" className="main-clr">
              {t("branches")}
            </label>
            <span>{t("required")}</span>
          </div>

          <div className="form-custom form-label form-icon mt-3">
            <i className="bi bi-pencil-fill font-12" />
            <input
              className="form-control rounded-xs  main-clr"
              id="c32"
              type="text"
              placeholder={t("fName")}
              {...register("fName")}
              defaultValue={employeeData?.fName}
              onChange={handleChange}
              required
            />
            <label className="main-clr" htmlFor="c32">
              {t("fName")}
            </label>
            <span>{t("required")}</span>
            <div className="mb-30">
              <ErrorMessage errors={errors} name="fName" render={FormError} />
            </div>
          </div>
          <div className="pb-2" />

          <div className="form-custom form-label form-icon">
            <i className="bi bi-pencil-fill font-12" />
            <input
              className="form-control rounded-xs main-clr"
              id="c32"
              type="text"
              placeholder={t("mName")}
              {...register("mName")}
              defaultValue={employeeData?.mName}
              onChange={handleChange}
              required
            />
            <label htmlFor="c32" className="main-clr">
              {t("mName")}
            </label>
            <span>{t("required")}</span>
            <div className="mb-30">
              <ErrorMessage errors={errors} name="mName" render={FormError} />
            </div>
          </div>
          <div className="pb-2" />

          <div className="form-custom form-label form-icon">
            <i className="bi bi-pencil-fill font-12" />
            <input
              className="form-control rounded-xs main-clr"
              id="c7"
              type="text"
              placeholder={t("lName")}
              {...register("lName")}
              defaultValue={employeeData?.lName}
              onChange={handleChange}
              required
            />
            <label htmlFor="c7" className="main-clr font-11">
              {t("lName")}
            </label>
            <span>{t("required")}</span>
            <div className="mb-30">
              <ErrorMessage errors={errors} name="lName" render={FormError} />
            </div>
          </div>
          <div className="pb-2" />

          <div className="form-custom form-label form-icon">
            <i className="bi bi-pencil-fill font-12" />
            <input
              className="form-control rounded-xs main-clr"
              id="c7"
              type="number"
              placeholder={t("mNumber")}
              {...register("mobileNum")}
              defaultValue={employeeData?.mobileNum}
              onChange={handleChange}
              required
            />
            <label htmlFor="c7" className="main-clr font-11">
              {t("mNumber")}
            </label>
            <span>{t("required")}</span>
            <div className="mb-30">
              <ErrorMessage
                errors={errors}
                name="mobileNum"
                render={FormError}
              />
            </div>
          </div>
          <div className="pb-2" />

          <div className="form-custom form-label form-icon">
            <i className="bi bi-pencil-fill font-12" />
            <input
              className="form-control rounded-xs main-clr"
              id="c7"
              type="text"
              placeholder={t("Rule")}
              {...register("rule")}
              defaultValue={employeeData?.rule}
              onChange={handleChange}
              required
            />
            <label htmlFor="c7" className="main-clr font-11">
              {t("Rule")}
            </label>
            <span>{t("required")}</span>
            <div className="mb-30">
              <ErrorMessage errors={errors} name="rule" render={FormError} />
            </div>
          </div>
          <div />

          {edit ? (
            <button
              disabled={isSubmitting}
              className=" mb-3 btn d-block w-100 main-btn  mt-3"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-3 me-1"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                />
              </svg>
              {t("updateEmployee")}
            </button>
          ) : (
            ``
          )}
        </form>
      }
    </>
  );
};

export default EmployeeProfilePage;
