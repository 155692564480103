/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { ErrorMessage } from "@hookform/error-message";
import makeAnimated from "react-select/animated";
import moment from "moment";
import Datetime from "react-datetime";

import { AddOfferSchema } from "../../utils/validationSchema";
import { FormError } from "../form";
import { updateSpinnerStatus } from "../../redux/actions";
import api from "../../services/api";

import "../../translations/i18n";

import { IonItem, IonList, IonSelect, IonSelectOption } from "@ionic/react";

const DefaultFormActionSheet = ({ children, title, back, backState }: any) => {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const token = useSelector<any>((state) => state.userState.token);
  const [branches, setBranches] = useState<any>([]);
  const [selectedBranch, setSelectedBranch] = useState([]);
  const rule = localStorage.getItem("role");
  const [tags, setTags] = useState<any>([]);
  const [selectedTag, setSelectedTag] = useState([]);

  const [, getBranches] = api.useAxios(
    { url: "/merchant/branch/list", method: "post" },
    { manual: true }
  );

  const [, getTags] = api.useAxios(
    { url: "/merchant/tag/list", method: "post" },
    { manual: true }
  );

  useEffect(() => {
    const limit = 1000;
    rule === "merchant" &&
      getBranches({ data: { pageNum: 1, limit, token } })
        .then((res) => {
          const branchesBeforeFilter = res.data.data.rows;
          const filteredBranchesToValueAndLabel = branchesBeforeFilter?.map(
            (item: any) => ({
              value: item.id,
              label: item.branchName,
            })
          );
          setBranches(filteredBranchesToValueAndLabel);
        })
        .catch((err) => {
          console.error(err);
        });
  }, [getBranches, token]);

  useEffect(() => {
    const limit = 1000;
    rule === "merchant" &&
      getTags({ data: { pageNum: 1, limit, token } })
        .then((res) => {
          const tagsBeforeFilter = res.data.data.rows;
          const filteredTagsToValueAndLabel = tagsBeforeFilter?.map(
            (item: any) => ({
              value: item.id,
              label: item.name,
            })
          );
          setTags(filteredTagsToValueAndLabel);
        })
        .catch((err) => {
          console.error(err);
        });
  }, [getTags, token]);

  const inputStartProps = {
    placeholder: t("startDate"),
  };

  const inputEndProps = {
    placeholder: t("endDate"),
  };

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(AddOfferSchema),
  });

  const [{ loading: addOfferLoading }, addOffer] = api.useAxios(
    { url: "/merchant/offer/add", method: "post" },
    { manual: true }
  );

  useEffect(() => {
    dispatch(updateSpinnerStatus({ addOfferLoading }));
  }, [addOfferLoading, dispatch]);

  const handleBranchChange = (selectedOption: any) => {
    setSelectedBranch(selectedOption);
  };

  const handleTagChange = (selectedOption: any) => {
    setSelectedTag(selectedOption);
  };

  return (
    <div className="collapse show" id="tab-17" data-bs-parent="#tab-group-6">
      <div>
        <div className="d-flex mx-3 mt-3 py-1">
          <div className="align-self-center">
            <h1
              className="m-0 main-clr"
              style={{
                fontWeight: "700",
                fontSize: "18px",
                lineHeight: "30px",
              }}
            >
              {back ? (
                <i
                  onClick={() => backState(false)}
                  className="bi main-clr bi-arrow-left-short font-26"
                ></i>
              ) : null}{" "}
              {title}
            </h1>
          </div>
          <div className="align-self-center ms-auto">
            <Link
              to="#"
              className="ps-4"
              shadow-0=""
              me-n2
              data-bs-dismiss="offcanvas"
            >
              <i className="bi bi-x main-clr font-26 line-height-xl"></i>
            </Link>
          </div>
        </div>
        <div className="divider divider-margins mt-3"></div>
        <div className="content mt-0">{children}</div>
      </div>
    </div>
  );
};

export default DefaultFormActionSheet;
