import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { NodataContainer } from "../../components/common/noDataContainer";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import api from "../../services/api";
import LoadingContainer from "../../components/LoadingContainer";

type DeliveryProductProps = {
  id: any;
  showDetails: any;
};

const DeliveryProductRequests = ({ id, showDetails }: DeliveryProductProps) => {
  const [, requestList] = api.useAxios(
    { url: "/merchant/product/req/list", method: "post" },
    { manual: true }
  );
  const [userListDelivery, setUserListDelivery] = useState<any>();
  const token = useSelector<any>((state) => state.userState.token);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    requestList({
      data: { token, isCanceled: false, type: "delivery" },
    }).then((res: any) => {
      setUserListDelivery(res?.data.data.productRequests);
      setLoading(false);
    });
  }, []);
  const { t } = useTranslation();
  return (
    <div className="px-1">
      {loading ? (
        <LoadingContainer />
      ) : userListDelivery?.rows?.length !== 0 ? (
        userListDelivery?.rows?.map((delivery: any) => (
          <div key={delivery?.id}>
            <div className="d-flex justify-content-between align-items-center p-3 offer-det-list mt-1">
              <div className="d-flex align-items-center gap-3">
                <div className="align-self-center">
                  <i className="has-bg rounded-xs bi p-1 bi bi-bag requests-bill-container "></i>
                </div>
                <div className="align-self-center">
                  <h5 className="branch-name text-capitalize">
                    {delivery?.user?.name}
                    <br />
                    <span>
                      <span>
                        <strong className="branches-phone">
                          {t("mobile")} : <span>{delivery?.user?.mobile}</span>
                        </strong>{" "}
                        <br />
                      </span>
                      <span>
                        <strong className="branches-phone">
                          {t("address")}{" "}
                          <span>
                            {" "}
                            : {delivery?.UserAddress?.Address?.address1}:{" "}
                            {delivery?.UserAddress?.Address?.address2}
                          </span>
                        </strong>
                        <br />
                      </span>
                      <span>
                        <strong className="branches-phone">
                          {" "}
                          {t("product")}{" "}
                          <span> :{delivery?.Product?.name}</span>
                        </strong>{" "}
                      </span>
                    </span>
                  </h5>
                </div>
              </div>
              <Link
                to={`/productrequest/${delivery?.id}`}
                className="align-self-center ms-auto text-end"
              >
                <span className="btn btn-xxs request-active text-uppercase ">
                  {t("details")}
                </span>
              </Link>
            </div>
          </div>
        ))
      ) : (
        <NodataContainer />
      )}
    </div>
  );
};

export default DeliveryProductRequests;
