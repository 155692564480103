import { IonContent, IonPage } from "@ionic/react";
import { useTranslation } from "react-i18next";
import FooterContainer from "../../components/common/Footer";
import HeaderContainer from "../../components/common/Header";
import OfferList from "../offers/OfferList";
import ProductList from "../products/ProductList";

const TransactionDetails: React.FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <IonPage>
        <IonContent fullscreen>
          <div className="p-3">
            <HeaderContainer pageTitle={t("Transaction Details")} />{" "}
            <div className="p-1 border rounded">
              <p className="para-style font-weight-bold mb-0">
                date : 5 - 5 - 2023{" "}
              </p>
              <p className="para-style font-weight-bold mb-0">price : 2000 $</p>
            </div>
          </div>
        </IonContent>
        <FooterContainer
          offersheet={"transactionsOfferSheet"}
          productsheet={"transactionProdSheet"}
        />
        <div
          id="transactionProdSheet"
          className="offcanvas offcanvas-bottom offcanvas-detached  offcanvas-style "
          aria-modal="true"
          role="dialog"
        >
          <ProductList
            instock={"footerprod18"}
            outofstock={"outfooterprod18"}
          />
        </div>
        <div
          id="transactionsOfferSheet"
          className="offcanvas offcanvas-bottom offcanvas-detached  offcanvas-style "
          aria-modal="true"
          role="dialog"
        >
          <OfferList />
        </div>
      </IonPage>
    </>
  );
};

export default TransactionDetails;
