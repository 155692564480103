import { IonContent, IonPage } from "@ionic/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Redirect, Switch } from "react-router";
import { Link, NavLink, Route } from "react-router-dom";

import FooterContainer from "../../components/common/Footer";
import HeaderContainer from "../../components/common/Header";
import api from "../../services/api";
import OfferList from "../offers/OfferList";
import ProductList from "../products/ProductList";

import InProgressDeliveris from "./InProgressDeliveris";
import PendingDeliveris from "./PendingDeliveris";

const AvailableDeliveries: React.FC = () => {
  const { t } = useTranslation();
  const token = useSelector<any>((state) => state.userState.token);
  const [userLists, setUserList] = useState<any>([]);
  const [list, setList] = useState("Pending");

  const [, requestList] = api.useAxios(
    { url: "/merchantUser/product/req/list", method: "post" },
    { manual: true }
  );

  useEffect(() => {
    requestList({ data: { token } }).then((res: any) => {
      setUserList(res?.data.data);
    });
  }, [requestList, token]);

  return (
    <div className="content">
      <>
        <h6 className="text-center branch-title m-4">
          <i
            className="bi bi-bell-fill font-20 requests-bill"
            style={{
              color: "#FFC107",
            }}
          ></i>{" "}
          {t("availableDeliveries")}
        </h6>
        <i
          data-bs-dismiss="offcanvas"
          className="bi bi-x main-clr font-26 line-height-xl close-offcanvas"
        ></i>
      </>
      <ul className="nav nav-tabs capsuled" role="tablist">
        <li className="nav-item">
          <Link
            className="nav-link active "
            data-bs-toggle="tab"
            to="#"
            role="tab"
            onClick={() => {
              setList("Pending");
            }}
          >
            Pending{" "}
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className="nav-link"
            data-bs-toggle="tab"
            to="#"
            role="tab"
            onClick={() => {
              setList("inProgress");
            }}
          >
            InProgress{" "}
          </Link>
        </li>
      </ul>

      <div className="mt-3" />
      <div className="tab-content ">
        {list === "Pending" ? (
          <div
            className="tab-pane fade active show"
            id="pending-del"
            role="tabpanel"
          >
            <PendingDeliveris data={userLists} />
          </div>
        ) : (
          <div
            className="tab-pane fade active show"
            id="inprogress-del"
            role="tabpanel"
          >
            <InProgressDeliveris data={userLists} />
          </div>
        )}
      </div>
    </div>
  );
};

export default AvailableDeliveries;
