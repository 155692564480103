import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import api from "../services/api";

import "./../translations/i18n";
import "react-toastify/dist/ReactToastify.css";
import LoadingContainer from "./LoadingContainer";
import { setDetails } from "../redux/actions";
import {
  IonContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonPage,
} from "@ionic/react";
import InvoicesDetails from "../pages/invoicesDetails";
import { NodataContainer } from "./common/noDataContainer";

const InvoicesContainer = () => {
  const { t } = useTranslation();

  const token = useSelector<any>((state) => state.userState.token);
  const dispatch = useDispatch();
  const [packageRequests, setPackageRequests] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [pageNum, setPageNum] = useState(1);
  const [InfiniteDisabled, setInfiniteDisabled] = useState<any>(false);

  let limit = 10;
  // let page = 1;

  const [, getPackageRequestsList] = api.useAxios(
    { url: "/merchant/package/req/list", method: "post" },
    { manual: true }
  );

  useEffect(() => {
    setLoading(true);

    getPackageRequestsList({ data: { token, limit: 1000, pageNum: pageNum } })
      .then((res) => {
        setPackageRequests([...res.data.data.rows]);
        setTotalCount(res.data.data.count);
        // let last = 0;
        // const plusPage = totalCount % limit > 0 ? 1 : 0;
        // last = Math.trunc(totalCount / limit) + plusPage;
        // setLastPage(last);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderDetails = (item: any) => {
    dispatch(setDetails(item));
  };

  const loadData = useCallback(
    (event: any) => {
      setLoading(false);
      setPageNum(pageNum + 1);
      setInfiniteDisabled(false);
      setTimeout(() => {
        getPackageRequestsList({
          data: { token, limit: limit, pageNum: pageNum + 1 },
        }).then((res: any) => {
          setPackageRequests([...packageRequests, ...res.data.data.rows]);
          setTotalCount(res.data.data.count);
        });
        event.target.complete();
        if (packageRequests.length < totalCount) {
          return setInfiniteDisabled(false);
        } else {
          return setInfiniteDisabled(true);
        }
      }, 1000);
    },
    [pageNum, getPackageRequestsList, token, limit, packageRequests, totalCount]
  );

  const renderReqList = () => {
    return (
      <IonPage>
        <IonContent fullscreen>
          <div className="pb-3 content">
            <h6 className="text-center branch-title m-4"> {t("invoices")}</h6>
            <i
              data-bs-dismiss="offcanvas"
              className="bi bi-x main-clr font-26 line-height-xl close-offcanvas"
            ></i>
            <div>
              {packageRequests.length !== 0 ? (
                packageRequests?.map((item: any) => (
                  <Link
                    key={item.id}
                    to={`/invoices/${item?.id}`}
                    className="d-flex justify-content-between align-items-center  invoices-list"
                  >
                    <div className="row align-items-center m-0 w-100">
                      <div className="col-8 flex flex-column gap-2 ">
                        <strong className="main">{item.name}</strong>
                        <p className="det">{item.description}</p>
                      </div>
                      <div className=" col-2 text-end">
                        <i className="bi bi-upc text-dark font-22"></i>
                      </div>
                      <div className="col-2 text-end p-0">
                        <button className=" btn">$ {item.amount}</button>
                      </div>
                    </div>
                  </Link>
                ))
              ) : (
                <NodataContainer />
              )}

              {/* {packageRequests.length < totalCount ? (
                <IonInfiniteScroll
                  onIonInfinite={loadData}
                  disabled={InfiniteDisabled}
                  threshold="100px"
                >
                  <IonInfiniteScrollContent
                    loadingText={"loading"}
                  ></IonInfiniteScrollContent>
                </IonInfiniteScroll>
              ) : (
                ``
              )} */}
            </div>
          </div>
        </IonContent>
      </IonPage>
    );
  };

  return (
    <div className=" pb-5  " id="tab-group-6">
      <div
        className="collapse show "
        style={{
          display: "block",
          visibility: "visible",
          height: "600px",
        }}
        id="tab-16"
        data-bs-parent="#tab-group-6"
      >
        <div className="">
          {loading === true ? <LoadingContainer /> : renderReqList()}
        </div>
        {/* {(lastPage < page || page !== lastPage) && (
              <div className="text-center pb-2">
                <button onClick={handleLoadingMore} className="btn btn-primary">
                  {t("See More")}
                </button>
              </div>
            )} */}
      </div>
    </div>
  );
};

export default InvoicesContainer;
