import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import ProductRequests from "../../pages/ProductRequests";
import ProductList from "../../pages/products/ProductList";
import middle from "../../assets/images/middlefooter.svg";
import home from "../../assets/images/homefooter.svg";
import menuleft from "../../assets/images/menuleft.svg";
import "../../translations/i18n";
type FooterProps = {
  offersheet: any;
  productsheet: any;
};
const FooterContainer = ({ offersheet, productsheet }: FooterProps) => {
  const { t } = useTranslation();
  const role = localStorage.getItem("role");
  return (
    <div
      id="footer-bar"
      className="footer-bar-1 footer-bar-detached"
      style={{
        backgroundColor: "#2c236a",
        width: "96%",
      }}
    >
      <Link
        to="/dashboard"
        style={{
          color: "rgb(86, 238, 168)",
          width: "19%",
        }}
      >
        <img src={home} alt="home" />
        <span>{t("dashboard")}</span>
      </Link>
      {role === "merchant" ? (
        <Link
          to="#"
          data-bs-toggle="offcanvas"
          data-bs-target={`#${productsheet}`}
          style={{
            color: "rgb(86, 238, 168)",
            width: "19%",
          }}
        >
          <i className="bi bi-wallet"></i>
          <span>{t("Product List")}</span>
        </Link>
      ) : null}

      {role === "delivery" ? (
        ``
      ) : (
        <>
          <Link
            to="#"
            className="circle-nav-2"
            style={{
              width: "19%",
            }}
          >
            <i
              className="bi bi-circle-fill"
              style={{
                color: "rgb(86, 238, 168)",
                position: "relative",
              }}
            >
              <img
                src={middle}
                alt="icon"
                style={{
                  position: "absolute",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  top: "50%",
                }}
              />
            </i>
          </Link>
        </>
      )}
      {role === "merchant" ? (
        <Link
          data-bs-toggle="offcanvas"
          data-bs-target={`#${offersheet}`}
          to="#"
          style={{
            color: "rgb(86, 238, 168)",
            width: "19%",
          }}
        >
          <i
            className="bi bi-gift "
            style={{
              color: "rgb(86, 238, 168)",
            }}
          ></i>
          <span>{t("Offer List")}</span>
        </Link>
      ) : null}
      <Link
        to="/setting"
        style={{
          color: "rgb(86, 238, 168)",
          width: "19%",
        }}
      >
        <img src={menuleft} alt="menu" />
        <span>{t("menu")}</span>
      </Link>
    </div>
  );
};

export default FooterContainer;
