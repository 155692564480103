/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useState } from "react";

import { IonContent, IonPage } from "@ionic/react";
import HeaderContainer from "../../components/common/Header";
import FooterContainer from "../../components/common/Footer";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import api from "../../services/api";
import LoadingContainer from "../../components/LoadingContainer";
import "../../translations/i18n";
import { ToastContainer, toast } from "react-toastify";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Bar, Line } from "react-chartjs-2";
import "./index.css";
import ProductList from "../products/ProductList";
import OfferList from "../offers/OfferList";
// import { Link } from "react-router-dom";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

export const offerRequestsOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: true,
      title: "Offer Requests",
    },
  },
};

export const productRequestsOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: true,
      title: "Product Requests",
    },
  },
};

const outGoingPointOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: true,
    },
  },
};

const revenueOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: true,
    },
  },
};

const AccountReports: React.FC = () => {
  const { t } = useTranslation();
  const token = useSelector<any>((state) => state.userState.token);

  const [charts, setCharts] = useState<any>([]);
  const [loading, setLoading] = useState(false);

  const [xAxisLabels, setXAxisLabels] = useState<any>([]);
  const [numberOfRequests, setNumberOfRequests] = useState<any>([]);
  const [numberOfProductRequests, setNumberOfProductRequests] = useState<any>(
    []
  );
  const [totalOfRequestsPoints, setTotalOfRequestsPoints] = useState<any>([]);

  const [totalOfProductRequestsPrice, setTotalOfProductRequestsPrice] =
    useState<any>([]);

  const offerRequests = {
    labels: xAxisLabels,
    datasets: [
      {
        label: "Number of offers",
        data: numberOfRequests,
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };

  const productRequests = {
    labels: xAxisLabels,
    datasets: [
      {
        label: "Number of product Requests",
        data: numberOfProductRequests,
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };

  const outGoingPoints = {
    labels: xAxisLabels,
    datasets: [
      {
        fill: true,
        label: "Outgoing Points (points)",
        data: totalOfRequestsPoints,
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };

  const Revenue = {
    labels: xAxisLabels,
    datasets: [
      {
        fill: true,
        label: "Revenue ($)",
        data: totalOfProductRequestsPrice,
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };

  const [, getCharts] = api.useAxios(
    { url: "/merchant/chart/orders/branches", method: "post" },
    { manual: true }
  );

  useEffect(() => {
    setLoading(true);
    getCharts({ data: { token } })
      .then((res) => {
        setCharts([...res.data.data]);

        const branchNameLabels = res.data.data.map((branch: any) => {
          return branch.branchName;
        });
        setXAxisLabels(branchNameLabels);

        const requestsNumber = res.data.data.map((branch: any) => {
          return branch.countRequests;
        });
        setNumberOfRequests(requestsNumber);

        const productRequestsNumber = res.data.data.map((branch: any) => {
          return branch.countProductRequests;
        });
        setNumberOfProductRequests(productRequestsNumber);

        const requestsTotalNumber = res.data.data.map((branch: any) => {
          return branch.totalRequestsAmountPoints;
        });
        setTotalOfRequestsPoints(requestsTotalNumber);

        const productRequestsTotalNumber = res.data.data.map((branch: any) => {
          return branch.totalProductRequestsAmountPrice;
        });
        setTotalOfProductRequestsPrice(productRequestsTotalNumber);

        setLoading(false);
      })
      .catch((err) => {
        toast(err?.response?.data.message);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <IonPage>
        <HeaderContainer
          pageTitle={t("accountReports")}
          pageIcon={
            <i
              className=" rounded-xs bi bi-clipboard-data font-14"
              style={{
                color: "rgb(86, 238, 168)",
              }}
            ></i>
          }
        />
        <IonContent fullscreen>
          <div
            className="tabs tabs-links mb-3 pb-5 theme-light"
            id="tab-group-6"
          >
            <div className="tab-controls justify-content-center bg-transparent mx-3 mb-5"></div>

            <div className="card card-style">
              <div className="content my-1 mb-4">
                <div
                  className="collapse show"
                  id="tab-16"
                  data-bs-parent="#tab-group-6"
                >
                  <div className="list-group list-custom list-group-m list-group-flush rounded-xs">
                    {loading === true ? (
                      <LoadingContainer />
                    ) : (
                      <>
                        <h6 style={{ color: `rgb(9, 44, 76)` }}>
                          {t("menuofferrequests")}
                        </h6>
                        <div className="card my-1">
                          <div id="canvas-container">
                            <Bar
                              options={offerRequestsOptions}
                              data={offerRequests}
                            />
                          </div>
                        </div>

                        <h6 style={{ color: `rgb(9, 44, 76)` }}>
                          {t("productRequests")}
                        </h6>
                        <div className="card my-1">
                          <div id="canvas-container">
                            <Bar
                              options={productRequestsOptions}
                              data={productRequests}
                            />
                          </div>
                        </div>

                        <h6 style={{ color: `rgb(9, 44, 76)` }}>
                          {t("branchOutgoingPoints")}
                        </h6>
                        <div className="card my-1">
                          <div id="canvas-container">
                            <Line
                              options={outGoingPointOptions}
                              data={outGoingPoints}
                            />
                          </div>
                        </div>

                        <h6 style={{ color: `rgb(9, 44, 76)` }}>
                          {t("branchRevenue")}
                        </h6>
                        <div className="card my-1">
                          <div id="canvas-container">
                            <Line options={revenueOptions} data={Revenue} />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <FooterContainer
            offersheet={"accountrepOfferSheet"}
            productsheet={"accouintrepProdSheet"}
          />
          <div
            id="accouintrepProdSheet"
            className="offcanvas offcanvas-bottom offcanvas-detached  offcanvas-style "
            aria-modal="true"
            role="dialog"
          >
            <ProductList
              instock={"footerprod7"}
              outofstock={"outfooterprod7"}
            />
          </div>
          <div
            id="accountrepOfferSheet"
            className="offcanvas offcanvas-bottom offcanvas-detached  offcanvas-style "
            aria-modal="true"
            role="dialog"
          >
            <OfferList />
          </div>
        </IonContent>
      </IonPage>
    </>
  );
};

export default AccountReports;
