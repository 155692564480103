import { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { ErrorMessage } from "@hookform/error-message";

import { ChangePasswordValidation } from "../utils/validationSchema";
import { FormError } from "./form";
import { changePasswordUser, updateSpinnerStatus } from "../redux/actions";
import api from "../services/api";
import "../translations/i18n";

const ChangePasswordContainer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const token = useSelector<any>((state) => state.userState.token);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(ChangePasswordValidation),
  });

  const [{ loading: loginLoading }, change] = api.useAxios(
    { url: "/merchant/changepassword", method: "post" },
    { manual: true }
  );

  const onSubmit = (data: any) => {
    change({ data: { data, token } })
      .then((res: any) => {
        reset();
        dispatch(changePasswordUser({ ...res.data.data }));
        history.push("/dashboard");
        toast.success("Password Changed", {
          closeOnClick: true,
          draggable: false,
          autoClose: 1000,
          hideProgressBar: true,
          style: {
            top: "70px",
          },
        });
      })
      .catch((err: any) => {
        toast.error(err?.response?.data.message);
      });
  };

  useEffect(() => {
    dispatch(updateSpinnerStatus({ loginLoading }));
  }, [dispatch, loginLoading]);

  return (
    <>
      <div
        className="page-title d-flex "
        style={{
          backgroundColor: "rgb(9, 44, 76)",
        }}
      >
        <div className="align-self-center">
          <Link
            to="/profile"
            data-bs-dismiss="offcanvas"
            className="me-3 ms-0 icon icon-xxs  rounded-s shadow-lg"
          >
            <i
              className="bi bi-chevron-left  font-14"
              style={{
                color: "rgb(86, 238, 168)",
              }}
            ></i>
          </Link>
        </div>
        <div className="align-self-center me-auto">
          <h1
            className=" m-0 font-18"
            style={{
              color: "rgb(86, 238, 168)",
            }}
          >
            {t("backtoProfile")}
          </h1>
        </div>
      </div>
      <div className="page-content my-0 py-0">
        <div
          className="card card-fixed card-center mx-3 px-4 py-4 bg-white rounded-m"
          style={{
            border: "none",
          }}
        >
          <div className="card-center  px-4 py-4 bg-white rounded-m">
            <h1
              className="font-30 font-800 mb-0"
              style={{
                color: "rgb(9, 44, 76)",
              }}
            >
              {t("changepasswordhead")}
            </h1>
            <p>{t("changeYourAccountPassword")}</p>

            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-custom form-label form-border form-icon mb-4 bg-transparent">
                <i className="bi bi-asterisk font-13"></i>
                <input
                  type="password"
                  className="form-control rounded-xs"
                  {...register("newPassword")}
                  placeholder={t("newpassword")}
                />
                <label className="color-theme">{t("newpassword")}</label>
                <span>{t("required")}</span>
                <ErrorMessage
                  errors={errors}
                  name="newPassword"
                  render={FormError}
                />
              </div>
              <div className="form-custom form-label form-border form-icon mb-4 bg-transparent">
                <i className="bi bi-asterisk font-13"></i>
                <input
                  type="password"
                  className="form-control rounded-xs"
                  {...register("confirmNewPassword")}
                  placeholder={t("confirmnewpassword")}
                />
                <label className="color-theme">{t("confirmnewpassword")}</label>
                <span>{t("required")}</span>
                <ErrorMessage
                  errors={errors}
                  name="confirmNewPassword"
                  render={FormError}
                />
              </div>

              <button
                className="btn btn-full shadow-bg shadow-bg-s mt-4 "
                style={{
                  backgroundColor: "rgb(9, 44, 76)",
                  color: "rgb(86, 238, 168)",
                }}
                disabled={isSubmitting}
                type="submit"
              >
                {t("confirm")}
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePasswordContainer;
