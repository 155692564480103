import { IonItem, IonList, IonSelect, IonSelectOption } from "@ionic/react";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../../services/api";

type ProductRequestsProps = {
  data: any;
  backState?: any;
};

const AssignDelivery = ({ data, backState }: ProductRequestsProps) => {
  const history = useHistory();
  const token = useSelector<any>((state) => state.userState.token);
  const [selectProductDelivery, setSelectProductDelivery] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState<any>();
  const [employees, setEmployees] = useState([]);
  const { handleSubmit, reset } = useForm();

  const handleRequestProductDelivery = (selectedOption: any) => {
    setSelectProductDelivery(selectedOption);
  };

  const handleEmployee = (selectedOption: any) => {
    setSelectedEmployee(selectedOption);
  };

  const [, assignDelivery] = api.useAxios(
    { url: "/merchant/product/employee/assignDelivery", method: "post" },
    { manual: true }
  );
  const [, userList] = api.useAxios(
    { url: "/merchant/user/list", method: "post" },
    { manual: true }
  );

  useEffect(() => {
    userList({ data: { token, limit: 1000, rule: "delivery" } }).then(
      (res: any) => {
        setEmployees(res?.data.data.rows);
      }
    );
  }, [token, userList]);

  const onSubmit = (data: any) => {
    data.productRequestIds = JSON.stringify(
      selectProductDelivery?.map((item: any) => item)
    );

    data.employeeId = selectedEmployee;

    const finalData = { ...data, token };

    assignDelivery({ data: finalData })
      .then((res: any) => {
        reset();

        toast.success("assigned successfully");
        window.location.reload();
      })
      .catch((err: any) => {
        toast(err?.response?.data.message);
      });
  };
  console.log(employees);
  return (
    <div className="collapse show " id="tab-17" data-bs-parent="#tab-group-6">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-custom form-label form-icon w-100 p-0 m-0 ">
          <IonList lines="none">
            <IonItem className="" style={{ height: "70px" }}>
              <IonSelect
                multiple={true}
                interface="alert"
                placeholder={t("selectproduct")}
                className="form-select rounded-xs d-flex w-100 h-100"
                color=""
                onIonChange={(e: any) => {
                  handleRequestProductDelivery(e.detail.value);
                }}
              >
                {data?.rows?.map((product: any) => (
                  <IonSelectOption value={product?.id} key={product?.id}>
                    {product?.Product.name}
                  </IonSelectOption>
                ))}
              </IonSelect>
              <label
                className="form-label-always-active main-clr font-11 over-background"
                style={{
                  top: "3px",
                  zIndex: "999",
                }}
              >
                {t("selectproduct")}
              </label>
            </IonItem>
          </IonList>
        </div>

        <div className="form-custom form-label form-icon w-100 p-0 m-0 ">
          <IonList lines="none">
            <IonItem className="" style={{ height: "70px" }}>
              <IonSelect
                interface="alert"
                placeholder={t("selectemployee")}
                className="form-select rounded-xs d-flex w-100 h-100"
                color=""
                onIonChange={(e: any) => handleEmployee(e.detail.value)}
              >
                {employees &&
                  employees.map((employee: any) => (
                    <IonSelectOption value={employee?.id} key={employee?.id}>
                      {employee.merchantUser.fName}{" "}
                      {employee.merchantUser.mName}{" "}
                      {employee.merchantUser.lName}
                    </IonSelectOption>
                  ))}
              </IonSelect>
              <label
                className="form-label-always-active main-clr font-11 over-background"
                style={{
                  top: "3px",
                  zIndex: "999",
                }}
              >
                {t("selectemployee")}
              </label>
            </IonItem>
          </IonList>
        </div>
        <div className="pb-2" />

        {selectedEmployee && selectProductDelivery.length > 0 ? (
          <button
            type="submit"
            className="btn btn-full  w-100 main-btn mt-2 mb-3"
          >
            {t("assigndelivery")}
          </button>
        ) : (
          ``
        )}
      </form>
    </div>
  );
};
export default AssignDelivery;
