import { IonContent, IonPage } from "@ionic/react";
import SettingContainer from "../components/Setting";
import HeaderContainer from "../components/common/Header";
import FooterContainer from "../components/common/Footer";
import "./Home.css";

const Home: React.FC = () => {
  return (
    <IonPage>
      <HeaderContainer />
      <IonContent fullscreen>
        <SettingContainer />
      </IonContent>
    </IonPage>
  );
};

export default Home;
