/* eslint-disable @typescript-eslint/no-unused-vars */
import { IonContent, IonPage } from "@ionic/react";
import HeaderContainer from "../../components/common/Header";
import FooterContainer from "../../components/common/Footer";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { toast, ToastContainer } from "react-toastify";
import { ErrorMessage } from "@hookform/error-message";
import { FormError } from "../../components/form";
import api from "../../services/api";

import "../../translations/i18n";

const AddUser: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    // resolver: yupResolver(CreateUserValidation),
  });

  const [, CreateUser] = api.useAxios(
    { url: "/user/signup", method: "post" },
    { manual: true }
  );

  const onSubmit = (data: any) => {
    data.userCurrncy = "USD";
    data.password = "123456";
    const finalData = { ...data };

    CreateUser({ data: finalData })
      .then((res: any) => {
        reset();
        toast.success("added successfully");
      })
      .catch((err: any) => {
        toast(err?.response?.data.message);
      });
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="pb-3"></div>
        <div className="form-custom form-label form-icon">
          <i className="bi bi-person font-14 main-clr"></i>
          <input
            type="text"
            className="form-control main-clr rounded-xs"
            id="c3"
            {...register("name")}
            placeholder={t("name")}
            required
          />
          <label
            htmlFor="c3"
            className="form-label-always-active main-clr font-11"
          >
            {t("name")}
          </label>
          <span className="font-10">{t("required")}</span>
          <div className="mb-30">
            <ErrorMessage errors={errors} name="name" render={FormError} />
          </div>
        </div>
        <div className="pb-3"></div>
        <div className="form-custom form-label form-icon">
          <i
            className="bi bi-phone font-14 main-clr"
            style={{ color: "#747474" }}
          ></i>
          <input
            type="number"
            className="form-control main-clr rounded-xs"
            id="c4"
            {...register("mobile")}
            placeholder={t("mobile")}
            required
          />
          <label
            htmlFor="c4"
            className="form-label-always-active main-clr font-11"
          >
            {t("mobile")}
          </label>
          <span>{t("required")}</span>
          <div className="mb-30">
            <ErrorMessage errors={errors} name="mobile" render={FormError} />
          </div>
        </div>
        <button
          className=" mb-3 btn d-block w-full main-btn mt-3"
          style={{ width: "100%" }}
        >
          {t("adduser")}
        </button>
      </form>

      {/* <div
      className="collapse show mb-5 pb-5"
      id="tab-17"
      data-bs-parent="#tab-group-6"
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          width: `80%`,
          margin: `auto`,
        }}
      >
        <h5>Add User</h5>
        <div className="form-custom form-label form-icon mt-3">
          <i className="bi bi-pencil-fill font-12" />
          <input
            className="form-control rounded-xs"
            id="c32"
            type="text"
            placeholder={t("Name")}
            {...register("name")}
            required
            style={{ border: `1px solid DarkCyan` }}
          />
          <label className="color-highlight" htmlFor="c32">
            {t("Name")}
          </label>
          <span>{t("required")}</span>
          <div className="mb-30">
            <ErrorMessage
              errors={errors}
              name="name"
              render={FormError}
            />
          </div>
        </div>
        <div className="pb-2" />

        <div className="form-custom form-label form-icon">
          <i className="bi bi-pencil-fill font-12" />
          <input
            className="form-control rounded-xs"
            id="c32"
            type="text"
            placeholder={t("Phone")}
            {...register("mobile")}
            style={{ border: `1px solid DarkCyan` }}
            required
          />
          <label htmlFor="c32" className="color-highlight">
            {t("Phone")}
          </label>
          <span>{t("required")}</span>
          <div className="mb-30">
            <ErrorMessage
              errors={errors}
              name="mobile"
              render={FormError}
            />
          </div>
        </div>
        <div className="pb-2" />

        <button
          disabled={isSubmitting}
          className="btn btn-full gradient-highlight w-100 rounded-s shadow-bg shadow-bg-xs mt-3 mb-3"
        >
          {t("Add User")}
        </button>
      </form>
    </div> */}
    </>
  );
};

export default AddUser;
