/* eslint-disable import/no-cycle */
import {
  LOGIN_USER,
  LOGOUT_USER,
  UPDATE_USER,
  CREATE_USER,
  FORGET_PASSWORD_USER,
  CHANGE_PASSWORD_USER,
} from "../types";

export const loginUser = (data) => ({
  type: LOGIN_USER,
  data,
});
export const forgetPasswordUser = (data) => ({
  type: FORGET_PASSWORD_USER,
  data,
});
export const changePasswordUser = (data) => ({
  type: CHANGE_PASSWORD_USER,
  data,
});

export const createUser = (data) => ({
  type: CREATE_USER,
  data,
});

export const updateUser = (data) => ({
  type: UPDATE_USER,
  data,
});

export const logoutUser = () => ({
  type: LOGOUT_USER,
});
