import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./../translations/i18n";
import { toast } from "react-toastify";
import AddUser from "../pages/addUser";
import FooterContainer from "./common/Footer";
import DefaultFormActionSheet from "./common/DefaultFormActionSheet";
import RequestsContainer from "./RequestsContainer";
import BranchesList from "../pages/branches/BranchesList";
import OfferList from "../pages/offers/OfferList";
import Employeeslist from "../pages/employees/EmployeesList";
import InitialSettlement from "../pages/initialSettlement/index";
import ProductList from "../pages/products/ProductList";
import PendingSettlementPage from "../pages/pendingSettlement/index";
import DeliveryEmployeeList from "../pages/deliveryEmployee/DeliveryEmployeeList";
import InvoicesContainer from "./Invoices";
import PackageContainer from "./PackageContainer";
import ProductRequests from "../pages/ProductRequests";
import loginm from "../assets/images/setting-m.svg";
import AvailableDeliveries from "../pages/AvailableDeliveries";
import AddPaymentMethod from "../pages/addpeymentmethods";
import PaymentMethods from "../pages/paymentmethods";
import React, { useEffect, useState } from "react";
import LoadingContainer from "./LoadingContainer";
import Tickets from "../pages/tickets";
const SettingContainer = () => {
  const { t } = useTranslation();
  const role = localStorage.getItem("role");
  const [load, setLoad] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoad(false);
    }, 200);
  });

  return (
    <>
      {load ? (
        <LoadingContainer />
      ) : (
        <div className="page-content footer-clear ">
          <div className="row text-center">
            <div className="col-12 mb-n2 text-start">
              <Link
                to="/accountReports"
                className="default-link card card-style"
                style={{ height: "90px" }}
              >
                <div className="card-center px-4">
                  <div className="d-flex">
                    <div className="align-self-center">
                      <span className="icon icon-m rounded-s gradient-brown shadow-bg shadow-bg-xs">
                        <i className="bi bi-clipboard-data font-20 color-white"></i>
                      </span>
                    </div>
                    <div className="align-self-center ps-3 ms-1">
                      <h1 className="font-20 mb-n1">{t("accountReports")}</h1>
                      <p className="mb-0 font-12 opacity-50">
                        {t("seeYourtStatistics")}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>

          <div>
            <div className="content mb-0 mt-2">
              <div className="">
                {role === "merchant" ? (
                  <>
                    <Link
                      to="#"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#menu-branches"
                      className="d-flex position-relative justify-content-between item setting-list align-items-center px-3"
                    >
                      <div className="d-flex gap-3 align-items-center">
                        <i className="has-bg gradient-magenta p-1 shadow-bg shadow-bg-xs color-white rounded-xs bi bi-building font-24"></i>
                        <strong className="font-600 setting-list-text">
                          {t("branches")}
                        </strong>
                      </div>
                      <i className="bi bi-chevron-right text-secondary me-2"></i>
                    </Link>
                    <div
                      id="menu-branches"
                      className="offcanvas offcanvas-bottom offcanvas-detached   offcanvas-style"
                      aria-modal="true"
                      role="dialog"
                    >
                      <BranchesList />
                    </div>
                  </>
                ) : (
                  ``
                )}

                {role === "merchant" ? (
                  <>
                    <Link
                      data-bs-toggle="offcanvas"
                      data-bs-target="#menu-offers"
                      to="#"
                      className="d-flex position-relative justify-content-between item setting-list align-items-center px-3"
                    >
                      <div className="d-flex gap-3 align-items-center">
                        <i className="has-bg gradient-green  shadow-bg shadow-bg-xs color-white rounded-xs bi bi-gift font-24 p-1"></i>
                        <div>
                          <strong className="font-600 setting-list-text">
                            {t("menuoffers")}
                          </strong>
                        </div>
                      </div>
                      <i className="bi bi-chevron-right text-secondary me-2"></i>
                    </Link>
                    <div
                      id="menu-offers"
                      className="offcanvas offcanvas-bottom offcanvas-detached  offcanvas-style "
                      aria-modal="true"
                      role="dialog"
                    >
                      <OfferList />
                    </div>
                  </>
                ) : (
                  ``
                )}

                {role === "merchant" ? (
                  <>
                    <Link
                      to="#"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#menu-employees"
                      className="d-flex position-relative justify-content-between item setting-list align-items-center px-3"
                    >
                      <div className="d-flex gap-3 align-items-center">
                        <i className="has-bg gradient-red shado-4g shadow-bg-xs color-white rounded-xs bi bi-people  font-24 p-1"></i>
                        <div>
                          <strong className="font-600 setting-list-text">
                            {t("menuemployees")}
                          </strong>
                        </div>
                      </div>
                      <i className="bi bi-chevron-right text-secondary me-2"></i>
                    </Link>
                    <div
                      id="menu-employees"
                      className="offcanvas offcanvas-bottom offcanvas-detached  offcanvas-style"
                      aria-modal="true"
                      role="dialog"
                    >
                      <Employeeslist />
                    </div>
                  </>
                ) : (
                  ``
                )}
                {role == "merchant" || role == "merchantUser" ? (
                  <>
                    <Link
                      to="#"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#menu-products"
                      className="d-flex position-relative justify-content-between item setting-list align-items-center px-3"
                    >
                      <div className="d-flex gap-3 align-items-center">
                        <i className="has-bg gradient-blue shad-4bg shadow-bg-xs color-white rounded-xs bi bi-bag  font-24 p-1"></i>
                        <div>
                          <strong className="font-600 setting-list-text">
                            {t("products")}
                          </strong>
                        </div>
                      </div>
                      <i className="bi bi-chevron-right text-secondary me-2"></i>
                    </Link>
                    <div
                      id="menu-products"
                      className="offcanvas offcanvas-bottom offcanvas-detached  offcanvas-style "
                      aria-modal="true"
                      role="dialog"
                    >
                      <ProductList
                        instock={"footerprod"}
                        outofstock={"outfooterprod"}
                      />
                    </div>
                  </>
                ) : null}

                {role === "merchant" ? (
                  <>
                    <Link
                      data-bs-toggle="offcanvas"
                      data-bs-target="#menu-delivery-employees"
                      to="#"
                      className="d-flex position-relative justify-content-between item setting-list align-items-center px-3"
                    >
                      <div className="d-flex gap-3 align-items-center">
                        <i className="has-bg gradient-red shado-4g shadow-bg-xs color-white rounded-xs bi bi-people  font-24 p-1"></i>

                        <div>
                          <strong className="font-600 setting-list-text">
                            {t("deliveryemployees")}
                          </strong>
                        </div>
                      </div>
                      <i className="bi bi-chevron-right text-secondary me-2"></i>
                    </Link>
                    <div
                      id="menu-delivery-employees"
                      className="offcanvas offcanvas-bottom offcanvas-detached  offcanvas-style "
                      aria-modal="true"
                      role="dialog"
                    >
                      <DeliveryEmployeeList />
                    </div>
                  </>
                ) : null}

                {role === "merchant" || role === "merchantUser" ? (
                  <>
                    {/* <Link to="/redeems"                     className="d-flex position-relative justify-content-between item setting-list align-items-center px-3"
>
                    <i className="has-bg gradient-green shadow-bg shadow-bg-xs color-white rounded-xs bi bi-wallet2  font-24 p-1"></i>
                    <div>
                         <strong className="font-600 setting-list-text">{t("redeems")}</strong>
                    </div>
                      <i className="bi bi-chevron-right text-secondary me-2"></i>
                  </Link> */}

                    <Link
                      to="#"
                      className="d-flex position-relative justify-content-between item setting-list align-items-center px-3"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#menu-requests-home"
                    >
                      <div className="d-flex gap-3 align-items-center">
                        <i className="has-bg gradient-brown sha-4-bg shadow-bg-xs color-white rounded-xs bi bi-card-checklist  font-24 p-1"></i>
                        <div>
                          <strong className="font-600 setting-list-text">
                            {t("offerRequests")}
                          </strong>
                        </div>
                      </div>
                      <i className="bi bi-chevron-right text-secondary me-2"></i>
                    </Link>
                    <div
                      id="menu-requests-home"
                      className="offcanvas offcanvas-bottom offcanvas-detached  offcanvas-style "
                      aria-modal="true"
                      role="dialog"
                    >
                      <RequestsContainer />
                    </div>
                  </>
                ) : (
                  ``
                )}

                {role === "merchant" ? (
                  <>
                    <Link
                      to="#"
                      className="d-flex position-relative justify-content-between item setting-list align-items-center px-3"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#menu-invoices"
                    >
                      <div className="d-flex gap-3 align-items-center">
                        <i className="has-bg gradient-blue shad-4bg shadow-bg-xs color-white rounded-xs bi bi-receipt  font-24 p-1"></i>
                        <div>
                          <strong className="font-600 setting-list-text">
                            {t("invoices")}
                          </strong>
                        </div>
                      </div>
                      <i className="bi bi-chevron-right text-secondary me-2"></i>
                    </Link>
                    <div
                      id="menu-invoices"
                      className="offcanvas offcanvas-bottom offcanvas-detached  offcanvas-style "
                      aria-modal="true"
                      role="dialog"
                    >
                      <InvoicesContainer />
                    </div>
                  </>
                ) : (
                  ``
                )}

                {role === "merchant" ? (
                  <>
                    <Link
                      to="#"
                      className="d-flex position-relative justify-content-between item setting-list align-items-center px-3"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#menu-packages"
                    >
                      <div className="d-flex gap-3 align-items-center">
                        <i className="has-bg gradient-red shado-4g shadow-bg-xs color-white rounded-xs bi bi-cash-coin  font-24 p-1"></i>
                        <div>
                          <strong className="font-600 setting-list-text">
                            {t("packages")}
                          </strong>
                        </div>
                      </div>
                      <i className="bi bi-chevron-right text-secondary me-2"></i>
                    </Link>
                    <div
                      id="menu-packages"
                      className="offcanvas offcanvas-bottom offcanvas-detached  offcanvas-style "
                      style={{
                        display: "block",
                        visibility: "visible",
                      }}
                      aria-modal="true"
                      role="dialog"
                    >
                      <PackageContainer />
                    </div>
                    <Link
                      to="#"
                      className="d-flex position-relative justify-content-between item setting-list align-items-center px-3"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#menu-tickets"
                    >
                      <div className="d-flex gap-3 align-items-center">
                        <i className="has-bg gradient-red shado-4g shadow-bg-xs color-white rounded-xs bi bi-cash-coin  font-24 p-1"></i>
                        <div>
                          <strong className="font-600 setting-list-text">
                            {t("Tickets")}
                          </strong>
                        </div>
                      </div>
                      <i className="bi bi-chevron-right text-secondary me-2"></i>
                    </Link>
                    <div
                      id="menu-tickets"
                      className="offcanvas offcanvas-bottom offcanvas-detached  offcanvas-style "
                      style={{
                        display: "block",
                        visibility: "visible",
                        height: "650px",
                      }}
                      aria-modal="true"
                      role="dialog"
                    >
                      <Tickets />
                    </div>
                  </>
                ) : (
                  ``
                )}

                {role === "merchant" ? (
                  <>
                    <Link
                      to="#"
                      className="d-flex position-relative justify-content-between item setting-list align-items-center px-3"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#menu-paymentmethods"
                    >
                      <div className="d-flex gap-3 align-items-center">
                        <i className="has-bg gradient-red shado-4g shadow-bg-xs color-white rounded-xs bi bi-credit-card-2-back  font-24 p-1"></i>
                        <div>
                          <strong className="font-600 setting-list-text">
                            {t("Payment Methods")}
                          </strong>
                        </div>
                      </div>
                      <i className="bi bi-chevron-right text-secondary me-2"></i>
                    </Link>
                    <div
                      id="menu-paymentmethods"
                      className="offcanvas offcanvas-bottom offcanvas-detached  offcanvas-style "
                      style={{
                        display: "block",
                        visibility: "visible",
                        height: "600px",
                      }}
                      aria-modal="true"
                      role="dialog"
                    >
                      <PaymentMethods />
                    </div>
                  </>
                ) : (
                  ``
                )}

                {role === "merchant" || role === "merchantUser" ? (
                  <>
                    <Link
                      data-bs-toggle="offcanvas"
                      data-bs-target="#addUser"
                      to="#"
                      className="d-flex position-relative justify-content-between item setting-list align-items-center px-3"
                    >
                      <div className="d-flex gap-3 align-items-center">
                        <i className="has-bg gradient-red shado-4g shadow-bg-xs color-white rounded-xs bi bi-person  font-24 p-1"></i>
                        <div>
                          <strong className="font-600 setting-list-text">
                            {t("adduser")}
                          </strong>
                        </div>
                      </div>
                      <i className="bi bi-chevron-right text-secondary me-2"></i>
                    </Link>
                    <div
                      id="addUser"
                      className="offcanvas offcanvas-bottom offcanvas-detached  offcanvas-style "
                      aria-modal="true"
                      role="dialog"
                    >
                      <DefaultFormActionSheet title={t("adduser")}>
                        <AddUser />
                      </DefaultFormActionSheet>
                    </div>
                  </>
                ) : (
                  ``
                )}

                {role === "merchant" ? (
                  <>
                    <Link
                      data-bs-toggle="offcanvas"
                      data-bs-target="#product-requests"
                      to="#"
                      className="d-flex position-relative justify-content-between item setting-list align-items-center px-3"
                    >
                      <div className="d-flex gap-3 align-items-center">
                        <i className="has-bg gradient-red shado-4g shadow-bg-xs color-white rounded-xs bi bi-cash-coin  font-24 p-1"></i>
                        <div>
                          <strong className="font-600 setting-list-text">
                            {t("productRequests")}
                          </strong>
                        </div>
                      </div>
                      <i className="bi bi-chevron-right text-secondary me-2"></i>
                    </Link>
                    <div
                      id="product-requests"
                      className="offcanvas offcanvas-bottom offcanvas-detached  offcanvas-style "
                      aria-modal="true"
                      role="dialog"
                    >
                      <ProductRequests />
                    </div>
                  </>
                ) : (
                  ``
                )}

                {role === "delivery" ? (
                  <>
                    <Link
                      data-bs-toggle="offcanvas"
                      data-bs-target="#available-deliveries-menu"
                      to="#"
                      className="d-flex position-relative justify-content-between item setting-list align-items-center px-3"
                    >
                      <div className="d-flex gap-3 align-items-center">
                        <i className="has-bg gradient-blue shad-4bg shadow-bg-xs color-white rounded-xs bi bi-truck  font-24 p-1"></i>
                        <div>
                          <strong className="font-600 setting-list-text">
                            {t("Available Deliveries")}
                          </strong>
                        </div>
                      </div>
                      <i className="bi bi-chevron-right text-secondary me-2"></i>
                    </Link>
                    <div
                      id="available-deliveries-menu"
                      className="offcanvas offcanvas-bottom offcanvas-detached  offcanvas-style "
                      aria-modal="true"
                      role="dialog"
                      style={{
                        display: "block",
                        visibility: "visible",

                        height: "650px",
                      }}
                    >
                      <AvailableDeliveries />
                    </div>
                  </>
                ) : (
                  ``
                )}

                {role === "merchant" ? (
                  <>
                    <Link
                      to="#"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#menu-initialSettlement"
                      className="d-flex position-relative justify-content-between item setting-list align-items-center px-3"
                    >
                      <div className="d-flex gap-3 align-items-center">
                        <i className="has-bg gradient-green sha-4-bg shadow-bg-xs color-white rounded-xs bi bi-collection-fill  font-24 p-1"></i>
                        <div>
                          <strong className="font-600 setting-list-text">
                            {t("initialSettlements")}
                          </strong>
                        </div>
                      </div>
                      <i className="bi bi-chevron-right text-secondary me-2"></i>
                    </Link>
                    <div
                      id="menu-initialSettlement"
                      className="offcanvas offcanvas-bottom offcanvas-detached  offcanvas-style "
                      aria-modal="true"
                      role="dialog"
                      style={{
                        display: "block",
                        visibility: "visible",
                        height: "650px",
                      }}
                    >
                      <InitialSettlement />
                    </div>
                    <Link
                      to="#"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#menu-pending-settlement"
                      className="d-flex position-relative justify-content-between item setting-list align-items-center px-3"
                    >
                      <div className="d-flex gap-3 align-items-center">
                        <i className="has-bg gradient-mint shad-4bg shadow-bg-xs color-white rounded-xs bi bi-collection-fill  font-24 p-1"></i>
                        <div>
                          <strong className="font-600 setting-list-text">
                            {t("pendingSettlements")}
                          </strong>
                        </div>
                      </div>
                      <i className="bi bi-chevron-right text-secondary me-2"></i>
                    </Link>
                    <div
                      id="menu-pending-settlement"
                      className="offcanvas offcanvas-bottom offcanvas-detached  offcanvas-style "
                      aria-modal="true"
                      role="dialog"
                    >
                      <PendingSettlementPage />
                    </div>
                  </>
                ) : (
                  ``
                )}
              </div>

              <h6 className="pb-1 setting-title">{t("menuaccount")}</h6>
              <div className="divider mb-0"></div>
              <div className="">
                {role === "merchant" && (
                  <Link
                    to="/profile"
                    className="d-flex position-relative justify-content-between item setting-list align-items-center px-3"
                  >
                    <div className="d-flex gap-3 align-items-center">
                      <i className="has-bg gradient-green sha-4-bg shadow-bg-xs color-white rounded-xs bi bi-person-badge-fill  font-24 p-1"></i>
                      <div>
                        <strong className="font-600 setting-list-text">
                          {t("menuprofile")}
                        </strong>
                      </div>
                    </div>
                    <i className="bi bi-chevron-right text-secondary me-2"></i>
                  </Link>
                )}

                <Link
                  to="/languages"
                  className="d-flex position-relative justify-content-between item setting-list align-items-center px-3"
                >
                  <div className="d-flex gap-3 align-items-center">
                    <i className="has-bg gradient-red shado-4g shadow-bg-xs color-white rounded-xs bi bi-translate  font-24 p-1"></i>
                    <div>
                      <strong className="font-600 setting-list-text">
                        {t("changelanguage")}
                      </strong>
                    </div>
                  </div>
                  <i className="bi bi-chevron-right text-secondary me-2"></i>
                </Link>
                <h6 className="pb-1 setting-title">
                  {t("account")} {t("Pages")}
                </h6>
                <Link
                  to="/login"
                  className="d-flex position-relative justify-content-between item setting-list align-items-center px-3"
                  onClick={() => {
                    localStorage.clear();
                    toast.success("logged out");
                  }}
                >
                  <div className="d-flex gap-3 align-items-center">
                    <i className="has-bg gradient-green sha-4-bg shadow-bg-xs color-white rounded-xs bi bi-unlock-fill  font-24 p-1"></i>
                    <div>
                      <strong className="font-600 setting-list-text">
                        {t("signOut")}
                      </strong>
                    </div>
                  </div>
                  <i className="bi bi-chevron-right text-secondary me-2"></i>
                </Link>
              </div>
            </div>
          </div>

          <FooterContainer
            offersheet={"offerSettlingList"}
            productsheet={"productSettingList"}
          />
          <div
            id="productSettingList"
            className="offcanvas offcanvas-bottom offcanvas-detached  offcanvas-style "
            aria-modal="true"
            role="dialog"
          >
            <ProductList
              instock={"footerprod1"}
              outofstock={"outfooterprod1"}
            />
          </div>
          <div
            id="offerSettlingList"
            className="offcanvas offcanvas-bottom offcanvas-detached  offcanvas-style "
            aria-modal="true"
            role="dialog"
          >
            <OfferList />
          </div>
        </div>
      )}
    </>
  );
};

export default SettingContainer;
