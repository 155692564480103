import { IonItem, IonList, IonSelect, IonSelectOption } from "@ionic/react";
import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../../services/api";
import circles from "../../assets/images/fading.gif";

const CreateTicketForm = ({ setUpdate }: any) => {
  const [selectOfferData, setOfferSelectData] = useState<any>();
  const [selectProductData, setSelectProductData] = useState<any>();
  const [loading, setLoading] = useState<any>(false);
  const [getTypeValue, setGetTypeValue] = useState("general");
  const [typeId, setTypeId] = useState<any>();
  const [ticketMessage, setTicketMessage] = useState();
  const options = [{ name: "general" }, { name: "offer" }, { name: "product" }];

  const token = useSelector<any>((state) => state.userState.token);

  const [, createTicket] = api.useAxios(
    { url: "/merchant/ticket/create", method: "post" },
    { manual: true }
  );
  const [, getMerchantOffers] = api.useAxios(
    { url: "/merchant/offer/list", method: "post" },
    { manual: true }
  );
  const [, getMerchantProducts] = api.useAxios(
    { url: "/merchant/product/list", method: "post" },
    { manual: true }
  );

  useEffect(() => {
    getTypeValue == "offer" && setLoading(true);
    getMerchantOffers({ data: { token } }).then((res: any) => {
      setOfferSelectData(res?.data.data.rows);
      setLoading(false);
    });
  }, [getMerchantOffers, getTypeValue, token]);
  useEffect(() => {
    getTypeValue === "product" && setLoading(true);
    getMerchantProducts({ data: { token } }).then((res: any) => {
      setSelectProductData(res?.data.data.rows);
      setLoading(false);
    });
  }, [getMerchantProducts, getTypeValue, token]);
  const HandleCreateTicket = (e: any) => {
    e.preventDefault();
    try {
      createTicket({
        data: {
          token,
          portalType: "user",
          ticketType: getTypeValue,
          ticketTypeId: typeId,
          ticketBody: ticketMessage,
        },
      }).then(() => {
        toast.success("Ticket Created Successfuly");
        setUpdate(false);
      });
    } catch (err: any) {
      toast.error(err?.response?.data.message);
    }
  };

  console.log(selectOfferData, "offer");
  console.log(selectProductData);
  return (
    <div className="mx-auto">
      <div className=" p-3 text-center w-100 mt-5">
        <form className="text-center" onSubmit={HandleCreateTicket}>
          <IonList className="bg-transparent0">
            <IonItem className="placeholder:bg-white w-100">
              <div className="bg-white w-100">
                <IonSelect
                  interface="action-sheet"
                  placeholder="Choose Type Of Ticket"
                  className="w-100"
                  onIonChange={(e: any) => {
                    setGetTypeValue(e.detail.value);
                  }}
                >
                  {options?.map((option: any) => (
                    <div key={option?.name}>
                      <IonSelectOption value={option.name} key={option}>
                        {option.name}
                      </IonSelectOption>
                    </div>
                  ))}
                </IonSelect>
              </div>
            </IonItem>
          </IonList>
          <div className="my-3"></div>
          {loading && getTypeValue === "offer" ? (
            <img src={circles} alt="circles" />
          ) : getTypeValue === "offer" ? (
            <IonList className="bg-transparent">
              <IonItem className="placeholder:bg-white w-100">
                <div className="bg-white w-100">
                  <IonSelect
                    interface="action-sheet"
                    placeholder={"Choose Your Offer"}
                    className="w-100"
                    onIonChange={(e: any) => {
                      setTypeId(e.detail.value);
                    }}
                  >
                    {selectOfferData?.map((data: any) => (
                      <div key={data?.id}>
                        <IonSelectOption value={data?.id} key={data?.id}>
                          {data?.title}
                        </IonSelectOption>
                      </div>
                    ))}
                  </IonSelect>
                </div>
              </IonItem>
            </IonList>
          ) : null}
          {loading && getTypeValue === "product" ? (
            <img src={circles} alt="circles" />
          ) : getTypeValue === "product" ? (
            <IonList className="bg-transparent">
              <IonItem className="placeholder:bg-white w-100">
                <div className="bg-white w-100">
                  <IonSelect
                    interface="action-sheet"
                    placeholder={"Choose Your Product"}
                    className="w-100"
                    onIonChange={(e: any) => {
                      setTypeId(e.detail.value);
                    }}
                  >
                    {selectProductData?.map((data: any) => (
                      <div key={data?.id}>
                        <IonSelectOption value={data?.id} key={data?.id}>
                          {data?.name}
                        </IonSelectOption>
                      </div>
                    ))}
                  </IonSelect>
                </div>
              </IonItem>
            </IonList>
          ) : null}
          <div className="text-center p-0">
            <textarea
              placeholder="Type Your Ticket Here ...."
              rows={10}
              required
              cols={40}
              className="form-control mt-3"
              id="exampleFormControlTextarea1"
              onChange={(e: any) => {
                setTicketMessage(e.target.value);
              }}
            />
          </div>
          <div className="text-center w-100 ">
            <button
              type="submit"
              className="btn main-btn btn-block btn-lg mt-3"
            >
              Create
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
export default CreateTicketForm;
