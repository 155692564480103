import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import api from "../services/api";
import "../translations/i18n";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import LoadingContainer from "./LoadingContainer";
import { IonInfiniteScroll, IonInfiniteScrollContent } from "@ionic/react";
import { NodataContainer } from "./common/noDataContainer";

const RedeemsContainer = () => {
  const { t } = useTranslation();
  const token = useSelector<any>((state) => state.userState.token);
  const role = localStorage.getItem("role");
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [redeems, setRedeems] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [InfiniteDisabled, setInfiniteDisabled] = useState<any>(false);

  let limit = 10;

  const [, getRedeems] = api.useAxios(
    {
      url:
        role === "merchant"
          ? "/merchant/req/redeem/list"
          : "/merchantUser/req/redeem/list",
      method: "post",
    },
    { manual: true }
  );

  useEffect(() => {
    setLoading(true);
    getRedeems({ data: { token, limit: 1000 } })
      .then((res) => {
        setRedeems([...res.data.data.rows]);
        setTotalCount(res.data.data.count);
        // let last = 0;
        // const plusPage = totalCount % limit > 0 ? 1 : 0;
        // last = Math.trunc(totalCount / limit) + plusPage;
        // setLastPage(last);
        setLoading(false);
      })
      .catch((err) => {
        toast(err?.response?.data.message);
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const handleLoadingMore = () => {
  //   setPage(page + 1);
  // };
  const loadData = useCallback(
    (event: any) => {
      setLoading(false);
      setPage(page + 1);
      setInfiniteDisabled(false);
      setTimeout(() => {
        getRedeems({ data: { token, limit, pageNum: page + 1 } }).then(
          (res: any) => {
            setRedeems([...redeems, ...res.data.data.rows]);
          }
        );
        event.target.complete();
        if (redeems.length < totalCount) {
          return setInfiniteDisabled(false);
        } else {
          return setInfiniteDisabled(true);
        }
      }, 500);
    },
    [getRedeems, token, limit, page, redeems, totalCount]
  );

  const renderRedeems = () => {
    return (
      <>
        {redeems?.map((redeem: any) => (
          <Link
            key={redeem.id}
            to={`/redeem/${redeem.id}`}
            className="list-group-item"
          >
            <i
              className="has-bg rounded-xs bi bi-wallet2"
              style={{
                color: "rgb(86, 238, 168)",
                backgroundColor: "rgb(9, 44, 76)",
              }}
            ></i>
            <div>
              <h5
                className="font-15 mb-1 text-capitalize"
                style={{
                  color: "rgb(9, 44, 76)",
                }}
              >
                {redeem.user.name}
              </h5>
              <span
                className="mt-0 main-clr text-capitalize"
                style={{
                  opacity: "0.7",
                  fontSize: "11px",
                }}
              >
                <strong
                  className="d-inline main-clr font-11"
                  style={{
                    opacity: "0.7",
                  }}
                >
                  {" "}
                  {t("amount")} : ${redeem.amount}&nbsp;&nbsp;&nbsp;
                </strong>

                <br />
                <strong
                  className="d-inline font-11 main-clr"
                  style={{
                    opacity: "0.7",
                  }}
                >
                  {" "}
                  {t("phone")} : {redeem.user.mobile}
                </strong>
              </span>
            </div>

            <div>
              <span className="badge rounded-xl">
                {redeem.isApproved ? (
                  <div>
                    <span
                      className="badge rounded-xl py-2 px-3"
                      style={{
                        backgroundColor: "rgb(9, 44, 76)",
                        color: "rgb(86, 238, 168)",
                      }}
                    >
                      {t("approved")}
                    </span>
                  </div>
                ) : (
                  <div>
                    <span className="badge rounded-xl bg-danger py-2 px-3">
                      {t("notApproved")}
                    </span>
                  </div>
                )}
              </span>
            </div>
          </Link>
        ))}

        {/* {redeems.length < totalCount ? (
          <IonInfiniteScroll
            onIonInfinite={loadData}
            disabled={InfiniteDisabled}
            threshold="100px"
          >
            <IonInfiniteScrollContent
              loadingText={"loading"}
            ></IonInfiniteScrollContent>
          </IonInfiniteScroll>
        ) : (
          ``
        )} */}
      </>
    );
  };

  return (
    <div className="mb-5 pb-5 pt-5 theme-light" id="tab-group-6">
      <div className="card card-style">
        <div className="content my-1">
          {InfiniteDisabled && redeems?.length === 0 && <NodataContainer />}
          <div
            className="collapse show"
            id="tab-16"
            data-bs-parent="#tab-group-6"
          >
            <div className="list-group list-custom list-group-m list-group-flush rounded-xs">
              {loading === true ? <LoadingContainer /> : renderRedeems()}
            </div>
            {/* {(lastPage < page || page !== lastPage) && (
              <div className="text-center pb-4">
                <button onClick={handleLoadingMore} className="btn btn-primary">
                  {t("See More")}
                </button>
              </div>
            )} */}
            {/* {lastPage < page || page !== lastPage ? (
              redeems?.length !== 0 ? (
                <div className="text-center pb-2">
                  <button
                    onClick={handleLoadingMore}
                    className="btn btn-primary"
                  >
                    {t("See More")}
                  </button>
                </div>
              ) : (
                ``
              )
            ) : (
              ``
            )} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RedeemsContainer;
