import * as yup from "yup";

const localeMessage = (type, values) => ({
  transKey: `forms.validation.${type}`,
  values,
});

yup.setLocale({
  mixed: {
    default: ({ path }) => localeMessage("invalid", { name: path }),
    matches: ({ path }) => localeMessage("invalid", { name: path }),
    required: ({ path }) => localeMessage("required", { name: path }),
  },
  number: {
    default: ({ path }) => localeMessage("invalid", { name: path }),
    max: ({ path, max, value }) =>
      localeMessage("max", { name: path, max, value }),
    required: ({ path }) => localeMessage("required", { name: path }),
  },
  string: {
    default: ({ path }) => localeMessage("invalid", { name: path }),
    matches: ({ path }) => localeMessage("invalid", { name: path }),
    required: ({ path }) => localeMessage("required", { name: path }),

    min: ({ min }) => ({
      transKey: "forms.validation.minLength",
      values: { min },
    }),
    max: ({ max }) => ({
      transKey: "forms.validation.maxLength",
      values: { max },
    }),
  },
});

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const RegisterValidation = yup.object().shape({
  name: yup
    .string()
    .min(1, "Name is a required field")
    .required("Name is a required field"),

  password: yup.string().required("Password is a required field"),
  check: yup.boolean().oneOf([true], "Accept privacy policy"),
  // userCurrncy: yup.string().required("User Currency is a required field"),
});

export const LoginValidation = yup.object().shape({
  password: yup.string().required("Password is a required field"),
});
export const ChangePasswordValidation = yup.object().shape({
  newPassword: yup.string().required("Required"),
  confirmNewPassword: yup
    .string()
    .oneOf([yup.ref("newPassword"), null], "Passwords don't match!")
    .required("Required"),
});
export const ForgetPasswordValidation = yup.object().shape({
  username: yup.string().required("Number is a required field"),
});

export const CreateUserValidation = yup.object().shape({
  fName: yup.string().required(),
  mName: yup.string().required(),
  lName: yup.string().required(),
  mobileNum: yup.number().required(),
  branchId: yup.string().required(),
  rule: yup.string().required(),
});

export const AddOfferSchema = yup.object().shape({
  // branchsIds: yup.array().required(),chooseBranches
  chooseBranches: yup.array().min(1).required("Please choose a branch"),
  chooseTags: yup.array().min(1).required("Tags are required"),
  points: yup.string().required("Points are required"),
  availableNumberOfOffers: yup
    .string()
    .required("available Number Of Offers are required"),
  price: yup.string().required("Price is required"),
  startDate: yup.string().required("Startdate is required"),
  endDate: yup.string().required("endDate is required"),
  title: yup.string().required("title is required"),
  titleAr: yup.string().required("tilteAr is required"),
  description: yup
    .string()
    .required("description is required")
    .matches(
      /^.{30,300}$/,
      "description must be more than 30 and less than 300 charachters "
    ),
  descriptionAr: yup
    .string()
    .required("description is required")
    .matches(
      /^.{30,300}$/,
      "arabic description must be more than 30 and less than 300 charachters"
    ),
});

export const AddProductSchema = yup.object().shape({
  // branchsIds: yup.array().required(),
  price: yup.number().required("price are requrired"),
  // .matches(/^[0-9]+$/, "points should be a number"),
  stockAmount: yup.string().required("Stock amount is requried"),
  name: yup.string().required("Name is required"),
  nameAr: yup.string().required("NameAr is required"),
  description: yup
    .string()
    .required("description is required")
    .matches(
      /^.{30,300}$/,
      "description must be more than 30 and less than 300 charachters "
    ),
  descriptionAr: yup
    .string()
    .required("description is required")
    .matches(
      /^.{30,300}$/,
      "arabic description must be more than 30 and less than 300 charachters"
    ),
});

export const PhoneLoginSchema = yup.object().shape({
  phone: yup.string().matches(phoneRegExp).required(),
  // phone: yup.string().matches(phoneRegExp, "phone number is not valid").required(),
});
