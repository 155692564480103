import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { toast, ToastContainer } from "react-toastify";
import { ErrorMessage } from "@hookform/error-message";
import Select from "react-select";
import makeAnimated from "react-select/animated";

import HeaderContainer from "../../components/common/Header";
import FooterContainer from "../../components/common/Footer";
import { FormError } from "../../components/form";
import api from "../../services/api";

import "../../translations/i18n";
import { IonContent, IonPage } from "@ionic/react";

const AddEmployee: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const animatedComponents = makeAnimated();
  const token = useSelector<any>((state) => state.userState.token);
  const [branches, setBranches] = useState<any>([]);

  const [, getBranches] = api.useAxios(
    { url: "/merchant/branch/list", method: "post" },
    { manual: true }
  );

  const selectStyles = {
    control: (styles: any) => ({
      ...styles,
      height: "55px",
    }),

    menu: (styles: any) => ({
      ...styles,
      zIndex: " 99999999 !important",
      backgroundColor: "red",
      width: "50%",
    }),
  };

  useEffect(() => {
    const limit = 1000;
    getBranches({ data: { pageNum: 1, limit, token } })
      .then((res) => {
        setBranches([...res.data.data.rows]);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [getBranches]);

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    // resolver: yupResolver(CreateUserValidation),
  });

  const [{ loading: addEmployeeLoading }, addEmployee] = api.useAxios(
    { url: "/merchant/addEmployee", method: "post" },
    { manual: true }
  );

  const onSubmit = (data: any) => {
    const finalData = { ...data, token };

    addEmployee({ data: finalData })
      .then((res: any) => {
        reset();

        toast.success("added successfully");
        window.location.reload();
      })
      .catch((err: any) => {
        toast(err?.response?.data.message);
      });
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-custom form-label form-icon mt-3">
          <Controller
            control={control}
            name="branchId"
            render={({ field: { onChange } }) => (
              <Select
                options={branches}
                getOptionValue={(option: any) => option.id}
                getOptionLabel={(option: any) => option.branchName}
                closeMenuOnSelect={false}
                components={animatedComponents}
                styles={selectStyles}
                onChange={(option: any) => onChange(option.id)}
              />
            )}
          />
          <label htmlFor="c2" className="color-highlight">
            {t("Branches")}
          </label>
          <span>(required)</span>
        </div>

        <div className="form-custom form-label form-icon mt-3">
          <i className="bi bi-pencil-fill font-12" />
          <input
            className="form-control rounded-xs"
            id="c32"
            type="text"
            placeholder={t("First Name")}
            {...register("fName")}
          />
          <label className="color-highlight" htmlFor="c32">
            {t("First Name")}
          </label>
          <span>(required)</span>
          <div className="mb-30">
            <ErrorMessage errors={errors} name="fName" render={FormError} />
          </div>
        </div>
        <div className="pb-2" />

        <div className="form-custom form-label form-icon">
          <i className="bi bi-pencil-fill font-12" />
          <input
            className="form-control rounded-xs"
            id="c32"
            type="text"
            placeholder={t("Middle Name")}
            {...register("mName")}
          />
          <label htmlFor="c32" className="color-highlight">
            {t("Middle Name")}
          </label>
          <span>(required)</span>
          <div className="mb-30">
            <ErrorMessage errors={errors} name="mName" render={FormError} />
          </div>
        </div>
        <div className="pb-2" />

        <div className="form-custom form-label form-icon">
          <i className="bi bi-pencil-fill font-12" />
          <input
            className="form-control rounded-xs"
            id="c7"
            type="text"
            placeholder={t("Last Name")}
            {...register("lName")}
          />
          <label htmlFor="c7" className="color-highlight font-11">
            {t("Last Name")}
          </label>
          <span>(required)</span>
          <div className="mb-30">
            <ErrorMessage errors={errors} name="lName" render={FormError} />
          </div>
        </div>
        <div className="pb-2" />

        <div className="form-custom form-label form-icon">
          <i className="bi bi-pencil-fill font-12" />
          <input
            className="form-control rounded-xs"
            id="c7"
            type="number"
            placeholder={t("Mobile Number")}
            {...register("mobileNum")}
          />
          <label htmlFor="c7" className="color-highlight font-11">
            {t("Mobile Number")}
          </label>
          <span>(required)</span>
          <div className="mb-30">
            <ErrorMessage errors={errors} name="mobileNum" render={FormError} />
          </div>
        </div>
        <div className="pb-2" />

        <div className="form-custom form-label form-icon">
          <i className="bi bi-pencil-fill font-12" />
          <input
            className="form-control rounded-xs"
            id="c7"
            type="text"
            placeholder={t("Rule")}
            {...register("rule")}
          />
          <label htmlFor="c7" className="color-highlight font-11">
            {t("Rule")}
          </label>
          <span>(required)</span>
          <div className="mb-30">
            <ErrorMessage errors={errors} name="rule" render={FormError} />
          </div>
        </div>
        <div className="pb-2" />

        <button
          disabled={isSubmitting}
          className="btn btn-full gradient-highlight w-100 rounded-s shadow-bg shadow-bg-xs mt-3 mb-3"
        >
          {t("Add Employee")}
        </button>
      </form>
    </>
  );
};

export default AddEmployee;
