import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { map } from "lodash";
import api from "../../services/api";
import LoadingContainer from "../../components/LoadingContainer";
import { toast } from "react-toastify";
import "../../translations/i18n";
import {
  IonContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonPage,
} from "@ionic/react";
import DefaultFormActionSheet from "../../components/common/DefaultFormActionSheet";
import AddOffer from "./AddOffer";
import { OfferRequests, OfferUsers } from "../offer-details/Components";
import { UpdateOffer } from "../offer-details/Components/UpdateOffer";
import { NodataContainer } from "../../components/common/noDataContainer";
import loginm from "../../assets/images/nothing.svg";

const OfferList = () => {
  const { t } = useTranslation();
  const IMAGEURL = process.env.REACT_APP_IMAGEURL;
  const token = useSelector<any>((state) => state.userState.token);
  const role = localStorage.getItem("role");
  const [totalCountOffers, setTotalCountOffers] = useState(0);
  const [page, setPage] = useState(1);
  const [offers, setOffers] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [addOffer, setAddOffer] = useState(false);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [activeOffer, setActiveOffer] = useState<any>();

  const [InfiniteDisabled, setInfiniteDisabled] = useState<any>(false);

  let limit = 10;

  const [, getOffers] = api.useAxios(
    {
      url:
        role === "merchant"
          ? "/merchant/offer/list"
          : "/merchantUser/offer/list",
      method: "post",
    },
    { manual: true }
  );

  useEffect(() => {
    setLoading(true);
    (role === "merchant" || role === "merchantUser") &&
      getOffers({ data: { token, limit: 1000 } })
        .then((res) => {
          setOffers([...res.data.data.rows]);
          setTotalCountOffers(res.data.data.count);
          // let last = 0;
          // const plusPage = totalCount % limit > 0 ? 1 : 0;
          // last = Math.trunc(totalCount / limit) + plusPage;
          // setLastPage(last);
          setLoading(false);
        })
        .catch((err) => {
          toast(err?.response?.data.message);
          setLoading(false);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceUpdate]);

  const loadData = useCallback(
    (event: any) => {
      setLoading(false);
      setPage(page + 1);
      setInfiniteDisabled(false);
      setTimeout(() => {
        getOffers({ data: { token, pageNum: page + 1, limit } }).then(
          (res: any) => {
            setOffers([...offers, ...res.data.data.rows]);
            setTotalCountOffers(res.data.data.count);
          }
        );
        event.target.complete();
        if (offers.length < totalCountOffers) {
          return setInfiniteDisabled(false);
        } else {
          return setInfiniteDisabled(true);
        }
      }, 500);
    },
    [page, getOffers, token, limit, offers, totalCountOffers]
  );

  const renderOffers = () => {
    return (
      <>
        <IonPage>
          <IonContent fullscreen>
            <div className="content">
              {!addOffer ? (
                <>
                  <h6 className="text-center branch-title m-4">
                    {" "}
                    {t("offers")}
                  </h6>
                  <i
                    data-bs-dismiss="offcanvas"
                    className="bi bi-x main-clr font-26 line-height-xl close-offcanvas"
                  ></i>

                  {offers?.length == 0 ? (
                    <div className="text-center">
                      <img src={loginm} className="text-center svg-pages" />
                      <h6 className="text-sm">
                        {" "}
                        You don't have any offers here
                      </h6>
                    </div>
                  ) : (
                    <div
                      className="row product_____list justify-content-center"
                      style={{ height: "30rem", overflow: "auto" }}
                    >
                      {offers?.map((offer: any) => (
                        <Link
                          key={offer.id}
                          to={`/offer/${offer?.id}`}
                          onClick={() => {
                            setActiveOffer(offer?.id);
                          }}
                          className={`col-6 gap-3 align-items-center p-3  ${
                            offer?.id === activeOffer
                              ? "product_____list__active"
                              : "product_____list__inactive"
                          }  `}
                        >
                          {offer?.images !== null ? (
                            <img
                              src={`${IMAGEURL}${offer?.images[0]}`}
                              width={33}
                              height={50}
                              className=" rounded-xs"
                              alt="img"
                            />
                          ) : (
                            <i
                              className="has-bg rounded-xs bi bi-gift font-20 p-1 "
                              style={{
                                color: "#4ABA99",
                              }}
                            ></i>
                          )}
                          <div className="mt-3">
                            <h5 className=" m-0 text-capitalize name">
                              {offer.title}
                            </h5>
                            {/* <strong className="branches-address">
                        {t("validTo")} :{" "}
                        <span>{new Date(offer?.endDate).toDateString()}</span>
                      </strong>
                      <br /> */}
                            <p className="desc mt-1 mb-0">
                              {offer?.description}
                            </p>
                            <div className="d-flex justify-content-between">
                              <span className="points-price">
                                {offer?.price}$
                              </span>

                              <span className="points-price">
                                {offer?.points}P
                              </span>
                            </div>
                          </div>

                          <div className="d-flex gap-1 flex-column align-items-center col-4">
                            {/* {offer.isActive ? (
                      <div>
                        <span
                          style={{
                            backgroundColor: "rgb(9, 44, 76)",
                            color: "rgb(86, 238, 168)",
                          }}
                          className="badge rounded-m"
                        >
                          {t("branchActive")}
                        </span>
                      </div>
                    ) : (
                      <div>
                        <span className="badge rounded-m bg-danger">
                          {t("branchNotActive")}
                        </span>
                      </div>
                    )} */}
                          </div>
                        </Link>
                      ))}
                      {/* {offers?.length < totalCountOffers ? (
                        <IonInfiniteScroll
                          onIonInfinite={loadData}
                          disabled={InfiniteDisabled}
                          threshold="0px"
                        >
                          <IonInfiniteScrollContent
                            loadingText={"loading"}
                          ></IonInfiniteScrollContent>
                        </IonInfiniteScroll>
                      ) : (
                        ``
                      )} */}
                    </div>
                  )}
                  <div className="d-flex justify-content-between align-items-center px-3 pt-2 ">
                    <span className="total-requests">
                      {totalCountOffers}
                      {` `}
                      {totalCountOffers > 1 ? "offers" : "offer"}
                    </span>
                    <button
                      className="addemployee-btn"
                      onClick={() => setAddOffer(true)}
                    >
                      Add offer
                    </button>
                  </div>
                </>
              ) : (
                <DefaultFormActionSheet
                  back={true}
                  backState={setAddOffer}
                  title={t("addOffer")}
                >
                  <AddOffer
                    backState={setAddOffer}
                    update={forceUpdate}
                    setUpdate={setForceUpdate}
                  />
                </DefaultFormActionSheet>
              )}
            </div>
          </IonContent>
        </IonPage>
      </>
    );
  };

  return (
    <>
      {InfiniteDisabled && offers?.length === 0 && <NodataContainer />}
      <div
        className="collapse show "
        style={{ height: "600px" }}
        id="tab-16"
        data-bs-parent="#tab-group-6"
      >
        <div>{loading === true ? <LoadingContainer /> : renderOffers()}</div>
        {/* {lastPage < page || page !== lastPage ? (
          offers?.length !== 0 ? (
            <div className="text-center pb-2"></div>
          ) : (
            ``
          )
        ) : (
          ``
        )} */}
      </div>
    </>
  );
};

export default OfferList;
