import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import api from "../services/api";
import "./../translations/i18n";
import "react-toastify/dist/ReactToastify.css";
import LoadingContainer from "./LoadingContainer";
import {
  IonContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonPage,
} from "@ionic/react";
import PackageDetails from "../pages/packageDetails/PackageDetails";
import { NodataContainer } from "./common/noDataContainer";

const PackageContainer = () => {
  const { t } = useTranslation();

  const token = useSelector<any>((state) => state.userState.token);
  const [packages, setPackages] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [pageNum, setPageNum] = useState(1);
  const [InfiniteDisabled, setInfiniteDisabled] = useState<any>(false);
  const [showDetails, setShowDetails] = useState(false);
  const [id, setId] = useState();

  const [, getPackages] = api.useAxios(
    { url: "/merchant/package/list", method: "post" },
    { manual: true }
  );
  let limit = 10;

  useEffect(() => {
    setLoading(true);

    getPackages({ data: { token, limit: 1000 } })
      .then((res) => {
        setPackages(res?.data?.data.packageData?.rows);
        setTotalCount(res.data.data.count);
        // let last = 0;
        // const plusPage = totalCount % limit > 0 ? 1 : 0;
        // last = Math.trunc(totalCount / limit) + plusPage;
        // setLastPage(last);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = useCallback(
    (event: any) => {
      setLoading(false);
      setPageNum(pageNum + 1);
      setInfiniteDisabled(false);
      setTimeout(() => {
        getPackages({ data: { token, page: pageNum + 1, limit } }).then(
          (res: any) => {
            setPackages([...packages, ...res.data.data.rows]);
          }
        );
        event.target.complete();
        if (packages.length < totalCount) {
          return setInfiniteDisabled(false);
        } else {
          return setInfiniteDisabled(true);
        }
      }, 500);
    },
    [pageNum, getPackages, token, limit, packages, totalCount]
  );
  console.log(packages);

  const renderPackages = () => {
    return (
      <IonPage>
        <IonContent fullscreen>
          <div className="content">
            {" "}
            <h6 className="text-center branch-title m-4"> {t("packages")}</h6>
            <i
              data-bs-dismiss="offcanvas"
              className="bi bi-x main-clr font-26 line-height-xl close-offcanvas"
            ></i>
            <div
              className="px-3"
              style={{ height: "32rem", overflow: "scroll" }}
            >
              {packages.length !== 0 ? (
                packages?.map((item: any) => (
                  <Link
                    key={item.id}
                    to={`/package/${item?.id}`}
                    className="row  align-items-center invoices-list"
                    onClick={() => {
                      setShowDetails(true);
                      setId(item?.id);
                    }}
                  >
                    <div className="d-flex align-items-center gap-3 col-12 ">
                      <div className="col-10">
                        <div>
                          <strong className="main">{item.name}</strong> <br />
                          <span className="det">{item.description}</span>
                        </div>
                      </div>
                      <button className="btn col-2 text-center">
                        ${item.amount}
                      </button>
                    </div>
                  </Link>
                ))
              ) : (
                <NodataContainer />
              )}
            </div>
            {/* {packages.length < totalCount ? (
              <IonInfiniteScroll
                onIonInfinite={loadData}
                disabled={InfiniteDisabled}
                threshold="100px"
              >
                <IonInfiniteScrollContent
                  loadingText={"loading"}
                ></IonInfiniteScrollContent>
              </IonInfiniteScroll>
            ) : (
              ``
            )} */}
          </div>
        </IonContent>
      </IonPage>
    );
  };

  return (
    <div
      className="  "
      style={{
        height: "650px",
      }}
    >
      <div className="collapse show" id="tab-16" data-bs-parent="#tab-group-6">
        <div className="">
          {loading === true ? <LoadingContainer /> : renderPackages()}
        </div>
      </div>
    </div>
  );
};

export default PackageContainer;
