import { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { ErrorMessage } from "@hookform/error-message";
// import Select from "react-select";
import { LoginValidation } from "../utils/validationSchema";
import { FormError } from "../components/form";
import { loginUser, updateSpinnerStatus } from "../redux/actions";
import api from "../services/api";
import "./../translations/i18n";
import LoadingContainer from "../components/LoadingContainer";
import { IonItem, IonList, IonSelect, IonSelectOption } from "@ionic/react";
import { Device } from "@capacitor/device";
// import loginm from "../assets/images/login-m.svg";
import loginm from "../assets/images/delivery-dash.gif";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
// import loginm from "../assets/images/login-man.gif";

const LoginContainer = () => {
  const [value, setValue] = useState<any>();
  const [phoneErrMssg, setPhoneErrMssg] = useState<any>(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [loadingset, setLoadingset] = useState(false);
  const [country, setCountry] = useState<any>();

  const [selectedRole, setSelectedRole] = useState("");

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting, isValid, isDirty },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(LoginValidation),
  });

  const ruleOptions = [
    {
      value: "merchant",
      label: "Merchant",
    },
    { value: "employee", label: "Employee" },
    { value: "delivery", label: "Delivery" },
  ];

  const [{ loading: loginLoading }, login] = api.useAxios(
    {
      url: `/merchant/login`,
      method: "post",
    },
    { manual: true }
  );

  useEffect(() => {
    localStorage.clear();
  }, []);
  console.log(selectedRole);

  const getDeviceId = async () => {
    return await Device.getId();
  };

  const onSubmit = async (loginData: any) => {
    if (!isValid || !isDirty) {
      return;
    }
    if (
      value === undefined ||
      value.length < 5 ||
      value.replace(country?.dialCode, "") == ""
    ) {
      return setPhoneErrMssg(true);
    }
    let pushToken =
      localStorage.getItem("pushToken") ||
      "dxHRvVdNQKW6ZVFbc1hobO:APA91bGUkU10F701QzUkds8Vjf1La6cmNoEVphmftbuZmUf5n-gJwqpLg3RbmHL-S7114CPP2VbI0RSXKv69j-djfdrMtpriY37RdnsqNv7SEron_Vkvsnfg852Ll_bwKkx0xQCdxSnt";

    /* ---------- get device id  ---------- */

    const deviceId = await getDeviceId();

    /* ---------- device object  ---------- */
    const device = {
      deviceId: deviceId.uuid,
      deviceToken: pushToken,
      lastLogin: new Date(),
    };
    loginData.username = value;
    let data = { ...loginData, ...device };
    console.log("-----".repeat(25));
    console.log(data);
    console.log("-----".repeat(25));
    setLoadingset(true);
    login({ data })
      .then((res: any) => {
        console.log(res);
        reset();
        dispatch(loginUser({ ...res.data.data }));
        if (
          res.data.data.rule !== "merchant" &&
          res.data.data.rule !== "merchantUser" &&
          res.data.data.rule !== "delivery"
        ) {
          setLoadingset(false);
          return toast.error("You are not authorized to access this App");
        } else {
          localStorage.setItem("role", res.data.data.rule);
          localStorage.setItem("isAproved", res.data.data.isAproved);
          localStorage.setItem("merchantId", res?.data?.data?.id);
          if (
            res.data.data.rule === "merchant" &&
            res.data.data.isAproved === false
          ) {
            return history.push("/profile/activateaccount");
          }
          setValue("");
          history.push("/dashboard");
          toast.success("Logged In");
        }
        setLoadingset(false);
      })

      .catch((err: any) => {
        setValue("");
        setPhoneErrMssg(false);
        toast.error(err?.response?.data.message);
        if (err?.response?.data.message) console.log("fffffff");
        if (err?.response?.data.message) setLoadingset(false);
      });
  };

  useEffect(() => {
    dispatch(updateSpinnerStatus({ loginLoading }));
  }, [dispatch, loginLoading]);

  const handleRuleChange = (selectedOption: any) => {
    setSelectedRole(selectedOption);
  };

  return (
    <>
      <div className="pt-0">
        <div className="">
          <div className="section login-header">
            <img className="login-big-img" src={loginm} alt="lang" />
            <h1 className="login-h1 "> {t("loginheader")}</h1>
            <h6 className="login-h6">
              {t("logindetails")}
              <br /> {t("registerdetails2")}
            </h6>
          </div>

          <form onSubmit={handleSubmit(onSubmit)} className="px-3">
            {/* <Select
                styles={selectStyles}
                options={ruleOptions}
                getOptionValue={(option: any) => option.value}
                getOptionLabel={(option: any) => option.label}
                onChange={handleRuleChange}
                placeholder="Select Role"
                className="text-black"
              /> */}

            <div className="form-group boxed">
              <label className="login-label">{t("username")}</label>
              <PhoneInput
                inputClass="form-control register-input w-100"
                country="qa"
                value={value}
                inputProps={{
                  name: "phone",
                  required: true,
                  autoFocus: true,
                }}
                inputStyle={{
                  textIndent: "30px",
                }}
                onChange={(e: any, country: any) => {
                  setValue(e);
                  setCountry(country);
                }}
              />
              {/* <input
                type="number"
                className="form-control login-input"
                placeholder={t("username")}
                {...register("username")}
              />

              <span>{t("required")}</span>
              <ErrorMessage
                errors={errors}
                name="username"
                render={FormError}
              /> */}
              {phoneErrMssg && (
                <span className="text-danger font-9">Invalid Phone Num</span>
              )}
            </div>

            <div className="form-group boxed">
              <label className="login-label">{t("password")}</label>
              <input
                type="password"
                className="form-control login-input"
                {...register("password")}
                placeholder={t("enterpassword")}
              />

              <span>{t("required")}</span>
              <ErrorMessage
                errors={errors}
                name="password"
                render={FormError}
              />
            </div>

            {loadingset === true ? <LoadingContainer /> : <div></div>}

            <button
              className="btn btn-full main-btn mt-4"
              disabled={isSubmitting}
              type="submit"
            >
              {t("signin")}
            </button>

            <div className="row text-center">
              <div className="col-12 ">
                <br />
                <br />
                <p
                  style={{
                    color: `#092C4C`,
                  }}
                >
                  {t("havenotaccount")}{" "}
                  <Link
                    className="text-nowrap "
                    to="/register"
                    style={{
                      color: "#25E38B",
                    }}
                  >
                    {t("registernow")}
                  </Link>
                  <br />
                  {/* <Link
                      className="text-nowrap"
                      to="/forgetpassword"
                      style={{
                        color: "rgb(9, 44, 76)",
                      }}
                    >
                      {t("forgetpassword")}
                    </Link>
                    <br /> */}
                  <Link
                    to="/languages"
                    style={{
                      color: "rgb(9, 44, 76)",
                    }}
                  >
                    {t("changelanguage")}
                  </Link>
                </p>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default LoginContainer;
