export const TRANSLATIONS_AR = {
  welcomeToTutorial: "مرحبا بكم",
  about: "عن البرنامج",
  languages: "إختر لغة",
  branches: "الفروع",
  loginhead: "قم بعمليه تسجيل الدخول",
  registerhead: "تسجيل حساب تاجر جديد",
  changelanguage: "تغيير اللغه",
  changepasswordhead: "تغيير كلمة السر",
  newpassword: "كلمة السر جديدة",
  confirmnewpassword: "تأكيد كلمة السر",
  signin: "تسجيل الدخول",
  forgetpassword: "إستعادة كلمة المرور",
  send: "ارسال",
  havenotaccount: "ليس لديك حساب؟",
  haveaccount: "هل لديك حساب بالفعل؟",
  registernow: "تسجيل حساب جديد",
  chooselanguage: "إختر اللغة",
  username: "رقم الهاتف الجوال",
  enterpassword: "كلمه المرور",
  recentOffers: "أحدث العروض",
  branch: "الفرع",
  viewDetails: "التفاصيل",
  offer: "العرض",
  workzone: "منطقة الأعمال",
  menudashboard: "القائمة",
  menubranches: "الفروع",
  menuoffers: "العروض",
  menuemployees: "الموظفين",
  menuofferrequests: "طلبات العملاء",
  menuaccount: "الحساب الشخصى",
  menuprofile: "الملف الشخصى",
  menuabout: "ماذا عنا",
  menulanguage: "اللغات",
  menucreateuser: "تسجيل حساب عميل جديد",
  logout: "تسجيل الخروج",
  merchantname: "اسم التاجر",
  currency: "قطر",
  entervalidname: "إدخل إسم صحيح",
  entervalidpassword: "أدخل رقم سرى صحيح",
  entervalidmobile: "أدخل رقم هاتف جوال صحيح",
  failedtoregister: "فشل فى تسجيل حساب جديد",
  failedtologin: "فشل فى تسجيل الدخول تأكد من البيانات ",
  entervaliddata: "تأكد من إدخال البيانات المطلوبه صحيحه",
  dashboard: "القائمه الرئيسيه",
  offers: "العروض",
  menu: "القائمه",
  requests: "الطلبات",
  packages: "الباقات",
  loginnow: "تسجيل دخول",
  alreadyhaveanaccount: "لديك حساب بالفعل؟",
  confirm: "تأكيد",
  activateacchead: "ملاحظات لتفعيل حسابك",
  sendto: "ارسال المستندات الى",
  personalinformation: "معلومات شخصية",
  changepw: "تغيير كلمة السر",
  activateaccount: "تفعيل الأكونت",
  employess: "الموظفين",
  recentemployees: "الموظفين الحاليين",
  addemployee: "اضافة موظف",
  updateemployee: "تحديث بيانات الموظف",
  fName: "الاسم الاول",
  mName: "الاسم الاوسط",
  lName: "الاسم الاخير",
  mNumber: "رقم الهاتف",
  Rule: "الوظيفة",
  notifications: "إشعارات",
  yourpayments: "مدفوعاتك",
  yourstatistics: "إحصائياتك",
  colorscheme: "نظام اللون",
  get: "احصل علي",
  points: "النقاط",
  price: "السعر",
  startDate: "تاريخ البدء",
  endDate: "تاريخ الإنتهاء",
  description: "الوصف",
  descriptionAr: "الوصف باللغة العربية",
  redeem: " رد قيمة",
  create: "إنشاء",
  starts: "البدء",
  Point: "نقطة",
  Price: "السعر",
  employee: "موظف",
  buyNow: "اشتري الآن",
  Pro: "ترقية",
  Username: "اسم المستخدم",
  Update: "تحديث",
  active: "(نشط)",
  notdeactive: "(غير نشط)",
  users: "المستخدمين",
  for: "لأجل",
  startsAt: "يبدأ في",
  endsAt: "ينتهي في",
  status: "الحالة",
  recentRequests: "الطلب الحالي",
  updateBranch: "تحديث الفرع",
  branchName: "اسم الفرع",
  phone: "الرقم",
  mobile: "رقم الهاتف",
  code: "الرمز",
  pendingRequests: "الطلبات المعلقة",
  createAccount: "إنشاء حساب",
  signIn: "تسجيل الدخول",
  validTo: "ساري حتي",
  recentEmployees: "الموظفين الحاليين",
  addEmployee: "إضافة موظف",
  latestRequests: "الطلبات الأخيرة",
  employeename: "اسم الموظف",
  recentbranches: "الفروع الحالية",
  address: "العنوان",
  addressAr: "العنوان باللغة العربية",
  chooseRule: "إختر دورك",
  yourBalance: "الفرع الخاص بك",
  mainAccount: "الحساب الرئيسي",
  welcome: "مرحبا بك",
  renewPlan: "تجديد الخطة",
  userRequeste: "طلبات المستخدم",
  billsHistory: "سجل الفواتير",
  accountStatistics: "إحصائيات الحساب ",
  totalActiveNonBranches: "مجموع الفروع النشطة / غير النشطة ",
  totalCount: "العدد الإجمالي",
  totalActiveOffers: "إجمالي العروض النشطة",
  totalpendingRequests: "إجمالي طلبات العملاء المعلقة",
  goLive: "إطلاق",
  accountStats: "إحصائيات الحساب",
  activateStats: "تفعيل الحالة",
  merchantApproveMessage:
    "تم تفعيل حساب التاجر الخاص بك بنجاح، وأنت في الوضع المباشر",
  merchantNotApprovedMessage:
    "لم يتم تفعيل حساب التاجر الخاص بك بعد، برجاء الضغط علي زر الإطلاق",
  userReedems: "قائمة إستردادات المستخدم",
  redeems: "قائمة الإستردادات",
  yourAccountIsActive: "تم تفعيل الحساب الخاص بك.",
  yourAccountIsNotActive: "لم يتم تفعيل الحساب الخاص بك.",
  productRequests: "طلبات المنتج",
  productRequestDetails: "تفاصيل طلبات المنتج",

  merchantSetting: "إعدادات التاجر",
  renew: "تجديد",
  renewPackage: "تجديد الباقة",
  billsAndInvoices: "فواتير",
  accountReports: "تقارير الحساب",
  seeYourtStatistics: "اعرض الإحصائيات الخاصة بك",
  Pages: "صفحات",
  products: "منتجات",
  invoices: "فواتير",
  pendingSettlements: "التسويات المعلقة",
  settlementDetails: "تفاصيل التسوية",
  termsOfService: "شروط الخدمة",
  account: "الحساب",
  signOut: "تسجيل الخروج",
  branchActive: "نشط",
  branchNotActive: "غير نشط",
  addBranch: "إضافة فرع",
  Phone: "الرقم",
  lat: " خط العرض",
  long: "خط الطول",
  required: "(مطلوب)",
  name: "الأسم",
  details: "تفاصيل",
  sorryBranchEmployees: " عذرا، ليس لديك أي موظفين في فرعك!",
  sorryBranchOffers: "عذرا، ليس لديك أي عروض في عرضك!",
  latestOffers: "العروض الآخيرة",
  sorryOffers: " عذرا ليس لديك أي عروض في حسابك!",
  addOffer: "إضافة عرض",
  selectBranches: "إختر الفروع",
  chooseBranches: "إختر الفرع/الفروع",
  title: "العنوان",
  titleAr: "العنوان باللغة العربية",
  selectTags: "حدد العلامات",
  chooseTags: "اختر علامة/علامات",
  offerDetails: "تفاصيل العرض",
  branchDetails: "تفاصيل الفرع",
  uploadOfferImages: "رفع صورة العرض",
  update: "تحديث",
  updateOffer: "تحديث العرض",
  sorryOfferUsers: " عذرا ليس لديك أي مستخدمين في عرضك!",
  sorryOfferRequests: "عذرا ليس لديك أي طلبات في عرضك!",

  latestPackages: "أحدث الباقات ",
  sorryPackages: " عذرا لا يوجد باقات متاحة!",
  packageDetails: "تفاصيل الباقة",
  taxes: "الضرائب",
  total: "الاجمالي",
  sorryRequests: " عذرا ليس لديك طلبات في حسابك!",
  requestDetails: "تفاصيل الطلب",
  approveRequest: "اقبل الطلب",
  requestAlreadyApproved: "هذا الطلب تم قبوله بالفعل",
  scanNow: "قم بمسح الكود الآن للحصول علي الطلب",
  amount: "مقدار",
  approved: "مقبول",
  notApproved: "غير مقبول",
  latestRedeems: "أحدث قوائم الاستردادات",
  sorryRedeems: "! عذرا لا يوجد قوائم استردادات متاحة ",
  redeemDetails: "تفاصيل قائمة الإستردادات",
  user: "المستخدم",
  merchant: "التاجر",
  createdAt: "أنشئت في ",
  updatedAt: "تم التحديث في",
  approveRedeem: "قائمة الاستردادات المقبولة",
  redeemAlreadyApproved: "تم بالفعل قبول قائمة الاستردادات",
  addProduct: "إضافة منتج",
  nameAr: "الاسم باللغة العربية",
  stockAmount: "الكمية",
  latestProducts: "أحدث قوائم الإسترداد",
  sorryProducts: " عذرا ليس لديك منتجات في حسابك!",
  productDetails: "تفاصيل المنتج",
  uploadProductImage: "رفع صورة المنتج",
  updateProduct: "تحديث المنتج",
  selectBranch: "اختر الفرع",
  chooseBranch: "اختر الفرع",
  password: "كلمة السر",
  employeeProfile: "الملف الشخصي للموظف",

  latestInvoices: "أحدث طلبات الباقات ",
  sorryInvoices: " عذرا السجل الخاص بك فارغ!",
  invoiceDetails: "تفاصيل فاتورة ",
  adduser: "إضافة مستخدم",
  verifiedAccountHolder: "هذا المستخدم تم التحقق منه",
  uploadLogo: "رفع الشعار",
  backtoProfile: "العودة إلي الملف الشخصي",
  submit: "إرسال",
  changeYourAccountPassword: "تغيير كلمة المرور الخاصة بحسابك",
  thankYouRegistration: "شكرا لتسجيلك",
  accountUnderReview:
    "حسابك قيد المراجعة برجاء تقديم المستندات المطلوبة لفريق الدعم",
  contactSupport: "التواصل مع فرق الدعم",
  logintoyourAccount: "تسجيل الدخول إلي حسابك",
  pointPrice: "السعر بالنقاط",
  settings: "الاعدادات",
  pending: "معلق",
  assigndelivery: "تعيين مندوب",
  selectproduct: "اختار المنتج",
  selectemployee: "اختار الموظف",
  pickup: "من الفرع",
  delivery: "التوصيل",
  product: "المنتج",
  availableDeliveries: "التوصيلات المتاحه",
  updatemployee: "تحديث الموظف",
  deliveryemployees: "موظفين التوصيل",
  deliveryEmployeeDetails: "تفاصيل موظف التوصيل",
  adddeliveryemployee: "اضافة موظف توصيل",
  updatedeliverymployee: "تحديث موظف توصيل",
  initialSettlements: "التسويات الاوليه",
  noInitialTransactions: "لا توجد تسويات أولية",
  noPendingTransactions: "لا توجد تسويات معلقة",
  branchOutgoingPoints: "النقاط الخارجه من الفرع",
  branchRevenue: "ارباح الفرع",
  loginheader: "تسجيل دخول",
  logindetails: "نحن لدينا بعض العروض",
  logindetails2: "فى انتظارك",
  registerheader: "انضم الينا",
  registerdetails: "سجل اكونت معنا ",
  registerdetails2: " و احصل على عروض حصرية",
  privancyandpolicy: "اوافق على الخصوصية و الاحكام ",
  offerRequests: "طلبات العروض",
  availableNumberOfOffers: "العدد المتاح من العر,ض",
};
