/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from "react";
import api from "../../services/api";
import { IonContent, IonPage } from "@ionic/react";
import { Link, useRouteMatch, Redirect } from "react-router-dom";
import HeaderContainer from "../../components/common/Header";
import FooterContainer from "../../components/common/Footer";
import { DebounceInput } from "react-debounce-input";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { toast, ToastContainer } from "react-toastify";

import "../../translations/i18n";
import ProductList from "../products/ProductList";
import OfferList from "../offers/OfferList";

const SearchPage: React.FC = () => {
  const { t } = useTranslation();
  const { path } = useRouteMatch();

  const token = useSelector<any>((state) => state.userState.token);

  const [requests, setRequests] = useState<any>([]);
  const [searchKey, setSearchKey] = useState("");

  const [, getOffers] = api.useAxios(
    { url: "/merchant/req/list", method: "post" },
    { manual: true }
  );

  useEffect(() => {
    getOffers({ data: { token } })
      .then((res) => {
        setRequests([...res.data.data.rows]);
      })
      .catch((err) => {
        toast(err?.response?.data.message);
      });
  }, [getOffers, token, searchKey]);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <IonPage>
        <HeaderContainer pageTitle="Search" />

        <IonContent fullscreen>
          <div
            className="tabs tabs-links mb-5 pb-5 theme-light"
            id="tab-group-6"
          >
            <div className="search-box ms-2 mb-2 d-inline-block"></div>

            <div className="card card-style">
              <div className="position-relative container mt-3">
                <DebounceInput
                  type="text"
                  style={{
                    borderRadius: `25px`,
                    border: `1px solid lightgray`,
                    width: `350px`,
                    padding: `10px`,
                    color: `black`,
                  }}
                  className="bg-white"
                  debounceTimeout={1000}
                  onChange={(e) => {
                    setSearchKey(e.target.value);
                  }}
                  placeholder="Search by phone Number.."
                />
              </div>
              <div className="content my-1 text-black">
                {requests.length !== 0 ? (
                  <div>
                    <div
                      className="collapse show"
                      id="tab-16"
                      data-bs-parent="#tab-group-6"
                    >
                      <div className="list-group list-custom list-group-m list-group-flush rounded-xs">
                        {requests?.map((request: any) => (
                          <Link
                            key={request.id}
                            to={`/request/${request.id}`}
                            className="list-group-item"
                          >
                            <img
                              src={
                                request?.images ||
                                "./assets/images/pictures/1s.jpg"
                              }
                              width={33}
                              className="me-3 rounded-xs"
                              alt="img"
                            />
                            <div>
                              <h5 className="font-15 mb-2">
                                {request?.Branch.branchName}
                              </h5>
                              <span className="font-12 mb-1">
                                {t("Get")} {request.offerDetail.points}{" "}
                                {t("point for only")}{" "}
                                {request.offerDetail.price}
                              </span>
                              <span>
                                {t("valid to")}{" "}
                                {new Date(
                                  request?.offerDetail.endDate
                                ).toDateString()}
                              </span>
                            </div>
                            {request.isActive ? (
                              <div>
                                <span className="badge rounded-xl">Active</span>
                              </div>
                            ) : (
                              <div>
                                <span className="badge rounded-xl bg-danger">
                                  Not Active
                                </span>
                              </div>
                            )}
                          </Link>
                        ))}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    className="alert custom-alert-3 alert-warning alert-dismissible fade show"
                    role="alert"
                  >
                    <div className="alert-text">
                      <h6 className="mb-3">
                        Sorry , you don't have any request in your account!
                      </h6>
                      <br />
                      {/* <Link
              to="/add-request"
              className="btn btn-sm btn-warning mt-2"
              href="#"
            >
              {t("Add request")}
            </Link> */}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </IonContent>
        <div
          id="searchProdSheet"
          className="offcanvas offcanvas-bottom offcanvas-detached  offcanvas-style "
          aria-modal="true"
          role="dialog"
        >
          <ProductList
            instock={"footerprod27"}
            outofstock={"outfooterprod27"}
          />
        </div>
        <div
          id="searchOfferSheet"
          className="offcanvas offcanvas-bottom offcanvas-detached  offcanvas-style "
          aria-modal="true"
          role="dialog"
        >
          <OfferList />
        </div>
        <FooterContainer
          offersheet={"searchOfferSheet"}
          productsheet={"searchProdSheet"}
        />
      </IonPage>
    </>
  );
};

export default SearchPage;
