/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useState } from "react";

import {
  IonContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonPage,
} from "@ionic/react";
import HeaderContainer from "../../components/common/Header";
import FooterContainer from "../../components/common/Footer";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import api from "../../services/api";
import LoadingContainer from "../../components/LoadingContainer";
import "../../translations/i18n";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";
import { NodataContainer } from "../../components/common/noDataContainer";

const pendingSettlementPage: React.FC = () => {
  const { t } = useTranslation();
  const token = useSelector<any>((state) => state.userState.token);
  const [page, setPage] = useState(1);
  const [InfiniteDisabled, setInfiniteDisabled] = useState<any>(false);
  const [pendingSettlements, setPendingSettlements] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  let limit = 10;
  const [, getPendingSettlements] = api.useAxios(
    { url: "/merchant/settlement/pending/list", method: "post" },
    { manual: true }
  );

  useEffect(() => {
    setLoading(true);
    getPendingSettlements({ data: { token, limit: 1000 } })
      .then((res) => {
        setPendingSettlements([...res.data.data]);
        setLoading(false);
      })
      .catch((err) => {
        toast(err?.response?.data.message);
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const loadData = async () => {
    setLoading(false);
    const nextPage = page + 1;
    setInfiniteDisabled(false);
    setTimeout(() => {
      getPendingSettlements({ data: { token, pageNum: nextPage, limit } }).then(
        (res: any) => {
          if (res?.data?.data.length === 0) {
            setInfiniteDisabled(true);
            return;
          }
          setPendingSettlements([...pendingSettlements, ...res.data.data]);
        }
      );
    }, 500);
  };
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <div className="tabs tabs-links  pb-5 pt-2 " id="tab-group-6">
        <div className="">
          <div className="">
            <div
              className="collapse show"
              id="tab-16"
              data-bs-parent="#tab-group-6"
            >
              <div className="content">
                {loading === true ? (
                  <LoadingContainer />
                ) : (
                  <div style={{ height: "32rem", overflow: "scroll" }}>
                    <h6 className="text-center branch-title m-4">
                      {" "}
                      {t("pendingSettlements")}
                    </h6>
                    <i
                      data-bs-dismiss="offcanvas"
                      className="bi bi-x main-clr font-26 line-height-xl close-offcanvas"
                    ></i>
                    <div className="px-3 pb-3">
                      {pendingSettlements.length !== 0 ? (
                        pendingSettlements.map((settlement: any) => (
                          <Link
                            className="row  align-items-center p-3 branch-list"
                            key={settlement.id}
                            to={`/settlement/${settlement.id}`}
                          >
                            <div className="d-flex align-items-center gap-3 col-9">
                              <i
                                className="has-bg rounded-xs bi bi-collection-fill"
                                style={{
                                  color: "rgb(86, 238, 168)",
                                  backgroundColor: "rgb(9, 44, 76)",
                                }}
                              ></i>
                              <div>
                                <h5
                                  className="font-15 mb-1 text-capitalize mt-0"
                                  style={{
                                    color: "rgb(9, 44, 76)",
                                  }}
                                >
                                  {settlement?.user?.name}
                                </h5>
                                <span
                                  className="mt-0 main-clr text-capitalize"
                                  style={{
                                    opacity: "0.7",
                                    fontSize: "11px",
                                  }}
                                >
                                  <strong
                                    className="d-inline main-clr font-11"
                                    style={{
                                      opacity: "0.7",
                                    }}
                                  >
                                    {" "}
                                    {t("amount")} : {settlement?.amount}
                                    &nbsp;&nbsp;&nbsp;
                                  </strong>

                                  <br />
                                  <strong
                                    className="d-inline font-11 main-clr"
                                    style={{
                                      opacity: "0.7",
                                    }}
                                  >
                                    {" "}
                                    {t("phone")} : {settlement?.user?.mobile}
                                  </strong>
                                </span>
                              </div>
                            </div>

                            <div className="col-3 text-end">
                              <span
                                className="badge rounded-xl  py-2 px-3"
                                style={{
                                  backgroundColor: "rgb(9, 44, 76)",
                                }}
                              >
                                {settlement?.status}
                              </span>
                            </div>
                          </Link>
                        ))
                      ) : (
                        <NodataContainer />
                      )}
                      {/* <IonInfiniteScroll
                        onIonInfinite={(e) => {
                          loadData();
                          setTimeout(() => e.target.complete(), 500);
                        }}
                        disabled={InfiniteDisabled}
                        threshold="100px"
                      >
                        <IonInfiniteScrollContent
                          loadingText={"loading"}
                        ></IonInfiniteScrollContent>
                      </IonInfiniteScroll> */}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default pendingSettlementPage;
