import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import api from "../../services/api";
import { IonContent, IonPage } from "@ionic/react";
import FooterContainer from "../../components/common/Footer";
import ProductList from "../products/ProductList";
import OfferList from "../offers/OfferList";
import HeaderContainer from "../../components/common/Header";
import LoadingContainer from "../../components/LoadingContainer";

const SettlementDetails: React.FC = () => {
  const settlementId: any = useParams();
  const { t } = useTranslation();
  const token: any = useSelector<any>((state) => state.userState.token);
  const [loading, setLoading] = useState(false);
  const [settlementDetails, setSettlementDetails] = useState<any>();

  const [, settlementDetailsApi] = api.useAxios(
    { url: "merchant/settlement/details", method: "post" },
    { manual: true }
  );

  useEffect(() => {
    setLoading(true);
    settlementDetailsApi({ data: { token, id: settlementId.id } })
      .then((res: any) => {
        setSettlementDetails(res.data.data);
        setLoading(false);
      })
      .catch((err: any) => {
        toast(err?.response?.data.message);
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settlementId.id, token]);

  return (
    <IonPage>
      {loading ? (
        <LoadingContainer />
      ) : (
        <>
          <IonContent fullscreen>
            <HeaderContainer pageTitle={t("settlementDetails")} />
            <div className="content invoivces-details">
              <h3 className="text-center mt-3 py-3 ">
                {settlementDetails?.productRequest.Product?.name}
              </h3>
              <div className="card  invoivces-details-card">
                <div className="">
                  <div
                    className="d-flex pb-2"
                    style={{
                      borderBottom: "1px dashed #211CA0",
                    }}
                  >
                    <div className="mt-1">
                      <h1 className="mb-2 branch-name">
                        {settlementDetails?.productRequest.Product?.name}
                      </h1>
                      <h5 className="det m-0">
                        {settlementDetails?.productRequest.Product?.description}
                      </h5>
                    </div>
                  </div>

                  <div
                    className="d-flex justify-content-between my-5 pb-2"
                    style={{
                      borderBottom: "1px dashed #211CA0",
                    }}
                  >
                    <div className=" d-flex align-items-center gap-2  flex-column">
                      {" "}
                      <h5 className=" main m-0">{t("points")}</h5>
                      <h5 className=" det m-0">
                        {" "}
                        {settlementDetails?.productRequest.Product?.points}
                      </h5>
                    </div>
                  </div>

                  <div className="d-flex mb-3 justify-content-between align-items-center">
                    <div className="d-flex flex-column align-items-center gap-2 ">
                      <div>
                        <h3 className=" main m-0">{t("price")}</h3>
                      </div>
                      <div className=" price">
                        <h3 className="m-0 ">
                          {" "}
                          ${
                            settlementDetails?.productRequest.Product?.price
                          }{" "}
                        </h3>
                      </div>
                    </div>
                    <div>
                      <i className="bi bi-upc text-dark font-22"></i>
                      <i className="bi bi-upc text-dark font-22"></i>
                      <i className="bi bi-upc text-dark font-22"></i>
                    </div>
                  </div>
                  <div className=" d-flex justify-content-center align-items-center gap-2 text-center  flex-column">
                    {" "}
                    <h5 className=" branch-name m-0">
                      {settlementDetails?.settlementDetails[0].user?.name}
                      <br />
                      {settlementDetails?.settlementDetails[0].user?.mobile}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </IonContent>
          <FooterContainer
            offersheet={"settlementOfferSheet"}
            productsheet={"settllementProdSheet"}
          />
          <div
            id="settllementProdSheet"
            className="offcanvas offcanvas-bottom offcanvas-detached  offcanvas-style "
            aria-modal="true"
            role="dialog"
          >
            <ProductList
              instock={"footerprod28"}
              outofstock={"outfooterprod28"}
            />
          </div>
          <div
            id="settlementOfferSheet"
            className="offcanvas offcanvas-bottom offcanvas-detached  offcanvas-style "
            aria-modal="true"
            role="dialog"
          >
            <OfferList />
          </div>
        </>
      )}
    </IonPage>
  );
};
export default SettlementDetails;
