import { IonContent, IonPage } from "@ionic/react";
import AboutContainer from "../../components/AboutContainer";
import HeaderContainer from "../../components/common/Header";
import FooterContainer from "../../components/common/Footer";
import ProductList from "../products/ProductList";
import OfferList from "../offers/OfferList";

const AboutPage: React.FC = () => {
  return (
    <IonPage>
      <HeaderContainer pageTitle="about" />
      <IonContent fullscreen>
        <AboutContainer />
      </IonContent>
      <FooterContainer
        offersheet={"repOfferSheet"}
        productsheet={"repProdSheet"}
      />
      <div
        id="repProdSheet"
        className="offcanvas offcanvas-bottom offcanvas-detached  offcanvas-style "
        aria-modal="true"
        role="dialog"
      >
        <ProductList instock={"footerprod6"} outofstock={"outfooterprod6"} />
      </div>
      <div
        id="repOfferSheet"
        className="offcanvas offcanvas-bottom offcanvas-detached  offcanvas-style "
        aria-modal="true"
        role="dialog"
      >
        <OfferList />
      </div>
    </IonPage>
  );
};

export default AboutPage;
