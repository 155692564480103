import {
  LOGIN_USER,
  LOGOUT_USER,
  UPDATE_USER,
  CREATE_USER,
  FORGET_PASSWORD_USER,
  CHANGE_PASSWORD_USER,
} from "../types";

const INITIAL_STATE = {
  user: null,
  token: null,
  type: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case LOGIN_USER:
      return {
        ...state,
        ...action.data,
      };
    case LOGOUT_USER:
      return { ...INITIAL_STATE };
    case UPDATE_USER:
      return { ...state, ...action.data };
    case CREATE_USER:
      return { ...state, ...action.data };
    case FORGET_PASSWORD_USER:
      return { ...state, ...action.data };
    case CHANGE_PASSWORD_USER:
      return { ...state, ...action.data };
    default:
      return state;
  }
};
