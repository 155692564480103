import { IonContent, IonPage } from "@ionic/react";
import { useParams } from "react-router";
import { useSelector } from "react-redux";

import { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import QRCode from "react-qr-code";
import api from "../../services/api";
import FooterContainer from "../../components/common/Footer";
import HeaderContainer from "../../components/common/Header";
import gps from "../../assets/images/gps.png";

import "../../translations/i18n";
import ProductList from "../products/ProductList";
import OfferList from "../offers/OfferList";
import LoadingContainer from "../../components/LoadingContainer";

const RequestPage: React.FC = () => {
  const { t } = useTranslation();
  const request = useParams<any>();
  const token = useSelector<any>((state) => state.userState.token);
  const role = localStorage.getItem("role");
  const IMAGEURL = process.env.REACT_APP_IMAGEURL;
  const [requestData, setRequestData] = useState<any>();
  const [loading, setLoading] = useState(false);
  console.log(requestData);
  const [, getRequestDetails] = api.useAxios(
    {
      url:
        role === "merchant"
          ? "/merchant/req/details"
          : "/merchantUser/req/details",
      method: "post",
    },
    { manual: true }
  );

  const [, postApproveRequest] = api.useAxios(
    {
      url:
        role === "merchant"
          ? "/merchant/req/approve"
          : "/merchantUser/req/approve",
      method: "post",
    },
    { manual: true }
  );

  useEffect(() => {
    setLoading(true);
    getRequestDetails({ data: { token, reqId: request.id } })
      .then((res) => {
        setRequestData(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        toast(err?.response?.data.message);
        setLoading(false);
      });
  }, [getRequestDetails, request.id, token]);

  const handleApproveRequest = () => {
    postApproveRequest({
      data: { token, reqId: request?.id },
    })
      .then(() => {
        window.location.reload();
      })
      .catch((err) => {
        toast(err?.response?.data.message);
      });
  };
  console.log(requestData);
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <IonPage>
        {loading ? (
          <LoadingContainer />
        ) : (
          <>
            <IonContent fullscreen>
              <HeaderContainer pageTitle={t("requestDetails")} />
              <div className="content mb-0 request____details____box request____det">
                <div className="card   border-0  align-items-center header_____box">
                  <div className="row px-1 py-2 w-100 align-items-center">
                    <div
                      className="col-6"
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <strong className="no-click title">
                        {requestData?.offerDetail?.title}
                      </strong>
                      <br />
                      <strong className=" no-click  address">
                        <img src={gps} alt="gps" className="me-2" />{" "}
                        {requestData?.Branch?.address}
                      </strong>
                      <br />
                      <strong className="no-click user">
                        {requestData?.user?.name}
                      </strong>
                      <strong className=" no-click   expired">
                        <br />
                        {t("Created At")}{" "}
                        {new Date(
                          requestData?.offerDetail?.createdAt
                        ).toDateString()}
                      </strong>
                      <strong className=" no-click   expired">
                        <br />
                        {t("expire")}{" "}
                        {new Date(
                          requestData?.offerDetail?.endDate
                        ).toDateString()}
                      </strong>
                    </div>
                    <div className="col-4">
                      <img
                        src={
                          requestData?.offerDetail.images?.length !== 0 &&
                          requestData?.offerDetail.images !== null
                            ? `${IMAGEURL}${requestData?.offerDetail.images[0]}`
                            : "./assets/images/pictures/1s.jpg"
                        }
                        width={50}
                        height={50}
                        className="me-3 rounded-xs"
                        alt="img"
                      />
                    </div>
                    <div className="col-2 bordered">
                      <button className="accept me-2">
                        <i className="bi bi-telephone-x"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center gap-4 mt-2">
                  <div className="col-5 text-center price ">
                    <strong> {requestData?.offerDetail.price}$</strong>
                  </div>
                  <div className="col-5 text-center points">
                    <strong>{requestData?.offerDetail.points}P</strong>
                  </div>
                </div>
                <div className="mt-3 text-center px-2">
                  <img
                    src={
                      requestData?.offerDetail.images?.length !== 0 &&
                      requestData?.offerDetail.images !== null
                        ? `${IMAGEURL}${requestData?.offerDetail.images[0]}`
                        : "./assets/images/pictures/1s.jpg"
                    }
                    width={50}
                    height={50}
                    className=" rounded-xs"
                    alt="img"
                  />
                  <p className="desc">
                    {" "}
                    {requestData?.offerDetail.description}
                  </p>
                </div>

                {!requestData?.isAproved ? (
                  <div className="text-center">
                    <button
                      onClick={handleApproveRequest}
                      className=" mb-3 btn  w-full main-btn shadow-bg shadow-bg-s mt-3"
                      style={{
                        width: `50%`,
                      }}
                    >
                      {t("approveRequest")}
                    </button>
                  </div>
                ) : (
                  <h5 className="text-success text-center">
                    {t("requestAlreadyApproved")}
                  </h5>
                )}

                <div className="card card-style pb-5 mb-5 mt-4">
                  <div className="content mb-0">
                    <div className="section m-auto text-center mt-3 full mb-2">
                      <QRCode value={request.id} />
                      <strong className="d-block no-click scan pt-2">
                        {t("scanNow")}
                      </strong>
                    </div>
                  </div>
                </div>
              </div>

              <div className="text-center content mb-5 pb-5">
                {requestData?.isAproved === false ? (
                  <button className=" mb-3 btn d-block w-100 reject-btn mt-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="#7A0223"
                      className="w-3 me-1"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                    Reject Request
                  </button>
                ) : (
                  ``
                )}
              </div>
            </IonContent>
            <FooterContainer
              offersheet={"requestDetOfferSheet"}
              productsheet={"requestDetProdSheet"}
            />
            <div
              id="requestDetProdSheet"
              className="offcanvas offcanvas-bottom offcanvas-detached  offcanvas-style "
              aria-modal="true"
              role="dialog"
            >
              <ProductList
                instock={"footerprod25"}
                outofstock={"outfooterprod25"}
              />
            </div>
            <div
              id="requestDetOfferSheet"
              className="offcanvas offcanvas-bottom offcanvas-detached  offcanvas-style "
              aria-modal="true"
              role="dialog"
            >
              <OfferList />
            </div>
          </>
        )}
      </IonPage>
    </>
  );
};

export default RequestPage;
