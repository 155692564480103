/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { ErrorMessage } from "@hookform/error-message";
import { AddProductSchema } from "../../../utils/validationSchema";
import { FormError } from "../../../components/form";
import { updateSpinnerStatus } from "../../../redux/actions";
import api from "../../../services/api";
import makeAnimated from "react-select/animated";
import { IonItem, IonList, IonSelect, IonSelectOption } from "@ionic/react";

const deliveryOptions = [
  {
    value: true,
    label: "Yes",
  },
  {
    value: false,
    label: "No",
  },
];

type Props = {
  productData: any;
};

export const UpdateProduct: React.FC<Props> = ({ productData }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const animatedComponents = makeAnimated();
  const dispatch = useDispatch();
  const token = useSelector<any>((state) => state.userState.token);

  const [tags, setTags] = useState<any>([]);
  const [selectedTag, setSelectedTag] = useState([]);
  const rule = localStorage.getItem("role");
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({});

  const [{ loading: updateProductLoading }, UpdateProduct] = api.useAxios(
    { url: "/merchant/product/update", method: "post" },
    { manual: true }
  );

  const [, getTags] = api.useAxios(
    { url: "/merchant/tag/list", method: "post" },
    { manual: true }
  );

  useEffect(() => {
    const limit = 1000;
    rule === "merchant" &&
      getTags({ data: { limit, token } })
        .then((res: any) => {
          const tagsBeforeFilter = res.data.data.rows;
          const filteredTagsToValueAndLabel = tagsBeforeFilter?.map(
            (item: any) => ({
              value: item.id,
              label: item.name,
            })
          );
          setTags(filteredTagsToValueAndLabel);
        })
        .catch((err: any) => {
          console.error(err);
        });
  }, [getTags, token]);

  const onSubmit = (data: any) => {
    data = {
      name: data.name || productData?.name,
      nameAr: data.nameAr || productData?.nameAr,
      price: parseInt(data.price) || productData?.price,
      stockAmount: data.stockAmount || productData?.stockAmount,
      description: data.description || productData?.description,
      descriptionAr: data.descriptionAr || productData?.descriptionAr,
      tagsIds: JSON.stringify(selectedTag.map((item: any) => item)),
      deliveryAvailability:
        data.deliveryAvailability || productData?.deliveryAvailability,
    };

    const finalData = { ...data, token, productId: productData?.id };
    UpdateProduct({ data: finalData })
      .then((res: any) => {
        reset();
        window.location.reload();
        toast.success("updated successfully");
      })
      .catch((err: any) => {
        toast(err?.response?.data.message);
      });
  };

  useEffect(() => {
    dispatch(updateSpinnerStatus({ updateProductLoading }));
  }, [updateProductLoading, dispatch]);

  const handleTagChange = (selectedOption: any) => {
    setSelectedTag(selectedOption);
    setValue("chooseTags", selectedOption);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="form-custom form-label form-icon mt-3"></div>
      <div className="pb-2" />

      <div className="form-custom form-label form-icon">
        <i className="bi bi-pencil-fill font-12 main-clr" />
        <input
          className="form-control main-clr rounded-xs"
          id="c7"
          type="text"
          placeholder={t("name")}
          {...register("name")}
          defaultValue={productData?.name}
        />
        <label
          htmlFor="c7"
          className="form-label-always-active main-clr font-11"
        >
          {t("name")}
        </label>
        <div className="mb-30">
          <ErrorMessage errors={errors} name="name" render={FormError} />
        </div>
      </div>
      <div className="pb-2" />

      <div className="form-custom form-label form-icon">
        <i className="bi bi-pencil-fill font-12 main-clr" />
        <input
          className="form-control main-clr rounded-xs"
          id="c7"
          type="text"
          placeholder={t("nameAr")}
          {...register("nameAr")}
          defaultValue={productData?.nameAr}
        />
        <label
          htmlFor="c7"
          className="form-label-always-active main-clr font-11"
        >
          {t("nameAr")}
        </label>
        <div className="mb-30">
          <ErrorMessage errors={errors} name="nameAr" render={FormError} />
        </div>
      </div>
      <div className="pb-2" />

      <div className="form-custom form-label form-icon">
        <input
          className="form-control main-clr rounded-xs"
          id="c32"
          type="text"
          placeholder={t("price")}
          {...register("price")}
          defaultValue={productData?.price}
        />
        <label
          htmlFor="c7"
          className="form-label-always-active main-clr font-11"
        >
          {t("price")}
        </label>

        <div className="mb-30">
          <ErrorMessage errors={errors} name="price" render={FormError} />
        </div>
      </div>
      <div className="pb-2" />

      {/* <div className="form-custom form-label form-icon">
                <i className="bi bi-pencil-fill font-12 main-clr" />
                <input
                  className="form-control main-clr rounded-xs"
                  id="c7"
                  type="text"
                  placeholder={t("Price")}
                  {...register("price")}
                  defaultValue={productData?.price}
                />
                <label
                  htmlFor="c7"
                  className="form-label-always-active main-clr font-11"
                >
                  {t("Price")}
                </label>
                <div className="mb-30">
                  <ErrorMessage
                    errors={errors}
                    name="price"
                    render={FormError}
                  />
                </div>
              </div>
              <div className="pb-2" /> */}

      <div className="form-custom form-label form-icon w-100 p-0 m-0 ">
        <IonList lines="none">
          <IonItem className="" style={{ height: "70px" }}>
            <IonSelect
              multiple={true}
              interface="alert"
              placeholder={t("selectTags")}
              className="form-select rounded-xs d-flex w-100 h-100"
              color=""
              onIonChange={(e: any) => handleTagChange(e.detail.value)}
            >
              {tags &&
                tags.map((tag: any) => (
                  <IonSelectOption value={tag?.value} key={tag?.value}>
                    {tag.label}
                  </IonSelectOption>
                ))}
            </IonSelect>
            <label
              className="form-label-always-active main-clr font-11 over-background"
              style={{
                top: "3px",
                zIndex: "999",
              }}
            >
              {t("chooseTags")}
            </label>
          </IonItem>
        </IonList>
      </div>
      <div className="pb-2" />

      <div className="form-custom form-label form-icon">
        <i className="bi bi-pencil-fill font-12 main-clr" />
        <input
          className="form-control main-clr rounded-xs"
          id="c7"
          type="text"
          placeholder={t("stockAmount")}
          {...register("stockAmount")}
          defaultValue={productData?.stockAmount}
        />
        <label
          htmlFor="c7"
          className="form-label-always-active main-clr font-11"
        >
          {t("stockAmount")}
        </label>
        <div className="mb-30">
          <ErrorMessage errors={errors} name="stockAmount" render={FormError} />
        </div>
      </div>
      <div className="pb-2" />
      <div className="pb-2" />

      <div className="form-custom form-label form-icon mb-3">
        <i className="bi bi-pencil-fill font-12 main-clr" />
        <textarea
          className="form-control main-clr rounded-xs"
          placeholder="English Description"
          id="c7"
          {...register("description")}
          defaultValue={productData?.description}
        />
        <label
          htmlFor="c7"
          className="form-label-always-active main-clr font-11"
        >
          {t("description")}
        </label>
        <div className="mb-30">
          <ErrorMessage errors={errors} name="description" render={FormError} />
        </div>
      </div>
      <div className="pb-2" />

      <div className="form-custom form-label form-icon mb-3">
        <i className="bi bi-pencil-fill font-12 main-clr" />
        <textarea
          className="form-control main-clr rounded-xs"
          placeholder={t("descriptionAr")}
          id="c7"
          {...register("descriptionAr")}
          defaultValue={productData?.descriptionAr}
        />
        <label
          htmlFor="c7"
          className="form-label-always-active main-clr font-11"
        >
          {t("descriptionAr")}
        </label>
        <div className="mb-30">
          <ErrorMessage
            errors={errors}
            name="descriptionAr"
            render={FormError}
          />
        </div>
      </div>
      <div className="pb-2" />

      {/* <div className="form-custom form-label form-icon mb-5">
          <Controller
            control={control}
            name="deliveryAvailability"
            render={({ field: { onChange } }) => (
              <Select
                options={deliveryOptions}
                getOptionValue={(option: any) => option.value}
                getOptionLabel={(option: any) => option.label}
                components={animatedComponents}
                styles={selectStyles}
                onChange={(option: any) => onChange(option.value)}
                placeholder="Select delivery availability"
              />
            )}
          />
          <label htmlFor="c2" className="color-highlight">
            Is available for delivery?
          </label>
          <span>(required)</span>
        </div> */}

      <button type="submit" className="btn btn-full main-btn w-100  mt-2 mb-3">
        {t("updateProduct")}
      </button>
    </form>
  );
};
