import { IonContent, IonPage } from '@ionic/react';
import IntroContainer from '../../components/common/IntroContainer';

const VerificationPage: React.FC = () => {
  return (
    <IonPage>
      <IonContent fullscreen>
        <IntroContainer />
      </IonContent>
    </IonPage>
  );
};

export default VerificationPage;
