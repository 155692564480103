import { IonContent, IonPage } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import FooterContainer from "../../components/common/Footer";
import HeaderContainer from "../../components/common/Header";
import "../../translations/i18n";
import api from "../../services/api";
import { useTranslation } from "react-i18next";
import LoadingContainer from "../../components/LoadingContainer";
import ProductList from "../products/ProductList";
import OfferList from "../offers/OfferList";

const InvoicesDetails: React.FC = () => {
  const { t } = useTranslation();
  const requestID = useParams<any>();
  const token = useSelector<any>((state) => state.userState.token);
  const [loading, setLoading] = useState(false);

  const [requestData, setrequestData] = useState<any>();

  const [, getRequestDetials] = api.useAxios(
    { url: "/merchant/package/req/details", method: "post" },
    { manual: true }
  );

  useEffect(() => {
    setLoading(true);
    getRequestDetials({ data: { token, id: requestID.id } })
      .then((res) => {
        setrequestData(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast(err?.response?.data.message);
      });
  }, [getRequestDetials, requestID.id, token]);

  const handleValidDate = (date: any) => {
    const date1 = moment.utc(new Date(date)).format("DD MMM Y");
    return date1;
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {loading ? (
        <LoadingContainer />
      ) : (
        <IonPage>
          <IonContent fullscreen>
            <HeaderContainer pageTitle={t("invoicesDetails")} />{" "}
            <div className="content invoivces-details">
              <h3 className="text-center mt-3 py-3 ">
                {requestData?.Package.name} {t("invoiceDetails")}
              </h3>
              <div className="card  invoivces-details-card">
                <div className="">
                  <div
                    className="d-flex pb-2"
                    style={{
                      borderBottom: "1px dashed #211CA0",
                    }}
                  >
                    <div className="mt-1">
                      <h1 className="mb-2 branch-name">
                        {requestData?.Package.name}
                      </h1>
                      <h5 className="det m-0">
                        {requestData?.Package.description}
                      </h5>
                    </div>
                  </div>

                  <div
                    className="d-flex justify-content-between my-5 pb-2"
                    style={{
                      borderBottom: "1px dashed #211CA0",
                    }}
                  >
                    <div className=" d-flex align-items-center gap-2  flex-column">
                      {" "}
                      <h5 className=" main m-0">{t("points")}</h5>
                      <h5 className=" det m-0">{requestData?.points}</h5>
                    </div>

                    <div className=" d-flex align-items-center gap-2  flex-column">
                      {" "}
                      <h5 className=" main m-0">{t("validTo")}</h5>
                      <h5 className=" det m-0">
                        {handleValidDate(requestData?.Package.validto)}
                      </h5>
                    </div>
                  </div>

                  <div className="d-flex mb-3 justify-content-between align-items-center">
                    <div className="d-flex flex-column align-items-center gap-2 ">
                      <div>
                        <h3 className=" main m-0">{t("total")}</h3>
                      </div>
                      <div className=" price">
                        <h3 className="m-0 ">{requestData?.amount}$ </h3>
                      </div>
                    </div>
                    <div>
                      <i className="bi bi-upc text-dark font-22"></i>
                      <i className="bi bi-upc text-dark font-22"></i>
                      <i className="bi bi-upc text-dark font-22"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              id="invoicesdetProduct"
              className="offcanvas offcanvas-bottom offcanvas-detached  offcanvas-style "
              aria-modal="true"
              role="dialog"
            >
              <ProductList
                instock={"footerprod15"}
                outofstock={"outfooterprod15"}
              />
            </div>
            <div
              id="invoicesdetOffer"
              className="offcanvas offcanvas-bottom offcanvas-detached  offcanvas-style "
              aria-modal="true"
              role="dialog"
            >
              <OfferList />
            </div>
            <FooterContainer
              offersheet={"invoicesdetOffer"}
              productsheet={"invoicesdetProduct"}
            />
          </IonContent>
        </IonPage>
      )}
    </>
  );
};

export default InvoicesDetails;
