import { useHistory } from "react-router-dom";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import "./../translations/i18n";
import loginm from "../assets/images/languages-m.svg";

const LanguageContainer = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const chooseLang = (lang: any) => {
    localStorage.setItem("language", lang);

    i18n.changeLanguage(lang);
  };

  const submit = () => {
    if (!localStorage.getItem("language")) history.push("/login");
    history.push("/setting");
  };

  return (
    <div className="section ">
      <div className="login-header">
        <img className="login-big-img" src={loginm} alt="lang" />
      </div>

      <div className="card card-style">
        <div className="content" style={{ color: `#092C4C` }}>
          <h4>{t("languages")}</h4>
          <p>Default Language is English.</p>
          <div className="list-group list-custom list-group-m rounded-xs bordered">
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="planChoose"
                onChange={() => chooseLang("ar")}
              />
              <label className="form-check-label ms-2">اللغة العربيه</label>
            </div>
            <div
              className="divider mt-4"
              style={{ backgroundColor: `#56eea8` }}
            ></div>
            <div className="form-check mb-0">
              <input
                className="form-check-input"
                type="radio"
                name="planChoose"
                onChange={() => chooseLang("en")}
              />
              <label className="form-check-label ms-2 ">English</label>
            </div>
            <div className="text-center pb-5">
              <button
                className="btn btn-full main-btn mt-4 mb-5"
                onClick={submit}
              >
                {t("chooselanguage")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LanguageContainer;
