/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { NodataContainer } from "../../../components/common/noDataContainer";
import person from "../../../assets/images/man-mainclr.png";

import "../../../translations/i18n";

type Props = {
  requests: [];
};

export const OfferRequests: React.FC<Props> = ({ requests }) => {
  const { t } = useTranslation();

  return (
    <>
      {requests?.length === 0 ? (
        <NodataContainer />
      ) : (
        <>
          <div className="collapse show">
            <div className="table-responsive">
              <div id="tab-5" data-bs-parent="#tab-group-2">
                {requests?.map((request: any) => (
                  <>
                    <Link
                      to={`/request/${request?.id}`}
                      className="d-flex justify-content-between align-items-center p-3 offer-det-list mt-2"
                    >
                      <div className="d-flex align-items-center gap-3">
                        <div className="align-self-center">
                          <img src={person} alt="icon" />
                        </div>
                        <div className="align-self-center ps-1">
                          <h5 className="pt-1 mt-0 mb-1 request_____name text-capitalize">
                            {request?.user.name}
                          </h5>
                          <strong className=" branches-address ">
                            {t("branch")}:{" "}
                            <span>{request?.Branch.branchName}</span>
                          </strong>
                          <br />
                          <strong className="request-code ">
                            {t("code")}: <span>{request?.code}</span>
                          </strong>
                        </div>
                      </div>
                      <div className="align-self-center ms-auto text-center">
                        <Link
                          to={`/request/${request?.id}`}
                          className="btn btn-xxs request-active "
                        >
                          {t("details")}
                        </Link>
                        <p
                          className={`${
                            request?.status === "pending"
                              ? "mb-0 mt-1 font-11 request-active"
                              : "mb-0 mt-1 font-11 text-danger"
                          }`}
                        >
                          {request?.status}
                        </p>
                      </div>
                    </Link>
                    <div className="divider my-2 opacity-50"></div>
                  </>
                ))}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
